import React from 'react';

import { useQuery } from '@apollo/client';
import LinearProgress from '@mui/material/LinearProgress';
import { matchPath, useLocation } from 'react-router-dom';

import { QUERY_SIMULATION_FROM_EXECUTION_ID } from 'client/app/api/gql/queries';
import { hasExecution } from 'client/app/apps/simulation-details/dataUtils';
import ExecutionDetails from 'client/app/apps/simulation-details/ExecutionDetails';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { isUUIDInfer } from 'common/lib/strings';

type ExecutionDetailsScreenRouteProps = {
  executionId: ExecutionId;
};

export default function ExecutionDetailsScreen() {
  const location = useLocation();
  const match = matchPath<ExecutionDetailsScreenRouteProps>(location.pathname, {
    path: executionRoutes.executionDetailsSubscreen.pathTemplate,
    exact: true,
  });

  const params = match?.params.executionId
    ? {
        variables: {
          id: match.params.executionId,
          withTasks: true,
          withVisualisations: true,
        },
      }
    : { skip: true };

  const { data, loading, error, refetch } = useQuery(QUERY_SIMULATION_FROM_EXECUTION_ID, {
    ...params,
  });

  if (!match?.params.executionId || !isUUIDInfer<ExecutionId>(match.params.executionId)) {
    return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
  }

  if (loading) {
    return <LinearProgress />;
  }
  if (error) {
    return <UIErrorBox>{error.message}</UIErrorBox>;
  }

  // Because we are querying for the simulation with the execution Id, the return object
  // is the simulation associated with that execution, which itself contains specific
  // info about the execution. Our downstream components currently require this execution to
  // be defined and with specific status, so we need this check here until a point
  // when we refactor.
  if (!data || !hasExecution(data.execution.simulation)) {
    return <UIErrorBox>Sorry we could not find this Execution</UIErrorBox>;
  }

  return <ExecutionDetails simulation={data.execution.simulation} onRefresh={refetch} />;
}
