import { Moment } from 'moment';

import { AnthaHubDeviceGUID } from 'common/types/device';

export type ExecutionID = string;
export type ExecutionTaskID = string;

/**
 * Status of a task once it has been scheduled.
 * See the diagram:
 * https://paper.dropbox.com/doc/Execution-Task-status--A1gWL14ERwTDqbc_O51pfcOnAg-2hTIMC8xbd32tistIXBBg
 */
export enum ExecutionTaskStatus {
  /**
   * The task is being sent, or has been delivered to AnthaHub.
   */
  QUEUED = 'queued',
  /**
   * The user manually started the task on AnthaHub, or it is being started by the auto-start
   * algorithm. The task will start running on the device soon.
   */
  PENDING = 'pending',
  /**
   * The task is running on AnthaHub. The payload has been sent to the device.
   */
  RUNNING = 'running',
  /**
   * The cancellation happened. The native Windows driver process in AnthaHub was killed.
   */
  CANCELLED = 'cancelled',
  /**
   * The task has completed successfully on AnthaHub.
   */
  SUCCEEDED = 'succeeded',
  /**
   * The task has failed on AnthaHub.
   */
  FAILED = 'failed',
  /**
   * The task has been skipped by the user. Only applicable if auto-run tasks is toggled on
   */
  SKIPPED = 'skipped',
  /**
   * AnthaHub failed to reconnect to a running task. From the user's perspective,
   * this is the same as `FAILED`. The task will stay in this state until manually retried.
   */
  RESUME_FAILED = 'resume-failed',
}

/**
 * A terminal status marks the end of processing an execution task. Once a task
 * is in one of these states, it's "finished" and can no longer change status.
 */
export function isTerminalExecutionTaskStatus(status: ExecutionTaskStatus) {
  switch (status) {
    case ExecutionTaskStatus.CANCELLED:
    case ExecutionTaskStatus.FAILED:
    case ExecutionTaskStatus.SKIPPED:
    case ExecutionTaskStatus.SUCCEEDED:
      return true;
  }
  return false;
}

/**
 * A denormalised representation of ExecutionTask, to suit the needs of
 * AnthaHub. Notably, fields such as `dependsOn`, `orderNum` and
 * `anthaHubDeviceGuid` are derived from the ExecutionTask's associated
 * SimulationTask and presented inline in this type.
 */
export type AnthaHubExecutionTask = {
  id: ExecutionTaskID;
  anthaHubDeviceGuid: AnthaHubDeviceGUID;
  dependsOn: ExecutionTaskID[];
  driverMessage?: string;
  isInternal: boolean;
  name: string;
  orderNum: number;
  shouldRunAutomatically: boolean;
  status: ExecutionTaskStatus;
  // TODO: T4028. lastModifiedAt is only shown in antha-com (not anthahub) so once we update to show
  // this in anthahub it won't need to remain undefined.
  lastModifiedAt?: Moment;
};

/**
 * A denormalised representation of Execution, to suit the needs of AnthaHub.
 * Notably, fields such as `simulationName` and `userDisplayName` are derived
 * from data associated with the Execution, and presented inline in this type.
 */
export type AnthaHubExecution = {
  id: ExecutionID;
  executionCreatedAt: Moment;
  simulationName: string;
  tasks: AnthaHubExecutionTask[];
  userDisplayName: string;
  isLocal?: boolean;
};
