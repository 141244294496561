import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import { v4 as uuid } from 'uuid';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { calculateCombinatorialLayouts } from 'client/app/components/Parameters/PlateLayout/lib/combinatorialWellGenerationUtils';
import {
  reorderWellCoord,
  WellAvailability,
} from 'client/app/components/Parameters/PlateLayout/lib/WellAvailability';
import {
  addWellsToWellSet,
  getLiquidParametersForElement,
  LiquidParameters,
  removeWellsFromWellSet,
  useInputLiquidNamesAndGroups,
  useInputLiquids,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { Liquid, WellCoord } from 'common/types/bundle';
import {
  Measurement,
  VolumeOrConcentration,
  WellLocationOnDeckItem,
} from 'common/types/mix';
import {
  LiquidAssignment,
  LiquidSortMode,
  PlateAssignment as PlateAssignment,
  PlateAssignmentMode,
  PlateLayer,
  PlateNamesWithAssignment,
  SortOrder,
  WellSet,
  WellSortMode,
} from 'common/types/plateAssignments';
import { PlateType } from 'common/types/plateType';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';

// `plateType` is a required field, but initially unset within this editor.
export type PartialPlateAssigment = Omit<PlateAssignment, 'plateType'> & {
  plateType?: string;
};

type SidePanelTab = 'setup' | 'liquids';

type State = {
  plateName: string;
  plateAssignment: PartialPlateAssigment;
  isReadonly: boolean;
  plateType?: PlateType;
  focusedLayerId?: string;
  liquidColors: LiquidColors;
  selectedWells: readonly WellLocationOnDeckItem[];
  editingAvailability: boolean;
  wellAvailability: WellAvailability | undefined;
  setAvailableWells: (value: readonly WellLocationOnDeckItem[]) => void;
  setFocusedLayerId: (value: string) => void;
  toggleEditingAvailability: (value: boolean) => void;
  highlightedLiquidId: string | undefined;
  setPlateType: (value?: PlateParameterValue, resetPlateAssignment?: boolean) => void;
  setReplicates: (value: number) => void;
  setTotalVolume: (value?: Measurement) => void;
  setDiluentName: (value: string) => void;
  setPlateAssignmentMode: (
    value: PlateAssignmentMode,
    resetPlateAssignment?: boolean,
  ) => void;
  selectedLiquidOrLayerId: string | undefined;
  setSelectedLiquidOrLayerId: (id: string | undefined) => void;
  addNewLayer: () => void;
  setPlateLayerName: (id: string, newName: string) => void;
  setSelectedWells: (value: readonly WellLocationOnDeckItem[]) => void;
  addSelectedWellsToSelectedLiquid: () => void;
  removeSelectedWellsFromSelectedLiquid: () => void;
  setLiquidIdentifier: (id: string, newName: string, isPartOfGroup: boolean) => void;
  setLiquidVolumeOrConcentration: (
    id: string,
    volumeOrConcentration: VolumeOrConcentration,
  ) => void;
  activeSidePanelTab: SidePanelTab;
  setActiveSidePanelTab: (tab: SidePanelTab) => void;
  setWellSetSortMode: (id: string, sortBy: WellSortMode) => void;
  setLayerSortMode: (sortBy: WellSortMode) => void;
  setLiquidSortOrder: (wellSetId: string, sortOrder: SortOrder) => void;
  setLiquidSortSubComponent: (
    wellSetId: string,
    subComponentName: string | undefined,
  ) => void;
  addNewLiquidAssignment: () => void;
  deleteLayer: (layerId: string) => void;
  deleteLiquidAssignment: (liquidAssigmentId: string) => void;
  setHighlightedLiquidId: Dispatch<SetStateAction<string | undefined>>;
  combinatorialPlateLayouts: Map<string, PlateAssignment> | undefined;
  combinatorialPlateName: string | undefined;
  setCombinatorialPlateName: (name: string | undefined) => void;
  inputLiquids: Liquid[];
  isMixOnto: boolean;
  liquidParameters: LiquidParameters;
};

const PlateLayoutEditorContext = createContext<State>({} as any);

export default function PlateLayoutEditorContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const dispatch = useWorkflowBuilderDispatch();
  const elementInstance = useElementInstance();
  const parameters = useWorkflowBuilderSelector(state => state.parameters);
  const [plateTypes] = usePlatesByType();
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const [selectedWells, setSelectedWells] = useState<readonly WellLocationOnDeckItem[]>(
    [],
  );
  const [editingAvailability, setEditingAvailability] = useState(false);
  const [wellAvailability, setWellAvailability] = useState<
    WellAvailability | undefined
  >();
  const [highlightedLiquidId, setHighlightedLiquidId] = useState<string | undefined>();

  const { plateName, plateNameIndex, outputParam } = useWorkflowBuilderSelector(
    state => state.plateEditorPanelProps,
  );

  const isReadonly = useWorkflowBuilderSelector(state =>
    isWorkflowReadonly(state.editMode, state.source),
  );

  // `plateNameIndex` is set by `PlateNamesWithAssignmentParameter` when launching the editor
  // So we can use this as a check for whether we are performing a Mix Onto.
  const isMixOnto = plateNameIndex !== undefined;

  if (!elementInstance) {
    throw new Error('Plate layout editor was opened with no element selected.');
  }

  if (!plateName) {
    throw new Error('Plate layout editor was opened with no plate selected.');
  }

  if (!outputParam) {
    throw new Error('No output parameter was supplied.');
  }

  const [plateAssignment, paramName] = useMemo<[PartialPlateAssigment, string]>(() => {
    const paramValue = parameters[elementInstance.name]?.[outputParam] as
      | PlateNamesWithAssignment[]
      | Record<string, PlateAssignment>;

    const value = Array.isArray(paramValue)
      ? paramValue?.[plateNameIndex ?? 0]?.plateAssignment
      : paramValue?.[plateName];

    const sanitisedValue = value
      ? produce<PlateAssignment>(value, draft => {
          // The elements expect the layers to be passed in order if dispensing.
          // In the UI, we show them in reverse.
          draft.plateLayers = [...draft.plateLayers.reverse()];
        })
      : undefined;

    return [sanitisedValue ?? defaultPlateAssignment(), outputParam];
  }, [elementInstance.name, outputParam, parameters, plateName, plateNameIndex]);

  useEffect(() => {
    if (
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL &&
      plateAssignment.plateType &&
      plateTypes[plateAssignment.plateType]
    ) {
      if (!wellAvailability) {
        const plate = plateTypes[plateAssignment.plateType];
        const currentAvailableWells =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.wells;
        const sortMode =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.sortBy ?? WellSortMode.BY_ROW;
        const newWellAvailability = currentAvailableWells
          ? new WellAvailability(
              plateTypes[plateAssignment.plateType],
              sortMode,
            ).withAvailability(
              currentAvailableWells.map(well => ({
                col: well.x,
                row: well.y,
                deck_item_id: plate.id,
              })),
            )
          : new WellAvailability(plateTypes[plateAssignment.plateType], sortMode);
        setWellAvailability(newWellAvailability);
      }
    }
  }, [
    plateAssignment.assignmentMode,
    plateAssignment.plateLayers,
    plateAssignment.plateType,
    plateTypes,
    wellAvailability,
  ]);

  const liquidParameters = getLiquidParametersForElement(elementInstance);
  const {
    inputLiquids: [inputLiquids],
  } = useInputLiquids(liquidParameters.inputLiquids);
  const liquidNamesAndGroups = useInputLiquidNamesAndGroups(inputLiquids);

  const combinatorialPlateLayouts = useMemo(() => {
    if (
      !plateAssignment.plateType ||
      !plateTypes[plateAssignment.plateType] ||
      !liquidNamesAndGroups ||
      plateAssignment.assignmentMode !== PlateAssignmentMode.COMBINATORIAL
    ) {
      return undefined;
    }
    return calculateCombinatorialLayouts(
      plateAssignment,
      plateTypes[plateAssignment.plateType],
      liquidNamesAndGroups,
      plateName,
      wellAvailability,
    );
  }, [liquidNamesAndGroups, plateAssignment, plateName, plateTypes, wellAvailability]);

  const [combinatorialPlateName, setCombinatorialPlateName] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    if (
      (combinatorialPlateLayouts && !combinatorialPlateName) ||
      (combinatorialPlateName && !combinatorialPlateLayouts?.has(combinatorialPlateName))
    ) {
      setCombinatorialPlateName(combinatorialPlateLayouts?.keys().next().value);
    }
  }, [combinatorialPlateName, combinatorialPlateLayouts]);

  const [focusedLayerId, setFocusedLayerId] = useState(
    plateAssignment.plateLayers[0]?.id,
  );

  const updatePlateAssignment = useCallback(
    (update: (draft: WritableDraft<PartialPlateAssigment>) => void) => {
      const paramValue = parameters[elementInstance.name]?.[outputParam] as
        | PlateNamesWithAssignment[]
        | Record<string, PlateAssignment>;

      const handleUpdate = (draft: WritableDraft<PartialPlateAssigment>) => {
        update(draft);
        // The elements expect the layers to be passed in order if dispensing.
        // In the UI, we show them in reverse.
        draft.plateLayers = [...draft.plateLayers.reverse()];
      };

      const updated = produce(plateAssignment, handleUpdate);

      dispatch({
        type: 'updateParameter',
        payload: {
          instanceName: elementInstance?.name,
          parameterName: paramName,
          value: Array.isArray(paramValue)
            ? paramValue.map((item, index) =>
                index === plateNameIndex ? { ...item, plateAssignment: updated } : item,
              )
            : {
                ...paramValue,
                [plateName]: updated,
              },
        },
      });
    },
    [
      dispatch,
      elementInstance.name,
      outputParam,
      paramName,
      parameters,
      plateAssignment,
      plateName,
      plateNameIndex,
    ],
  );

  const setPlateType = useCallback(
    (value?: PlateParameterValue, resetPlateAssignment?: boolean) => {
      const plateType =
        value && typeof value === 'string' ? plateTypes[value] : undefined;

      const defaultValues = defaultPlateAssignment();

      updatePlateAssignment(draft => {
        if (resetPlateAssignment) {
          draft.diluentName = defaultValues.diluentName;
          draft.plateLayers = defaultValues.plateLayers;
          draft.replicates = defaultValues.replicates;
          draft.totalVolume = defaultValues.totalVolume;
        }
        draft.plateType = plateType?.type;
      });

      if (plateType && wellAvailability) {
        setWellAvailability(wellAvailability.withPlate(plateType));
      }
    },
    [wellAvailability, plateTypes, updatePlateAssignment],
  );

  const setPlateAssignmentMode = useCallback(
    (value: PlateAssignmentMode, resetPlateAssignment?: boolean) => {
      const defaultValues = defaultPlateAssignment();
      updatePlateAssignment(draft => {
        if (resetPlateAssignment) {
          draft.diluentName = defaultValues.diluentName;
          draft.plateLayers = defaultValues.plateLayers;
          draft.replicates = defaultValues.replicates;
          draft.totalVolume = defaultValues.totalVolume;
        }
        draft.assignmentMode = value;
      });
      setSelectedWells([]);
      setEditingAvailability(false);
    },
    [updatePlateAssignment],
  );

  const updatePlateLayer = useCallback(
    (id: string, value: Partial<Omit<PlateLayer, 'id'>>) => {
      updatePlateAssignment(draft => {
        const existingLayerIndex = draft.plateLayers.findIndex(layer => layer.id === id);
        draft.plateLayers[existingLayerIndex] = {
          ...draft.plateLayers[existingLayerIndex],
          ...value,
        };
      });
    },
    [updatePlateAssignment],
  );

  const setPlateLayerName = useCallback(
    (id: string, newName: string) => {
      updatePlateLayer(id, { name: newName });
    },
    [updatePlateLayer],
  );

  const addNewLayer = useCallback(() => {
    const newLayerID = uuid();
    updatePlateAssignment(draft => {
      draft.plateLayers.unshift({
        id: newLayerID,
        name: '',
        liquids: [],
        wellSets: [],
      });
    });
    setSelectedLiquidOrLayerId(newLayerID);
    setFocusedLayerId(newLayerID);
  }, [updatePlateAssignment]);

  const updateLiquidAssignment = useCallback(
    (id: string, value: Partial<Omit<LiquidAssignment, 'id'>>) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          const liquidIndex = layer.liquids.findIndex(liquid => liquid.wellSetID === id);
          if (liquidIndex > -1) {
            draft.plateLayers[layerIndex].liquids[liquidIndex] = {
              ...draft.plateLayers[layerIndex].liquids[liquidIndex],
              ...value,
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const updateWellSet = useCallback(
    (id: string, value: Partial<Omit<WellSet, 'id'>>) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          const wellSetIndex = layer.wellSets.findIndex(wellSet => wellSet.id === id);
          if (wellSetIndex > -1) {
            draft.plateLayers[layerIndex].wellSets[wellSetIndex] = {
              ...draft.plateLayers[layerIndex].wellSets[wellSetIndex],
              ...value,
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const setWellSetSortMode = useCallback(
    (id: string, sortMode: WellSortMode | undefined) => {
      updateWellSet(id, {
        sortBy: sortMode,
      });
    },
    [updateWellSet],
  );

  const setLayerSortMode = useCallback(
    (sortMode: WellSortMode | undefined) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach(layer => {
          layer.wellSets.forEach(wellSet => {
            wellSet.sortBy = sortMode;
            wellSet.wells = reorderWellCoord(wellSet.wells, sortMode);
          });
        });
        if (sortMode) {
          wellAvailability?.setSortMode(sortMode);
        }
      });
    },
    [updatePlateAssignment, wellAvailability],
  );
  const setLiquidSortOrder = useCallback(
    (wellSetId: string, sortOrder: SortOrder) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          layer.liquids.forEach((liquid, liquidIndex) => {
            if (liquid.wellSetID === wellSetId) {
              draft.plateLayers[layerIndex].liquids[liquidIndex] = {
                ...draft.plateLayers[layerIndex].liquids[liquidIndex],
                sortOrder: sortOrder,
                sortBy: LiquidSortMode.CONCENTRATION,
              };
            }
          });
        });
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidSortSubComponent = useCallback(
    (wellSetId: string, subComponentName: string | undefined) => {
      updatePlateAssignment(draft => {
        for (const layer of draft.plateLayers) {
          for (const liquid of layer.liquids) {
            if (liquid.wellSetID === wellSetId) {
              liquid.subComponentName = subComponentName;
              return;
            }
          }
        }
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidIdentifier = useCallback(
    (id: string, newName: string, isPartOfGroup: boolean) => {
      updatePlateAssignment(draft => {
        draft.plateLayers.forEach((layer, layerIndex) => {
          // If user has updated the name to be a non-group, we should remove any
          // group-specific parameters from the well set, which is achieved here
          // both in the liquid (removing sort options) and wellset (removing sort
          // options).

          const liquidIndex = layer.liquids.findIndex(liquid => liquid.wellSetID === id);
          if (liquidIndex > -1) {
            draft.plateLayers[layerIndex].liquids[liquidIndex] = {
              ...draft.plateLayers[layerIndex].liquids[liquidIndex],
              ...(isPartOfGroup
                ? {
                    liquidGroup: newName,
                    liquidName: undefined,
                    sortBy:
                      draft.plateLayers[layerIndex].liquids[liquidIndex].sortBy ??
                      LiquidSortMode.CONCENTRATION,
                    sortOrder:
                      draft.plateLayers[layerIndex].liquids[liquidIndex].sortOrder ??
                      SortOrder.ASCENDING,
                  }
                : {
                    liquidName: newName,
                    liquidGroup: undefined,
                    sortBy: undefined,
                    sortOrder: undefined,
                  }),
            };
          }
          const wellSetIndex = layer.wellSets.findIndex(wellSet => wellSet.id === id);
          if (wellSetIndex > -1) {
            draft.plateLayers[layerIndex].wellSets[wellSetIndex] = {
              ...draft.plateLayers[layerIndex].wellSets[wellSetIndex],
              ...(!isPartOfGroup
                ? {
                    sortBy:
                      draft.plateLayers[layerIndex].wellSets[wellSetIndex].sortBy ??
                      undefined,
                  }
                : {
                    sortBy:
                      draft.plateLayers[layerIndex].wellSets[wellSetIndex].sortBy ??
                      WellSortMode.BY_ROW,
                  }),
            };
          }
        });
      });
    },
    [updatePlateAssignment],
  );

  const setLiquidVolumeOrConcentration = useCallback(
    (id: string, volumeOrConcentration: VolumeOrConcentration) => {
      updateLiquidAssignment(id, {
        target: volumeOrConcentration,
      });
    },
    [updateLiquidAssignment],
  );

  const [selectedLiquidOrLayerId, setSelectedLiquidOrLayerId] = useState<
    string | undefined
  >(undefined);

  const [activeSidePanelTab, setActiveSidePanelTab] = useState<SidePanelTab>(
    plateAssignment.plateType ? 'liquids' : 'setup',
  );

  const addNewLiquidAssignment = useCallback(() => {
    if (!focusedLayerId) {
      return;
    }
    const layer = plateAssignment.plateLayers.find(layer => layer.id === focusedLayerId);
    if (!layer) {
      return;
    }
    const sortBy =
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL
        ? layer.wellSets[0]?.sortBy ?? WellSortMode.BY_ROW
        : undefined;
    const newLiquidWellSetID = uuid();

    const wellsFromAvailability: WellCoord[] =
      wellAvailability?.available.map(well => ({
        x: well.col,
        y: well.row,
      })) ?? [];

    updatePlateAssignment(draft => {
      const layer = draft.plateLayers.find(layer => layer.id === focusedLayerId);

      if (layer) {
        layer.liquids.push({
          wellSetID: newLiquidWellSetID,
          liquidName: 'New Liquid',
          target: { concentration: { value: 1, unit: 'X' } },
        });

        layer.wellSets.push({
          id: newLiquidWellSetID,
          wells:
            plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL
              ? wellsFromAvailability
              : [],
          sortBy: sortBy,
        });

        addWellsToWellSet(layer, newLiquidWellSetID, selectedWells);
      }
    });

    setSelectedWells([]);
    setSelectedLiquidOrLayerId(newLiquidWellSetID);
  }, [
    focusedLayerId,
    plateAssignment.assignmentMode,
    plateAssignment.plateLayers,
    selectedWells,
    updatePlateAssignment,
    wellAvailability?.available,
  ]);

  const addSelectedWellsToSelectedLiquid = useCallback(() => {
    updatePlateAssignment(assignment => {
      if (selectedLiquidOrLayerId) {
        const layer = assignment.plateLayers.find(layer =>
          layer.wellSets.some(ws => ws.id === selectedLiquidOrLayerId),
        );

        if (layer) {
          addWellsToWellSet(layer, selectedLiquidOrLayerId, selectedWells);
        }
      }
    });

    setSelectedWells([]);
  }, [selectedLiquidOrLayerId, selectedWells, updatePlateAssignment]);

  const removeSelectedWellsFromSelectedLiquid = useCallback(() => {
    updatePlateAssignment(assignment => {
      const selectedWellSet = assignment.plateLayers
        .flatMap(layer => layer.wellSets)
        .find(wellSet => wellSet.id === selectedLiquidOrLayerId);

      if (selectedWellSet) {
        removeWellsFromWellSet(selectedWellSet, selectedWells);
      }

      setSelectedWells([]);
    });
  }, [selectedLiquidOrLayerId, selectedWells, updatePlateAssignment]);

  const deleteLayer = useCallback(
    (layerId: string) => {
      updatePlateAssignment(assignment => {
        assignment.plateLayers = assignment.plateLayers.filter(
          layer => layer.id !== layerId,
        );
      });

      if (focusedLayerId === layerId) {
        // If we delete this layer, and the user had an item selected
        // then we should pick the nearest layer and select that, to prevent
        // the user having to reselect layers. We will pick the nearest layer below
        // or above, if they are available.
        const layerIndex = plateAssignment.plateLayers.findIndex(
          layer => layer.id === layerId,
        );
        const nearestLayerId =
          plateAssignment.plateLayers[layerIndex - 1]?.id ??
          plateAssignment.plateLayers[layerIndex + 1]?.id ??
          undefined;
        setFocusedLayerId(nearestLayerId);
        setSelectedLiquidOrLayerId(undefined);
      }
    },
    [focusedLayerId, plateAssignment.plateLayers, updatePlateAssignment],
  );

  const deleteLiquidAssignment = useCallback(
    (liquidAssigmentId: string) => {
      updatePlateAssignment(assignment => {
        assignment.plateLayers.forEach(layer => {
          layer.liquids = layer.liquids.filter(
            liquid => liquid.wellSetID !== liquidAssigmentId,
          );
          layer.wellSets = layer.wellSets.filter(
            wellSet => wellSet.id !== liquidAssigmentId,
          );
        });
      });

      if (selectedLiquidOrLayerId === liquidAssigmentId) {
        setSelectedLiquidOrLayerId(undefined);
      }
    },
    [selectedLiquidOrLayerId, updatePlateAssignment],
  );

  const setAvailableWells = useCallback((value: readonly WellLocationOnDeckItem[]) => {
    setWellAvailability(current => current?.withAvailability(value));
  }, []);

  const toggleEditingAvailability = useCallback(
    (value: boolean) => {
      if (value && !wellAvailability && plateAssignment.plateType) {
        const plateType = plateTypes[plateAssignment.plateType];
        const sortMode =
          plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
            ?.sortBy ?? WellSortMode.BY_ROW;
        setWellAvailability(new WellAvailability(plateType, sortMode));
      }
      setEditingAvailability(value);

      if (!value && wellAvailability) {
        const wells = wellAvailability.available.map<WellCoord>(({ col, row }) => ({
          x: col,
          y: row,
        }));

        updatePlateAssignment(draft => {
          draft.plateLayers.forEach(layer => {
            layer.wellSets.forEach(ws => {
              ws.wells = wells;
            });
          });
        });
      }
    },
    [
      wellAvailability,
      plateAssignment.plateType,
      plateAssignment.plateLayers,
      plateTypes,
      updatePlateAssignment,
    ],
  );

  const setReplicates = useCallback(
    (value: number) => {
      updatePlateAssignment(draft => {
        draft.replicates = value;
      });
    },
    [updatePlateAssignment],
  );

  const setTotalVolume = useCallback(
    (value?: Measurement) => {
      updatePlateAssignment(draft => {
        draft.totalVolume = value;
      });
    },
    [updatePlateAssignment],
  );

  const setDiluentName = useCallback(
    (value?: string) => {
      updatePlateAssignment(draft => {
        draft.diluentName = value;
      });
    },
    [updatePlateAssignment],
  );

  const value = useMemo<State>(
    () => ({
      focusedLayerId: focusedLayerId,
      wellAvailability,
      editingAvailability,
      isReadonly,
      liquidColors,
      plateAssignment,
      plateName,
      plateType: plateAssignment.plateType
        ? plateTypes[plateAssignment.plateType]
        : undefined,
      selectedWells: selectedWells,
      highlightedLiquidId: highlightedLiquidId,
      setHighlightedLiquidId: setHighlightedLiquidId,
      setPlateType,
      setPlateAssignmentMode,
      selectedLiquidOrLayerId,
      setSelectedLiquidOrLayerId,
      addNewLayer,
      setPlateLayerName,
      setSelectedWells,
      setLiquidIdentifier,
      setLiquidVolumeOrConcentration,
      activeSidePanelTab,
      setActiveSidePanelTab,
      addNewLiquidAssignment,
      addSelectedWellsToSelectedLiquid,
      removeSelectedWellsFromSelectedLiquid,
      deleteLayer,
      deleteLiquidAssignment,
      setAvailableWells,
      toggleEditingAvailability,
      setWellSetSortMode,
      setLayerSortMode,
      setLiquidSortOrder,
      setFocusedLayerId: setFocusedLayerId,
      setReplicates,
      setTotalVolume,
      setDiluentName,
      combinatorialPlateLayouts,
      combinatorialPlateName,
      setCombinatorialPlateName,
      setLiquidSortSubComponent,
      inputLiquids,
      isMixOnto,
      liquidParameters,
    }),
    [
      focusedLayerId,
      wellAvailability,
      editingAvailability,
      isReadonly,
      liquidColors,
      plateAssignment,
      plateName,
      plateTypes,
      selectedWells,
      highlightedLiquidId,
      setPlateType,
      setPlateAssignmentMode,
      selectedLiquidOrLayerId,
      addNewLayer,
      setPlateLayerName,
      setLiquidIdentifier,
      setLiquidVolumeOrConcentration,
      activeSidePanelTab,
      addNewLiquidAssignment,
      addSelectedWellsToSelectedLiquid,
      removeSelectedWellsFromSelectedLiquid,
      deleteLayer,
      deleteLiquidAssignment,
      setAvailableWells,
      toggleEditingAvailability,
      setWellSetSortMode,
      setLayerSortMode,
      setLiquidSortOrder,
      setReplicates,
      setTotalVolume,
      setDiluentName,
      combinatorialPlateLayouts,
      combinatorialPlateName,
      setLiquidSortSubComponent,
      inputLiquids,
      isMixOnto,
      liquidParameters,
    ],
  );

  return (
    <PlateLayoutEditorContext.Provider value={value}>
      {children}
    </PlateLayoutEditorContext.Provider>
  );
}

export function usePlateLayoutEditorContext() {
  return useContext(PlateLayoutEditorContext);
}

export function defaultPlateAssignment(): PartialPlateAssigment {
  return {
    assignmentMode: PlateAssignmentMode.DESCRIPTIVE,
    replicates: 1,
    plateLayers: [
      {
        id: uuid(),
        liquids: [],
        name: '',
        wellSets: [],
      },
    ],
  };
}
