import React, { useCallback, useContext, useMemo } from 'react';

import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ScreenContext from 'client/app/components/AppRouter/ScreenContext';
import { useContentsOfLayer } from 'client/app/components/Parameters/PlateLayout/lib/useContentsOfLayer';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import WellSelector, {
  WellSelectionProps,
} from 'client/app/components/WellSelector/WellSelector';
import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import { PlateAssignmentMode } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellHighlightMode } from 'common/ui/components/simulation-details/mix/Well';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import Dropdown from 'common/ui/filaments/Dropdown';

export default function LayoutPlate() {
  const {
    plateType,
    plateAssignment,
    focusedLayerId,
    liquidColors,
    editingAvailability,
    combinatorialPlateLayouts,
    combinatorialPlateName,
  } = usePlateLayoutEditorContext();

  const activeLayerIndex = plateAssignment.plateLayers.findIndex(
    layer => layer.id === focusedLayerId,
  );

  const combinatorialLayers =
    plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL &&
    combinatorialPlateName
      ? combinatorialPlateLayouts?.get(combinatorialPlateName)?.plateLayers
      : undefined;

  const [contents, contentsBelow] = useContentsOfLayer(
    combinatorialLayers ?? plateAssignment.plateLayers,
    activeLayerIndex,
    liquidColors,
  );

  const plateState = useMemo<PlateState | undefined>(
    () =>
      plateType ? { ...makeWellSelector(plateType), contents, contentsBelow } : undefined,
    [contents, contentsBelow, plateType],
  );

  if (!plateState) {
    return (
      <NoPlateMessage>
        <Typography variant="h2">No plate type selected</Typography>
      </NoPlateMessage>
    );
  }

  return editingAvailability ? (
    <WellAvailabilityPlate plateState={plateState} />
  ) : (
    <LiquidAssignmentPlate plateState={plateState} activeLayerIndex={activeLayerIndex} />
  );
}

function WellAvailabilityPlate({ plateState }: { plateState: PlateState }) {
  const { screenId } = useContext(ScreenContext);
  const { liquidColors, wellAvailability, setAvailableWells } =
    usePlateLayoutEditorContext();

  const deckLayout = useMemo(() => getLayoutForWellSelector(plateState), [plateState]);

  const wellSelectionProps = useMemo<WellSelectionProps>(
    () => ({
      selectedWells: wellAvailability?.available ?? [],
      onSelectWells: value => {
        setAvailableWells(value);
      },
    }),
    [setAvailableWells, wellAvailability?.available],
  );

  return (
    <Wrapper>
      <WellSelector
        deckLayout={deckLayout}
        googleAnalyticsCategory={screenId ?? ''}
        liquidColors={liquidColors}
        plate={{ ...plateState, contents: {} }}
        highlightMode={WellHighlightMode.AVAILABILITY}
        wellSelectionProps={wellSelectionProps}
      />
    </Wrapper>
  );
}

function LiquidAssignmentPlate({
  plateState,
  activeLayerIndex,
}: {
  plateState: PlateState;
  activeLayerIndex: number;
}) {
  const { screenId } = useContext(ScreenContext);
  const {
    setSelectedWells,
    liquidColors,
    plateAssignment,
    selectedWells,
    selectedLiquidOrLayerId,
    wellAvailability,
    highlightedLiquidId,
    setHighlightedLiquidId,
    plateType,
    isReadonly,
  } = usePlateLayoutEditorContext();

  const unavailableWells = useMemo(
    () => wellAvailability?.unavailable,
    [wellAvailability],
  );

  const selectedWellSet = useMemo(
    () =>
      activeLayerIndex > -1
        ? plateAssignment.plateLayers[activeLayerIndex].wellSets.find(
            ws => ws.id === highlightedLiquidId ?? selectedLiquidOrLayerId,
          )
        : undefined,
    [
      activeLayerIndex,
      highlightedLiquidId,
      plateAssignment.plateLayers,
      selectedLiquidOrLayerId,
    ],
  );

  const getWellContentLabel = useCallback(
    (
      _well: string,
      _contents: WellContents | undefined,
      location: { row: number; col: number },
    ): WellLabelContent => {
      const selectedIndex = selectedWells.findIndex(
        well => well.col === location.col && well.row === location.row,
      );

      if (selectedIndex > -1) {
        return { heading: `${selectedIndex + 1}` };
      }

      if (selectedWellSet) {
        const selectedWellSetIndex = selectedWellSet.wells.findIndex(
          well => well.x === location.col && well.y === location.row,
        );

        if (selectedWellSetIndex > -1) {
          return { heading: `${selectedWellSetIndex + 1}` };
        }
      }

      return { heading: '' };
    },
    [selectedWellSet, selectedWells],
  );

  const focusedWells = useMemo<readonly WellLocationOnDeckItem[] | undefined>(() => {
    return selectedWellSet
      ? [
          ...selectedWellSet.wells.map(({ x, y }) => ({
            col: x,
            row: y,
            deck_item_id: plateType?.id ?? '',
          })),
          ...selectedWells,
        ]
      : undefined;
  }, [plateType?.id, selectedWellSet, selectedWells]);

  const deckLayout = useMemo(() => getLayoutForWellSelector(plateState), [plateState]);

  const wellSelectionProps = useMemo<WellSelectionProps>(
    () =>
      plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL
        ? {
            selectedWells: [],
            onSelectWells: () => {},
            disabledWells: unavailableWells,
          }
        : {
            selectedWells,
            onSelectWells: isReadonly ? () => {} : value => setSelectedWells(value),
          },
    [
      isReadonly,
      plateAssignment.assignmentMode,
      selectedWells,
      setSelectedWells,
      unavailableWells,
    ],
  );

  const onWellMouseEnter = useCallback(
    (loc: WellLocationOnDeckItem) => {
      if (selectedLiquidOrLayerId) {
        return;
      }

      const activeLayer = plateAssignment.plateLayers[activeLayerIndex];
      const wellSet = activeLayer?.wellSets.find(ws =>
        ws.wells.some(well => well.x === loc.col && well.y === loc.row),
      );

      if (wellSet) {
        setHighlightedLiquidId(wellSet.id);
      }
    },
    [
      activeLayerIndex,
      plateAssignment.plateLayers,
      selectedLiquidOrLayerId,
      setHighlightedLiquidId,
    ],
  );

  const onWellMouseLeave = useCallback(
    (loc: WellLocationOnDeckItem) => {
      if (selectedLiquidOrLayerId) {
        return;
      }

      const activeLayer = plateAssignment.plateLayers[activeLayerIndex];
      const wellSet = activeLayer?.wellSets.find(ws =>
        ws.wells.some(well => well.x === loc.col && well.y === loc.row),
      );

      if (wellSet) {
        setHighlightedLiquidId(current => (current === wellSet.id ? undefined : current));
      }
    },
    [
      activeLayerIndex,
      plateAssignment.plateLayers,
      selectedLiquidOrLayerId,
      setHighlightedLiquidId,
    ],
  );

  return (
    <Wrapper>
      <WellSelector
        deckLayout={deckLayout}
        googleAnalyticsCategory={screenId ?? ''}
        liquidColors={liquidColors}
        plate={plateState}
        wellSelectionProps={wellSelectionProps}
        getContentLabel={getWellContentLabel}
        showContentLabels
        onWellMouseEnter={onWellMouseEnter}
        onWellMouseLeave={onWellMouseLeave}
        focusedWells={focusedWells}
      />
    </Wrapper>
  );
}

type ActivePlateSelectorProps = {
  disabled?: boolean;
};

export function ActivePlateSelector(props: ActivePlateSelectorProps) {
  const { disabled } = props;
  const {
    plateAssignment,
    combinatorialPlateLayouts,
    combinatorialPlateName,
    setCombinatorialPlateName,
  } = usePlateLayoutEditorContext();

  if (!combinatorialPlateName) {
    return null;
  }

  const plateNames = combinatorialPlateLayouts
    ? [...combinatorialPlateLayouts.keys()]
    : [];

  const allOptions = plateNames.map(name => ({
    label: name,
    value: name,
  }));

  return (
    <StyledCollapse
      in={plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL}
    >
      <ActivePlateSelectorWrapper>
        <Typography variant="body2">{`${plateNames.length} plates`}</Typography>
        <Dropdown
          placeholder="Plate name"
          valueLabel={combinatorialPlateName}
          options={allOptions}
          onChange={setCombinatorialPlateName}
          isDisabled={disabled}
          isRequired
        />
      </ActivePlateSelectorWrapper>
    </StyledCollapse>
  );
}

const StyledCollapse = styled(Collapse)({
  gridArea: 'plateSelect',
});

const ActivePlateSelectorWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  marginTop: theme.spacing(6),
  gap: theme.spacing(4),
  '& .MuiFormControl-root': {
    maxWidth: '270px',
  },
}));

const Wrapper = styled('div')({
  gridArea: 'main',
});

const NoPlateMessage = styled('div')({
  display: 'grid',
  placeContent: 'center',
  background: Colors.GREY_10,
  color: Colors.TEXT_DISABLED,
  borderRadius: '4px',
});
