import { useCallback, useMemo } from 'react';

import { useApolloClient } from '@apollo/client';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { QUERY_SIMULATION } from 'client/app/api/gql/queries';
import { useCherryPickContext } from 'client/app/apps/cherry-picker/CherryPickContext';
import ExecutionEntityItem from 'client/app/components/nav/breadcrumbs/components/ExecutionEntityItem';
import ExperimentEntityItem from 'client/app/components/nav/breadcrumbs/components/ExperimentEntityItem';
import ExperimentsDropdown from 'client/app/components/nav/breadcrumbs/components/ExperimentsDropdown';
import ItemsDropdown from 'client/app/components/nav/breadcrumbs/components/ItemsDropdown';
import SimulationEntityItem from 'client/app/components/nav/breadcrumbs/components/SimulationEntityItem';
import VisEntityItem from 'client/app/components/nav/breadcrumbs/components/VisEntityItem';
import { NavNode, NavNodeSubItem } from 'client/app/components/nav/breadcrumbs/types';
import useAddEntityToExperiment from 'client/app/components/nav/breadcrumbs/useAddEntityToExperiment';
import useRenameEntity, {
  useRenameMethodVisapp,
} from 'client/app/components/nav/breadcrumbs/useRenameEntity';
import { BreadcrumbsEntityEnum, WorkTreeApplicationName } from 'client/app/gql';
import * as navigation from 'client/app/lib/nav/actions';
import { useWorkflowBuilderDispatch } from 'client/app/state/WorkflowBuilderStateContext';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import DataAnalysisIcon from 'common/ui/icons/DataAnalysisIcon';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { ExperimentReportIcon } from 'common/ui/icons/ExperimentReportIcon';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

const NO_ITEMS: NavNodeSubItem[] = [];

export default function useBreadcrumbsEntity({
  entity,
  items = NO_ITEMS,
  currentItemID,
}: NavNode) {
  const location = useLocation();
  const history = useHistory();

  const { navigate, getAbsoluteURL } = useNavigation();
  const navigateVisUrl = useCallback(
    (url: string) => history.push(url.replace(/^\/#/, '')),
    [history],
  );

  const experimentEntityManager = useAddEntityToExperiment();
  const makeRenameEntity = useRenameEntity();
  const renameEntityVisApp = useRenameMethodVisapp();
  const { renameWorkflowInBuilder, renameWorkflowInCherryPicker } = useRenameWorkflow();
  const renameSimulationInCache = useRenameSimulation();

  const currentSubItem = useMemo(
    () => (currentItemID ? items.find(item => item.id === currentItemID) : items[0]),
    [currentItemID, items],
  );

  return useMemo(() => {
    switch (entity) {
      case BreadcrumbsEntityEnum.WORK_TREE: {
        const match = matchPath<{ experimentId: string }>(
          location.pathname,
          navigation.workTreeRoutes.workTreeExperiment.pathTemplate,
        );
        const id = match?.params.experimentId as ExperimentId;
        return {
          id,
          title: 'Experiment',
          color: '#2094AD',
          level: 1,
          currentSubItem,

          EntityIcon: NewExperimentsIcon,
          EntityItem: ExperimentEntityItem,
          ItemsDropdown: ExperimentsDropdown,

          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.workTreeRoutes.workTreeExperiment, {
                experimentId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.workTreeRoutes.workTreeExperiment, {
              experimentId: entityItem.id,
            });
          },
          renameEntity: makeRenameEntity(id, entity),
        };
      }
      case BreadcrumbsEntityEnum.EXPERIMENT: {
        const match = matchPath<{ id: string }>(
          location.pathname,
          navigation.experimentsRoutes.detail.pathTemplate,
        );
        const id = match?.params.id as ExperimentId;
        return {
          id,
          title: 'Experiment',
          color: '#2094AD',
          level: 1,
          currentSubItem,

          EntityIcon: ExperimentReportIcon,
          EntityItem: ExperimentEntityItem,
          ItemsDropdown: ExperimentsDropdown,

          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.experimentsRoutes.detail, { id: entityItem.id }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.experimentsRoutes.detail, { id: entityItem.id });
          },
          renameEntity: makeRenameEntity(id, entity),
        };
      }
      case BreadcrumbsEntityEnum.WORKFLOW: {
        const match = matchPath<{ workflowId: string }>(
          location.pathname,
          navigation.workflowRoutes.openInWorkflowBuilder.pathTemplate,
        );
        const id = match?.params.workflowId as WorkflowId;
        return {
          id,
          title: 'Workflow',
          color: '#1773C9',
          level: 2,
          currentSubItem,

          EntityIcon: WorkflowIcon,

          async addToNewExperiment(experimentName: string): Promise<void> {
            const experimentId = await experimentEntityManager.createNewExperiment(
              experimentName,
            );
            if (experimentId) {
              await experimentEntityManager.addToExistingExperiment.addBuilderWorkflow(
                id,
                experimentId,
              );
            }
          },
          async addToExistingExperiment(experimentId: ExperimentId): Promise<void> {
            await experimentEntityManager.addToExistingExperiment.addBuilderWorkflow(
              id,
              experimentId,
            );
          },
          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.workflowRoutes.openInWorkflowBuilder, {
                workflowId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.workflowRoutes.openInWorkflowBuilder, {
              workflowId: entityItem.id,
            });
          },
          renameEntity: makeRenameEntity(id, entity, renameWorkflowInBuilder),
        };
      }
      case BreadcrumbsEntityEnum.CHERRY_PICKER: {
        const match = matchPath<{ workflowId: string }>(
          location.pathname,
          navigation.workflowRoutes.openInCherryPicker.pathTemplate,
        );
        const id = match?.params.workflowId as WorkflowId;
        return {
          id,
          title: 'Cherry Picker',
          color: '#1773C9',
          level: 2,
          currentSubItem,

          EntityIcon: CherryPickerIcon,

          async addToNewExperiment(experimentName: string): Promise<void> {
            const experimentId = await experimentEntityManager.createNewExperiment(
              experimentName,
            );
            if (experimentId) {
              await experimentEntityManager.addToExistingExperiment.addCherryPickerWorkflow(
                id,
                experimentId,
              );
            }
          },
          async addToExistingExperiment(experimentId: ExperimentId): Promise<void> {
            await experimentEntityManager.addToExistingExperiment.addCherryPickerWorkflow(
              id,
              experimentId,
            );
          },
          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.workflowRoutes.openInCherryPicker, {
                workflowId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.workflowRoutes.openInCherryPicker, {
              workflowId: entityItem.id,
            });
          },
          renameEntity: makeRenameEntity(id, entity, renameWorkflowInCherryPicker),
        };
      }
      case BreadcrumbsEntityEnum.FORM: {
        const match = matchPath<{ workflowId: string }>(
          location.pathname,
          navigation.workflowRoutes.editForm.pathTemplate,
        );
        const id = match?.params.workflowId as WorkflowId;
        return {
          id,
          title: 'Form',
          color: '#1773C9',
          level: 2,
          currentSubItem,

          EntityIcon: TemplateWorkflowIcon,

          async addToNewExperiment(experimentName: string): Promise<void> {
            const experimentId = await experimentEntityManager.createNewExperiment(
              experimentName,
            );
            if (experimentId) {
              await experimentEntityManager.addToExistingExperiment.addFormWorkflow(
                id,
                experimentId,
              );
            }
          },
          async addToExistingExperiment(experimentId: ExperimentId): Promise<void> {
            await experimentEntityManager.addToExistingExperiment.addFormWorkflow(
              id,
              experimentId,
            );
          },
          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.workflowRoutes.editForm, {
                workflowId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.workflowRoutes.editForm, { workflowId: entityItem.id });
          },
        };
      }
      case BreadcrumbsEntityEnum.SIMULATION: {
        const match = matchPath<{ simulationId: string }>(
          location.pathname,
          navigation.simulationRoutes.openInSimulationDetails.pathTemplate,
        );
        const id = match?.params.simulationId as SimulationId;
        return {
          id,
          title: 'Simulation',
          color: '#7554BC',
          level: 3,
          currentSubItem,

          EntityIcon: SimulationIcon,
          EntityItem: SimulationEntityItem,
          ItemsDropdown,

          async addToNewExperiment(experimentName: string): Promise<void> {
            const experimentId = await experimentEntityManager.createNewExperiment(
              experimentName,
            );
            if (experimentId) {
              await experimentEntityManager.addToExistingExperiment.addSimulation(
                id,
                experimentId,
              );
            }
          },
          async addToExistingExperiment(experimentId: ExperimentId): Promise<void> {
            await experimentEntityManager.addToExistingExperiment.addSimulation(
              id,
              experimentId,
            );
          },
          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.simulationRoutes.openInSimulationDetails, {
                simulationId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.simulationRoutes.openInSimulationDetails, {
              simulationId: entityItem.id,
            });
          },
          renameEntity: makeRenameEntity(id, entity, renameSimulationInCache(id)),
        };
      }
      case BreadcrumbsEntityEnum.EXECUTION: {
        const match = matchPath<{ executionId: string }>(
          location.pathname,
          navigation.executionRoutes.openInExecutionDetails.pathTemplate,
        );
        const id = match?.params.executionId as ExecutionId;
        return {
          id,
          title: 'Execution',
          color: '#CB9322',
          level: 4,
          currentSubItem,

          EntityIcon: ExecutionIcon,
          EntityItem: ExecutionEntityItem,
          ItemsDropdown,

          async addToNewExperiment(experimentName: string): Promise<void> {
            const experimentId = await experimentEntityManager.createNewExperiment(
              experimentName,
            );
            if (experimentId) {
              await experimentEntityManager.addToExistingExperiment.addExecution(
                id,
                experimentId,
              );
            }
          },
          async addToExistingExperiment(experimentId: ExperimentId): Promise<void> {
            await experimentEntityManager.addToExistingExperiment.addExecution(
              id,
              experimentId,
            );
          },
          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(
              getAbsoluteURL(navigation.executionRoutes.openInExecutionDetails, {
                executionId: entityItem.id,
              }),
              '_blank',
            );
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            navigate(navigation.executionRoutes.openInExecutionDetails, {
              executionId: entityItem.id,
            });
          },
        };
      }
      case BreadcrumbsEntityEnum.VIS: {
        const queryParams = new URLSearchParams(location.search);
        const methodId = queryParams.get('method_id') as MethodId;

        const title = '';
        let color = '#159A6A';
        let icon = DataSetIcon;
        let level = 5;

        if (currentSubItem?.appName === WorkTreeApplicationName.doe_analyse_responses) {
          color = '#B0AA09';
          icon = DataAnalysisIcon;
          level = 6;
        }

        return {
          id: methodId,
          title,
          color,
          level,
          currentSubItem,

          EntityIcon: icon,
          EntityItem: VisEntityItem,
          ItemsDropdown,

          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(window.location.origin + entityItem.url, '_blank');
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            if (entityItem.url) {
              navigateVisUrl(entityItem.url);
            } else {
              throw new Error(`No URL provided for visapp: method_id=${methodId}`);
            }
          },
          renameEntity: renameEntityVisApp,
        };
      }
      case BreadcrumbsEntityEnum.DATASET_DERIVATION: {
        const matchBioprocessing = matchPath<{
          experimentId: string;
          derivationId: string;
        }>(location.pathname, navigation.experimentsRoutes.bioprocess.pathTemplate);

        const id = matchBioprocessing?.params.derivationId as DatasetDerivationId;

        return {
          id,
          title: 'Bioprocessing',
          color: '#B0AA09',
          level: 2,
          currentSubItem,

          EntityIcon: DataAnalysisIcon,
          EntityItem: VisEntityItem,
          ItemsDropdown,

          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(window.location.origin + entityItem.url, '_blank');
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            if (entityItem.url) {
              navigateVisUrl(entityItem.url);
            } else {
              throw new Error(`No URL provided for Bioprocessing: ${entityItem.id}`);
            }
          },
        };
      }
      case BreadcrumbsEntityEnum.VISUALISATION: {
        const matchChromatographyResults = matchPath<{
          simulationID: string;
          visserverApp: string;
          id: string;
        }>(location.pathname, navigation.visualizationRoutes.base.pathTemplate);

        const id = matchChromatographyResults?.params.id as DataVisualisationId;

        return {
          id,
          title: 'Chromatography result',
          color: '#B0AA09',
          level: 5,
          currentSubItem,

          EntityIcon: DataAnalysisIcon,
          EntityItem: VisEntityItem,
          ItemsDropdown,

          openInNewTab(entityItem: NavNodeSubItem): void {
            window.open(window.location.origin + entityItem.url, '_blank');
          },
          navigateToEntity(entityItem: NavNodeSubItem): void {
            if (entityItem.url) {
              navigateVisUrl(entityItem.url);
            } else {
              throw new Error(`No URL provided for Visualisation: ${entityItem.id}`);
            }
          },
        };
      }
      default:
        throw new Error(`Unsupported navigation entity: ${entity}`);
    }
  }, [
    currentSubItem,
    entity,
    experimentEntityManager,
    getAbsoluteURL,
    location.pathname,
    location.search,
    makeRenameEntity,
    renameEntityVisApp,
    navigate,
    navigateVisUrl,
    renameSimulationInCache,
    renameWorkflowInBuilder,
    renameWorkflowInCherryPicker,
  ]);
}

function useRenameWorkflow() {
  const dispatch = useWorkflowBuilderDispatch();
  const renameWorkflowInBuilder = useCallback(
    (newName: string) => {
      dispatch({
        type: 'setWorkflowName',
        payload: newName,
      });
    },
    [dispatch],
  );

  const { setWorkflowName: renameWorkflowInCherryPicker } = useCherryPickContext();

  return {
    renameWorkflowInBuilder,
    renameWorkflowInCherryPicker,
  };
}

function useRenameSimulation() {
  const apollo = useApolloClient();

  return useCallback(
    (id: SimulationId) => (newName: string) => {
      apollo.cache.updateQuery(
        {
          query: QUERY_SIMULATION,
          variables: {
            id,
            withTasks: true,
            withVisualisations: true,
          },
        },
        data =>
          !data
            ? data
            : {
                ...data,
                simulation: {
                  ...data.simulation,
                  name: newName,
                },
              },
      );
    },
    [apollo.cache],
  );
}
