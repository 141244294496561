import React, { useCallback, useMemo, useRef } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import cx from 'classnames';

import { getArrayTypeFromAnthaType } from 'common/elementConfiguration/parameterUtils';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { ParameterEditorBaseProps } from 'common/ui/components/ParameterEditorBaseProps';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const ARBITRARY_LENGTH = -1;

type ItemProps = Omit<Props, 'onChange'> & {
  Component: React.ComponentType<any>;
  index: number;
  maxLength: number;
  onChange: (index: number, value: any) => void;
  onClear: (index: number) => void;
};

function ArrayItemEditor(props: ItemProps) {
  const classes = useStyles();
  const { Component, anthaType, index, value, maxLength, onClear, onChange } = props;
  let warning = null;
  if (maxLength !== ARBITRARY_LENGTH && index > maxLength) {
    warning = (
      <p>Number of items in list exceeds permitted array length for type {anthaType}</p>
    );
  }

  const handleClear = useCallback(() => {
    onClear(index);
  }, [index, onClear]);

  const handleChange = useMemo(() => onChange.bind(null, index), [index, onChange]);

  const {
    placeholder,
    type: editorType,
    additionalProps: editorProps,
  } = props.itemEditorProps ?? {};

  return (
    <div
      className={cx(classes.arrayValue, {
        // When disabled, always show the label. Otherwise hide it to show
        // the red X used to delete the value.
        [classes.hideLabelOnHover]: !props.isDisabled,
      })}
      key={'array-entry-' + index}
    >
      <div className={classes.arrayKey}>
        {!props.isDisabled && (
          <span className={classes.clearButton} onClick={handleClear}>
            <ClearIcon />
          </span>
        )}
        {/* Scientists (like many humans) prefer to count from 1.
            Hence, idx + 1. */}
        <span className={classes.arrayKeyLabel}>{index + 1}</span>
      </div>
      {warning}
      <Component
        anthaType={anthaType}
        value={value}
        onChange={handleChange}
        isDisabled={props.isDisabled}
        placeholder={placeholder}
        editorType={editorType ?? null}
        editorProps={editorProps ?? undefined}
        index={index}
      />
    </div>
  );
}

type Props = {
  anthaType: string;
  onChange: (value: any[] | undefined) => void;
  onItemDelete?: (index: number) => void;
  itemEditorProps?: ParameterEditorConfigurationSpec;
  overrideAddNewItemCopy?: string;
  component: React.ComponentType<any>;
} & ParameterEditorBaseProps<any[]>;

// This function extracts the integer from within an antha type declaration
// if one is provided.
//   e.g. [2]string  => 2
//
// If the array has no length (i.e. []string), it will return a flag
// value indicating that the UI should allow arbitrary length.
function getArrayLengthFromType(typeName: string): number {
  const match = typeName.match(/^\[(\d+)\]/);
  if (!match) {
    return ARBITRARY_LENGTH;
  }

  return parseInt(match[1], 10);
}

export default function ArrayEditor(props: Props) {
  const arrayLength = getArrayLengthFromType(props.anthaType);
  const anthaType = getArrayTypeFromAnthaType(props.anthaType);
  return (
    <ArrayEditorBase
      {...props}
      Component={props.component}
      arrayLength={arrayLength}
      anthaType={anthaType}
    />
  );
}

type PropsBase = {
  arrayLength: number;
  Component: React.ComponentType<any>;
} & Props;

function ArrayEditorBase(props: PropsBase) {
  const classes = useStyles();
  const listRef = useRef<HTMLInputElement | null>(null);

  const onItemChange = (idx: number, oneValue: any) => {
    const newValueList = [...(props.value ?? [])];
    newValueList.splice(idx, 1, oneValue);
    props.onChange(newValueList);
  };

  const pushNewValue = () => {
    const newValueList = Array.isArray(value) ? [...value] : [];
    newValueList.push(null);
    props.onChange(newValueList);

    // Autofocus the newly added input.
    setTimeout(() => {
      if (!listRef.current) {
        return;
      }
      const listItems = listRef.current.children;
      const lastItemIndex = listItems.length - (showAddMoreButton ? 2 : 1);
      const lastItem = listItems[lastItemIndex];
      lastItem.getElementsByTagName('input')[0]?.focus();
    }, 200);
  };

  const onClearClick = (idx: number) => {
    props.onItemDelete?.(idx);
    const newValueList = props.value ? [...props.value] : [];
    newValueList.splice(idx, 1);
    // Reset the value to undefined rather than [] to allow our rulesEditor to evaluate
    // an empty array correctly.
    const newValue = newValueList.length === 0 ? undefined : newValueList;
    props.onChange(newValue);
  };

  if (!props.value) {
    return null;
  }
  const { Component, arrayLength } = props;

  // Sadly, in the wild, we have some elements whose metadata configuration
  // is incorrect. Rather than crashing the app, let's be a bit defensive
  // here and drop any values we get that aren't an array. It's not ideal,
  // but at least it's not a crash.
  let { value } = props;
  let errorMessage: JSX.Element | null = null;
  if (!Array.isArray(value)) {
    errorMessage = (
      <div className={classes.error}>
        Ignored value <em>{JSON.stringify(value)}</em> because it is not an array
      </div>
    );
    value = [];
  }

  const children = value.map((val, index) => (
    <ArrayItemEditor
      component={props.component}
      key={index} // There's no stable ID and values can change so just use the index.
      value={val}
      index={index}
      anthaType={props.anthaType}
      maxLength={arrayLength}
      Component={Component}
      onChange={onItemChange}
      onClear={onClearClick}
      isDisabled={props.isDisabled}
      itemEditorProps={props.itemEditorProps}
    />
  ));

  if (arrayLength !== ARBITRARY_LENGTH && children.length !== arrayLength) {
    // Have to freeze the length of the array before we modify it below,
    // otherwise the numbers will be off by an increasingly larger and
    // larger amount.
    const len = children.length;
    for (let index = 0; index < arrayLength - len; index++) {
      children.push(
        <ArrayItemEditor
          component={props.component}
          key={index} // There's no stable ID and values can change so just use the index.
          value={undefined}
          index={len + index}
          anthaType={props.anthaType}
          maxLength={arrayLength - 1}
          Component={Component}
          onChange={onItemChange}
          onClear={onClearClick}
          isDisabled={props.isDisabled}
          itemEditorProps={props.itemEditorProps}
        />,
      );
    }
  }

  const showAddMoreButton = arrayLength === ARBITRARY_LENGTH && !props.isDisabled;

  return (
    <div ref={listRef}>
      {errorMessage}
      {children}
      {showAddMoreButton ? (
        <Button
          color="primary"
          variant="tertiary"
          startIcon={<AddIcon color="primary" fontSize="small" />}
          onClick={pushNewValue}
          className={classes.button}
          fullWidth
        >
          {props.overrideAddNewItemCopy ?? 'Add new item'}
        </Button>
      ) : (
        children.length === 0 && <span className={classes.emptyMessage}>None</span>
      )}
    </div>
  );
}

const useStyles = makeStylesHook({
  arrayValue: {
    marginBottom: '8px',
    paddingLeft: '30px',
    position: 'relative',

    '&:hover $clearButton': {
      display: 'block',
    },

    '&:first-of-type': {
      marginTop: '4px',
    },
  },
  button: {
    alignItems: 'stretch',
  },
  hideLabelOnHover: {
    '&:hover $arrayKeyLabel': {
      display: 'none',
    },
  },
  arrayKey: {
    borderRight: `1px ${Colors.GREY_30} solid`,
    color: Colors.GREY_40,
    cursor: 'pointer',
    fontSize: '11px',
    height: '100%',
    left: 0,
    position: 'absolute',
    textAlign: 'center',
    top: 0,
    width: '20px',
  },
  clearButton: {
    color: Colors.RED,
    display: 'none',
    left: '-7px',
    position: 'relative',
    top: 'calc(50% - 12px)',
  },
  arrayKeyLabel: {
    position: 'relative',
    top: 'calc(50% - 6px)',
  },
  error: {
    color: Colors.ERROR,
    fontSize: '11px',
  },
  emptyMessage: {
    color: Colors.GREY_40,
    fontStyle: 'italic',
  },
});
