import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Layer from 'client/app/components/Parameters/PlateLayout/Layer';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';

export default function LiquidLevels() {
  const { plateAssignment, addNewLayer, isReadonly } = usePlateLayoutEditorContext();

  const plateLayers = plateAssignment.plateLayers;

  return (
    <Wrapper>
      <StyledButton
        disabled={isReadonly}
        startIcon={<AddIcon />}
        variant="text"
        onClick={() => addNewLayer()}
      >
        New Layer
      </StyledButton>
      <InnerWrapper>
        {plateLayers.map(layer => (
          <Layer key={layer.id} layer={layer} />
        ))}
      </InnerWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div')({
  padding: '6px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
});

const InnerWrapper = styled('div')({
  overflowY: 'auto',
});

const StyledButton = styled(Button)({
  width: '100%',
  '& > svg': {
    fontSize: '16px',
  },
});
