import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import IconArrowLeft from '@mui/icons-material/ArrowLeft';
import IconArrowRight from '@mui/icons-material/ArrowRight';
import IconCollapse from '@mui/icons-material/ExpandLess';
import IconExpand from '@mui/icons-material/ExpandMore';
import IconFirstPage from '@mui/icons-material/FirstPage';
import IconLastPage from '@mui/icons-material/LastPage';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { formatDuration } from 'common/lib/format';
import { getPreviewTotalDuration, getStageNameForStep } from 'common/lib/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import { SIMULATION_DETAILS_PREVIEW_TAB_ID } from 'common/ui/AnalyticsConstants';
import { DeckItemState } from 'common/ui/components/simulation-details/mix/MixState';
import SimulationPlaybackControl from 'common/ui/components/simulation-details/SimulationPlaybackControl';
import StepSliderBar from 'common/ui/components/simulation-details/StepSliderBar';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { targetElementIsTextInput } from 'common/ui/lib/browser';
import Keys from 'common/ui/lib/keyboard';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

/**
 * A step that is considered particularly interesting and will be visually
 * highlighted on the slider.
 */
export type KeyPoint = {
  /**
   * A step that will be highlighted
   */
  step: number;
  /**
   * Type of key point.
   *
   * For RoboColumns workflows each transfer can have a 'stage' associated with it (such
   * as Elute, Load, Wash). A slider bar shows for each stage. Transfers for the stage are
   * marked as keypoints with kind 'stage.
   */
  kind: 'tipbox' | 'prompt' | 'transfer' | 'stage' | 'error';
};

type Stage = {
  name: string;
  keyPoints: KeyPoint[];
};

type SliderInteractionMethod = 'hotkey' | 'mouse';

export type Props = {
  /**
   * Current step determines the position of the slider button. If 0, the first
   * step has not been reached. 1 corresponds to first step (steps[0]).
   */
  currentStep: number;
  /**
   * Steps that are considered particularly interesting and will be visually
   * highlighted on the slider.
   */
  keyPoints: readonly KeyPoint[];
  /**
   * The estimated time the robot will spend to get to current step
   */
  timeElapsed: number;
  /**
   * All steps for the execution
   */
  steps: MixPreviewStep[];
  deckItems: readonly DeckItemState[];
  onStepChange: (step: number, playbackTime?: number) => void;
  /**
   * Show a play/pause button which will automatically step through the
   * simulation using each step's estimated time.
   */
  allowPlayback?: boolean;
};

export default function StepSlider({
  currentStep,
  keyPoints,
  timeElapsed,
  steps,
  deckItems,
  onStepChange,
  allowPlayback,
}: Props) {
  const classes = useStyles();
  const context = useContext(RouteHiddenContext);

  const [showStages, setShowStages] = useState<boolean>(false);

  const oneStepForward = useCallback(
    () => onStepChange(Math.min(steps.length, currentStep + 1)),
    [currentStep, steps, onStepChange],
  );

  const oneStepBack = useCallback(
    () => onStepChange(Math.max(0, currentStep - 1)),
    [currentStep, onStepChange],
  );

  const handleJumpToStart = useCallback(() => {
    logEvent('jump-to-start', SIMULATION_DETAILS_PREVIEW_TAB_ID);
    onStepChange(0);
  }, [onStepChange]);

  const handleJumpToEnd = useCallback(() => {
    logEvent('jump-to-end', SIMULATION_DETAILS_PREVIEW_TAB_ID);
    onStepChange(steps.length);
  }, [steps, onStepChange]);

  const goToNextKeyPoint = useCallback(() => {
    // Smallest step larger than current
    const nextStep = Math.min(
      ...keyPoints.filter(kp => kp.step > currentStep).map(kp => kp.step),
    );
    const nextKeyPoint = keyPoints.find(kp => kp.step === nextStep);
    if (nextKeyPoint) {
      onStepChange(nextKeyPoint.step);
    } else {
      handleJumpToEnd();
    }
  }, [currentStep, handleJumpToEnd, keyPoints, onStepChange]);

  const goToPreviousKeyPoint = useCallback(() => {
    // Largest step smaller than current
    const previousStep = Math.max(
      ...keyPoints.filter(kp => kp.step < currentStep).map(kp => kp.step),
    );
    const previousKeyPoint = keyPoints.find(kp => kp.step === previousStep);
    if (previousKeyPoint) {
      onStepChange(previousKeyPoint.step);
    } else {
      handleJumpToStart();
    }
  }, [currentStep, handleJumpToStart, keyPoints, onStepChange]);

  const stepForwardSmart = useCallback(
    (shiftKey: boolean, interactionType: SliderInteractionMethod) => {
      if (shiftKey) {
        logEvent('next-key-point', SIMULATION_DETAILS_PREVIEW_TAB_ID, interactionType);
        goToNextKeyPoint();
      } else {
        logEvent('step-forward', SIMULATION_DETAILS_PREVIEW_TAB_ID, interactionType);
        oneStepForward();
      }
    },
    [goToNextKeyPoint, oneStepForward],
  );

  const stepBackwardSmart = useCallback(
    (shiftKey: boolean, interactionType: SliderInteractionMethod) => {
      if (shiftKey) {
        logEvent(
          'previous-key-point',
          SIMULATION_DETAILS_PREVIEW_TAB_ID,
          interactionType,
        );
        goToPreviousKeyPoint();
      } else {
        logEvent('step-back', SIMULATION_DETAILS_PREVIEW_TAB_ID, interactionType);
        oneStepBack();
      }
    },
    [goToPreviousKeyPoint, oneStepBack],
  );

  const handleStepForwardClick = useCallback(
    (e: React.MouseEvent) => stepForwardSmart(e.shiftKey, 'mouse'),
    [stepForwardSmart],
  );

  const handleStepBackwardClick = useCallback(
    (e: React.MouseEvent) => stepBackwardSmart(e.shiftKey, 'mouse'),
    [stepBackwardSmart],
  );

  // keydown - holding the key repeats the action
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (context.hidden) {
        // view is hidden, do nothing
        return;
      }
      if (targetElementIsTextInput(e.target)) {
        // Moving cursor inside an input using keyboard arrows
        return;
      }
      if (!e.altKey && !e.ctrlKey && !e.metaKey) {
        if (e.key === Keys.ARROW_LEFT) {
          stepBackwardSmart(e.shiftKey, 'hotkey');
          e.preventDefault();
        }
        if (e.key === Keys.ARROW_RIGHT) {
          stepForwardSmart(e.shiftKey, 'hotkey');
          e.preventDefault();
        }
      }
    },
    [context.hidden, stepBackwardSmart, stepForwardSmart],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  let step: MixPreviewStep | undefined;
  // Skip the zeroth step, which is reserved for viewing the simulation before
  // any steps have completed.
  if (currentStep > 0) {
    // 1 corresponds to first step (steps[0]).
    step = steps[currentStep - 1];
  }

  // For some liquid handlers, we have a cumulative time estimate to get to this step.
  const duration = formatDuration(timeElapsed);
  // If available, compute the total time the execution will take
  const totalDuration = formatDuration(getPreviewTotalDuration(steps));

  // If this is a transfer, grab the stage name. The channel we pick the stage name from
  // is arbitrary because the planner does not currently support concurrent transfers for
  // different stages (e.g channel 1 transfer for a wash stage and channel 2 transfer for
  // an elute stage).
  const stageName = step?.kind === 'parallel_transfer' && getStageNameForStep(step);

  const stages = useMemo(() => {
    const stageDict = new Map<string, Stage>();

    for (let stepNumber = 0; stepNumber < steps.length; stepNumber++) {
      const step = steps[stepNumber];
      const stageName = step?.kind === 'parallel_transfer' && getStageNameForStep(step);

      if (!stageName) {
        continue;
      }

      let stage = stageDict.get(stageName);
      if (!stage) {
        stage = { name: stageName, keyPoints: [] };
        stageDict.set(stageName, stage);
      }
      stage.keyPoints.push({ kind: 'stage', step: stepNumber });
    }

    return [...stageDict.values()];
  }, [steps]);

  return (
    <div className={classes.sliderAndControls}>
      <div className={classes.sliderLeft}>
        <Tooltip title="Go to start">
          <span>
            <IconButton
              className={classes.button}
              disabled={currentStep <= 0}
              onClick={handleJumpToStart}
              size="large"
            >
              <IconFirstPage />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip
          title="Step back (left keyboard arrow).
        If holding Shift: Go to previous key point."
        >
          <span>
            <IconButton
              className={classes.button}
              disabled={currentStep <= 0}
              onClick={handleStepBackwardClick}
              size="large"
            >
              <IconArrowLeft />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <div className={classes.sliderContainer}>
        <StepSliderBar
          maxStep={steps.length}
          currentStep={currentStep}
          onStepChange={onStepChange}
          keyPoints={keyPoints}
        />
        {/*
        Show a label to describe the step.
        Don't display "Step 0" in the UI. It's obvious from the rendering
        of the slider that we are at the start.
        */}
        {step && (
          <div className={classes.sliderLabel}>
            <span className={classes.sliderMessage}>
              Step {currentStep}: {getStepLabel(step, deckItems)}{' '}
              {stageName && `(${stageName})`}
            </span>
            {/*
            Show the estimated duration and total execution duration if they are available
            */}
            {duration && (
              <span className={classes.sliderDuration}>
                {duration} {totalDuration && <>/ {totalDuration}</>}
              </span>
            )}
          </div>
        )}
      </div>
      <div className={classes.sliderRight}>
        <Tooltip
          title="Step forward (right keyboard arrow).
        If holding Shift: Go to next key point."
        >
          <span>
            <IconButton
              className={classes.button}
              disabled={currentStep >= steps.length}
              onClick={handleStepForwardClick}
              size="large"
            >
              <IconArrowRight />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Go to end">
          <span>
            <IconButton
              className={classes.button}
              disabled={currentStep >= steps.length}
              onClick={handleJumpToEnd}
              size="large"
            >
              <IconLastPage />
            </IconButton>
          </span>
        </Tooltip>
        {allowPlayback && (
          <SimulationPlaybackControl
            steps={steps}
            onStepChange={onStepChange}
            isPlaybackEnabled
          />
        )}
        {stages.length > 0 && (
          <Tooltip title="Toggle stages">
            <IconButton
              className={classes.button}
              onClick={() => setShowStages(currVal => !currVal)}
              size="large"
            >
              {showStages ? <IconCollapse /> : <IconExpand />}
            </IconButton>
          </Tooltip>
        )}
      </div>
      {showStages &&
        stages.map(stage => (
          <Fragment key={stage.name}>
            <div className={classes.sliderLeft}>
              <Typography variant="caption">{stage.name}</Typography>
            </div>
            <StepSliderBar
              maxStep={steps.length}
              currentStep={currentStep}
              onStepChange={onStepChange}
              keyPoints={stage.keyPoints}
            />
          </Fragment>
        ))}
    </div>
  );
}

// Plain english descriptions of each kind of step to be displayed above slider.
// We apply a type to ensure there is an entry for every kind.
function getStepLabel(step: MixPreviewStep, deckItems: readonly DeckItemState[]): string {
  switch (step.kind) {
    case 'tipbox_refresh':
      return 'Replace tipbox';
    case 'load':
      return 'Loading tips';
    case 'unload':
      return 'Unloading tips';
    case 'prompt':
      return 'Prompt';
    case 'error':
      return 'Error';
    // move_plate is due to be renamed because it can now be used for moving any deck
    // item, not just plates.
    case 'move_plate': {
      const itemKinds = step.effects.map(
        effect => deckItems.find(item => item.name === effect.plate_id)?.kind,
      );
      // There can be up to 2 effects, so we can join them with "and".
      let label = `Moving ${itemKinds.join(' and ')}`;
      if (step.gripper_name) {
        label += ` using ${step.gripper_name}`;
      }
      return label;
    }
    case 'parallel_transfer':
      return 'Transferring liquids';
    case 'parallel_dispense':
      return 'Dispensing liquids';
    case 'tip_wash':
      return 'Washing tips';
    case 'highlight':
      return step.title;
  }
}

const useStyles = makeStylesHook(theme => ({
  sliderAndControls: {
    alignItems: 'center',
    display: 'grid',
    marginBottom: theme.spacing(2),
    justifyContent: 'left',
  },
  sliderLeft: {
    gridColumn: 1,
    textAlign: 'right',
    marginRight: theme.spacing(3),
    display: 'flex',
  },
  sliderRight: {
    gridColumn: 3,
    marginLeft: theme.spacing(3),
    display: 'flex',
  },
  button: {
    // The default padding is very large
    padding: '4px',
  },
  sliderContainer: {
    position: 'relative',
    height: '32px',
  },
  sliderLabel: {
    position: 'absolute',
    left: '0px',
    top: '-13px',
    width: '100%',
    display: 'flex',
    // Label on the left, duration on the right
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  sliderDuration: {
    fontSize: '10px',
  },
  sliderMessage: {
    fontSize: '13px',
  },
}));
