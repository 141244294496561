import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useMutation } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography';

import {
  MUTATION_CREATE_LABWARE,
  MUTATION_DELETE_LABWARE,
  MUTATION_UPDATE_LABWARE,
} from 'client/app/api/gql/mutations';
import { QUERY_SIMULATION } from 'client/app/api/gql/queries';
import {
  generateMockSingleTransfer,
  MOCK_VOLUME,
} from 'client/app/apps/simulation-details/mockSimulationData';
import { SimulationWithExecution } from 'client/app/apps/simulation-details/overview/results/ExecutionResultsScreen';
import {
  createPlateMappingId,
  getMappedPlateForLabware,
} from 'client/app/apps/simulation-details/overview/results/plateMapperUtils';
import { CreateLabwareLocationContents, CreateLabwareSource } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { SBS_STANDARD_LENGTH, SBS_STANDARD_WIDTH } from 'common/lib/defaultPlate';
import doNothing from 'common/lib/doNothing';
import { formatWellPosition } from 'common/lib/format';
import { alphanumericCompare } from 'common/lib/strings';
import { mapObject } from 'common/object';
import createDummyPlate, {
  COMMON_PLATE_DIMENSIONS,
  CommonPlateSizes,
} from 'common/types/dummyPlates';
import { Deck, Plate, WellLocationOnDeckItem } from 'common/types/mix';
import Button from 'common/ui/components/Button';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IconButton from 'common/ui/components/IconButton';
import LiquidColors, {
  summaryOfWell,
} from 'common/ui/components/simulation-details/LiquidColors';
import computeSelectedWells from 'common/ui/components/simulation-details/mix/computeSelectedWells';
import { getFinalPlatesOnDeck } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout, {
  millimetersToPixels,
  pixelsToMillimeters,
} from 'common/ui/components/simulation-details/mix/DeckLayout';
import {
  isSameLocation,
  MixState,
} from 'common/ui/components/simulation-details/mix/MixState';
import MixView from 'common/ui/components/simulation-details/mix/MixView';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Dropdown, { Option } from 'common/ui/filaments/Dropdown';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog, { DialogProps } from 'common/ui/hooks/useDialog';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

const PLATE_SIZE = {
  x_mm: SBS_STANDARD_WIDTH,
  y_mm: SBS_STANDARD_LENGTH,
};
const PLATE_WIDTH_PX = millimetersToPixels(PLATE_SIZE.x_mm);
const PLATE_LENGTH_PX = millimetersToPixels(PLATE_SIZE.y_mm);
const SPACE_BETWEEN_PLATES_PX = 12;
// We need space above and below the plate so that the arrows don't get cut off
const TOP_MARGIN_PX = 40;
const BOTTOM_MARGIN_PX = 20;
// Source plate should be on the left
const SOURCE_PLATE_POS = { x_mm: 0, y_mm: 0, z_mm: 0 };
// Dest plate should be on the right
const DEST_PLATE_POS = {
  x_mm: PLATE_SIZE.x_mm + pixelsToMillimeters(SPACE_BETWEEN_PLATES_PX),
  y_mm: 0,
  z_mm: 0,
};
const SOURCE_DECK_POSITION_NAME = 'SOURCE';
const DEST_DECK_POSITION_NAME = 'DEST';

export const DEST_PLATE_ID = 'DESTINATION_PLATE';

export type Transfer = {
  id: string;
  src: WellLocationOnDeckItem;
  dest: WellLocationOnDeckItem;
  liquidName: string;
};

/**
 * Server representation of a transfer of liquid from one or more sources to a single
 * destination. Identical to autogenerated `CreateLabwareLocationContents` type except the
 * `sources` array is not readonly. We need to write to `sources` as we accumulate the
 * sources in `covertTransfersToContents`.
 */
type LabwareLocationContents = Omit<CreateLabwareLocationContents, 'sources'> & {
  sources: CreateLabwareSource[];
};

/**
 * Convert the transfers created in the UI to the data structure required by the server.
 * The key difference is the UI represents transfers as a single source to a single
 * destination, whereas the server represents transfers as one or more sources to a single
 * destination.
 */
function convertTransfersToContents(
  transfers: Transfer[],
  sourcePlates: Plate[],
): LabwareLocationContents[] {
  const contentsByDest = new Map<string, LabwareLocationContents>();

  const platesById: Record<string, Plate> = {};
  for (const plate of sourcePlates) {
    platesById[plate.id] = plate;
  }

  // Iterating through each transfer and accumulate the sources for each destination.
  for (const { src, dest } of transfers) {
    const destWellStr = formatWellPosition(dest);
    const plate = platesById[src.deck_item_id];
    if (!plate) {
      // Shouldn't be possible unless the source plates array somehow changed since the
      // user launched the dialog
      continue;
    }
    let contentsInDest = contentsByDest.get(destWellStr);
    if (!contentsInDest) {
      contentsInDest = {
        location: { column: dest.col, row: dest.row },
        sources: [],
      };
      contentsByDest.set(destWellStr, contentsInDest);
    }
    contentsInDest.sources.push({
      labwareName: plate.name,
      location: { column: src.col, row: src.row },
    });
  }

  return [...contentsByDest.values()];
}

type CreatePlateMapperButtonProps = {
  simulation: SimulationWithExecution;
  /**
   * We need the contents of plates, which come from layout.json and not available via the
   * execution.
   */
  deck: Deck;
};

/**
 * Launches the Plate Mapper in a dialog to let the user define a new plate they
 * manually created by cherry-picking from plates out of an execution.
 */
export function CreateMappedPlateButton({
  deck,
  simulation,
}: CreatePlateMapperButtonProps) {
  const [plateMapperDialog, openPlateMapperDialog] = useDialog(PlateMapperDialog);

  const handleClick = useCallback(async () => {
    // The new plate name will be checked against this list of labware names already in use.
    const usedLabwareNames = simulation.execution.labwareHistory.map(plate => plate.name);
    const sourcePlates = getFinalPlatesOnDeck(deck);
    await openPlateMapperDialog({ simulation, sourcePlates, usedLabwareNames });
  }, [deck, openPlateMapperDialog, simulation]);

  if (!useFeatureToggle('PLATE_MAPPER')) {
    return null;
  }

  return (
    <>
      <IconButton icon={<AddIcon />} onClick={handleClick} size="large" />
      {plateMapperDialog}
    </>
  );
}

type EditMappedPlateButtonProps = Pick<
  CreatePlateMapperButtonProps,
  'deck' | 'simulation'
> & {
  /**
   * The labware ID of the plate to edit
   */
  labwareId: LabwareId;
};

export function EditMappedPlateButton({
  simulation,
  deck,
  labwareId,
}: EditMappedPlateButtonProps) {
  const [plateMapperDialog, openPlateMapperDialog] = useDialog(PlateMapperDialog);

  const handleClick = useCallback(async () => {
    // Get list of names a plate created in Plate Mapper should not use
    const usedLabwareNames = simulation.execution.labwareHistory
      .filter(plate => plate.labware.id !== labwareId)
      .map(plate => plate.name);
    const sourcePlates = getFinalPlatesOnDeck(deck);
    const plate = getMappedPlateForLabware(labwareId, sourcePlates, simulation.execution);

    await openPlateMapperDialog({
      simulation,
      sourcePlates,
      usedLabwareNames,
      labwareId,
      plate,
    });
  }, [deck, labwareId, openPlateMapperDialog, simulation]);

  if (!useFeatureToggle('PLATE_MAPPER')) {
    return null;
  }

  return (
    <>
      <Button variant="secondary" onClick={handleClick}>
        Edit Layout
      </Button>
      {plateMapperDialog}
    </>
  );
}

type DeleteMappedPlateButtonProps = {
  /**
   * The labware ID of the plate to delete
   */
  labwareId: LabwareId;
  plateName: string;
  simulationId: SimulationId;
};

export function DeleteMappedPlateButton({
  labwareId,
  plateName,
  simulationId,
}: DeleteMappedPlateButtonProps) {
  const [confirmationDialog, openConfirmationDialog] = useDialog(ConfirmationDialog);
  const [deleteLabware] = useMutation(MUTATION_DELETE_LABWARE);
  // We could use the loading value returned by useMutation, but this finishes before the
  // UI has removed the plate from the list. So just use a state instead.
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    const shouldDelete = await openConfirmationDialog({
      action: 'delete',
      isActionDestructive: true,
      object: 'mapped plate',
      specificObject: plateName,
    });
    if (!shouldDelete) {
      return;
    }
    setLoading(true);
    await deleteLabware({
      variables: { id: labwareId },
      refetchQueries: [{ query: QUERY_SIMULATION, variables: { id: simulationId } }],
      // Wait for results screen to refresh
      awaitRefetchQueries: true,
    });
  }, [deleteLabware, labwareId, openConfirmationDialog, plateName, simulationId]);

  if (!useFeatureToggle('PLATE_MAPPER')) {
    return null;
  }

  return (
    <>
      <IconButton
        icon={loading ? <CircularProgress size="14px" /> : <DeleteIcon />}
        disabled={loading}
        onClick={handleClick}
        size="large"
      />
      {confirmationDialog}
    </>
  );
}

export type MappedPlate = {
  destPlateName: string;
  destPlateType: CommonPlateSizes;
  transfers: Transfer[];
};

type PlateMapperDialogProps = DialogProps<void> & {
  simulation: SimulationWithExecution;
  /**
   * Plates from the execution which the user manually cherry-picked from.
   */
  sourcePlates: Plate[];
  /**
   * The new plate name will be checked against this list of labware names already in use.
   */
  usedLabwareNames: string[];
  /**
   * The current mapped plate to be edited
   */
  plate?: MappedPlate;
  labwareId?: LabwareId;
  isReadOnly?: boolean;
};

enum PlateMapperError {
  PLATE_NAME_EMPTY = 'PLATE_NAME_EMPTY',
  PLATE_NAME_CONFLICT = 'PLATE_NAME_CONFLICT',
}

export function PlateMapperDialog({
  isOpen,
  onClose,
  simulation,
  sourcePlates,
  usedLabwareNames,
  labwareId,
  plate,
  isReadOnly,
}: PlateMapperDialogProps) {
  const classes = useStyles();

  const snackbarManager = useSnackbarManager();

  const [destPlateName, setDestPlateName] = useState<string>('');
  const [destPlateType, setDestPlateType] = useState<CommonPlateSizes>('96 wells');
  const [transfers, setTransfers] = useState<Transfer[]>([]);
  const [error, setError] = useState<PlateMapperError | undefined>();

  useEffect(() => {
    if (isOpen) {
      // Update the displayed contents when launching the dialog
      setDestPlateName(plate?.destPlateName ?? '');
      setDestPlateType(plate?.destPlateType ?? '96 wells');
      setTransfers(plate?.transfers ?? []);
      setError(undefined);
    }
  }, [isOpen, plate]);

  const [createLabware, { loading: creating }] = useMutation(MUTATION_CREATE_LABWARE);

  const [updateLabware, { loading: updating }] = useMutation(MUTATION_UPDATE_LABWARE);

  const loading = creating || updating;

  const savePlate = useCallback(
    async (newPlate: MappedPlate) => {
      const mutationArgs = {
        refetchQueries: [{ query: QUERY_SIMULATION, variables: { id: simulation.id } }],
        // Wait until the result screen has refreshed
        awaitRefetchQueries: true,
      };
      if (!labwareId) {
        await createLabware({
          ...mutationArgs,
          variables: {
            name: newPlate.destPlateName,
            executionId: simulation.execution.id,
            labwareFormat: newPlate.destPlateType,
            contents: convertTransfersToContents(newPlate.transfers, sourcePlates),
          },
        });
      } else {
        await updateLabware({
          ...mutationArgs,
          variables: {
            id: labwareId,
            newPlateName: newPlate.destPlateName,
            labwareFormat: newPlate.destPlateType,
            contents: convertTransfersToContents(newPlate.transfers, sourcePlates),
          },
        });
      }
    },
    [
      createLabware,
      labwareId,
      simulation.execution.id,
      simulation.id,
      sourcePlates,
      updateLabware,
    ],
  );

  const handleDone = useCallback(async () => {
    try {
      if (!destPlateName) {
        setError(PlateMapperError.PLATE_NAME_EMPTY);
        return;
      }
      if (usedLabwareNames.includes(destPlateName)) {
        setError(PlateMapperError.PLATE_NAME_CONFLICT);
        return;
      }
      await savePlate({ destPlateName, destPlateType, transfers });

      onClose();
    } catch (error) {
      // If there's an error while saving, do not close the dialog (that would mean loss
      // of work). Pop up a snackbar with the error content.
      console.error(error);
      snackbarManager.showError(`Failed to save plate mapping: ${error.message}`);
    }
  }, [
    destPlateName,
    destPlateType,
    onClose,
    savePlate,
    snackbarManager,
    transfers,
    usedLabwareNames,
  ]);

  const handleCancel = useCallback(() => onClose(), [onClose]);

  // Override the Paper style within the Dialog to set a fixed width
  const dialogClasses = useMemo(() => ({ paper: classes.paper }), [classes.paper]);
  return (
    <Dialog open={isOpen} maxWidth="md" classes={dialogClasses} onClose={doNothing}>
      {loading && <LinearProgress />}
      <DialogTitle>Plate Mapper</DialogTitle>
      <DialogContent>
        <PlateMapper
          sourcePlates={sourcePlates}
          destPlateName={destPlateName}
          destPlateType={destPlateType}
          transfers={transfers}
          onDestPlateNameChange={setDestPlateName}
          onDestPlateTypeChange={setDestPlateType}
          onTransfersChange={setTransfers}
          error={error}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="tertiary" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          variant="tertiary"
          color="primary"
          onClick={handleDone}
          disabled={isReadOnly || loading}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type PlateMapperProps = {
  sourcePlates: Plate[];
  destPlateName: string;
  destPlateType: CommonPlateSizes;
  transfers: Transfer[];
  error?: PlateMapperError;
  onDestPlateNameChange: (plateName: string) => void;
  onDestPlateTypeChange: (plateType: CommonPlateSizes) => void;
  onTransfersChange: (transfers: Transfer[]) => void;
};

/**
 * The Plate Mapper lets the users define a new plate they created by pipetting
 * manually (i.e. not using devices controlled by Antha)
 *
 * The left half of the dialog shows plates from the execution. The right half
 * shows the new plate the user manually cherry picked. The user can click wells
 * on the left and right to indicate where they moved liquids from and to.
 */
function PlateMapper({
  sourcePlates,
  destPlateType,
  destPlateName,
  transfers,
  error,
  onDestPlateNameChange,
  onDestPlateTypeChange,
  onTransfersChange,
}: PlateMapperProps) {
  const classes = useStyles();

  const [sourcePlate, setSourcePlate] = useState<Plate>(sourcePlates[0]);
  const [selectedWells, setSelectedWells] = useState<readonly WellLocationOnDeckItem[]>(
    [],
  );

  const selectWell = useCallback(
    (loc: WellLocationOnDeckItem, e: React.PointerEvent) =>
      setSelectedWells(
        computeSelectedWells(selectedWells, loc, e, 'plate-mapper', true)
          .newSelectedWells,
      ),
    [selectedWells],
  );

  const handleSourcePlateChange = useCallback(
    (plate?: Plate) => plate && setSourcePlate(plate),
    [],
  );

  const handleDestPlateNameChange = useTextFieldChange(onDestPlateNameChange);

  const handleDestPlateTypeChange = useCallback(
    (type?: CommonPlateSizes) => type && onDestPlateTypeChange(type),
    [onDestPlateTypeChange],
  );

  const createTransfersFromSelection = useCallback(() => {
    const srcWells = selectedWells.filter(well => well.deck_item_id === sourcePlate.id);
    const destWells = selectedWells.filter(well => well.deck_item_id === DEST_PLATE_ID);
    // Create a transfer for each selected source-dest pair
    const newTransfers: Transfer[] = srcWells.flatMap(src => {
      const contents = sourcePlate?.contents?.[src.col]?.[src.row];
      // Ignore attempted transfers from empty wells or non-liquid wells (e.g.
      // robocolumns)
      if (contents?.kind !== 'liquid_summary') {
        return [];
      }
      // Grab a human readable string for the liquid.
      const liquidName = summaryOfWell(contents);
      return destWells.map(dest => ({
        id: createPlateMappingId(src, dest),
        src,
        dest,
        liquidName,
      }));
    });
    onTransfersChange([...transfers, ...newTransfers]);
    setSelectedWells([]);
  }, [onTransfersChange, selectedWells, sourcePlate, transfers]);

  const deleteTransfer = useCallback(
    (transferID: string) =>
      onTransfersChange(transfers.filter(transfer => transfer.id !== transferID)),
    [onTransfersChange, transfers],
  );

  // Dropdown on the left should list all plates from the simulation
  const sourcePlateOptions = useMemo<Option<Plate>[]>(
    () =>
      sourcePlates
        .map(plate => ({ label: plate.name, value: plate }))
        .sort((a, b) => alphanumericCompare(a.label, b.label)),
    [sourcePlates],
  );
  // Dropdown on the right should let user select the kind of plate they moved
  // liquid to, e.g. 96 well, 12 well.
  const destPlateOptions = useMemo<Option<CommonPlateSizes>[]>(
    () =>
      Object.keys(COMMON_PLATE_DIMENSIONS).map(name => ({
        label: name,
        value: name as CommonPlateSizes,
      })),
    [],
  );

  // Generate the plate to display on the right
  const destPlate = useMemo<Plate>(() => {
    const liquidsAtEachWell: Record<number, Record<number, Set<string>>> = {};
    // Iterate through each transfer, collating the liquids moved to each well.
    // Multiple liquids may be moved into a single well, so we collate the
    // liquid names in a Set for each well location.
    for (const { dest, liquidName } of transfers) {
      if (!liquidsAtEachWell[dest.col]) {
        liquidsAtEachWell[dest.col] = {};
      }
      if (!liquidsAtEachWell[dest.col][dest.row]) {
        liquidsAtEachWell[dest.col][dest.row] = new Set();
      }
      liquidsAtEachWell[dest.col][dest.row].add(liquidName);
    }
    return {
      ...makeWellSelector(createDummyPlate(destPlateType)),
      // ID should not change when changing plate type (makeWellSelector uses
      // type for ID)
      id: DEST_PLATE_ID,
      contents: mapObject(liquidsAtEachWell, (_, rows) =>
        mapObject(rows, (_, liquidNames) => {
          const liquidName = [...liquidNames].join(' + ');
          return {
            kind: 'liquid_summary',
            id: liquidName,
            name: liquidName,
            total_volume: MOCK_VOLUME,
          };
        }),
      ),
    };
  }, [destPlateType, transfers]);

  // Only allow a mapping to be created when the user has selected at least one
  // well on both the source and destination plates.
  const canCreateMapping = useMemo<boolean>(
    () =>
      selectedWells.some(well => well.deck_item_id === sourcePlate.id) &&
      selectedWells.some(well => well.deck_item_id === destPlate.id),
    [destPlate.id, selectedWells, sourcePlate.id],
  );

  let plateNameError: string | undefined;
  if (error === PlateMapperError.PLATE_NAME_EMPTY) {
    plateNameError = 'You must specify a new plate name';
  } else if (error === PlateMapperError.PLATE_NAME_CONFLICT) {
    plateNameError = 'Plate name already in use';
  }

  return (
    <>
      <div className={classes.toolbars}>
        <div className={classes.toolbarLeft}>
          <Typography variant="body2" gutterBottom>
            Plate from Execution
          </Typography>
          <Dropdown
            valueLabel={sourcePlate.name}
            options={sourcePlateOptions}
            onChange={handleSourcePlateChange}
          />
        </div>
        <div className={classes.toolbarRight}>
          <Typography variant="body2" gutterBottom>
            New plate
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <TextField
                variant="standard"
                value={destPlateName}
                onChange={handleDestPlateNameChange}
                placeholder="Plate name"
                fullWidth
                error={plateNameError !== undefined}
                helperText={plateNameError}
              />
            </Grid>
            <Grid item xs>
              <Dropdown
                valueLabel={destPlateType}
                options={destPlateOptions}
                onChange={handleDestPlateTypeChange}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <PlateMapperPreview
        sourcePlates={sourcePlates}
        sourcePlate={sourcePlate}
        destPlate={destPlate}
        transfers={transfers}
        selectedWells={selectedWells}
        onWellPointerUp={selectWell}
      />
      <div className={classes.createMappingContainer}>
        <Button
          variant="secondary"
          onClick={createTransfersFromSelection}
          disabled={!canCreateMapping}
        >
          Create mapping from selection
        </Button>
      </div>
      <TransferTable
        sourcePlates={sourcePlates}
        transfers={transfers}
        onDelete={deleteTransfer}
      />
    </>
  );
}

type PlateMapperPreview = {
  sourcePlates: Plate[];
  sourcePlate: Plate;
  destPlate: Plate;
  transfers: Transfer[];
  selectedWells: readonly WellLocationOnDeckItem[];
  onWellPointerUp: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
};

function PlateMapperPreview({
  sourcePlates,
  sourcePlate,
  destPlate,
  transfers,
  selectedWells,
  onWellPointerUp,
}: PlateMapperPreview) {
  const classes = useStyles();

  const [hoveredWell, setHoveredWell] = useState<WellLocationOnDeckItem | undefined>();

  const handleWellMouseEnter = useCallback(
    (loc: WellLocationOnDeckItem) => setHoveredWell(loc),
    [],
  );
  const handleWellMouseLeave = useCallback(() => setHoveredWell(undefined), []);

  // Convert the plate to a deck layout
  const deckLayout = useMemo<DeckLayout>(() => {
    const deckState = {
      positions: {
        [SOURCE_DECK_POSITION_NAME]: {
          position: SOURCE_PLATE_POS,
          size: PLATE_SIZE,
          item: sourcePlate,
        },
        [DEST_DECK_POSITION_NAME]: {
          position: DEST_PLATE_POS,
          size: PLATE_SIZE,
          item: destPlate,
        },
      },
    };
    return new DeckLayout({ before: deckState, after: deckState, version: '' });
  }, [destPlate, sourcePlate]);

  // Convert the transfers to a list of edges to display in the mixView
  const mixState = useMemo<MixState>(
    () => ({
      currentStep: 0,
      timeElapsed: 0,
      prompts: [],
      errors: [],
      deck: {
        items: [
          {
            ...sourcePlate,
            currentRotationDegrees: 0,
            currentDeckPositionName: SOURCE_DECK_POSITION_NAME,
          },
          {
            ...destPlate,
            currentRotationDegrees: 0,
            currentDeckPositionName: DEST_DECK_POSITION_NAME,
          },
        ],
      },
      edges: transfers
        .filter(
          ({ src: from, dest: to }) =>
            // only show transfers when the source plate is visible
            from.deck_item_id === sourcePlate.id &&
            // only show transfers for the hovered well
            hoveredWell &&
            (isSameLocation(from, hoveredWell) || isSameLocation(to, hoveredWell)),
        )
        .map(({ src: from, dest: to }) => ({
          type: 'liquid_transfer',
          stepNumber: 0,
          channel: 0,
          channelsAspiratingFromWell: [0],
          channelsDispensingToWell: [0],
          // The preview requires pipetting parameters, liquid policy, and volume for each
          // transfer. We don't ask the user for this info in the plate mapper UI, but
          // since we don't need to show it we can just provide mock data.
          action: generateMockSingleTransfer(from, to),
          from,
          to,
        })),
      tipboxReplacements: [],
      highlightedDeckPositionNames: new Set(),
    }),
    [destPlate, hoveredWell, sourcePlate, transfers],
  );

  // Generate liquid colours based on liquids in the source plates. If we let
  // MixSet generate liquid colours, it would also look at the destination
  // plate. The destination plate changes each time a transfer is added or
  // removed, which would cause the liquid colours to change.
  const liquidColors = useMemo(
    () => LiquidColors.createUsingColorGraph(sourcePlates),
    [sourcePlates],
  );

  return (
    <div className={classes.mixView}>
      {/* This MixView can never have a grid because it only contains plates */}
      <MixView
        gridVisible={false}
        deckLayout={deckLayout}
        filteredState={mixState}
        plateSettings={{
          liquidColorsOverride: liquidColors,
          plateTypes: [],
          selectedWells: selectedWells,
          hidePlateLabels: true,
          onWellPointerUp: onWellPointerUp,
          onWellMouseEnter: handleWellMouseEnter,
          onWellMouseLeave: handleWellMouseLeave,
        }}
      />
    </div>
  );
}

type TransferTableProps = {
  sourcePlates: Plate[];
  transfers: Transfer[];
  onDelete: (transferID: string) => void;
};

function TransferTable({ sourcePlates, transfers, onDelete }: TransferTableProps) {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Source plate</TableCell>
          <TableCell>Source well</TableCell>
          <TableCell>Destination well</TableCell>
          <TableCell>Liquid</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {transfers.length === 0 ? (
          <TableRow>
            <TableCell colSpan={5} align="center">
              <Typography variant="overline">
                No transfers have yet been defined
              </Typography>
            </TableCell>
          </TableRow>
        ) : (
          transfers.map(transfer => (
            <TransferTableRow
              sourcePlates={sourcePlates}
              key={transfer.id}
              transfer={transfer}
              onDelete={onDelete}
            />
          ))
        )}
      </TableBody>
    </Table>
  );
}

type TransferRowProps = {
  sourcePlates: Plate[];
  transfer: Transfer;
  onDelete: (transferID: string) => void;
};

function TransferTableRow({ sourcePlates, transfer, onDelete }: TransferRowProps) {
  const handleDelete = useCallback(() => onDelete(transfer.id), [onDelete, transfer.id]);
  const { src, dest } = transfer;
  const fromWell = useMemo(() => formatWellPosition(src), [src]);
  const toWell = useMemo(() => formatWellPosition(dest), [dest]);
  const plateName = useMemo(
    () => sourcePlates.find(plate => plate.id === src.deck_item_id)?.name || 'Untitled',
    [sourcePlates, src.deck_item_id],
  );
  return (
    <TableRow>
      <TableCell>{plateName}</TableCell>
      <TableCell>{fromWell}</TableCell>
      <TableCell>{toWell}</TableCell>
      <TableCell>{transfer.liquidName}</TableCell>
      <TableCell align="right">
        <IconButton icon={<DeleteIcon />} onClick={handleDelete} size="xsmall" />
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStylesHook({
  paper: {
    height: '800px',
  },
  plateContainer: { width: '400px' },
  toolbars: {
    position: 'relative',
  },
  toolbarLeft: {
    width: `${PLATE_WIDTH_PX}px`,
  },
  toolbarRight: {
    position: 'absolute',
    top: 0,
    left: `${PLATE_WIDTH_PX + SPACE_BETWEEN_PLATES_PX}px`,
    width: `${PLATE_WIDTH_PX}px`,
  },
  mixView: {
    height: `${PLATE_LENGTH_PX + TOP_MARGIN_PX + BOTTOM_MARGIN_PX}px`,
    padding: `${TOP_MARGIN_PX}px 0 ${BOTTOM_MARGIN_PX}px`,
    overflow: 'hidden',
  },
  createMappingContainer: {
    textAlign: 'center',
    marginBottom: '12px',
  },
});
