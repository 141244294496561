import React from 'react';

import cx from 'classnames';

import { ExecutionStatusIndicatorWithTooltip } from 'client/app/components/ExecutionStatusIndicator';
import {
  ArrayElement,
  ExecutionsQuery,
  ExecutionSummaryFragmentFragment as ExecutionSummaryFragment,
  SimulationSummaryFragmentFragment as SimulationSummaryFragment,
} from 'client/app/gql';
import { executionRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { formatDuration } from 'common/lib/format';
import { EntityCard } from 'common/ui/components/EntityCard';
import Tooltip from 'common/ui/components/Tooltip';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DataSetIcon } from 'common/ui/icons/DataSetIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';

type Execution = ArrayElement<ExecutionsQuery['executions']['items']>;

type ExecutionRowProps = {
  execution:
    | Execution
    | (ExecutionSummaryFragment & { simulation: SimulationSummaryFragment });
  isLink?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  highlighted?: boolean;
  tooltipTitle?: string;
};

export function ExecutionCard({
  execution,
  isLink,
  onClick,
  disabled,
  highlighted,
  tooltipTitle,
}: ExecutionRowProps) {
  const classes = useStyles();
  const path = executionRoutes.executionDetailsSubscreen.getPath({
    executionId: execution.id,
  });
  const logNavigation = () => {
    logEvent('open-simulation-details', ScreenRegistry.EXPERIMENTS);
    onClick?.();
  };
  const showDatasetIcon = 'hasDatasets' in execution;
  const hasDatasets = showDatasetIcon && execution.hasDatasets;

  const action = showDatasetIcon ? (
    <Tooltip
      title={
        disabled
          ? ''
          : hasDatasets
          ? 'Data available. Go to the execution page to see details.'
          : 'This execution doesn’t have data attached. Please go to the execution page to upload data first'
      }
    >
      <span>
        <DataSetIcon
          className={cx({ [classes.disabledIcon]: !hasDatasets })}
          fontSize="medium"
        />
      </span>
    </Tooltip>
  ) : undefined;

  return (
    <EntityCard
      icon={<ExecutionIcon fontSize="small" />}
      link={isLink ? path : undefined}
      onClick={logNavigation}
      entityName="Execution"
      name={execution.simulation.name}
      additionalColumnTitle="Estimated time"
      additionalColumnValue={
        execution.simulation.timeEstimateSeconds
          ? formatDuration(execution.simulation.timeEstimateSeconds)
          : 'N/A'
      }
      authorTitle="Scheduled by"
      author={execution.user.displayName}
      dateTitle="Date scheduled"
      date={new Date(execution.lastModifiedAt)}
      statusIcon={<ExecutionStatusIndicatorWithTooltip status={execution.status} />}
      disabled={disabled}
      isSelected={highlighted}
      action={action}
      actionText={showDatasetIcon ? 'Data' : undefined}
      tooltipTitle={tooltipTitle}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  disabledIcon: {
    color: theme.palette.text.disabled,
  },
}));
