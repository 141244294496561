import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';

import { formatVolumeObj, sanitizeDeckItemName } from 'common/lib/format';
import Colors from 'common/ui/Colors';
import PlateLayout, { Props as PlateLayoutProps } from 'common/ui/components/PlateLayout';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import { WellTooltipTitleProps } from 'common/ui/components/simulation-details/mix/WellTooltip';
import Styles from 'common/ui/components/simulation-details/Styles';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type MixPlateProps = {
  deckLayout: DeckLayout;
  humanReadablePlateLabel: string;
  /**
   * Text to display over the top of the plate.  If supplied, this will obscure
   * a portion of the plate, so it should only be used in scenarios where seeing
   * the whole plate isn't necessary.
   */
  overlayText?: string;
  hideLabel?: boolean;
  /**
   * If the plate is in the hotel, do not display it's contents. Instead, just show it's
   * label.
   */
  isInHotel?: boolean;
  /**
   * Called when the user clicks anywhere inside the plate
   */
  onPlatePointerUp?: (plate: string) => void;
} & Omit<PlateLayoutProps, 'geometry'>;

/**
 * Shows a 2d grid of wells with a title and summary. Used within execution
 * preview.
 */
export default React.memo(function MixPlate(props: MixPlateProps) {
  const {
    plate,
    humanReadablePlateLabel,
    overlayText,
    deckLayout,
    onPlatePointerUp,
    hideLabel,
    isInHotel,
    TooltipTitle,
    ...plateLayoutProps
  } = props;
  const classes = useStyles();
  const TubeTooltipTitle = useTubeTooltip(plate);

  /**
   * Since tubes are considered as a 1 well plates they should be handled separately.
   * Tubes should not have overlaping plate labels while being placed in a rack.
   * e.g. Falcon tubes rack
   */
  const showLabels = !(hideLabel || isTube(plate));
  const TooltipComponent = isTube(plate) ? TubeTooltipTitle : TooltipTitle;

  const handlePointerUp = useCallback(
    () => onPlatePointerUp?.(plate.name),
    [onPlatePointerUp, plate.name],
  );

  const geometry = useMemo(
    () => deckLayout.getCurrentGeometry(plate),
    [deckLayout, plate],
  );

  return (
    <div
      style={{ backgroundColor: plate.color }}
      className={classes.mixPlate}
      onPointerUp={handlePointerUp}
    >
      {overlayText && (
        <div className={classes.overlayTextContainer}>
          <span className={classes.overlayText}>{overlayText}</span>
        </div>
      )}
      {showLabels && (
        <div
          className={classNames(classes.deckItemLabel, {
            [classes.deckItemLabelInHotel]: isInHotel,
          })}
        >
          <div className={classes.deckItemName}>{sanitizeDeckItemName(plate.name)}</div>
          <div className={classes.deckItemPlate}>{humanReadablePlateLabel}</div>
        </div>
      )}
      {!isInHotel && (
        <svg className={classes.plateContainer}>
          <PlateLayout
            {...plateLayoutProps}
            geometry={geometry}
            plate={plate}
            showContentTooltip
            TooltipTitle={TooltipComponent}
          />
        </svg>
      )}
    </div>
  );
});

function isTube(plate: PlateState) {
  const is1x1Plate = plate.description.includes('1x1');
  const isTubeWellType = ['circle', 'cylinder'].includes(plate.well_type);
  return isTubeWellType && is1x1Plate;
}

function useTubeTooltip(plate: PlateState) {
  const classes = useStyles();

  return useCallback(
    ({ wellContents }: WellTooltipTitleProps) => (
      <dl className={classes.tooltipTable}>
        <dt>Plate Name</dt>
        <dd>{plate.name}</dd>
        {wellContents?.name && (
          <>
            <dt>Liquid Name</dt>
            <dd>{wellContents.name}</dd>
          </>
        )}
        {wellContents && wellContents?.total_volume?.value > 0 && (
          <>
            <dt>Liquid Volume</dt>
            <dd>{formatVolumeObj(wellContents.total_volume)}</dd>
          </>
        )}
        {wellContents?.type && (
          <>
            <dt>Liquid Type</dt>
            <dd>{wellContents?.type}</dd>
          </>
        )}
      </dl>
    ),
    [classes.tooltipTable, plate.name],
  );
}

const useStyles = makeStylesHook(theme => ({
  mixPlate: {
    position: 'relative',
    height: '100%',
    backgroundColor: 'white',
    display: 'grid',
    // First row  is the label, second is the plate.
    // Label takes 0 so that it doesn't shift the plate down
    gridTemplateRows: '0 100%',
    transition: 'box-shadow 1s',
    borderRadius: '4px',
  },
  deckItemLabel: Styles.deckItemLabel,
  deckItemLabelInHotel: {
    // Don't show the label above the plate
    transform: 'none',
    padding: theme.spacing(2),
  },
  deckItemName: Styles.deckItemName,
  deckItemPlate: Styles.deckItemPlate,
  overlayTextContainer: {
    position: 'absolute',
    top: '0',
    bottom: '0',
    left: '0',
    right: '0',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlayText: {
    fontSize: '300%',
    fontWeight: 'bold',
    background: Colors.GREY_20,
    borderRadius: '30px',
    padding: '10px',
    wordBreak: 'break-all',
    textAlign: 'center',
  },
  plateContainer: {
    // Ensure plate is on second row, even if label above is not present
    gridRow: 2,
    width: '100%',
    height: '100%',
  },
  tooltipTable: {
    display: 'grid',
    gap: theme.spacing(2, 2),
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: 'normal',
    color: Colors.TEXT_PRIMARY,
    '& dt': {
      gridColumn: 1,
      fontWeight: 'bold',
    },
    '& dd': {
      margin: 0,
      gridColumn: 2,
    },
  },
}));
