import React, { useMemo } from 'react';

import { GridAnnotationRect } from 'common/ui/components/simulation-details/mix/DeckLayout';
import GridSection from 'common/ui/components/simulation-details/mix/GridSection';

type Props = { grid: GridAnnotationRect[]; gridVisible: boolean };

export default React.memo(function MixGrid({ grid, gridVisible }: Props) {
  const rendered = useMemo(
    () =>
      grid.map(gridPosition => {
        return <GridSection key={gridPosition.label} gridPosition={gridPosition} />;
      }),
    [grid],
  );
  return gridVisible ? <>{rendered}</> : null;
});
