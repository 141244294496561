import React, { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import isWorkflowReadonly from 'client/app/apps/workflow-builder/lib/isWorkflowReadonly';
import { AdditionalPanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import {
  getDeviceConfigurationForUI,
  hasDispenserDevice,
  hasManualDevice,
  hasPeripheralDeviceOnly,
  isDataOnly,
} from 'client/app/lib/workflow/deviceConfigUtils';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import {
  emptyWorkflowConfig,
  WorkflowConfig,
  WorkflowDeviceConfiguration,
} from 'common/types/bundle';

export default function WorkflowSettingsStateContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const editMode = useWorkflowBuilderSelector(state => state.editMode);
  const source = useWorkflowBuilderSelector(state => state.source);
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);

  const isReadonly = isWorkflowReadonly(editMode, source);

  const workflowConfigProps = useMemo(() => {
    const deviceConfiguration = getDeviceConfigurationForUI(workflowConfig);

    const someDeviceSelected = !isEmpty(deviceConfiguration);

    const isDataOnlyMode = isDataOnly(workflowConfig);
    const isDispenserMode = hasDispenserDevice(workflowConfig);
    const isManualMode = hasManualDevice(workflowConfig);
    const isPeripheralOnly = hasPeripheralDeviceOnly(workflowConfig);

    const requiresDevice = workflowConfig.GlobalMixer.requiresDevice === true;
    const showDeviceSelectorCard = someDeviceSelected || isManualMode || isDataOnlyMode;

    const showCustomLHPolicies =
      someDeviceSelected &&
      !isManualMode &&
      !isDataOnlyMode &&
      !isDispenserMode &&
      !isPeripheralOnly;

    const showDeckOptions =
      someDeviceSelected &&
      !isManualMode &&
      !isDataOnlyMode &&
      !isDispenserMode &&
      !isPeripheralOnly;

    return {
      deviceConfiguration,
      requiresDevice,
      showCustomLHPolicies,
      showDeckOptions,
      showDeviceSelectorCard,
    };
  }, [workflowConfig]);

  return (
    <WorkflowSettingsStateContext.Provider
      value={useMemo(
        () => ({ additionalPanel, isReadonly, workflowConfig, ...workflowConfigProps }),
        [additionalPanel, isReadonly, workflowConfig, workflowConfigProps],
      )}
    >
      {children}
    </WorkflowSettingsStateContext.Provider>
  );
}

type WorkflowSettingsState = {
  additionalPanel: AdditionalPanelContent | null;
  deviceConfiguration: WorkflowDeviceConfiguration;
  workflowConfig: WorkflowConfig;
  isReadonly: boolean;
  requiresDevice: boolean;
  showDeckOptions: boolean;
  showDeviceSelectorCard: boolean;
  showCustomLHPolicies: boolean;
};

const WorkflowSettingsStateContext = createContext<WorkflowSettingsState>({
  additionalPanel: undefined,
  deviceConfiguration: {},
  workflowConfig: emptyWorkflowConfig(),
  isReadonly: false,
  requiresDevice: false,
  showDeckOptions: false,
  showDeviceSelectorCard: false,
  showCustomLHPolicies: false,
});

export function useWorkflowSettingsState() {
  return useContext(WorkflowSettingsStateContext);
}
