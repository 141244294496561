import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { QUERY_DATASET_DERIVATION } from 'client/app/apps/experiments/gql/queries';
import VisserverIFrame from 'client/app/components/VisserverIFrame';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type DatasetDerivationAppProps = {
  derivationId: DatasetDerivationId;
};

export function BioprocessApp({ derivationId }: DatasetDerivationAppProps) {
  const classes = useStyles();

  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);

  const {
    data,
    loading: queryLoading,
    error,
  } = useQuery(QUERY_DATASET_DERIVATION, {
    variables: { id: derivationId },
  });

  useEffect(() => {
    setBrowserTabTitle('Bioprocessing');
  }, []);

  if (queryLoading || !data) {
    return <LinearProgress />;
  }

  if (error) {
    return <GraphQLErrorPanel error={error} />;
  }

  const { inputs } = data.datasetDerivation;

  return (
    <>
      {visserverLoading && <LinearProgress />}
      <Typography variant="h1" className={classes.title}>
        Bioprocessing
      </Typography>
      <VisserverIFrame
        view="bioprocess"
        params={{
          dataset_ids: inputs.map(input => input.datasetId).join(','),
        }}
        onReady={() => setVisserverLoading(false)}
        onReload={() => setVisserverLoading(true)}
      />
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  title: {
    margin: theme.spacing(5, 0, 0, 6),
  },
}));
