import React, { ReactNode, useCallback, useState } from 'react';

import { useMutation } from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

import { useCreateWorkflow } from 'client/app/api/WorkflowsApi';
import { createAndNavigateToWorkflow } from 'client/app/apps/experiments/createAndNavigateToWorkflow';
import CreateButton, {
  CreateButtonAction,
} from 'client/app/apps/experiments/CreateButton';
import { MUTATION_CREATE_EXPERIMENT } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENTS } from 'client/app/apps/experiments/gql/queries';
import TemplateWorkflowsDialog from 'client/app/apps/experiments/Templates/TemplateWorkflowsDialog';
import useUploadFileBundle from 'client/app/apps/experiments/workflowCreationUtils';
import InventorySubmenu from 'client/app/components/nav/InventorySubmenu';
import { useEntitiesCount } from 'client/app/hooks/useEntitiesCount';
import { experimentsRoutes, workflowRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import { EditorType } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import useDialog from 'common/ui/hooks/useDialog';
import DOETemplateIcon from 'common/ui/icons/DOETemplateIcon';
import { DraftIcon } from 'common/ui/icons/DraftIcon';
import { ExampleGalleryIcon } from 'common/ui/icons/ExampleGalleryIcon';
import { ExecutionIcon } from 'common/ui/icons/Execution';
import { IncomingWorkflowIcon } from 'common/ui/icons/IncomingWorkflowIcon';
import { NewExperimentsIcon } from 'common/ui/icons/NewExperimentsIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';
import { navigateToAndKeepURLSearchParams } from 'common/ui/navigation';

export const NEW_EXPERIMENT_NAME = 'My experiment';

export default function NavigationSidepanel({ children }: { children: React.ReactNode }) {
  const [createExperimentMutation, { loading: isCreatingExperiment }] = useMutation(
    MUTATION_CREATE_EXPERIMENT,
  );

  const navigation = useNavigation();
  const snackbar = useSnackbarManager();
  const createWorkflow = useCreateWorkflow();
  const [templateWorklowsDialog, openTemplateWorklowsDialog] = useDialog(
    TemplateWorkflowsDialog,
  );
  const [isCreatingWorkflow, setCreatingWorkflow] = useState(false);

  // The person picked one of the actions available on the Create button,
  // such as: Create a new experiment, Create a new Workflow, etc.
  const handleCreateButtonItemClick = useCallback(
    async (action: CreateButtonAction) => {
      switch (action) {
        case 'new-experiment': {
          const { data } = await createExperimentMutation({
            variables: { name: NEW_EXPERIMENT_NAME },
            refetchQueries: [{ query: QUERY_EXPERIMENTS }],
          });
          const newExperimentId = data?.createExperiment?.id;
          if (newExperimentId) {
            // Navigate to the Experiment we just created
            navigation.navigate(experimentsRoutes.detail, {
              id: newExperimentId,
            });
          }
          break;
        }
        case 'new-workflow-from-template': {
          logEvent('open-template-dialog', ScreenRegistry.EXPERIMENTS);
          window.Intercom('trackEvent', 'open-template-dialog');
          await openTemplateWorklowsDialog({});
          break;
        }
        case 'new-workflow-builder': {
          logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'builder');
          void createAndNavigateToWorkflow(
            'New Workflow',
            EditorType.WORKFLOW_EDITOR,
            snackbar,
            navigation,
            createWorkflow,
            setCreatingWorkflow,
          );
          break;
        }
        case 'new-cherry-picker': {
          logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'cherry-picker');
          await createAndNavigateToWorkflow(
            'New Cherry-Picker',
            EditorType.CHERRY_PICKER,
            snackbar,
            navigation,
            createWorkflow,
            setCreatingWorkflow,
          );
          break;
        }
        default: {
          console.error('Unknown action from the Create button:', action);
        }
      }
    },
    [
      createExperimentMutation,
      createWorkflow,
      navigation,
      openTemplateWorklowsDialog,
      snackbar,
    ],
  );

  const uploadFileBundle = useUploadFileBundle();

  // The person picked a local file they want to upload.
  // The file contains a workflow and we'll create a new Workflow in Antha.
  const handleCreateButtonPickLocalWorkflowFile = useCallback(
    async (file: File) => {
      try {
        logEvent('create-new-workflow', ScreenRegistry.EXPERIMENTS, 'from-upload');
        const workflowId = await uploadFileBundle(file);
        navigation.navigate(workflowRoutes.openInWorkflowBuilder, {
          workflowId,
        });
      } catch (e) {
        console.error(e);
        snackbar.showError('Failed to import workflow');
      } finally {
        setCreatingWorkflow(false);
      }
    },
    [navigation, snackbar, uploadFileBundle],
  );

  const entityCounts = useEntitiesCount();

  return (
    <Screen>
      <Container>
        <CreateWithProgressIndicator>
          <CreateButton
            onItemClick={handleCreateButtonItemClick}
            onPickLocalWorkflowFile={handleCreateButtonPickLocalWorkflowFile}
          />
          {(isCreatingExperiment || isCreatingWorkflow) && (
            <CreatingProgress size={24} variant="indeterminate" />
          )}
        </CreateWithProgressIndicator>
        <IntercomTarget tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE} name="side-nav">
          <List dense>
            <IntercomTarget
              tour={IntercomTourIDs.EXPERIMENTS_HOME_PAGE}
              name="side-nav-experiments"
            >
              <NavItem
                route={experimentsRoutes.base.getPath()}
                icon={<NewExperimentsIcon />}
                text="Experiments"
              />
            </IntercomTarget>
            <NavItem
              route={experimentsRoutes.workflows.getPath()}
              icon={<WorkflowIcon />}
              text="Workflows"
            />
            <NavItem
              route={experimentsRoutes.drafts.getPath()}
              icon={<DraftIcon />}
              text="Drafts"
            />
            <NavItem
              route={experimentsRoutes.executions.getPath()}
              icon={<ExecutionIcon />}
              text="Executions"
            />
            {entityCounts && entityCounts.doeWorkflows > 0 && (
              <NavItem
                route={experimentsRoutes.doeTemplates.getPath()}
                icon={<DOETemplateIcon />}
                text="DOE templates"
              />
            )}
            {entityCounts && entityCounts.incomingWorkflows > 0 && (
              <NavItem
                route={experimentsRoutes.incoming.getPath()}
                icon={<IncomingWorkflowIcon />}
                text="Incoming"
              />
            )}
            <>
              <hr />
              <NavItem
                route={experimentsRoutes.exampleGallery.getPath()}
                icon={<ExampleGalleryIcon />}
                text="Example gallery"
              />
            </>
          </List>
        </IntercomTarget>
        <InventorySubmenu />
      </Container>
      {templateWorklowsDialog}
      {children}
    </Screen>
  );
}

export function withNavigationSidepanel<T extends React.ComponentType<any>>(
  Component: T,
) {
  return function Decorator(props: React.ComponentProps<T>) {
    return (
      <NavigationSidepanel>
        <Component {...props} />
      </NavigationSidepanel>
    );
  };
}

type NavItemProps = {
  route: string;
  icon: ReactNode;
  text: string;
};

function NavItem({ route, icon, text }: NavItemProps) {
  return (
    <ListItem>
      <NavLink
        activeStyle={{ color: Colors.PRIMARY_MAIN }}
        to={navigateToAndKeepURLSearchParams(route)}
        exact
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </NavLink>
    </ListItem>
  );
}

const Screen = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  height: '100%',
  padding: theme.spacing(8),
  background: Colors.GREY_5,
  overflowY: 'hidden',
}));

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '200px',
  marginRight: theme.spacing(8),
  flexDirection: 'column',
}));

const CreateWithProgressIndicator = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(5),
}));

const CreatingProgress = styled(CircularProgress)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));

const ListItem = styled(MuiListItem)(({ theme }) => ({
  color: 'inherit',
  padding: theme.spacing(3, 0, 3, 3),
  '& a': {
    display: 'flex',
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
}));

const ListItemIcon = styled(MuiListItemIcon)(() => ({
  // Need to reset the minWidth since ListItemIcon has a default minWidth.
  minWidth: 'auto',
  color: 'inherit',
}));

const ListItemText = styled(MuiListItemText)(({ theme }) => ({
  marginLeft: theme.spacing(3),
}));
