import React from 'react';

import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';

const plateTypeHelp = (
  <InlineHelp>
    <p>The type of plate on which mixtures are made.</p>
  </InlineHelp>
);
const assignmentModeHelp = (
  <InlineHelp>
    <p>Mode by which mixtures are created.</p>
  </InlineHelp>
);
const totalVolumeHelp = (
  <InlineHelp>
    <p>Volume of mixture to prepare.</p>
  </InlineHelp>
);
const diluentNameHelp = (
  <InlineHelp>
    <p>Diluent liquid.</p>
  </InlineHelp>
);
const replicatesHelp = (
  <InlineHelp>
    <p>Number of times to repeat the preparation of mixture.</p>
  </InlineHelp>
);
const wellSetSortByHelp = (
  <InlineHelp>
    <p>Mode by which wells are sorted.</p>
  </InlineHelp>
);
const layerNameHelp = (
  <InlineHelp>
    <p>Descriptor of layer.</p>
  </InlineHelp>
);
const liquidNameHelp = (
  <InlineHelp>
    <p>Name of the liquid or group of liquids.</p>
  </InlineHelp>
);
const liquidTargetHelp = (
  <InlineHelp>
    <p>Target volume or concentration for liquid.</p>
  </InlineHelp>
);
const liquidSortByHelp = (
  <InlineHelp>
    <p>Mode by which the liquids are sorted.</p>
  </InlineHelp>
);
const liquidSortOrderHelp = (
  <InlineHelp>
    <p>Order in which the liquids are sorted.</p>
  </InlineHelp>
);
const liquidSubComponentNameHelp = (
  <InlineHelp>
    <p>Subcomponent by which to sort the liquids.</p>
  </InlineHelp>
);

export default {
  plateTypeHelp,
  assignmentModeHelp,
  totalVolumeHelp,
  diluentNameHelp,
  replicatesHelp,
  wellSetSortByHelp,
  layerNameHelp,
  liquidNameHelp,
  liquidTargetHelp,
  liquidSortByHelp,
  liquidSortOrderHelp,
  liquidSubComponentNameHelp,
};
