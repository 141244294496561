import { produce } from 'immer';

import {
  DropdownAdditionalProps,
  MapAdditionalProps,
  PlateContentsAdditionalProps,
} from 'common/elementConfiguration/AdditionalEditorProps';
import { EditorType } from 'common/elementConfiguration/EditorType';
import {
  getDefaultEditorForAnthaType,
  getKeyTypeFromAnthaType,
  getValueTypeFromAnthaType,
} from 'common/elementConfiguration/parameterUtils';
import { wellLocationExists } from 'common/lib/format';
import { Markdown } from 'common/lib/markdown';
import { Parameter, ParameterValueDict } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import { PlateType } from 'common/types/plateType';

export const PLATE_TYPE = '*github.com/Synthace/antha/antha/anthalib/wtype.PlateType';

export type ContentsByWell = Map<string, ParameterValueDict>;

/**
 * WellParametersProps are common props passed to the wellParameters function in
 * WellGroupList and WellGroupListItem components.
 */
export type WellParametersProps<T> = {
  /**
   * Content of each well on the plate, where type T is the type of contents.
   *
   * For example, where T is RoboColumnContent:
   *   A1 => { RoboColumn: 'RoboColumn 1', Resin: 'Capto MMC', Volume: '100ul' }
   *   A2 => { RoboColumn: 'RoboColumn 2', Resin: 'Capto S', Volume: '50ul' }
   *   C8 => { RoboColumn: 'RoboColumn 30', Resin: 'Capto MMC', Volume: '100ul' }
   */
  contentsByWell: Map<string, T>;
  isDisabled?: boolean;
  onChange: (wellContents: Map<string, T>, valid: boolean) => void;
  /**
   * Optional function to return true if all the well parameters
   * can be saved (e.g. are all defined).
   */
  canSaveParameters?: (valid: boolean) => void;
};

export type OrderedParameter = Parameter & {
  /**
   * The original location of the parameter in the parameter group (which can be set in element
   * config).
   */
  order: number;
};

export type PlateContentParams = {
  /**
   * The antha type used to describe each plate, e.g. PlateName or PlateSetPrefix
   */
  plateNameType?: string;
  /**
   * The parameter where possible plate names can be found
   */
  plateNameParam?: Parameter;
  /**
   * Parameter that describes the locations for each content. This is of the type
   * `map[CONTENT_TYPE]WellLocations`, where CONTENT_TYPE is a string type (e.g.,
   * LiquidName, ResinName) and WellLocations is an array of wells (A1, B2, etc)
   */
  contentLocationParam: OrderedParameter;
  /**
   * Parameters that describe properties of the content. These are of the type
   * `map[CONTENT_NAME]PROPERTY_TYPE, where CONTENT_NAME matches the key of the
   * contentLocationParam type and PROPERTY_TYPE is any type except WellLocations.
   */
  contentPropertyParams: OrderedParameter[];
  plateTypeParam?: OrderedParameter;
};

/**
 * Given a list of input parameters, return the Content Location Parameter (if there is
 * one) and any Content Property Params.
 */
export function getPlateContentParams(
  params: readonly Parameter[],
): PlateContentParams | undefined {
  // Keep track of the original locations of the parameters for when we render them.
  const orderedParams: OrderedParameter[] = params.map((param, i) => ({
    ...param,
    order: i,
  }));
  const contentLocationParam = orderedParams.find(
    input =>
      (input.configuration?.editor.type ?? getDefaultEditorForAnthaType(input.type)) ===
      EditorType.PLATE_CONTENTS,
  );
  if (!contentLocationParam) {
    return;
  }
  const isNestedPlateContents = getValueTypeFromAnthaType(
    contentLocationParam.type,
  ).startsWith('map[');
  const plateNameType = isNestedPlateContents
    ? getKeyTypeFromAnthaType(contentLocationParam.type)
    : undefined;
  const contentNameType = getContentNameType(contentLocationParam, isNestedPlateContents);
  const contentPropertyParams = orderedParams.filter(param => {
    const valueMatchesContentName =
      param.name !== contentLocationParam.name &&
      getContentNameType(param, isNestedPlateContents) === contentNameType;
    if (plateNameType && isNestedPlateContents) {
      // If we have a plateNameType, then the parameter type we filter for
      // should include the plateNameType. Otherwise we might incorrectly filter
      // parameters that include just the contentNameType within a nested map.
      return valueMatchesContentName && param.type.includes(plateNameType);
    }
    return valueMatchesContentName;
  });
  const plateTypeParam = plateNameType
    ? orderedParams.find(param => param.type === `map[${plateNameType}]${PLATE_TYPE}`)
    : undefined;

  const plateNameParam = plateNameType
    ? params.find(param => param.type === `[]${plateNameType}`)
    : undefined;

  return {
    contentLocationParam,
    contentPropertyParams,
    plateTypeParam,
    plateNameType,
    plateNameParam,
  };
}

/**
 * Convert plate content parameter values to ContentsByWell.
 *
 * Example input:
 * ```
 * {
 *   LiquidLocations: { Water: ["A1"], Fairy: ["B1"] },
 *   LiquidVolume: { Water: "100ul", Fairy: "10ul" },
 * }
 * ```
 *
 * Output:
 * ```
 * Map(
 *   A1 => { LiquidLocations: "Water", LiquidVolume: "100ul" }
 *   B1 => { LiquidLocations: "Fairy", LiquidVolume: "10ul" }
 * )
 * ```
 *
 * If the contents are indexed by plate (plateContentParams.plateNameType), then plateName
 * must be provided.
 */
export function paramValuesToContentsByWell(
  paramValues: ParameterValueDict,
  plateContentParams: PlateContentParams,
  plateName: string = '',
): ContentsByWell {
  const { contentLocationParam, contentPropertyParams, plateNameType } =
    plateContentParams;
  const contentsByWell: ContentsByWell = new Map();
  const locationsByContentName: Record<string, string[]> | undefined = plateNameType
    ? paramValues[contentLocationParam.name]?.[plateName]
    : paramValues[contentLocationParam.name];
  for (const [contentName, locations] of Object.entries(locationsByContentName || {})) {
    if (!locations) {
      continue;
    }
    for (const location of locations) {
      // Eg. A1, A2
      contentsByWell.set(location, {
        [contentLocationParam.name]: contentName,
      });
    }
  }
  for (const contentParam of contentPropertyParams) {
    const contentByContentName: Record<string, string[]> | undefined = plateNameType
      ? paramValues[contentParam.name]?.[plateName]
      : paramValues[contentParam.name];
    for (const [contentName, content] of Object.entries(contentByContentName || {})) {
      const locations = locationsByContentName?.[contentName] || [];
      for (const location of locations) {
        const contentAtLocation = contentsByWell.get(location);
        if (!contentAtLocation) {
          continue;
        }
        contentAtLocation[contentParam.name] = content;
      }
    }
  }
  return contentsByWell;
}

/**
 * Convert ContentsByWell back to map parameter values.
 *
 * If the contents are indexed by plate (plateContentParams.plateNameType), then plateName
 * must be provided.
 *
 * Returns an object of parameter-values that have been changed
 */
export function contentsByWellsToParamValues(
  contentsByWell: ContentsByWell,
  plateContentParams: PlateContentParams,
  currParamValues: ParameterValueDict,
  plateName: string = '',
): ParameterValueDict {
  const { contentLocationParam, contentPropertyParams, plateNameType } =
    plateContentParams;

  const contentLocationParamValue: Record<string, string[]> = {};

  const contentPropertyParamValues: ParameterValueDict = {};

  for (const contentPropertyParam of contentPropertyParams) {
    contentPropertyParamValues[contentPropertyParam.name] = {};
  }

  for (const [wellLocation, wellParamValues] of contentsByWell) {
    // Get the map key, e.g. a liquid name.
    const contentName = wellParamValues[contentLocationParam.name] as string;
    if (!contentName) {
      continue;
    }
    if (!(contentName in contentLocationParamValue)) {
      contentLocationParamValue[contentName] = [];
    }
    contentLocationParamValue[contentName].push(wellLocation);

    for (const { name: paramName } of contentPropertyParams) {
      contentPropertyParamValues[paramName][contentName] = wellParamValues[paramName];
    }
  }

  if (!plateNameType) {
    return {
      [contentLocationParam.name]: contentLocationParamValue,
      ...contentPropertyParamValues,
    };
  } else {
    const newDoubleMapParams: ParameterValueDict = {};

    newDoubleMapParams[contentLocationParam.name] = produce<Record<string, unknown>>(
      currParamValues[contentLocationParam.name] ?? {},
      draft => {
        if (contentsByWell.size === 0) {
          delete draft[plateName];
        } else {
          draft[plateName] = contentLocationParamValue;
        }
      },
    );

    for (const [param, value] of Object.entries(contentPropertyParamValues)) {
      newDoubleMapParams[param] = produce<Record<string, unknown>>(
        currParamValues[param] ?? {},
        draft => {
          if (contentsByWell.size === 0) {
            delete draft[plateName];
          } else {
            draft[plateName] = value;
          }
        },
      );
    }

    return newDoubleMapParams;
  }
}

/**
 * Updates the oldPlateName from the relevant parameters in currParamValue that are
 * present in the plateContentParams, with the newPlateName
 * *
 * @param plateContentParams The relevant PlateContentParams associated with the currParamValues.
 * @param currParamValues The ParameterValueDict to update.
 * @param oldPlateName This plate name will be removed from the currParamValues.
 * @param newPlateName The oldPlateName will be replaced with this name.
 * @returns
 */
export function updatePlateNamesInParamValues(
  plateContentParams: PlateContentParams,
  currParamValues: ParameterValueDict,
  oldPlateName: string,
  newPlateName: string,
) {
  const { contentLocationParam, contentPropertyParams, plateTypeParam, plateNameParam } =
    plateContentParams;

  const newDoubleMapParams: ParameterValueDict = {};

  const contentPropertyParamValues: ParameterValueDict = {};

  for (const contentPropertyParam of [...contentPropertyParams, contentLocationParam]) {
    contentPropertyParamValues[contentPropertyParam.name] = {};
  }

  if (plateTypeParam) {
    newDoubleMapParams[plateTypeParam.name] = produce<Record<string, unknown>>(
      currParamValues[plateTypeParam.name] ?? {},
      draft => {
        // Some parameters are not required so might not be specified - in that case, skip.
        if (!currParamValues[plateTypeParam.name]?.[oldPlateName]) {
          return;
        }
        draft[newPlateName] = currParamValues[plateTypeParam.name][oldPlateName];
        delete draft[oldPlateName];
      },
    );
  }

  if (plateNameParam) {
    newDoubleMapParams[plateNameParam.name] = produce<Record<string, unknown>>(
      currParamValues[plateNameParam.name] ?? {},
      draft => {
        if (Array.isArray(draft)) {
          const index = draft.indexOf(oldPlateName);
          if (newPlateName && index >= 0) {
            draft[index] = newPlateName;
          }
        }
        return;
      },
    );
  }

  for (const [param, _value] of Object.entries(contentPropertyParamValues)) {
    newDoubleMapParams[param] = produce<Record<string, unknown>>(
      currParamValues[param] ?? {},
      draft => {
        // Some parameters are not required so might not be specified - in that case, skip.
        if (!currParamValues[param]?.[oldPlateName]) {
          return;
        }
        draft[newPlateName] = currParamValues[param][oldPlateName];
        delete draft[oldPlateName];
      },
    );
  }

  return { ...currParamValues, ...newDoubleMapParams };
}

/**
 * Deleted the plateNameToRemove from the relevant parameters in currParamValue that are
 * present in the plateContentParams.
 * *
 * @param plateContentParams The relevant PlateContentParams associated with the currParamValues.
 * @param currParamValues The ParameterValueDict to update.
 * @param plateNameToRemove This plate name will be removed from the currParamValues.
 * @returns
 */
export function deletePlateNamesInParamValues(
  plateContentParams: PlateContentParams,
  currParamValues: ParameterValueDict,
  plateNameToRemove: string,
) {
  const { contentLocationParam, contentPropertyParams, plateTypeParam, plateNameParam } =
    plateContentParams;

  const newDoubleMapParams: ParameterValueDict = {};

  const contentPropertyParamValues: ParameterValueDict = {};

  for (const contentPropertyParam of [...contentPropertyParams, contentLocationParam]) {
    contentPropertyParamValues[contentPropertyParam.name] = {};
  }

  if (plateTypeParam) {
    newDoubleMapParams[plateTypeParam.name] = produce<Record<string, unknown>>(
      currParamValues[plateTypeParam.name] ?? {},
      draft => {
        if (currParamValues[plateTypeParam.name]?.[plateNameToRemove]) {
          delete draft[plateNameToRemove];
        }
      },
    );
  }

  if (plateNameParam) {
    newDoubleMapParams[plateNameParam.name] = produce<Record<string, unknown>>(
      currParamValues[plateNameParam.name] ?? {},
      draft => {
        if (Array.isArray(draft)) {
          const index = draft.indexOf(plateNameToRemove);
          if (index >= 0) {
            draft.splice(index, 1);
          }
        }
      },
    );
  }

  for (const [param, _value] of Object.entries(contentPropertyParamValues)) {
    newDoubleMapParams[param] = produce<Record<string, unknown>>(
      currParamValues[param] ?? {},
      draft => {
        if (currParamValues[param]?.[plateNameToRemove]) {
          delete draft[plateNameToRemove];
        }
      },
    );
  }

  return { ...currParamValues, ...newDoubleMapParams };
}

/**
 * Returns parameters for modifying the content of a well within the Plate Contents
 * Editor. This comprises a parameter for editing the key of the Content Location Param and
 * parameters for each value of the Content Property Params.
 */
export function generatePlateContentParams(
  plateContentParams: PlateContentParams,
): Parameter[] {
  return [
    generateContentLocationParam(plateContentParams),
    ...plateContentParams.contentPropertyParams.map(param =>
      generateContentPropertyParam(plateContentParams, param),
    ),
  ].sort((paramA, paramB) => paramA.order - paramB.order);
}

/**
 * Return a parameter for editing the key of the Content Location Param. For example, if
 * the param is of type `map[LiquidName]WellLocations`, we return a LiquidName parameter.
 * If ignoreWhen grouping is set, then this will return a map parameter, e.g.
 * map[WellLocation][LiquidName].
 */
function generateContentLocationParam(
  plateContentParams: PlateContentParams,
): OrderedParameter {
  const param = plateContentParams.contentLocationParam;
  const mapType = plateContentParams.plateNameType
    ? getValueTypeFromAnthaType(param.type)
    : param.type;
  const additionalProps = getPlateContentsAdditionalProps(plateContentParams);
  const editor = additionalProps?.contentEditor;

  if (additionalProps?.ignoreWhenGrouping) {
    const defaultConfig = {
      displayName: param.name,
      displayDescription: '' as Markdown,
      isVisible: true,
    };
    // Return an inline map parameter
    return {
      ...param,
      type: `map[string]${getKeyTypeFromAnthaType(mapType)}`,
      configuration: {
        ...(param.configuration ?? defaultConfig),
        editor: {
          type: EditorType.MAP,
          placeholder: '',
          additionalProps: {
            editor: EditorType.MAP,
            inline: true,
            disableKeys: true,
          },
        },
      },
    };
  }

  return {
    ...param,
    // Instead of showing the original map parameter, we just show an editor for
    // modifying the key.
    type: getKeyTypeFromAnthaType(mapType),
    configuration: param.configuration && editor && { ...param.configuration, editor },
  };
}

/**
 * Return a parameter for editing the value of a Content Property Param. For example, for
 * a Content Property Param with the type `map[LiquidName]Volume`, we return a Volume
 * parameter.
 */
function generateContentPropertyParam(
  plateContentParams: PlateContentParams,
  param: OrderedParameter,
): OrderedParameter {
  const mapType = plateContentParams.plateNameType
    ? getValueTypeFromAnthaType(param.type)
    : param.type;

  const editor =
    param.configuration && getValueEditorRecursive(param.configuration?.editor);

  return {
    ...param,
    // Instead of showing the original map parameter, we just show an editor for
    // modifying the key.
    type: getValueTypeFromAnthaType(mapType),
    configuration: param.configuration && editor && { ...param.configuration, editor },
  };
}

/**
 * Returns the key of a map type. If indexed by plate (ie a double map), return the key of
 * the nested map.
 */
function getContentNameType(
  param: Parameter,
  isIndexedByPlate: boolean,
): string | undefined {
  try {
    return isIndexedByPlate
      ? getKeyTypeFromAnthaType(getValueTypeFromAnthaType(param.type))
      : getKeyTypeFromAnthaType(param.type);
  } catch {
    return undefined;
  }
}

/**
 * For a given map editor, recurse into it to get the nested value editor.
 *
 * This is used to get the value editor for plate contents parameters, which can be single
 * maps (indexed by content name) or double maps (indexed by plate name and content name).
 */
function getValueEditorRecursive(
  editor: ParameterEditorConfigurationSpec,
): ParameterEditorConfigurationSpec | undefined {
  if (editor?.additionalProps?.editor !== EditorType.MAP) {
    return;
  }
  const valueEditor = editor.additionalProps.valueEditor;
  if (valueEditor?.additionalProps?.editor === EditorType.MAP) {
    // The value editor is a map, so recurse into it to get its value editor.
    return getValueEditorRecursive(valueEditor);
  }
  return valueEditor;
}

/**
 * Generate the title for a given group of wells given the parameter values for that well.
 *
 * If a title template has been specified in element config, then return the template with
 * double-braced parameter names (e.g. {{LiquidName}} ) replaced with their respective
 * values.
 *
 * Otherwise return the value of the content location param.
 */
export function getWellGroupTitle(
  plateContentParams: PlateContentParams,
  wellParamValues?: ParameterValueDict,
): string {
  const template =
    getPlateContentsAdditionalProps(plateContentParams)?.wellGroupTitleTemplate;
  if (!wellParamValues) {
    return 'Empty';
  }
  if (!template) {
    return wellParamValues[plateContentParams.contentLocationParam.name] || '';
  }
  return template.replace(
    /\{\{([^}]+)\}\}/g,
    (_, paramName) => wellParamValues[paramName] || '',
  );
}

// The 'group ID' determines the group that a well is within. We use the key of the well
// locations param. For example, for a parameter with type map[LiquidName]WellLocations,
// wells will be grouped by LiquidName.
export function getWellGroupID(
  plateContentParams: PlateContentParams,
  wellParamValues?: ParameterValueDict,
) {
  const params: Parameter[] = [];
  if (!getPlateContentsAdditionalProps(plateContentParams)?.ignoreWhenGrouping) {
    params.push(plateContentParams.contentLocationParam);
  }
  params.push(...plateContentParams.contentPropertyParams);
  return params.map(param => wellParamValues?.[param.name]).join('-');
}

export function getPlateContentsAdditionalProps(
  plateContentParams: PlateContentParams,
): PlateContentsAdditionalProps | undefined {
  const additionalProps =
    plateContentParams.contentLocationParam.configuration?.editor.additionalProps;
  return additionalProps?.editor === EditorType.PLATE_CONTENTS
    ? additionalProps
    : undefined;
}

/**
 * Returns an array containing all unique liquid identifier names in contentsByWell.
 * The names are retrieved using the contentLocationParam.name as an index.
 */
export function getAllLiquidIdentifierNames(
  contentsByWell: ContentsByWell,
  plateContentParams: PlateContentParams,
): string[] {
  const { contentLocationParam } = plateContentParams;
  const contentsNames: string[] = [];
  for (const wellParamValues of contentsByWell.values()) {
    const contentName = wellParamValues[contentLocationParam.name] as string;
    if (contentName) {
      contentsNames.push(contentName);
    }
  }
  return [...new Set(contentsNames)];
}

/**
 * Returns the first liquid identifier name in the contentsByWell that is found using
 * the contentLocationParam.name field as an index. Assumes that the contentsByWell are
 * from a specific well group, such that there should only be one liquid identifier.
 */
export function getLiquidIdentifierForGroup(
  contentsByWell: ContentsByWell,
  plateContentParams: PlateContentParams,
): string {
  const { contentLocationParam } = plateContentParams;
  for (const wellParamValues of contentsByWell.values()) {
    const contentName = wellParamValues[contentLocationParam.name] as string;
    if (contentName) {
      return contentName;
    }
  }
  return '';
}

/**
 * Given a Map of well -> content, return only those that exist on the plate.
 */
export function cropContentsByWellToPlate(
  contentsByWell: ContentsByWell,
  plateType: PlateType | undefined,
): ContentsByWell {
  if (!plateType) return contentsByWell;

  const result: ContentsByWell = new Map();

  contentsByWell.forEach((contents, well) => {
    if (wellLocationExists(well, plateType)) {
      result.set(well, contents);
    }
  });

  return result;
}

/*
 * Return a mapping of the values and labels of a plate content parameter if the parameter
 * has a configuration set for a nested Dropdown.
 *
 * @param parameter A plate content parameter associated with PlateContentParams type. The type would
 * be a nested map type, with the value of the nested map having a configuration for Dropdown.
 * @returns Mapped object of the value and the label from the configuration.
 */
export function getContentParameterDropdownLabelsFromConfig(
  parameter: Parameter | undefined,
): {
  [value: string]: string;
} {
  const mappedValues: {
    [value: string]: string;
  } = {};
  if (!parameter?.configuration || parameter.configuration.editor.type !== 'MAP') {
    return mappedValues;
  }
  const mapProps = parameter.configuration.editor.additionalProps as MapAdditionalProps;
  if (!mapProps.valueEditor || mapProps.valueEditor.type !== 'MAP') {
    return mappedValues;
  }
  const nestedMapProps = mapProps.valueEditor.additionalProps as MapAdditionalProps;
  if (nestedMapProps.valueEditor?.type !== 'DROPDOWN') {
    return mappedValues;
  }
  const dropdownProps = nestedMapProps.valueEditor
    .additionalProps as DropdownAdditionalProps;
  dropdownProps.options.map(option => (mappedValues[option.value] = option.label));
  return mappedValues;
}
