import { useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { tooltipClasses } from '@mui/material/Tooltip';
import isEqual from 'lodash/isEqual';
import moment, { Moment } from 'moment';

import {
  QUERY_ALL_DEVICES,
  QUERY_DEVICE_BY_ID_FOR_RUN_CONFIG,
  QUERY_DEVICE_CONFIG_RESPONSE,
} from 'client/app/api/gql/queries';
import {
  DeviceCommonFragment as DeviceCommon,
  DeviceParsedRunConfigQuery,
} from 'client/app/gql';
import {
  configHasOutdatedDeviceRunConfig,
  getDeviceConfigurationForUI,
  hasDispenserOrManualDevice,
  isLiquidHandlingDevice,
} from 'client/app/lib/workflow/deviceConfigUtils';
import {
  isNamedPlate,
  LabwareType,
  SimpleLabwareType,
} from 'client/app/state/LabwarePreference';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { GlobalMixerConfig, WorkflowDeviceConfiguration } from 'common/types/bundle';
import { ValidAddresses } from 'common/types/device';
import Colors from 'common/ui/Colors';
import { usePopover } from 'common/ui/hooks/usePopover';
import useThrottle from 'common/ui/hooks/useThrottle';

export const DEFAULT_LAYOUT_LABEL = 'Default Layout';
export const DECK_LOCATION_CSS_CLASS = 'deckLocation';
export const SMALL_DECK_POSITION_SIZE = 100;

export type RunConfiguration = {
  label: string;
  value: UUID;
  version: number;
  lastModifiedAt: Moment | null;
};

/*
 * We need to determine which device is the main device (which is assumed to be one liquid handler)
 * Currently we are assuming it is the main device if there is only one device selected, if the device is a Gilson,
 * has a run config already, has accessible device ids, or isLiquidHandlingDevice().
 *
 * We will be enforcing that you can only have one liquid handler device before you get to picking the run config.
 * Note: Device will always be selected first.
 */
export function getSelectedMainDevice(
  deviceConfiguration: WorkflowDeviceConfiguration,
  devices: DeviceCommon[],
) {
  if (Object.keys(deviceConfiguration).length === 1) {
    const selectedDeviceId = Object.keys(deviceConfiguration)[0];
    return {
      selectedDevice: deviceConfiguration[selectedDeviceId],
      deviceId: selectedDeviceId,
      selectedDeviceCommon: devices.find(device => device.id === selectedDeviceId),
    };
  }

  for (const [deviceId, device] of Object.entries(deviceConfiguration)) {
    const deviceCommon = devices.find(device => device.id === deviceId);
    const isLiquidHandler = deviceCommon ? isLiquidHandlingDevice(deviceCommon) : false;
    if (
      device.runConfigId ||
      device.accessibleDeviceIds ||
      device.anthaLangDeviceClass === 'GilsonPipetMax' ||
      isLiquidHandler
    ) {
      return {
        selectedDevice: device,
        deviceId,
        selectedDeviceCommon: deviceCommon,
      };
    }
  }
  // Shouldn't reach this scenario because we should do a check to enforce only one liquid handler.
  return {
    selectedDevice: undefined,
    deviceId: '',
    selectedDeviceCommon: undefined,
  };
}

/**
 * Returns the devices in the workflow in the format of DeviceCommon[].
 */
export function useGetDeviceCommonForWorkflow(
  deviceConfiguration: WorkflowDeviceConfiguration,
): DeviceCommon[] {
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const deviceIds = Object.keys(deviceConfiguration);
  return data?.devices.filter(device => deviceIds.includes(device.id)) ?? [];
}

/**
 * Returns the main selected device in the workflow builder reducer config.
 * See getSelectedMainDevice()
 */
export function useGetSelectedMainDevice() {
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  const deviceConfiguration = getDeviceConfigurationForUI(workflowConfig);
  const devices = useGetDeviceCommonForWorkflow(deviceConfiguration);
  return getSelectedMainDevice(deviceConfiguration, devices);
}

export function useIsResetButtonEnabled(
  loadingSelectedRunConfig: boolean | string = false,
) {
  const { workflowConfig } = useWorkflowBuilderSelector(state => ({
    workflowConfig: state.config,
  }));
  const { selectedRunConfigId, selectedRunConfig } = useSelectedRunConfig();
  const { deviceId } = useGetSelectedMainDevice();
  const { data } = useQuery(QUERY_ALL_DEVICES);
  const { loading: loadingRunConfig, data: runConfigData } = useParseDeviceRunConfig(
    deviceId,
    selectedRunConfigId,
  );
  const { loading: loadingAllRunConfigs, allRunConfigOptions } = useGetDeviceRunConfigs();
  const areConfigsLoading =
    loadingAllRunConfigs || loadingRunConfig || loadingSelectedRunConfig;
  return useMemo(() => {
    if (
      areConfigsLoading ||
      // We disable if there are multiple run configs, but the user has not selected one (i.e. is set to the default)
      (allRunConfigOptions.length > 0 &&
        selectedRunConfig?.label === DEFAULT_LAYOUT_LABEL)
    ) {
      return false;
    }
    const newGlobalMixer = formatDefaultLayoutOptions(runConfigData);
    const allDevices = data?.devices ?? [];
    return (
      hasConfigurationChanges(workflowConfig.GlobalMixer, {
        ...workflowConfig.GlobalMixer,
        ...newGlobalMixer,
      }) ||
      configHasOutdatedDeviceRunConfig(
        allDevices,
        getDeviceConfigurationForUI(workflowConfig),
      )
    );
  }, [
    allRunConfigOptions.length,
    areConfigsLoading,
    data?.devices,
    runConfigData,
    selectedRunConfig?.label,
    workflowConfig,
  ]);
}

export function useSelectedRunConfig() {
  const { loading: loadingAllRunConfigs, allRunConfigOptions } = useGetDeviceRunConfigs();
  const { selectedDevice } = useGetSelectedMainDevice();
  return useMemo(() => {
    if (loadingAllRunConfigs) {
      return {
        selectedRunConfigId: undefined,
        selectedRunConfig: {
          value: undefined,
          label: 'Loading...',
          version: undefined,
          lastModifiedAt: null,
        },
      };
    }
    const selectedRunConfigId = selectedDevice?.runConfigId;
    const selectedRunConfig = allRunConfigOptions.find(
      config => config.value === selectedRunConfigId,
    );
    if (selectedRunConfig) {
      return {
        selectedRunConfigId: selectedRunConfigId,
        selectedRunConfig: selectedRunConfig,
      };
    }

    return {
      selectedRunConfigId: undefined,
      selectedRunConfig: {
        value: undefined,
        label: DEFAULT_LAYOUT_LABEL,
        version: undefined,
        lastModifiedAt: null,
      },
    };
  }, [allRunConfigOptions, loadingAllRunConfigs, selectedDevice?.runConfigId]);
}

export function useIsMissingSelectedRunConfig() {
  const { selectedDevice, selectedDeviceCommon } = useGetSelectedMainDevice();
  const { selectedRunConfigId } = useSelectedRunConfig();
  const workflowConfig = useWorkflowBuilderSelector(state => state.config);
  return (
    !selectedRunConfigId &&
    selectedDevice?.anthaLangDeviceClass !== 'GilsonPipetMax' &&
    selectedDeviceCommon?.model.series.category.name !== 'Analytic Devices' &&
    !hasDispenserOrManualDevice(workflowConfig)
  );
}

/**
 * We store the workflow config in the format of WorkflowConfig, but in the UI, we tend to use the
 * data in the format of WorkflowDeviceConfiguration because it is not Device specific.
 *
 * In order to show the various run configs available for the selected device, we need to do some
 * data massaging.
 * We need to query all of necessary run config details (id + name) for this particular device.
 *
 * This function returns the available run configs for the selected device (if they are available.)
 *
 * Note: This is only to be used in the workflow builder since we are getting the state from the
 * Workflow Builder State Context directly.
 */
export function useGetDeviceRunConfigs() {
  const { deviceId } = useGetSelectedMainDevice();
  const { loading, data, error } = useQuery(QUERY_DEVICE_BY_ID_FOR_RUN_CONFIG, {
    variables: { id: deviceId as DeviceId },
  });

  const allRunConfigOptions = useMemo<RunConfiguration[]>(() => {
    const allRunConfigs = data?.device.runConfigSummaries || [];
    return allRunConfigs
      .map(config => ({
        label: config.name.trim(),
        value: config.id,
        version: config.version,
        lastModifiedAt: config.lastModifiedAt ? moment(config.lastModifiedAt) : null,
      }))
      .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
  }, [data?.device.runConfigSummaries]);
  return { loading, error, allRunConfigOptions };
}

/**
 * Calls Appserver which calls Antha Core Service to get the proper deck layout
 * based on the run config specified.
 *
 * If run config id is not passed in, we will return the default run config
 * even if there are multiple configs available for this particular device. Generally, we will make
 * users pick the run config before parsing the device config.
 *
 * In the case of Gilson, there is no config id, but there is still a deck layout available.
 */
export function useParseDeviceRunConfig(deviceId: string, runConfigId?: string) {
  const { data, loading, error } = useQuery(QUERY_DEVICE_CONFIG_RESPONSE, {
    variables: { id: deviceId, runConfigId },
  });
  return { data, loading, error };
}

export type LayoutOptions = Record<SimpleLabwareType, string[]>;

/**
 * Returns the valid positions present in validAddresses for the given labwareType.
 * For namedPlates, we create a union of the inputPlates and outputPlates as valid
 * positions.
 */
export function getValidPositionsForLabwareType(
  labwareType: LabwareType,
  validAddresses: LayoutOptions,
): string[] {
  let validPositions: string[];
  if (isNamedPlate(labwareType)) {
    validPositions = [
      ...new Set([...validAddresses['inputPlates'], ...validAddresses['outputPlates']]),
    ];
  } else {
    validPositions = validAddresses[labwareType] ?? [];
  }
  return validPositions;
}

/**
 * Our run config response for validAddresses does not map directly to our labware types.
 * This function performs that mapping to return the list of valid positions for
 * each of the labware types.
 */
export function formatValidAddresses(validAddresses: ValidAddresses): LayoutOptions {
  return {
    inputPlates: validAddresses.inputs,
    outputPlates: validAddresses.outputs,
    tipBoxes: validAddresses.tipboxes,
    tipWastes: validAddresses.tipwastes,
    temporaryLocations: validAddresses.temporaryLocations,
    // moveableLocations is not included in the SIMPLE_LABWARE_TYPE deliberately
  };
}

/**
 * Converts the relevant default layout options from DeviceParsedRunConfig data into a
 * partial GlobalMixerConfig with the relevant fields updated.
 */
export function formatDefaultLayoutOptions(
  config: DeviceParsedRunConfigQuery | undefined,
): Partial<GlobalMixerConfig> {
  const defaultLayoutOptions = config?.parsedRunConfig.config.defaultLayoutOptions;
  return {
    driverSpecificInputPreferences: defaultLayoutOptions?.inputs || [],
    driverSpecificOutputPreferences: defaultLayoutOptions?.outputs || [],
    driverSpecificTipWastePreferences: defaultLayoutOptions?.tipwastes || [],
    driverSpecificTipPreferences: defaultLayoutOptions?.tipboxes || [],
    driverSpecificTemporaryLocations: defaultLayoutOptions?.temporaryLocations || [],
    driverSpecificPlatePreferences: {},
  };
}

/**
 * We only care about some fields to compare if changes have been made. Currently, these
 * are the fields that would be set by the user interacting with the Deck Options
 * Panel, which are the equivalent to what we would retrieve as defaultLayoutOptions as a
 * response from antha core service.
 */
function getDriveSpecificFieldsToCompare(config: GlobalMixerConfig) {
  const {
    driverSpecificInputPreferences,
    driverSpecificOutputPreferences,
    driverSpecificTipWastePreferences,
    driverSpecificTipPreferences,
    driverSpecificTemporaryLocations,
    driverSpecificPlatePreferences,
  } = config;
  return {
    driverSpecificInputPreferences,
    driverSpecificOutputPreferences,
    driverSpecificTipWastePreferences,
    driverSpecificTipPreferences,
    driverSpecificTemporaryLocations,
    driverSpecificPlatePreferences,
  };
}

export function hasConfigurationChanges(
  configuration: GlobalMixerConfig,
  originalConfiguration: GlobalMixerConfig,
): boolean {
  return !isEqual(
    getDriveSpecificFieldsToCompare(configuration),
    getDriveSpecificFieldsToCompare(originalConfiguration),
  );
}

/**
 * Some plates are too small to show deck position name or any other extra information.
 * For this there is a tooltip to contain that information for smaller plates.
 * This function returns styles for this tooltip.
 */
export function getSmallDeckPositionTooltipStyles(
  isDeckPositionSelectable: boolean = false,
) {
  return {
    backgroundColor: Colors.GREY_0,
    border: `1px solid ${isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_30}`,
    padding: 4,

    [`& .${tooltipClasses.arrow}`]: {
      color: isDeckPositionSelectable ? Colors.BLUE_50 : Colors.GREY_0,
    },
  };
}

export function useDeckPositionTooltip() {
  const { isPopoverOpen, onShowPopover, onHidePopover } = usePopover();
  const [lock, setLock] = useState(false);

  return {
    open: !lock && isPopoverOpen,
    lock: () => setLock(true),
    unlock: () => setLock(false),
    show: (event: React.MouseEvent<HTMLElement>) => !lock && onShowPopover(event),
    hide: () => onHidePopover(),
    hideThrottled: useThrottle(onHidePopover, 100),
  };
}
