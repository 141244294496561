import React, { ReactElement } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import ZoomOutMapOutlinedIcon from '@mui/icons-material/ZoomOutMapOutlined';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import Slider from 'common/ui/components/Slider';
import Switch from 'common/ui/components/Switch';
import Tooltip from 'common/ui/components/Tooltip';
import MouseModeControl from 'common/ui/components/Workspace/MouseModeControl';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DarkTheme } from 'common/ui/theme';

type Props = {
  onShowHelp?: () => void;
  onShowAll: () => void;
  onZoomChange: (ratio: number) => void;
  isGridSwitchVisible?: boolean;
  setGridVisible?: (visible: boolean) => void;
  gridVisible?: boolean;
  currentZoomRatio: number;
  disableShowAllButton?: boolean;
  status?: ReactElement;
  canvasControlVariant: CanvasControlVariant;
  disabledMouseSelect?: boolean;
  showHelpIcon?: ReactElement;
  showHelpTooltip?: string;
  showMouseModeControl?: boolean;
};

export type CanvasControlVariant = 'dark' | 'light' | 'light_float';
export function isLightVariant(canvasControlVariant: CanvasControlVariant) {
  return ['light', 'light_float'].includes(canvasControlVariant);
}
export function isFloatingVariant(canvasControlVariant: CanvasControlVariant) {
  return ['light_float'].includes(canvasControlVariant);
}

const CONTAINER_HEIGHT = 32;
const BORDER_HEIGHT = 1;
export const CANVAS_CONTROL_HEIGHT = `${CONTAINER_HEIGHT + BORDER_HEIGHT}px`;

export default React.memo(function CanvasControl({
  onShowHelp,
  onShowAll,
  onZoomChange,
  setGridVisible,
  gridVisible,
  isGridSwitchVisible: showGridSwitch,
  currentZoomRatio,
  disableShowAllButton,
  showMouseModeControl,
  disabledMouseSelect,
  canvasControlVariant,
  showHelpIcon = <HelpIcon />,
  showHelpTooltip = 'Show help',
  status,
}: Props) {
  const classes = useStyles();

  // Numbers will come in as a ratio but in this component we want to deal with them
  // as percentages, so some simple conversions.
  const toRatio = (num: number) => {
    return num / 100;
  };
  const toPercent = (num: number) => {
    return num * 100;
  };

  const currentZoomPercent = toPercent(currentZoomRatio);

  const handleZoomChange = (event: object, newValue: number | number[]) => {
    const value = typeof newValue == 'number' ? newValue : newValue[0];
    onZoomChange(toRatio(value));
  };

  const isLight = isLightVariant(canvasControlVariant);
  const isFloating = isFloatingVariant(canvasControlVariant);
  const Theme = !isLight ? DarkTheme : React.Fragment;
  return (
    <Theme>
      <Paper
        className={cx(
          classes.controlBarContainer,
          isLight && classes.light,
          isFloating && classes.floating,
          !isFloating && classes.controlBarContainerMinHeight,
        )}
      >
        {status}
        <div className={classes.controls}>
          {showMouseModeControl && (
            <>
              <MouseModeControl
                canvasControlVariant={canvasControlVariant}
                disabledMouseSelect={disabledMouseSelect}
              />
              <Divider className={classes.divider} orientation="vertical" />
            </>
          )}
          <Tooltip title="Show all (a)">
            <span>
              <IconButton
                className={cx(classes.iconButton, classes.showAllButton)}
                disabled={disableShowAllButton}
                size="xsmall"
                onClick={onShowAll}
                icon={<ZoomOutMapOutlinedIcon />}
              />
            </span>
          </Tooltip>
          <Slider
            classes={{
              root: classes.zoomSlider,
            }}
            size="small"
            max={100}
            min={0}
            value={currentZoomPercent}
            onChange={handleZoomChange}
          />
          {showGridSwitch ? (
            <>
              <Divider className={classes.divider} orientation="vertical" />
              <Tooltip title={gridVisible ? 'Hide Grid' : 'Show Grid'}>
                <span>
                  <Switch
                    checked={gridVisible}
                    onChange={(_, value: boolean) => {
                      setGridVisible?.(value);
                    }}
                  />
                </span>
              </Tooltip>
            </>
          ) : null}
          {onShowHelp && (
            <>
              <Divider className={classes.divider} orientation="vertical" />
              <Tooltip title={showHelpTooltip}>
                <IconButton
                  className={classes.iconButton}
                  size="xsmall"
                  onClick={onShowHelp}
                  icon={showHelpIcon}
                />
              </Tooltip>
            </>
          )}
        </div>
      </Paper>
    </Theme>
  );
});

const useStyles = makeStylesHook(theme => ({
  controlBarContainer: {
    '&.MuiPaper-root': {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      width: '100%',
      height: `${CONTAINER_HEIGHT}px`,
      borderTop: `${BORDER_HEIGHT}px solid ${Colors.GREY_50}`,
    },
  },
  controlBarContainerMinHeight: {
    '&.MuiPaper-root': {
      minHeight: `${CONTAINER_HEIGHT}px`,
    },
  },
  controls: {
    gridColumn: 2,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  divider: {
    '&.MuiDivider-root': {
      height: '16px',
      margin: theme.spacing(0, 5),
    },
  },
  floating: {
    '&.MuiPaper-root': {
      position: 'absolute',
      display: 'flex',
      bottom: theme.spacing(3),
      left: theme.spacing(3),
      width: 'auto',
      zIndex: 1,
      borderRadius: '4px',
      padding: theme.spacing(3),
    },
  },
  light: {
    '&.MuiPaper-root': {
      borderTop: `${BORDER_HEIGHT}px solid ${Colors.GREY_10}`,
      '& $iconButton': {
        color: Colors.TEXT_PRIMARY,
        '&:disabled': {
          color: Colors.ACTION_DISABLED,
        },
      },
    },
  },
  iconButton: {
    color: Colors.DARK_TEXT_SECONDARY,
    '&:hover': {
      color: Colors.BLUE_40,
      backgroundColor: 'inherit',
    },
  },
  showAllButton: {
    '&.MuiButtonBase-root': {
      margin: theme.spacing(0, 5, 0, 0),
    },
  },
  zoomSlider: {
    '&.MuiSlider-root': {
      margin: theme.spacing(0, 2),
      width: '160px',
    },
  },
}));
