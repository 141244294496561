import memo from 'lodash/memoize';

import { FeatureToggle } from 'common/features/featureToggles';
import {
  ElementConfigurationSpec,
  ParameterConfigurationSpec,
} from 'common/types/elementConfiguration';

export const DEFAULT_WORKFLOW_NAME = 'Untitled workflow';

export type Terminus = {
  ElementInstance: string;
  ParameterName: string;
};

export type Connection = {
  /** The output port of an element */
  Source: Terminus;
  /** The input port of an element */
  Target: Terminus;
};

export type Elements = {
  Instances: { [name: string]: ElementInstance };
  /**
   * A list of how values flow from one instance to another.
   */
  InstancesConnections: Connections;
};

type Size = {
  width: number;
  height: number;
};

export type ElementInstanceStatus = 'neutral' | 'warning' | 'error' | 'ok';

export type ElementInstanceMeta = {
  readonly x: number;
  readonly y: number;
  readonly annotation?: string;
  /**
   * These store the measured size of the element. They are not persisted to
   * the server as they will be reset whenever the workflow loads anyway.
   */
  readonly nameSize?: Size;
  readonly bodySize?: Size;
  /**
   * Parameter validation error comming from running elements through the planner.
   */
  readonly errors?: ElementError[];
  readonly status?: ElementInstanceStatus;
  readonly dirty?: boolean;
  readonly outputs?: ElementOutputs;
  readonly showValidation?: boolean;
};

export type ElementInstance = Readonly<{
  TypeName: string;
  Meta: ElementInstanceMeta;
  /** Added to make antha-core produce deterministic results. */
  Id: string;
  /**
   * The name is actually the key in the V2 workflow's instances map rather
   * than being a property of the object itself as it is here, but we include
   * it for convenience in the UI.
   */
  name: string;
  /**
   * There is no pointer to the element in the V2 workflow but it is
   * also included here for convenience.
   */
  element: Element;
}>;

export type Element = {
  id: string;
  description: string;
  elementSetId: string | null | undefined;
  inputs: readonly Parameter[];
  isDeprecated: boolean;
  name: string;
  outputs: readonly Parameter[];
  tags: readonly string[];
  configuration: ElementConfigurationSpec | null;
  releaseQuality: string;
  sourceCodeURL?: string | null;
};

export type Parameter = {
  description: string;
  name: string;
  /** The antha-lang type (float, wtype.Liquid, etc.) */
  type: string;
  /** This will be taken from the element's configuration if it has one,
   * otherwise it will be taken from the element itself. */
  groupName?: string;
  groupDescription?: string;
  configuration?: ParameterConfigurationSpec | null;
};

/**
 * The part of the workflow config that's global, not specific per device.
 */
export type GlobalMixerConfig = {
  allocateInputsVersion: number;
  ignorePhysicalSimulation: boolean;
  hamiltonStandardLanguage: boolean;
  barrierMerging: boolean;
  useDriverTipTracking: boolean;
  useTipboxAutofill: boolean;
  liquidHandlingPolicyXlsxJmpFile?: string;
  liquidHandlingPolicyXlsxJmpFileName?: string;

  balancingStrategy: 'empty' | 'manual volume' | 'automated volume';
  balancingTolerance: number;
  isCentrifugeEnabled: boolean;

  requiresDevice?: boolean;

  // Needed for UI until the config UI is rebuilt. Imagine the following:
  // The user selects an input plate type in the UI *before* selecting a device.
  // We have to store this information somewhere. The config UI should be rebuilt
  // so that you have select a device *first*, and only then can select options that
  // make sense for that device. Until the config UI is rebuilt, we store the info here.
  /** Example: ['pcrplate_skirted_riser18'] */
  inputPlateTypes?: readonly string[];

  // Needed for until the config UI is rebuilt
  /** Example: ['Tecan5000'] */
  tipTypes?: readonly string[];

  // Needed for until the config UI is rebuilt
  /** Example: ['TecanPos_2_1'] */
  temporaryLocations?: readonly string[];

  // Settings that affect where deck items are placed
  driverSpecificInputPreferences: readonly string[];
  driverSpecificOutputPreferences: readonly string[];
  // PlatePreferences can be used to override Input and Output Preferences
  driverSpecificPlatePreferences: {
    [plateNamePrefix: string]: readonly string[];
  };
  driverSpecificTipPreferences: readonly string[];
  driverSpecificTipWastePreferences: readonly string[];
  driverSpecificTemporaryLocations: readonly string[];
};

/**
 * Workflow config for a specific single device.
 * For example, if a workflow uses a qPCR machine and a Tecan liquid handler,
 * there would be one of these for the qPCR machine, and one for the Tecan.
 */
export type PerDeviceWorkflowConfig = {
  /**
   * Null if the device has no run configs (e.g. Gilson).
   * We want to make this null, not optional, to make it explicit when
   * a device has no run config.
   */
  runConfigId: string | null;
  runConfigVersion?: number;
  /** Example: ['pcrplate_skirted_riser18'] */
  inputPlateTypes?: readonly string[];
  /** Example: ['Tecan5000'] */
  tipTypes?: readonly string[];
  /** Example: ['TecanPos_2_1'] */
  temporaryLocations?: readonly string[];
  /**
   * Devices accessible by this device.
   * Design doc:
   * https://paper.dropbox.com/doc/Accessible-Devices-design-doc--A2drRA_43op5RoYK_Wv_8ncsAg-yZO18LmdKs6QXp6mFeI41
   */
  accessibleDeviceIds?: readonly string[];
};

/**
 * Groups configs for devices of the same class together.
 * For example, if the workflow uses multiple liquid handlers of class 'Tecan',
 * those liquid handlers would be grouped here.
 */
export type PerDeviceClassWorkflowConfig = {
  Devices: {
    [deviceId: string]: PerDeviceWorkflowConfig;
  };
};

/**
 * Config of a workflow, v2 format.
 * This is the format used in the WorkflowBuilderStateContext and Postgres,
 * and is understood by Antha core.
 */
// See config.go for the definition in Antha core
export type WorkflowConfig = {
  GlobalMixer: GlobalMixerConfig;
  // List the device classes below explicitly. Modeling these in a generic way (dictionary)
  // is difficult in TypeScript, because there's also the fixed key 'GlobalMixer' above.
  GilsonPipetMax?: PerDeviceClassWorkflowConfig;
  Tecan?: PerDeviceClassWorkflowConfig;
  TecanFluent?: PerDeviceClassWorkflowConfig;
  Hamilton?: PerDeviceClassWorkflowConfig;
  QPCR?: PerDeviceClassWorkflowConfig;
  CyBio?: PerDeviceClassWorkflowConfig;
  Labcyte?: PerDeviceClassWorkflowConfig;
  TTP?: PerDeviceClassWorkflowConfig;
  Formulatrix?: PerDeviceClassWorkflowConfig;
  Tempest?: PerDeviceClassWorkflowConfig;
  ShakerIncubator?: PerDeviceClassWorkflowConfig;
  PlateReader?: PerDeviceClassWorkflowConfig;
  PlateWasher?: PerDeviceClassWorkflowConfig;
  DeCapper?: PerDeviceClassWorkflowConfig;
  OpentronsOT2?: PerDeviceClassWorkflowConfig;
  CertusFlex?: PerDeviceClassWorkflowConfig;
  Manual?: PerDeviceClassWorkflowConfig;
  GilsonPipettePilot?: PerDeviceClassWorkflowConfig;
};

/** One of the keys in `WorkflowConfig`. */
export type WorkflowConfigDeviceClassKey = Exclude<keyof WorkflowConfig, 'GlobalMixer'>;

/** All of the device class keys of the `WorkflowConfig`. */
// Could we use reflection for this instead? But doing it this way is not too bad.
export const WORKFLOW_CONFIG_DEVICE_KEYS: readonly WorkflowConfigDeviceClassKey[] = [
  'GilsonPipetMax',
  'Tecan',
  'TecanFluent',
  'Hamilton',
  'QPCR',
  'CyBio',
  'Labcyte',
  'TTP',
  'Formulatrix',
  'Tempest',
  'ShakerIncubator',
  'PlateReader',
  'PlateWasher',
  'DeCapper',
  'OpentronsOT2',
  'CertusFlex',
  'Manual',
  'GilsonPipettePilot',
];

/**
 * A simple representation of a set of devices selected in the workflow config.
 * This is useful in some places in the UI, for example the device selector.
 */
export type WorkflowDeviceConfiguration = {
  [deviceUUID: string]: {
    /** RunConfig's UUID, can be undefined if the device doesn't need a run config. */
    runConfigId?: string;
    runConfigVersion?: number;
    /** For example 'HamiltonStar'. */
    anthaLangDeviceClass: string;
    /**
     * Devices accessible by this device.
     * Design doc:
     * https://paper.dropbox.com/doc/Accessible-Devices-design-doc--A2drRA_43op5RoYK_Wv_8ncsAg-yZO18LmdKs6QXp6mFeI41
     */
    accessibleDeviceIds?: readonly string[];
  };
};

/**
 * This emptyWorkflowConfig returns a workflow config with no preferences set for any of the driverSpecific preferences
 * or the plate or tip types. Other settings are the same as the defaultWorkflowConfig. Going forwards we will default
 * to an empty config once we have added advanced settings endpoints, and rely solely on the default config coming from
 * antha-core service.
 * See CI-1216
 */
export function emptyWorkflowConfig(): WorkflowConfig {
  return {
    GlobalMixer: {
      ...defaultWorkflowConfig().GlobalMixer,
      inputPlateTypes: [],
      tipTypes: [],
      driverSpecificInputPreferences: [],
      driverSpecificOutputPreferences: [],
      driverSpecificPlatePreferences: {},
      driverSpecificTipPreferences: [],
      driverSpecificTipWastePreferences: [],
      driverSpecificTemporaryLocations: [],
    },
  };
}

export function defaultWorkflowConfig(): WorkflowConfig {
  return {
    GlobalMixer: {
      balancingStrategy: 'empty',
      balancingTolerance: 20,
      isCentrifugeEnabled: true,
      allocateInputsVersion: 4,
      ignorePhysicalSimulation: false,
      hamiltonStandardLanguage: false,
      barrierMerging: false,
      useDriverTipTracking: true,
      useTipboxAutofill: false,
      requiresDevice: true,
      inputPlateTypes: [],
      tipTypes: [],
      // Advanced settings that affect where deck items are placed
      driverSpecificInputPreferences: [
        'position_4',
        'position_5',
        'position_7',
        'position_8',
        'position_9',
        'position_6',
        'position_1',
        'position_3',
      ],
      driverSpecificOutputPreferences: [
        'position_6',
        'position_5',
        'position_9',
        'position_8',
        'position_7',
        'position_4',
        'position_1',
      ],
      driverSpecificPlatePreferences: {},
      driverSpecificTipPreferences: [
        'position_3',
        'position_2',
        'position_4',
        'position_5',
        'position_6',
        'position_7',
        'position_8',
        'position_9',
      ],
      driverSpecificTipWastePreferences: ['position_1', 'position_7'],
      driverSpecificTemporaryLocations: ['temp1', 'temp2'],
    },
  };
}

/**
 * Returns the configs of all devices which is a bit easier to work with than separate
 * keys per device class.
 */
export const getDeviceConfigs = memo((workflowConfig: WorkflowConfig) => {
  const deviceConfigs: { [deviceId: string]: PerDeviceWorkflowConfig } = {};
  for (const configKey of WORKFLOW_CONFIG_DEVICE_KEYS) {
    const devicesOfClass = workflowConfig[configKey]?.Devices;
    if (!devicesOfClass) {
      continue;
    }
    for (const [deviceId, deviceConfig] of Object.entries(devicesOfClass)) {
      deviceConfigs[deviceId] = deviceConfig;
    }
  }
  return deviceConfigs;
});

export type BundleParameters = {
  // The key here is an ElementInstance name
  [elementInstanceName: string]: ParameterValueDict;
};

export type ParameterValueDict = {
  [parameterName: string]: ParameterValue;
};

export type ParameterValue = any;
export type Connections = Connection[];

/**
 * When creating a new bundle, we provide this dummy value for the `Repositories`.
 * The correct value (with the correct commit hash) gets filled in by the appserver
 * when saving the workflow in the db.
 * This is a temporary measure. In the future we'll use the `Repositories` instead
 * of the `elementSetId` and populate this on the client.
 */
export const DUMMY_REPOSITORIES = { Synthace: { Directory: '', Commit: '' } };

/**
 * A representation of a workflow
 */
export type Bundle = {
  Elements: Elements;
  /**
   * The Port values for each Element Instance.
   */
  Parameters: BundleParameters;
  Meta: {
    Name: string;
    Description?: string;
    /**
     * In workflows generated from a DoE design this is a filetree link
     * to the design file.
     */
    doeDesignFile?: string;
    /**
     * In workflows generated from a DoE design this is ID of the
     * DoE template workflow used to generate this one.
     */
    doeTemplateWorkflowId?: string;
  };
  Repositories: {
    /**
     * In the future, there could be a different key than 'Synthace' here, potentially
     * when customers use elements from their own private git repo.
     * Currently (Dec 2019) this is always 'Synthace'.
     */
    Synthace: {
      /** For example https://repos.antha.com/antha-com/elements-synthace */
      Directory: string;
      /** The commit hash of the elements this workflow uses */
      Commit: string;
      /** The branch of the elements */
      Branch?: string;
    };
  };
  /**
   * The template configuration for all the templated information of a workflow
   */
  Template?: TemplateWorkflow;
  /**
   * Antha core derives ids of things like plates from this id, during simulation.
   * We set this field in order to provide an trail for Antha core. The UI doesn't
   * need this field.
   */
  WorkflowId: string;
  /**
   * The settings required to run the workflow on actual hardware.
   */
  Config: WorkflowConfig;
  SchemaVersion: string;
  elementSetId: string;
  // Not tracking the `Inventory` section as part of the workflow.
  // In Antha-core world, worfklows v2 define an `Inventory` section. However, Antha-core
  // (as of Dec 2019) simply expects all available plate types here - no need to store those
  // in each workflow.
  // We could argue we want to store the plate type definitions *only* for the plate types
  // used in the workflow, but those are already represented in the `Config` (as strings, e.g.
  // 'pcrplate_skirted') and also in element parameters, again as strings.
  /**
   * Groups of elements for visual or logical organisation of the workflow.
   */
  Groups?: Group[];
  /**
   * Factors for DOE designs that are written to by the Workflow Builder
   */
  Factors?: Factors;
  /**
   * Designs for DOE written by the visserver DOE Designer
   */
  Design?: Design;
  /**
   * Required to pass latest feature toggles to AnthaCore
   */
  FeatureToggles?: FeatureToggle[];
};

/**
 * A subset of the properties that are on the client-side ElementInstance type.
 * We don't use the name and element pointers in appserver, for example.
 */
export type ServerSideElementInstance = Pick<
  ElementInstance,
  'Id' | 'TypeName' | 'Meta'
> & { Parameters: ParameterValueDict };

export type ServerSideElements = {
  Instances: { [name: string]: ServerSideElementInstance };
  InstancesConnections: Connection[];
};

export type ServerSideBundle = Omit<Bundle, 'Elements' | 'Parameters'> & {
  Elements: ServerSideElements;
};

/**
 * Depending on what kind of workflow we show in the UI, and who owns the workflow,
 * we provide a different experience.
 */
export enum WorkflowEditMode {
  /**
   * This is the latest, editable version of a workflow (not a snapshot).
   * The workflow belongs to me. Therefore I can edit this workflow.
   */
  ENABLED_LATEST_OWNED_BY_ME = 'ENABLED_LATEST_OWNED_BY_ME',
  /**
   * This is the latest, editable version of a workflow (not a snapshot).
   * But because it belongs to a different person, we don't allow current user to edit.
   * We only allow copying the workflow.
   */
  DISABLED_LATEST_OWNED_BY_SOMEONE_ELSE = 'DISABLED_LATEST_OWNED_BY_SOMEONE_ELSE',
  /**
   * This is an immutable snapshot of a workflow, e.g. linked from a Simulation.
   * It belongs to me but because it is immutable I cannot edit it.
   * We allow creating a copy, or jumping to the latest version of this workflow.
   */
  DISABLED_SNAPSHOT_OWNED_BY_ME = 'DISABLED_SNAPSHOT_OWNED_BY_ME',
  /**
   * This is an immutable snapshot of a workflow, e.g. linked from a Simulation.
   * It also belongs to someone else. I definitely cannot edit.
   * We allow creating a copy of the workflow.
   */
  DISABLED_SNAPSHOT_OWNED_BY_SOMEONE_ELSE = 'DISABLED_SNAPSHOT_OWNED_BY_SOMEONE_ELSE',
  /**
   * This is the latest, editable version of a workflow (not a snapshot).
   * The workflow is an example. Assuming I am in an example source org, I can edit it.
   */
  ENABLED_LATEST_EXAMPLE_GALLERY = 'ENABLED_LATEST_EXAMPLE_GALLERY',
}

/**
 * What type of editor to use for the workflow.
 */
export enum EditorType {
  // Only add values here if you added a new type of UI for editing
  // workflows or you know no UI is needed!
  // The value here tells the UI what type of visual editing experience
  // we should use when opening the workflow.

  // UI Editor needed
  WORKFLOW_EDITOR = 'WORKFLOW_EDITOR',
  CHERRY_PICKER = 'CHERRY_PICKER',
  DOE_TEMPLATE = 'DOE_TEMPLATE_EDITOR',
  FORM_EDITOR = 'FORM_EDITOR',

  // No UI Editor needed
  DOE_DESIGN = 'DOE_DESIGN',

  /**
   * DEPRECATED. Do not use. These were added by mistake, there is
   * no editor associated with them. They can be deleted (T4253).
   */
  DOE_DESIGN_TOOL = 'DOE_DESIGN_TOOL',
  ROBOCOLUMNS_PICKER = 'ROBOCOLUMNS_PICKER',
}

// Show the stage the element is in the development process based off of the package name.
// Comments based on Jaja's description in T2936.
export enum ReleaseQuality {
  /*
   * An element in prototypes is NOT part of product and no clients should ever have prototype elements.
   * They are for in house development and testing purposes that may be progressed to Beta/Product
   * at a later stage.
   */
  PROTOTYPE = 'PROTOTYPE',
  /* An element in beta is not product and may not be fully stable, but we are able to selectively
   * roll out to clients as part of beta programs for testing and feedback
   *  while we continue to learn about them and develop them.
   */
  BETA = 'BETA',
  /* Element is in a state that is ready to be used everywhere. */
  PRODUCT = 'PRODUCT',
  /* Element is client specific and should be used sparingly. If more clients want the functionality,
   * we should consider generalizing to product rather than only for specific clients. */
  CLIENT_SPECIFIC = 'CLIENT_SPECIFIC',
  /* This should not happen but in case, we should catch what the other release options are. */
  UNKNOWN = 'UNKNOWN',
}

export type TemplateWorkflow = {
  Config: TemplateWorkflowConfig[];
  Inputs: TemplateWorkflowInput[];
  Description: string;
};

export type TemplateWorkflowConfig = {
  PropertyName: string;
  DisplayName: string;
};

export type TemplateWorkflowInput = {
  ElementInstanceId: string;
  InputName: string;
  DisplayName: string;
  Collapsed?: boolean;
};

/**
 * Anything that only relates-to/controls the appearance and behaviour of the group
 * in the workflow builder and not the planner should be a meta property.
 **/
export type GroupMeta = {
  x: number;
  y: number;
  width: number;
  height: number;
  description?: string;
};

export type Group = {
  id: string;
  name: string;
  elementIds: string[];
  Meta: GroupMeta;
};

export type CoreError = {
  code: string;
  message: string;
  details?: string;
};

export type ElementError = CoreError & {
  severity: 'warning' | 'error';
  parameters?: string[];
  messageType: 'markdown' | 'text';
};

export type Measurement = {
  value: number;
  unit: string;
};

export type MetaData = { label: string } & (
  | { value_string: string }
  | { value_float: number }
);

export type WellCoord = {
  x: number;
  y: number;
};

export type Liquid = {
  // id is a hash of liquid subcomponents and metadata that makes the liquid
  // unique. Don't try to figure this out yourself using the subcomponents and
  // metadata fields; core antha knows this best. If two liquids have the same
  // id, then their properties are equivalent
  id: string;
  name: string;
  subComponents: Record<string, Measurement>;
  subLiquids?: SubLiquid[];
  volume?: Measurement;
  metaData?: MetaData[];
  // groups are the names that the liquid is a member of
  groups?: string[];
  position?: LiquidPosition;
};

// SubLiquid refers to the volume a Liquid provides to another Liquid
export type SubLiquid = {
  // id should match one of the Liquid.id's
  id: string;
  name: string;
  volume: Measurement;
};

export type LiquidPosition = {
  plateName: string;
  plateType: string;
  wellCoords: WellCoord;
};

export type ElementOutput = Liquid[] | number;
export type ElementOutputs = { [parameterName: string]: ElementOutput };

export type ElementContext = {
  status: ElementInstanceStatus;
  errors: ElementError[];
  outputs?: ElementOutputs;
  dirty: boolean;
};

export type ElementContextMap = {
  [elementId: string]: ElementContext;
};

export type FactorItemType =
  | 'boolean'
  | 'continuous'
  | 'discrete'
  | 'nominal'
  | 'ordinal'
  | 'structured';

export type FactorItemVariableType = 'factor' | 'derived' | 'quasi-replicating' | 'group';

export type FactorItem = {
  id: string;

  /**
   * Name of the factor visible to user
   */
  displayName: string;
  typeName: FactorItemType;

  /**
   * Levels (set-points) at which you wish to investigate your Factor.
   *
   * In case typeName == 'structured' this array has to be serialised
   *
   * e.g. ["{\"Yeast Extract A\": \"10g/l\"}", "{\"Yeast Extract B\": \"10g/l\"}"]
   */
  values: string[];

  /**
   * Defined only if this factor is a
   * [Numerical Factor](https://www.notion.so/synthace/What-are-numerical-factors-and-how-do-they-relate-to-element-parameters-b99a44caf065413fa8d8750efc8a6be4?pvs=4)
   *
   * Omitted if typeName != continuous/discrete/ordinal
   */
  unit?: string;

  /**
   * Indicates whether factor is [hard to change](https://www.notion.so/synthace/Hard-To-Change-eed1a97f9a604d968931c4d85842ec6a?pvs=4):
   * true for scalar types, false otherwise
   */
  hardToChange: boolean;

  /**
   * Unique path from workflow to factor.
   * Omitted if the factor does not refer to an element parameter.
   *
   * e.g.
   *
   * [ 'DOE workflow', 'Make Mixtures', 'TargetVolume' ]
   *
   * [ 'DOE workflow', 'Make Mixtures', 'Compositions', 'Glucose' ]
   */
  path?: FactorPath;

  /**
   * 'factor' - this factor is a [regular Design Factor](https://www.notion.so/synthace/Element-parameters-and-DOE-factors-a82b9500cd3b4e20860683dde4fa40af?pvs=4) that you wish to investigate
   *
   * 'derived' - this factor is a [Derived Factor](https://www.notion.so/synthace/Derived-Factors-583f9a5745df4128a598ff161180d557?pvs=4)
   *
   * 'quasi-replicating' - this is a [Quasi-replicate Factor](https://www.notion.so/synthace/What-are-quasi-replicates-and-when-might-I-use-them-ae51ac70303a459292986eea759f5144?pvs=4)
   */
  variableTypeName: FactorItemVariableType;

  /**
   * Defined only if this is a [Numerical Derived Factor](https://www.notion.so/synthace/Derived-numerical-factors-223071d6d02e414db500da868640ad47?pvs=4)
   *
   * Numerical Derived Factor = factor that does not define levels but instead they are computed
   * from the "derivingExpression" out of level values of Numerical Factors used as variables in that expression.
   *
   * [Numerical Factor](https://www.notion.so/synthace/Numerical-Factors-63161a274f4f439999b80847c9b32fcf?pvs=4) = factor with levels (set points) being numeric measurements of a certain unit.
   */
  derivingExpression?: string;

  /**
   * Defined only if this is a [Categorical Derived Factor](https://www.notion.so/synthace/Derived-categorical-factors-4d7c2f33346b471291095060d1368802?pvs=4)
   *
   * Categorical Derived Factor = factor which is a mapping between its levels (free flow text) and
   * levels of a "sourceFactor" where the later can be either a Numeric Factor or a Categorical Factor
   * but cannot be a Derived Factor itself.
   *
   * [Numerical Factor](https://www.notion.so/synthace/Numerical-Factors-63161a274f4f439999b80847c9b32fcf?pvs=4) = factor with levels (set points) being numeric measurements of a certain unit.
   *
   * [Categorical Factor](https://www.notion.so/synthace/Categorical-Factors-3f9bc346d7f140638902d583bbf9d9a6?pvs=4) = factor with levels being a free flow text.
   */
  sourceFactor?: {
    id: string;
    /**
     * The mapping from source factor value to this factor's value.
     * in the example, if the source factor has value "value_1" then this factor
     * will be set to "value_1_mapped".
     * An entry should exist for all values present in the source factor's
     * 'values' list.
     */
    valueMap: Record<string, string>;
  };

  /**
   * Link to the [Mutual Exclusion Group](https://www.notion.so/synthace/Mutual-Exclusion-909f5efac0a14e4d975a93d0068d71ac?pvs=4)
   *
   * Omitted if variableTypeName != 'group'
   */
  mutualExclusionGroup?: string;

  /**
   * Factor created by a [Mutual Exclusion Group](https://www.notion.so/synthace/Mutual-Exclusion-909f5efac0a14e4d975a93d0068d71ac?pvs=4): default is false
   */
  isMutualExclusionLatentFactor?: boolean;

  /**
   * For spacefill designs, causes the DOE designer to include zero or more zero-valued runs for the factor,
   * even if the minimum value for the factor is greated than zero.
   */
  numberOfZerosToInclude?: number;

  /**
   * For continuous factors in spacefill designs, specifies whether to only select the provided values
   * or, when there are two values, to choose values within the range they define.
   */
  sampleMode?: 'discrete' | 'continuous';

  /**
   * Flag indicating wether this factor should be considered in design computation or not.
   */
  included: boolean;
};

export type FactorPath =
  | [workflowName: string, elementInstanceName: string, parameterName: string]
  | [
      workflowName: string,
      elementInstanceName: string,
      parameterName: string,
      parameterMapKey: string,
    ];

export type Design = {
  factors: Factors;
  runs?: Runs;
  meta?: any;
};

export type Factors = FactorItem[];

/**
 * each run is a set of factors with specific values. Note a special factorID of
 * "workflows" designates a run to a specific workflow
 */
export type Runs = { [factorID: string]: { [runName: string]: any } };

/** The value of antha_class for Hamilton devices */
export const HAMILTON_ANTHA_CLASS = 'Hamilton';
/** The value of antha_class for Tecan Fluent devices */
export const TECAN_FLUENT_ANTHA_CLASS = 'TecanFluent';
/** The value of antha_class for Tecan EVO devices */
export const TECAN_EVO_ANTHA_CLASS = 'TecanFreedomEVO';
