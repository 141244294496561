import React from 'react';

import { useQuery } from '@apollo/client';
import FileCopyOutlined from '@mui/icons-material/FileCopyOutlined';
import GetApp from '@mui/icons-material/GetApp';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { QUERY_WORKFLOW_DETAILS_PANEL } from 'client/app/api/gql/queries';
import { useCopyWorkflowAndNavigate } from 'client/app/api/WorkflowsApi';
import IconButtonWithPopover from 'client/app/apps/workflow-builder/IconButtonWithPopover';
import useDownloadWorkflowBundle from 'client/app/apps/workflow-builder/lib/useDownloadWorkflowBundle';
import { PanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { isLocalOrNinjaEnvironment } from 'common/lib/envs';
import { IntercomTourIDs } from 'common/lib/intercom';
import { EditorType } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import BranchIcon from 'common/ui/icons/BranchIcon';
import { ElementGroupIcon } from 'common/ui/icons/ElementGroupIcon';
import { ElementsListIcon } from 'common/ui/icons/ElementsListIcon';
import { SimulationCardsIcon } from 'common/ui/icons/SimulationCardsIcon';

type Props = {
  handleClick: (panel: PanelContent) => void;
  isReadonly?: boolean;
  className?: string;
  workflowId: WorkflowId;
};

export default function Toolbar({
  handleClick,
  isReadonly,
  className,
  workflowId,
}: Props) {
  const classes = useStyles();

  const activePanel = useWorkflowBuilderSelector(state => state.activePanel);
  const elementSetName = useWorkflowBuilderSelector(state => state.elementSet?.name);
  const mode = useWorkflowBuilderSelector(state => state.mode);
  const workflowName = useWorkflowBuilderSelector(state => state.workflowName);

  const { data } = useQuery(QUERY_WORKFLOW_DETAILS_PANEL, {
    variables: {
      id: workflowId,
    },
  });
  const authorName = data?.workflow.createdBy.displayName;
  const lastModified = moment(data?.workflow.lastModifiedAt).format('DD MMM YYYY HH:mm');

  const copyWorkflow = useCopyWorkflowAndNavigate(
    data?.workflow.id,
    data?.workflow.version,
    EditorType.WORKFLOW_EDITOR,
  );

  const downloadWorkflowBundle = useDownloadWorkflowBundle();
  const downloadWorkflow = () => {
    if (!data?.workflow.id) {
      showError('Cannot download workflow: missing id or version');
    } else {
      downloadWorkflowBundle(data.workflow.id, data.workflow.version, workflowName).catch(
        error => showError(error.message),
      );
    }
  };

  const { showError } = useSnackbarManager();

  const handleClickSimulations = () => {
    handleClick('Simulations');
  };

  const handleClickWorkflowSettings = () => {
    handleClick('WorkflowSettings');
  };

  const handleClickElementGroup = () => {
    handleClick('ElementGroup');
  };

  const handleClickElementsList = () => {
    handleClick('ElementsList');
  };

  const handleClickElementsBranch = () => {
    handleClick('ElementsBranch');
  };

  return (
    <Paper className={className} elevation={4}>
      <Tooltip title="Workflow Settings" placement="bottom">
        <IconButton
          icon={<SettingsOutlined />}
          size="small"
          onClick={handleClickWorkflowSettings}
          className={
            activePanel === 'WorkflowSettings'
              ? classes.activeButton
              : classes.inactiveButton
          }
          data-intercom-target={`${IntercomTourIDs.BUILDER}-workflow-settings`}
        />
      </Tooltip>
      <Tooltip title="Create Element Group" placement="bottom">
        <span>
          <IconButton
            disabled={isReadonly || mode === 'DOE'}
            icon={<ElementGroupIcon />}
            size="small"
            onClick={handleClickElementGroup}
            className={
              activePanel === 'ElementGroup'
                ? classes.activeButton
                : classes.inactiveButton
            }
          />
        </span>
      </Tooltip>
      <Tooltip title="Elements" placement="bottom">
        <span>
          <IconButton
            disabled={isReadonly || mode === 'DOE'}
            icon={<ElementsListIcon />}
            size="small"
            onClick={handleClickElementsList}
            className={
              activePanel === 'ElementsList'
                ? classes.activeButton
                : classes.inactiveButton
            }
            data-intercom-target={`${IntercomTourIDs.BUILDER}-workflow-elements`}
          />
        </span>
      </Tooltip>
      {isLocalOrNinjaEnvironment() && (
        <IconButtonWithPopover
          variant="hover"
          popoverContent={
            <>
              <Typography
                className={classes.popoverTextTitle}
                variant="subtitle1"
                color="textPrimary"
              >
                Branch:
              </Typography>
              <Typography variant="body1" color="textPrimary">
                {elementSetName}
              </Typography>
            </>
          }
          iconButtonProps={{
            icon: <BranchIcon />,
            disabled: isReadonly || mode === 'DOE',
            size: 'small',
            className:
              activePanel === 'ElementsBranch'
                ? classes.activeButton
                : classes.inactiveButton,
          }}
          onClick={handleClickElementsBranch}
        />
      )}
      <Tooltip title="Simulations" placement="bottom">
        <span>
          <IconButton
            disabled={isReadonly}
            icon={<SimulationCardsIcon />}
            size="small"
            onClick={handleClickSimulations}
            className={
              activePanel === 'Simulations'
                ? classes.activeButton
                : classes.inactiveButton
            }
          />
        </span>
      </Tooltip>
      <IconButtonWithPopover
        variant="click"
        iconButtonProps={{
          icon: <InfoIcon />,
          size: 'small',
          className: classes.inactiveButton,
        }}
        popoverContent={
          <Stack gap={3}>
            <Typography variant="h5" color="textSecondary">
              Workflow details
            </Typography>
            <Typography variant="h5" color="textPrimary">
              Created by: {authorName}
            </Typography>
            <Typography variant="h5" color="textPrimary">
              Last modified: {lastModified}
            </Typography>
          </Stack>
        }
      />
      <IconButtonWithPopover
        variant="click"
        iconButtonProps={{
          icon: <KeyboardDoubleArrowDownIcon />,
          size: 'small',
          className: classes.inactiveButton,
        }}
        popoverContent={
          <Stack gap={3}>
            <ActionIconWithLabel>
              <ButtonWithIcon
                variant="tertiary"
                size="small"
                onClick={copyWorkflow}
                startIcon={<CopyIcon />}
              >
                <Typography variant="h5" color="textPrimary">
                  Copy this workflow
                </Typography>
              </ButtonWithIcon>
            </ActionIconWithLabel>
            <ActionIconWithLabel>
              <ButtonWithIcon
                variant="tertiary"
                size="small"
                onClick={downloadWorkflow}
                startIcon={<DownloadIcon />}
              >
                <Typography variant="h5" color="textPrimary">
                  Download this workflow
                </Typography>
              </ButtonWithIcon>
            </ActionIconWithLabel>
          </Stack>
        }
      />
    </Paper>
  );
}

const ActionIconWithLabel = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ActionIcon = (Component: OverridableComponent<SvgIconTypeMap>) =>
  styled(Component)(({ theme }) => ({
    color: theme.palette.text.primary,
  }));
const CopyIcon = ActionIcon(FileCopyOutlined);
const DownloadIcon = ActionIcon(GetApp);
const ButtonWithIcon = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  text-transform: none;
`;

const useStyles = makeStylesHook(theme => ({
  activeButton: {
    color: Colors.BLUE_40,
  },
  divider: {
    width: '24px',
    margin: theme.spacing(4, 0),
  },
  inactiveButton: {
    color: Colors.TEXT_PRIMARY,
  },
  popoverTextTitle: {
    marginRight: '4px', // Applies a space between the title and the branch name.
  },
}));
