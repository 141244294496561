import React, { useCallback, useMemo, useRef, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import {
  getSelectedSupportedWorkflowTypeFiltersOptions,
  getSupportedWorkflowTypeFiltersOptions,
} from 'client/app/apps/experiments/supportedFilterWorkflowTypes';
import { useUserList } from 'client/app/apps/experiments/useUserList';
import { WorkflowList } from 'client/app/apps/experiments/Workflows/WorkflowsList';
import { ContentType, WorkflowSourceEnum } from 'client/app/gql';
import { useEntitiesCount } from 'client/app/hooks/useEntitiesCount';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { ScreenRegistry } from 'client/app/registry';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithAutocomplete from 'common/ui/components/FilterChip/FilterChipWithAutocomplete';
import FilterChipWithCheckbox, {
  Option,
} from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import FilterChipWithDateRange, {
  DateRange,
  getDateFromTo,
  getDateRange,
} from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import FilterChipWithSwitch from 'common/ui/components/FilterChip/FilterChipWithSwitch';
import SearchField from 'common/ui/components/SearchField';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

/**
 * Display a list of all workflows
 */

export default function WorkflowsScreen() {
  return (
    <ExperimentsNavigation title="Workflows">
      <WorkflowsListWithURLState />
    </ExperimentsNavigation>
  );
}

type WorkflowFiltersProps = {
  searchQuery?: string;
  setSearchQuery: (value?: string) => void;
  favorited?: boolean;
  setFilterFavorited: (value?: boolean) => void;
  filterUserId?: string;
  setFilterUserId: (value?: string) => void;
  dateFrom?: string;
  setDateFrom: (value?: string) => void;
  dateTo?: string;
  setDateTo: (value?: string) => void;
  filterWorkflowTypes?: string[];
  setFilterWorkflowTypes: (value?: string[]) => void;
  filterSuccessfulSimulations?: boolean;
  setFilterSuccessful: (value?: boolean) => void;
};

type Props = {
  dialog?: boolean;
  onSelectWorkflowId?: (workflowId: WorkflowId) => void;
  disabledWorkflowIds?: WorkflowId[];
};
function WorkflowsListWithURLState({ dialog, onSelectWorkflowId }: Props) {
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });
  const [favorited, setFilterFavorited] = useStateWithURLParams({
    paramName: 'favorited',
    paramType: 'boolean',
    defaultValue: false,
  });
  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useStateWithURLParams({
    paramName: 'user',
    paramType: 'string',
    defaultValue: currentUserId,
    emptyValue: 'all',
  });
  const [dateFrom, setDateFrom] = useStateWithURLParams({
    paramName: 'dateFrom',
    paramType: 'string',
    defaultValue: '',
  });
  const [dateTo, setDateTo] = useStateWithURLParams({
    paramName: 'dateTo',
    paramType: 'string',
    defaultValue: '',
  });
  const [filterWorkflowTypes, setFilterWorkflowTypes] = useStateWithURLParams({
    paramName: 'type',
    paramType: 'string[]',
    defaultValue: [],
  });
  // Right now, only allow user to be able to filter by successful simulations even though
  // simulations technically can be in other intermediate states.
  const [filterSuccessfulSimulations, setFilterSuccessful] = useStateWithURLParams({
    paramName: 'successful',
    paramType: 'boolean',
    defaultValue: false,
  });

  const workflowFiltersProps = {
    dateFrom,
    dateTo,
    dialog,
    favorited,
    filterSuccessfulSimulations,
    filterUserId,
    filterWorkflowTypes,
    searchQuery,
    onSelectWorkflowId,
    setDateFrom,
    setDateTo,
    setFilterFavorited,
    setFilterSuccessful,
    setFilterUserId,
    setFilterWorkflowTypes,
    setSearchQuery,
  };

  return (
    <WorkflowsListBase
      {...workflowFiltersProps}
      dialog={dialog}
      onSelectWorkflowId={onSelectWorkflowId}
    />
  );
}

export function WorkflowsList({
  dialog,
  onSelectWorkflowId,
  disabledWorkflowIds,
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>('');
  const [favorited, setFilterFavorited] = useState<boolean | undefined>(false);
  const currentUserId = useUserProfile()?.id;
  const [filterUserId, setFilterUserId] = useState(currentUserId);
  const [dateFrom, setDateFrom] = useState<string | undefined>('');
  const [dateTo, setDateTo] = useState<string | undefined>('');
  const [filterWorkflowTypes, setFilterWorkflowTypes] = useState<string[] | undefined>(
    [],
  );

  // Right now, only allow user to be able to filter by successful simulations even though
  // simulations technically can be in other intermediate states.
  const [filterSuccessfulSimulations, setFilterSuccessful] = useState<
    boolean | undefined
  >(false);

  const workflowFiltersProps = {
    dateFrom,
    dateTo,
    dialog,
    favorited,
    filterSuccessfulSimulations,
    filterUserId,
    filterWorkflowTypes,
    searchQuery,
    onSelectWorkflowId,
    setDateFrom,
    setDateTo,
    setFilterFavorited,
    setFilterSuccessful,
    setFilterUserId,
    setFilterWorkflowTypes,
    setSearchQuery,
  };

  return (
    <WorkflowsListBase
      {...workflowFiltersProps}
      dialog={dialog}
      onSelectWorkflowId={onSelectWorkflowId}
      disabledWorkflowIds={disabledWorkflowIds}
    />
  );
}

function WorkflowsListBase({
  dateFrom,
  dateTo,
  dialog,
  favorited,
  filterSuccessfulSimulations,
  filterUserId,
  filterWorkflowTypes,
  searchQuery,
  onSelectWorkflowId,
  setDateFrom,
  setDateTo,
  setFilterFavorited,
  setFilterSuccessful,
  setFilterUserId,
  setFilterWorkflowTypes,
  setSearchQuery,
  disabledWorkflowIds,
}: Props & WorkflowFiltersProps) {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const entitiesCount = useEntitiesCount();
  const usersDropdownOptions = useUserList();
  const currentUserId = useUserProfile()?.id;

  const onFilterFavorited = (value: boolean) => {
    logEvent(
      'filter-simulations-by-favorited',
      ScreenRegistry.EXPERIMENTS,
      value ? 'favorited' : 'none',
    );
    setFilterFavorited(value);
  };

  const onFilterUser = (id?: string) => {
    logEvent(
      'filter-simulations-by-user',
      ScreenRegistry.EXPERIMENTS,
      id === currentUserId ? 'self' : 'others',
    );
    setFilterUserId(id);
  };

  const filterDateRange = useMemo(
    () => getDateRange(dateFrom, dateTo),
    [dateFrom, dateTo],
  );
  const onFilterByDateRange = useCallback(
    (newValue: DateRange) => {
      logEvent('filter-simulations-by-date', ScreenRegistry.EXPERIMENTS);
      const { dateFrom, dateTo } = getDateFromTo(newValue);
      setDateFrom(dateFrom);
      setDateTo(dateTo);
    },
    [setDateFrom, setDateTo],
  );

  const workflowTypesFilterList = getSupportedWorkflowTypeFiltersOptions()
    .filter(workflowType => filterWorkflowTypes?.includes(workflowType.label))
    .map(workflowType => workflowType.value);

  const onFilterByWorkflowTypes = (newValue: Option<WorkflowSourceEnum>[]) => {
    logEvent(
      'filter-workflows-by-source-type',
      ScreenRegistry.EXPERIMENTS,
      workflowTypesFilterList.join(','),
    );
    const newTypes = newValue
      .filter(option => option.selected)
      .map(option => option.label);
    setFilterWorkflowTypes(newTypes);
  };
  const onFilterSuccessfulSimulations = (value: boolean) => {
    logEvent('filter-simulations-by-successful-simulations', ScreenRegistry.EXPERIMENTS);
    setFilterSuccessful(value);
  };

  if (!entitiesCount) {
    return <LinearProgress variant="indeterminate" />;
  }

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableRef}
      headerLeftContent={
        <>
          <FilterChipWithAutocomplete
            heading="Filter by Author"
            defaultChipLabel="Author"
            dropdownOptions={usersDropdownOptions}
            filterValue={filterUserId}
            onFilter={onFilterUser}
          />
          <FilterChipWithSwitch
            heading="Filter by Simulation Status"
            activeChipLabel="Successful"
            inactiveChipLabel="Simulation Status"
            filterValue={!!filterSuccessfulSimulations}
            onFilter={onFilterSuccessfulSimulations}
          />
          <FilterChipWithSwitch
            heading="Filter by Stars"
            activeChipLabel="Starred"
            inactiveChipLabel="Stars"
            filterValue={!!favorited}
            onFilter={onFilterFavorited}
          />
          <FilterChipWithCheckbox
            heading="Filter by Workflow Type"
            defaultChipLabel="Workflow Type"
            filterValue={getSelectedSupportedWorkflowTypeFiltersOptions(
              filterWorkflowTypes ?? [],
            )}
            onFilter={onFilterByWorkflowTypes}
          />
          <FilterChipWithDateRange
            heading="Filter by Date Range"
            defaultChipLabel="Date Range"
            filterValue={filterDateRange}
            onFilter={onFilterByDateRange}
          />
        </>
      }
      headerRightContent={
        <SearchField
          label="Search"
          defaultValue={searchQuery}
          onQueryChange={setSearchQuery}
        />
      }
      content={
        <WorkflowList
          scrollableRef={scrollableRef}
          searchQuery={searchQuery}
          favorited={!!favorited}
          filterDateRange={filterDateRange}
          filterUserId={filterUserId}
          filterWorkflowTypes={workflowTypesFilterList}
          contentSource={ContentType.USER_GENERATED}
          filterSuccessfulSimulations={!!filterSuccessfulSimulations}
          dialog={dialog}
          onClick={onSelectWorkflowId}
          disabledWorkflowIds={disabledWorkflowIds}
        />
      }
      dense
    />
  );
}
