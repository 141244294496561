import { useCallback, useMemo } from 'react';

import uniqBy from 'lodash/uniqBy';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import { useElementContext } from 'client/app/apps/workflow-builder/lib/useElementContext';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { isDefined } from 'common/lib/data';
import { formatVolumeObj } from 'common/lib/format';
import { ElementInstance, Liquid, Measurement, SubLiquid } from 'common/types/bundle';
import { Mixture, WellLocation } from 'common/types/mix';
import { PlateLayer, WellSet } from 'common/types/plateAssignments';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import makeWellSelector from 'common/ui/components/simulation-details/PlateTransform';
import useDialog from 'common/ui/hooks/useDialog';

/**
 * Main Element parameter that controls plate based mixing
 */
export const PLATE_BASED_MIXING_PARAMETER = 'DefinitionMode';

/**
 * Maps from the `Liquid[]` data returned by the element context API into the `PlateState`
 * format expected by the family of plate viewer components.
 *
 * @param outputParameter The element output parameter to read liquids from.
 * @returns An array of `PlateState` objects describing the contents of each plate.
 */
export function useOutputLiquidsByPlate(
  liquidColors: LiquidColors,
  liquidParameters: LiquidParameters,
): [loading: boolean, platesByName: Map<string, PlateState>, uniqueMixtures: Mixture[]] {
  const {
    loading: inputsLoading,
    inputLiquids: [inputLiquids],
  } = useInputLiquids(liquidParameters.inputLiquids);
  const { loading: outputsLoading, outputMixtures } = useOutputMixtures(
    liquidParameters.outputLiquids,
  );

  const [plateTypes, loadingPlates] = usePlatesByType();

  return useMemo(() => {
    const platesMap = new Map<string, PlateState>();
    const uniqueMixtures = new Map<string, Mixture>();

    if (outputMixtures && Array.isArray(outputMixtures) && !loadingPlates) {
      const subLiquids = outputMixtures
        .flatMap(mixture => mixture.subLiquids)
        .filter(isDefined);
      const uniqueSubLiquids = uniqBy(subLiquids, 'id');
      const subLiquidNames = new SubLiquidNames(inputLiquids, uniqueSubLiquids);

      outputMixtures.forEach(mixture => {
        const position = mixture.position;

        if (!position) {
          // We cannot do anything with liquids that don't include position info.
          return;
        }

        let plate = platesMap.get(position.plateName);

        if (!plate) {
          const plateType = plateTypes[position.plateType];

          if (!plateType) {
            throw new Error(
              `Missing plate type information for type ${position.plateType}`,
            );
          }

          plate = { ...makeWellSelector(plateType), contents: {} };
          platesMap.set(position.plateName, plate);
        }

        const contents = plate.contents!;

        let row = contents[position.wellCoords.x];

        if (!row) {
          row = {};
          contents[position.wellCoords.x] = row;
        }

        const subComponents = Object.entries(mixture.subComponents);

        const wellContents: Mixture = {
          kind: 'mixture_summary',
          id: mixture.id,
          name: mixture.name,
          total_volume: mixture.volume ?? { value: 0, unit: '' },
          sub_liquids: mixture.subLiquids?.map(subLiquid => ({
            ...subLiquid,
            name: subLiquidNames.getName(subLiquid),
          })),
          solutes:
            subComponents.length > 0
              ? subComponents.map(([name, value]) => ({
                  name,
                  concentration: value,
                }))
              : undefined,
        };

        // We need to pre-calculate the colors here to ensure they are consistent
        //between the plate map and the mixture cards.
        wellContents.color = liquidColors.getColorForWell(wellContents);
        row[position.wellCoords.y] = wellContents;

        if (!uniqueMixtures.has(wellContents.id)) {
          uniqueMixtures.set(mixture.id, wellContents);
        }
      });
    }

    return [
      inputsLoading || outputsLoading || loadingPlates,
      platesMap,
      [...uniqueMixtures.values()],
    ];
  }, [
    inputLiquids,
    inputsLoading,
    liquidColors,
    loadingPlates,
    outputMixtures,
    outputsLoading,
    plateTypes,
  ]);
}

/**
 * Gets the array of liquids from a defined output parameter.
 * The output parameter should be of type []Liquid.
 *
 * @param outputParameter The element output parameter to read liquids from.
 * @returns An array of Liquid.
 */
export function useOutputMixtures(outputParameter: string): {
  loading: boolean;
  outputMixtures: Liquid[] | undefined;
} {
  const { loading, context } = useElementContext();
  const outputs = context?.outputs[outputParameter]?.value;
  return { loading, outputMixtures: outputs ? (outputs as Liquid[]) : undefined };
}

/**
 * Gets the array of liquids from a defined input parameter.
 * The input parameter should be of type []Liquid.
 *
 * @param inputParameter The element input parameter to read liquids from.
 * @returns An array of Liquid.
 */
export function useInputLiquids(...inputParameter: [string, ...string[]]) {
  const { loading, context } = useElementContext();
  const inputs = inputParameter.map(
    inputParameter => (context?.inputs[inputParameter]?.value as Liquid[]) ?? [],
  );

  return { loading, inputLiquids: inputs };
}

type LiquidNameOrGroupNameType = 'name' | 'group';

export type LiquidNameOrGroupName = {
  name: string;
  type: LiquidNameOrGroupNameType;
  liquidsInGroup?: number;
};

/**
 * Gets the list of unique liquid names or group names that define the input liquids.
 *
 * @returns Array of LiquidNameOrGroupName
 */
export function useInputLiquidNamesAndGroups(
  inputLiquids: Liquid[],
): LiquidNameOrGroupName[] {
  const liquidAndGroupNames: LiquidNameOrGroupName[] = [];
  inputLiquids?.forEach(liquid => {
    if (!liquid.groups || liquid.groups?.length === 0) {
      liquidAndGroupNames.push({ name: liquid.name, type: 'name' });
    } else {
      liquid.groups.forEach(groupName => {
        liquidAndGroupNames.push({ name: groupName, type: 'group' });
      });
    }
  });
  liquidAndGroupNames.forEach(liquidOrGroup => {
    if (liquidOrGroup.type === 'group') {
      let liquidsInGroup = 0;
      inputLiquids?.forEach(liquid => {
        if (liquid.groups?.includes(liquidOrGroup.name)) {
          liquidsInGroup++;
        }
      });
      liquidOrGroup.liquidsInGroup = liquidsInGroup;
    }
  });
  return uniqBy(liquidAndGroupNames, 'name');
}

type Row = {
  id: string; // id will be the 'mixtureName' and used for uniqueness when iterating.
  mixtureName: string;
  replicates: number;
  cells: Cell[];
};

type Cell = {
  id: string; // id will be the parent 'mixtureName' + 'column header' and used for uniqueness when iterating.
  content: string;
};

/**
 * Formats mixtures into a table format for display purposes, with information about
 * the volumes of each sub-liquid that make up each mixture, as well as the replicates.
 *
 * @returns An object with two fields: 'rows' to render out into a table; 'columnHeaders' for the table header.
 */
export function useMixturesTable(
  inputLiquids: Liquid[],
  liquidParameters: LiquidParameters,
): {
  rows: Row[] | undefined;
  columnHeaders: string[] | undefined;
} {
  const { outputMixtures } = useOutputMixtures(liquidParameters.outputLiquids);

  return useMemo(() => {
    if (!outputMixtures) {
      return { rows: undefined, columnHeaders: undefined };
    }

    // We don't show multiple rows for identical mixture compositions. The total number used
    // will be reflected in Replicates field
    const uniqueMixtures = uniqBy(outputMixtures, 'id');

    const replicates = new Map<string, number>();
    outputMixtures.forEach(mixture => {
      replicates.set(mixture.id, (replicates.get(mixture.id) ?? 0) + 1);
    });

    const rows: Row[] = [];

    const subLiquids = outputMixtures
      .flatMap(mixture => mixture.subLiquids)
      .filter(isDefined);
    // The uniqueSubLiquids are effectively going to be the column headers in our table.
    // We don't want to display duplicates, so we group them here, and use this as a reference
    // for ranging over our mixtures.
    const uniqueSubLiquids = uniqBy(subLiquids, 'id');
    uniqueMixtures.map(mixture => {
      const cells: Cell[] = [];
      const subLiquidIdsForMixture = new Set(
        mixture.subLiquids?.map(subLiquid => subLiquid.id),
      );

      uniqueSubLiquids.forEach(subLiquid => {
        // If this uniqueSubLiquid is not in the current mixture, create an
        // "empty" cell.
        if (!subLiquidIdsForMixture.has(subLiquid?.id)) {
          cells.push({
            id: `${mixture.id}-${subLiquid.id}`,
            content: '-',
          });
        } else {
          const mixtureSubLiquid = mixture.subLiquids?.find(
            mixtureSubLiquid => mixtureSubLiquid.id === subLiquid?.id,
          );
          if (mixtureSubLiquid) {
            cells.push({
              id: `${mixture.id}-${subLiquid.id}`,
              content: formatVolumeObj(mixtureSubLiquid.volume).replace('ul', ''),
            });
          }
        }
      });
      rows.push({
        id: mixture.id,
        mixtureName: mixture.name,
        cells: cells,
        replicates: replicates.get(mixture.id) ?? 0,
      });
      return rows;
    });

    const subLiquidNames = new SubLiquidNames(inputLiquids, uniqueSubLiquids);

    const columnHeaders = uniqueSubLiquids.map(
      subLiquid => `${subLiquidNames.getName(subLiquid)} (ul)`,
    );

    return { rows, columnHeaders };
  }, [inputLiquids, outputMixtures]);
}

class SubLiquidNames {
  #liquidConcentrations: Map<string, Measurement>;
  #subLiquidNameToHashes: Map<string, string[]>;

  constructor(liquids: Liquid[] | undefined, uniqueSubLiquids: SubLiquid[]) {
    // Sometimes subLiquids with the same names can be used, but at different concentrations.
    // To distinguish those, if a subLiquids with the same name has been used more than once
    // at different concentrations, we will append the header for that subLiquids with the
    // relevant concentration.
    this.#liquidConcentrations = new Map<string, Measurement>(
      liquids?.map(liquid => [liquid.id, liquid.subComponents[liquid.name]]) ?? [],
    );

    this.#subLiquidNameToHashes = new Map<string, string[]>();

    uniqueSubLiquids.forEach(subLiquid => {
      this.#subLiquidNameToHashes.has(subLiquid.name)
        ? this.#subLiquidNameToHashes.get(subLiquid.name)?.push(subLiquid.id)
        : this.#subLiquidNameToHashes.set(subLiquid.name, [subLiquid.id]);
    });
  }

  getName(subLiquid: SubLiquid) {
    if (this.#subLiquidNameToHashes.get(subLiquid.name)?.length === 1) {
      return subLiquid.name;
    }

    const concentration = this.#liquidConcentrations.get(subLiquid.id);
    const concentrationText = concentration
      ? ` (at ${concentration.value} ${concentration.unit}) `
      : '';

    return `${subLiquid.name}${concentrationText}`;
  }
}

export function addWellsToWellSet(
  layer: PlateLayer,
  wellSetId: string,
  wellsToAdd: readonly WellLocation[],
) {
  for (const wellSet of layer.wellSets) {
    if (wellSet.id === wellSetId) {
      wellSet.wells = [
        ...new Map([
          ...wellSet.wells.map(well => [`${well.x}-${well.y}`, well] as const),
          ...wellsToAdd.map(
            well => [`${well.col}-${well.row}`, { x: well.col, y: well.row }] as const,
          ),
        ]).values(),
      ];
    } else {
      removeWellsFromWellSet(wellSet, wellsToAdd);
    }
  }
}

export function removeWellsFromWellSet(
  wellSet: WellSet,
  wellsToRemove: readonly WellLocation[],
) {
  const retainedWells = new Map(
    wellSet.wells.map(well => [`${well.x}-${well.y}`, well] as const),
  );

  wellsToRemove.forEach(well => {
    retainedWells.delete(`${well.col}-${well.row}`);
  });

  wellSet.wells = [...retainedWells.values()];
}

/**
 * Allows deletion of a layer or liquid (and associated well sets).
 *
 * @returns An object containing the confirmDeleteDialog (to be added to the JSX of the component
 * where the hook is called), and the handleDelete callback, to call when layer or liquid
 * is deleted.
 */
export function useDeleteLayerOrLiquid() {
  const [confirmDeleteDialog, openConfirmdeleteDialog] = useDialog(ConfirmationDialog);

  const { deleteLayer, deleteLiquidAssignment } = usePlateLayoutEditorContext();

  const handleDelete = useCallback(
    async (id: string, layerOrLiquid: 'layer' | 'liquid') => {
      const isConfirmed = await openConfirmdeleteDialog({
        action: 'delete',
        isActionDestructive: true,
        object: layerOrLiquid,
      });
      if (!isConfirmed) {
        return;
      }
      layerOrLiquid === 'layer' ? deleteLayer(id) : deleteLiquidAssignment(id);
    },
    [deleteLayer, deleteLiquidAssignment, openConfirmdeleteDialog],
  );

  return { handleDelete, confirmDeleteDialog };
}

/**
 * Formats the name of the layer into a string, using the index to create an ordering
 * within the name.
 *
 * @param layerId id of the layer
 * @returns String formatted name
 */
export function useFormatLayerAutomaticName(layerId: string) {
  const { plateAssignment } = usePlateLayoutEditorContext();
  const plateLayers = plateAssignment.plateLayers;
  const layerIndex = plateLayers.findIndex(layer => layer.id === layerId);
  return `Layer ${plateLayers.length - layerIndex}${
    plateLayers[layerIndex].name ? `: ${plateLayers[layerIndex].name}` : ''
  }`;
}

export type LiquidParameters = {
  inputLiquids: string;
  existingLiquids: string | undefined;
  outputLiquids: string;
};

const MIX_ONTO = 'Mix_Onto';
const MAKE_MIXTURES = 'Make_Mixtures';

const LIQUID_PARAMETERS: Record<string, LiquidParameters> = {
  [MIX_ONTO]: {
    inputLiquids: 'LiquidsToAdd',
    existingLiquids: 'LiquidsInPlace',
    outputLiquids: 'MixedLiquids',
  },
  [MAKE_MIXTURES]: {
    inputLiquids: 'Liquids',
    existingLiquids: undefined,
    outputLiquids: 'Mixtures',
  },
};

export function getLiquidParametersForElement(elementInstance: ElementInstance) {
  return LIQUID_PARAMETERS[elementInstance.element.name ?? MAKE_MIXTURES];
}
