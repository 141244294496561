import React, { useCallback, useRef } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import DownloadIcon from '@mui/icons-material/GetApp';
import Divider from '@mui/material/Divider';
import produce from 'immer';

import { EditorType } from 'common/elementConfiguration/EditorType';
import { IntercomTourIDs } from 'common/lib/intercom';
import { SpreadsheetState } from 'common/rules/evaluateSpreadsheetRules';
import {
  SPREADSHEET_ANALYTICS_CATEGORY,
  SpreadsheetConfiguration,
} from 'common/types/spreadsheet';
import { DataTable, Spreadsheet } from 'common/types/spreadsheetEditor';
import Button from 'common/ui/components/Button';
import AddColumnDialog from 'common/ui/components/Dialog/AddColumnDialog';
import HeadersMappingDialog from 'common/ui/components/Dialog/HeadersMappingDialog';
import {
  checkHeadersMatchConfiguration,
  convertDataTableToSpreadsheet,
  downloadSpreadsheet,
  getCustomSpreadsheet,
  getDefaultSpreadsheetHeaders,
  getStartingRow,
  parseFileToSpreadsheet,
  sanitizeSpreadsheet,
  verifyParsedSheetConfiguration,
} from 'common/ui/components/Dialog/spreadsheetHelpers';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import {
  CONCENTRATION_UNIT_FIELD_NAME,
  getCustomColumnConfig,
  getCustomColumnConfigByName,
  isCustomColumn,
  isCustomUnitColumn,
  SOLUTE_CONC_PREFIX,
  SOLUTE_UNIT_PREFIX,
} from 'common/ui/components/Table';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';

export const TABLE_TOPBAR_HEIGHT = ' 50px';

export type MultiSheetProps = {
  activeTabIndex: number;
  sheetsInfo: TabsInfo<number>;
  onChangeTab: (activeTabIndex: number) => void;
};

export type EditableSheetProps = {
  setSpreadsheet: (spreadsheet: Spreadsheet) => void;
  configuration: SpreadsheetConfiguration;
  setConfiguration: (configuration: SpreadsheetConfiguration) => void;
  state: SpreadsheetState;
};

export type DownloadFileProps = {
  dataToDownload: Spreadsheet | DataTable | null;
  filename: string;
};

type TableTopBarProps = {
  canAddColumns?: boolean;
  downloadFileProps: DownloadFileProps;
  /**
   * If present, users will be able to upload files and add columns.
   * We validate uploaded files against a given configuration.
   */
  editableSheetProps?: EditableSheetProps;
  /**
   * Additional props required only when rendering
   * multiple sheets.
   */
  multiSheetProps?: MultiSheetProps;
};

/**
 * A toolbar with actions (download/upload), and Tabs in case
 * there are multiple sheets.
 **/
export default function TableTopBar({
  canAddColumns,
  editableSheetProps,
  multiSheetProps,
  downloadFileProps,
}: TableTopBarProps) {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const snackbarManager = useSnackbarManager();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [headersMappingDialog, openHeadersMappingDialog] =
    useDialog(HeadersMappingDialog);
  const [addColumnDialog, openAddColumnDialog] = useDialog(AddColumnDialog);

  const handleUploadFile = useCallback(async () => {
    if (!editableSheetProps || !fileInputRef.current) {
      return;
    }
    const { setSpreadsheet, configuration, state, setConfiguration } = editableSheetProps;

    // Reset input so users can upload the same file twice.
    const file = fileInputRef.current.files?.[0];
    fileInputRef.current.value = '';
    if (!file) {
      return;
    }
    logEvent('upload-spreadsheet', SPREADSHEET_ANALYTICS_CATEGORY);

    const accessToken = await getAccessTokenSilently();
    const parsedSpreadsheet = await parseFileToSpreadsheet(file, accessToken);

    const parseConfigError = verifyParsedSheetConfiguration(
      parsedSpreadsheet,
      configuration,
    );
    if (parseConfigError) {
      snackbarManager.showError(parseConfigError);
      return;
    }

    const headersMatchConfiguration = checkHeadersMatchConfiguration(
      parsedSpreadsheet,
      configuration,
    );

    if (headersMatchConfiguration) {
      // If headers match, there is still a chance rows have missing columns.
      // Here we fill in those gaps.
      const sanitizedSpreadsheet = sanitizeSpreadsheet(parsedSpreadsheet, configuration);
      setSpreadsheet(sanitizedSpreadsheet);
    } else {
      const { sheets: allExpectedSheets } = getDefaultSpreadsheetHeaders(configuration);
      const parsedSheets = parsedSpreadsheet.sheets;

      if (parsedSheets.length > allExpectedSheets.length) {
        snackbarManager.showWarning(
          `Uploaded file has more sheets than expected. Reading data only from the first ${allExpectedSheets.length}.`,
        );
      }

      const expectedSheets = allExpectedSheets.slice(0, parsedSheets.length);
      const sheetsToRemap = expectedSheets.map((expectedSheet, sheetIndex) => {
        const expectedColumns = expectedSheet.table.schema.fields.filter(
          field => state.sheets[expectedSheet.name]?.columns[field.name].isRequired,
        );
        const optionalColumns = expectedSheet.table.schema.fields.filter(
          field => !state.sheets[expectedSheet.name]?.columns[field.name].isRequired,
        );
        const parsedColumns = parsedSheets[sheetIndex].table.schema.fields;
        return { expectedColumns, parsedColumns, optionalColumns };
      });

      const mappingResults = await openHeadersMappingDialog({
        sheets: sheetsToRemap,
      });

      if (!mappingResults) {
        snackbarManager.showWarning(`Upload cancelled.`);
        return;
      }
      const { mappedHeadersBySheet, extraColumnsBySheet } = mappingResults;
      const hasAddedColumns = Object.values(extraColumnsBySheet ?? {}).some(
        columns => columns.length > 0,
      );
      if (extraColumnsBySheet && hasAddedColumns) {
        logEvent(
          'add-columns-when-importing-spreadsheet',
          SPREADSHEET_ANALYTICS_CATEGORY,
          Object.values(extraColumnsBySheet)
            .reduce((numOfColsSoFar, cols) => numOfColsSoFar + cols.length, 0)
            .toString(10),
        );
      }

      let numOfColsDiscarded = 0;
      const mappedSpreadsheet = { sheets: [...expectedSheets] };
      for (const sheetIndex in expectedSheets) {
        const allFields = [
          ...mappedSpreadsheet.sheets[sheetIndex].table.schema.fields,
          ...(extraColumnsBySheet?.[sheetIndex] ?? []),
        ];
        mappedSpreadsheet.sheets[sheetIndex].table.schema.fields = allFields;

        const mappedData = parsedSheets[sheetIndex].table.data.map(row =>
          Object.entries(row).reduce((newRow, [parsedHeader, parsedValue]) => {
            // Get rid of data from discarded columns
            if (!mappedHeadersBySheet[sheetIndex][parsedHeader]) {
              numOfColsDiscarded++;
              return newRow;
            }

            return {
              ...newRow,
              [mappedHeadersBySheet[sheetIndex][parsedHeader]]: parsedValue,
            };
          }, getStartingRow(allFields)),
        );
        mappedSpreadsheet.sheets[sheetIndex].table.data = mappedData;
        if (numOfColsDiscarded > 0) {
          logEvent(
            'discard-column-when-importing-spreadsheet',
            SPREADSHEET_ANALYTICS_CATEGORY,
            numOfColsDiscarded.toString(10),
          );
        }
      }

      const newConfiguration = produce(configuration, configDraft => {
        mappedSpreadsheet.sheets.forEach((sheet, sheetIndex) => {
          sheet.table.schema.fields.forEach((field, fieldIndex) => {
            const sheetDraft = configDraft.sheets[sheetIndex];
            const columnConfig = sheetDraft.columns[fieldIndex];

            if (columnConfig) {
              // do nothing
            } else if (isCustomUnitColumn(field)) {
              const columnConfig = getCustomColumnConfigByName(field.name);
              const concentrationUnitColumn = configDraft.sheets[sheetIndex].columns.find(
                c => c.name === CONCENTRATION_UNIT_FIELD_NAME,
              );

              sheetDraft.columns[fieldIndex] = {
                ...columnConfig,
                editor: concentrationUnitColumn
                  ? { ...concentrationUnitColumn.editor }
                  : {
                      type: EditorType.UNIT,
                      additionalProps: {
                        editor: EditorType.UNIT,
                        units: getSensibleMeasurementUnits('Concentration'),
                      },
                    },
              };
            } else if (isCustomColumn(field)) {
              sheetDraft.columns[fieldIndex] = getCustomColumnConfigByName(field.name);
            }
          });
        });
      });

      setConfiguration(newConfiguration);
      setSpreadsheet(getCustomSpreadsheet(newConfiguration, mappedSpreadsheet));
    }
  }, [
    getAccessTokenSilently,
    openHeadersMappingDialog,
    snackbarManager,
    editableSheetProps,
  ]);

  const handleDownloadFile = useCallback(async () => {
    if (!downloadFileProps.dataToDownload) {
      return;
    }
    logEvent('download-spreadsheet', SPREADSHEET_ANALYTICS_CATEGORY);

    const accessToken = await getAccessTokenSilently();
    if (isDataTable(downloadFileProps.dataToDownload)) {
      await downloadSpreadsheet(
        convertDataTableToSpreadsheet(downloadFileProps.dataToDownload),
        `${downloadFileProps.filename}.csv`,
        accessToken,
      );
    } else {
      await downloadSpreadsheet(
        downloadFileProps.dataToDownload,
        `${downloadFileProps.filename}.xlsx`,
        accessToken,
      );
    }
  }, [
    downloadFileProps.dataToDownload,
    downloadFileProps.filename,
    getAccessTokenSilently,
  ]);

  const handleAddColumn = useCallback(async () => {
    if (!canAddColumns || !downloadFileProps.dataToDownload) {
      return;
    }

    const { dataToDownload: dataTableOrSpreadsheet } = downloadFileProps;

    const dataWithConfig: {
      dataTable?: DataTable;
      config?: SpreadsheetConfiguration;
    } = { config: editableSheetProps?.configuration };

    let activeSheetIndex = 0;

    if (isDataTable(dataTableOrSpreadsheet)) {
      dataWithConfig.dataTable = dataTableOrSpreadsheet;
    } else {
      activeSheetIndex = multiSheetProps?.activeTabIndex ?? 0;
      dataWithConfig.dataTable = dataTableOrSpreadsheet.sheets[activeSheetIndex].table;
    }

    const columnInfo = await openAddColumnDialog({
      existingColumns: dataWithConfig.dataTable.schema.fields.map(field => field.name),
    });
    if (!columnInfo) {
      return;
    }

    const { name, prefix } = columnInfo;

    const { dataTable: newDataTable, config: newConfig } = produce(
      dataWithConfig,
      draft => {
        if (!draft.dataTable) return;

        const columnConfig = getCustomColumnConfig(columnInfo);

        draft.dataTable.schema.fields.push({
          name: columnConfig.name,
          type: columnConfig.dataType,
        });
        draft.dataTable.data.forEach(row => {
          row[columnConfig.name] = null;
        });

        draft.config?.sheets[activeSheetIndex].columns.push(columnConfig);

        // Solute concentration is always paired with solute concentration unit
        // TODO these can be merged into one column (TABLE-47)
        if (prefix === SOLUTE_CONC_PREFIX) {
          const concentrationUnitColumn = draft.config?.sheets[
            activeSheetIndex
          ].columns.find(c => c.name === CONCENTRATION_UNIT_FIELD_NAME);

          const unitColumnName = `${SOLUTE_UNIT_PREFIX}:${name}`;

          draft.dataTable.schema.fields.push({
            name: unitColumnName,
            type: 'string',
          });
          draft.dataTable.data.forEach(row => {
            row[unitColumnName] = null;
          });
          draft.config?.sheets[activeSheetIndex].columns.push({
            ...columnConfig,
            name: unitColumnName,
            editor: concentrationUnitColumn
              ? { ...concentrationUnitColumn.editor }
              : {
                  type: EditorType.UNIT,
                  additionalProps: {
                    editor: EditorType.UNIT,
                    units: getSensibleMeasurementUnits('Concentration'),
                  },
                },
          });
        }
      },
    );

    if (newConfig) {
      editableSheetProps?.setConfiguration(newConfig);
    }
    if (newDataTable) {
      let newSpreadsheet: Spreadsheet | null = null;
      if (isDataTable(dataTableOrSpreadsheet)) {
        newSpreadsheet = convertDataTableToSpreadsheet(newDataTable);
      } else {
        newSpreadsheet = produce(dataTableOrSpreadsheet, draft => {
          draft.sheets[activeSheetIndex].table = newDataTable;
        });
      }
      editableSheetProps?.setSpreadsheet(newSpreadsheet);
    }
  }, [
    canAddColumns,
    downloadFileProps,
    multiSheetProps?.activeTabIndex,
    openAddColumnDialog,
    editableSheetProps,
  ]);

  return (
    <div className={classes.topbarContainer}>
      <div className={classes.tabsContainer}>
        {multiSheetProps && downloadFileProps.dataToDownload && (
          <Tabs
            activeTab={multiSheetProps.activeTabIndex}
            onChangeTab={multiSheetProps.onChangeTab}
            tabsInfo={multiSheetProps.sheetsInfo}
          />
        )}
      </div>
      <div className={classes.actionButtons}>
        {editableSheetProps && (
          <>
            {canAddColumns && (
              <>
                <Button
                  variant="tertiary"
                  onClick={handleAddColumn}
                  startIcon={<AddIcon />}
                >
                  <span className={classes.btnTitle}>Add Subcomponent or Tag</span>
                </Button>
                <Divider className={classes.divider} orientation="vertical" />
              </>
            )}
            <Button
              variant="tertiary"
              data-intercom-target={`${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-upload`}
              onClick={() => fileInputRef.current?.click()}
              startIcon={<UploadIcon />}
            >
              <span className={classes.btnTitle}>Upload</span>
            </Button>
            <input
              id="spreadsheet-editor-upload-button"
              type="file"
              accept=".csv, .xlsx"
              ref={fileInputRef}
              hidden
              onChange={handleUploadFile}
            />
          </>
        )}
        <Button
          variant="tertiary"
          data-intercom-target={`${IntercomTourIDs.ELISA_SPREADSHEET}-spreadsheet-download`}
          onClick={handleDownloadFile}
          startIcon={<DownloadIcon />}
        >
          <span className={classes.btnTitle}>Download</span>
        </Button>
      </div>
      {headersMappingDialog}
      {addColumnDialog}
    </div>
  );
}

function isDataTable(data: DataTable | Spreadsheet): data is DataTable {
  return Array.isArray((data as DataTable).data);
}

const useStyles = makeStylesHook(theme => ({
  topbarContainer: {
    height: TABLE_TOPBAR_HEIGHT,
    display: 'flex',
  },
  tabsContainer: {
    display: 'inline-block',
  },
  actionButtons: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'sticky',
    left: '95%',
  },
  divider: {
    height: '33px',
    margin: theme.spacing(0, 4),
  },
  btnTitle: {
    marginLeft: theme.spacing(3),
  },
}));
