import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';

const CollapsibleParameter = styled(Collapse)(({ theme }) => ({
  '&:not(:first-of-type)': {
    '& .MuiCollapse-wrapperInner': {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(5),
    },
  },
}));

export default CollapsibleParameter;
