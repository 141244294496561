import React from 'react';

import List from '@mui/material/List';

import { ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME } from 'client/app/apps/simulation-details/AnalyseChromatographyButton';
import VisualizationLink from 'client/app/apps/simulation-details/overview/VisualizationLink';
import {
  useIsDOEAllowedForSimulation,
  useIsRobocolumnsSimulation,
} from 'client/app/apps/simulation-details/SimulationDetails';
import { SimulationQuery } from 'client/app/gql';
import { SimulationOrExecutionStatusesEnum } from 'client/app/gql';
import { switchFail } from 'common/assert';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { arrayIsFalsyOrEmpty } from 'common/lib/data';

type Props = {
  simulation: SimulationQuery['simulation'];
};

function isMatchTransitiveStatus(
  status: SimulationOrExecutionStatusesEnum,
  minStatus: SimulationOrExecutionStatusesEnum | null,
): boolean {
  if (status === minStatus || minStatus === null) {
    return true;
  }
  // Partial ordering of statuses. A status can be matched by
  // multiple possible 'lower' statuses.
  let lowerStatus: SimulationOrExecutionStatusesEnum[];
  // These statuses are all ordered highest to lowest.
  const ordered = [
    SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS,
    SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED,
    SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS,
    SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED,
    SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS,
    SimulationOrExecutionStatusesEnum.NOT_ERROR,
  ];
  switch (status) {
    case SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS:
    case SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED:
    case SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS:
    case SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED:
    case SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS:
    case SimulationOrExecutionStatusesEnum.NOT_ERROR:
      lowerStatus = ordered.slice(ordered.indexOf(status));
      break;
    case SimulationOrExecutionStatusesEnum.EXECUTION_FAILED:
      lowerStatus = [
        SimulationOrExecutionStatusesEnum.EXECUTION_COMPLETED,
        SimulationOrExecutionStatusesEnum.EXECUTION_IN_PROGRESS,
        SimulationOrExecutionStatusesEnum.EXECUTION_SCHEDULED,
        SimulationOrExecutionStatusesEnum.SIMULATION_SUCCESS,
      ];
      break;
    case SimulationOrExecutionStatusesEnum.SIMULATION_FAILED:
    case SimulationOrExecutionStatusesEnum.UNINTERESTING:
      lowerStatus = [];
      break;
    default:
      return switchFail(status);
  }

  return lowerStatus.includes(minStatus);
}

/**
 * Check if there are visualizations to show for the current simulation status.
 * For example, visualizations which require an execution should only be
 * included if the simulation status is EXECUTION_SUCCESS.
 */
export function simHasVisualizations(simulation: SimulationQuery['simulation']): boolean {
  return (
    simulation.visualisations?.some(v =>
      isMatchTransitiveStatus(simulation.transitiveStatus, v.availability),
    ) ?? false
  );
}

export default function VisualizationLinks({ simulation }: Props) {
  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');
  // Filter out vis links that eg. require an execution, if we aren't handling one.
  // A typical case will be to show the vis only if status is EXECUTION_SUCCESS.
  const linkVisualizations =
    simulation.visualisations?.filter(v =>
      isMatchTransitiveStatus(simulation.transitiveStatus, v.availability) &&
      isNewDOEEnabled
        ? v.name !== ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME
        : true,
    ) ?? [];

  if (arrayIsFalsyOrEmpty(linkVisualizations)) {
    return null;
  }
  return (
    <List dense>
      {linkVisualizations.map(vis => (
        // If we are handling an execution, pass this context to vis so that it
        // can retrieve data from analytic execution tasks.
        <VisualizationLink key={vis.id} simulation={simulation} visualization={vis} />
      ))}
    </List>
  );
}

export function useShowVisualizationLinks(simulation: SimulationQuery['simulation']) {
  const isNewDOEEnabled = useFeatureToggle('NEW_DOE');
  const DOESimulationType = useIsDOEAllowedForSimulation(simulation);
  const isRobocolumnsSimulation = useIsRobocolumnsSimulation(simulation);

  // We don't show visualization links on new DOE simulations
  if (DOESimulationType === 'new') return false;
  // We don't show visualization links on robocolumns visualisations if there is only one
  // chromatography analysis.
  if (
    isNewDOEEnabled &&
    isRobocolumnsSimulation &&
    simulation.visualisations?.length === 1
  )
    return false;

  return true;
}
