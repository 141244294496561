import React, { Fragment, useMemo } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import PlateContentsItemContainer from 'client/app/components/Parameters/PlateContents/PlateContentsItemContainer';
import { formatVolumeObj } from 'common/lib/format';
import { Mixture } from 'common/types/mix';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { getLayoutForWellSelector } from 'common/ui/components/simulation-details/mix/DeckLayout';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import PlateView from 'common/ui/components/simulation-details/plate-prep/PlateView';

type Props = {
  plate: PlateState;
  uniqueMixtures: Mixture[];
  liquidColors: LiquidColors;
};

export default function MixturesPlate({ plate, uniqueMixtures, liquidColors }: Props) {
  const deckLayout = useMemo(() => getLayoutForWellSelector(plate), [plate]);

  return (
    <>
      <PlateViewWrapper>
        <PlateView
          liquidColors={liquidColors}
          deckLayout={deckLayout}
          highlightedWellLocation={null}
          plate={plate}
        />
      </PlateViewWrapper>
      <MixtureCards>
        {uniqueMixtures.map(mixture => (
          <PlateContentsItemContainer
            key={mixture.id}
            title={mixture.name ?? ''}
            color={mixture.color}
          >
            <MixtureContent>
              {mixture.sub_liquids?.map(subLiquid => (
                <Fragment key={subLiquid.name}>
                  <Typography variant="body2">{subLiquid.name}</Typography>
                  <MixtureContentVolume variant="body2">
                    {formatVolumeObj(subLiquid.volume)}
                  </MixtureContentVolume>
                </Fragment>
              ))}
            </MixtureContent>
          </PlateContentsItemContainer>
        ))}
      </MixtureCards>
    </>
  );
}

const PlateViewWrapper = styled('div')({
  gridArea: 'plate',
});

const MixtureCards = styled('div')(({ theme }) => ({
  gridArea: 'mixtures',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  overflowY: 'auto',
}));

const MixtureContent = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  rowGap: theme.spacing(3),
}));

const MixtureContentVolume = styled(Typography)({
  textAlign: 'right',
});
