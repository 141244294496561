import React, { useCallback } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { mapPlateWells } from 'client/app/components/Parameters/PlateLayout/lib/WellAvailability';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { PlateAssignmentMode, WellSortMode } from 'common/types/plateAssignments';
import Button from 'common/ui/components/Button';

export default function Toolbar() {
  const {
    selectedWells,
    setSelectedWells,
    plateType,
    activeSidePanelTab,
    addNewLiquidAssignment,
    focusedLayerId,
    plateAssignment,
    editingAvailability,
    toggleEditingAvailability,
    wellAvailability,
    isReadonly,
  } = usePlateLayoutEditorContext();

  const selectAll = useCallback(() => {
    if (!plateType) {
      return;
    }

    setSelectedWells(
      mapPlateWells(plateType, WellSortMode.BY_ROW, (col, row) => ({
        col,
        row,
        deck_item_id: plateType.id,
      })),
    );
  }, [plateType, setSelectedWells]);

  return (
    <Wrapper>
      {activeSidePanelTab === 'setup' && (
        <>
          {plateAssignment.plateType ? (
            <StyledTypography>
              Switch to the <StrongTypography>Liquids</StrongTypography> tab to start
              adding contents to the plate
            </StyledTypography>
          ) : (
            <StyledTypography>
              Select a <StrongTypography>Plate Type</StrongTypography> to start editing
            </StyledTypography>
          )}
          <StyledArrowRightAltIcon />
        </>
      )}
      {activeSidePanelTab === 'liquids' && (
        <>
          {plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL ? (
            plateType && (
              <>
                <Typography variant="body1">
                  {wellAvailability?.available.length ??
                    plateType.columns * plateType.rows}{' '}
                  Wells Available
                </Typography>
                {editingAvailability ? (
                  <Button
                    variant="primary"
                    color="primary"
                    size="small"
                    onClick={() => toggleEditingAvailability(false)}
                  >
                    Save Availability
                  </Button>
                ) : (
                  <Button
                    disabled={isReadonly}
                    variant="secondary"
                    size="small"
                    onClick={() => toggleEditingAvailability(true)}
                  >
                    Edit Availability
                  </Button>
                )}
              </>
            )
          ) : (
            <>
              <Button
                variant="secondary"
                size="small"
                onClick={selectAll}
                disabled={
                  isReadonly ||
                  (plateType
                    ? selectedWells.length === plateType.rows * plateType.columns
                    : true)
                }
              >
                Select All
              </Button>
              <Button
                variant="secondary"
                size="small"
                disabled={isReadonly || selectedWells.length === 0}
                onClick={() => setSelectedWells([])}
              >
                Clear Selection
              </Button>
            </>
          )}
          <StyledButton
            onClick={() => addNewLiquidAssignment()}
            variant="primary"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            disabled={
              isReadonly ||
              !focusedLayerId ||
              editingAvailability ||
              (plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE &&
                selectedWells.length === 0)
            }
          >
            Add liquid
          </StyledButton>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  gridArea: 'toolbar',
  display: 'flex',
  gap: theme.spacing(4),
  marginBottom: theme.spacing(6),
  height: '30px',
  alignItems: 'center',
}));

const StyledButton = styled(Button)({
  marginLeft: 'auto',
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
}));

const StrongTypography = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
}));

const StyledArrowRightAltIcon = styled(ArrowRightAltIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '24px',
}));
