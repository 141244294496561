import React, { useContext, useState } from 'react';

import { useMutation } from '@apollo/client';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { TextWithLinks } from 'client/app/apps/experiments/blocks/TextWithLinks';
import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { MUTATION_UPDATE_EXPERIMENT_MODULE } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import { TextBlock as GraphQLTextBlock } from 'client/app/gql';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDebounce from 'common/ui/hooks/useDebounce';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  experimentId: ExperimentId;
  block: GraphQLTextBlock;
};

export default function TextBlock({ block, experimentId }: Props) {
  const classes = useStyles();

  const { isEditing } = useContext(ExperimentDetailContext);

  const [updateModule] = useMutation(MUTATION_UPDATE_EXPERIMENT_MODULE);

  const [description, setDescription] = useState<string | null>(block.text);

  // Debounce the request so we don't flood the server with mutations as the user types
  const updateBlockDebounced = useDebounce(() => {
    void updateModule({
      variables: { id: block.id, description },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experimentId } }],
    });
  }, 1000);

  const handleDescriptionChange = useTextFieldChange(newDescription => {
    setDescription(newDescription);
    updateBlockDebounced();
  });

  return (
    <>
      {isEditing ? (
        <TextField
          variant="standard"
          value={description ?? ''}
          onChange={handleDescriptionChange}
          placeholder="Share your thoughts..."
          multiline
          fullWidth
        />
      ) : (
        description && (
          <Typography variant="h6" className={classes.text} color="textSecondary">
            <TextWithLinks text={description ?? ''} />
          </Typography>
        )
      )}
    </>
  );
}

const useStyles = makeStylesHook({
  text: {
    // Respect new lines in the description
    whiteSpace: 'pre-wrap',
  },
});
