// The Mix Preview data is stored in filetree, as files attached to the Simulation
import { useCallback, useEffect, useMemo, useState } from 'react';

import * as FilesApi from 'client/app/api/FilesApi';
import { SimulationQuery } from 'client/app/gql';
import { createMixPreview } from 'common/lib/mix';
import { concatURL } from 'common/lib/strings';
import { DOEDesign, DOEDesignRun } from 'common/types/mix';
import { MixPreview } from 'common/types/mixPreview';

/**
 * Fetch Preview based on a Simulation.
 */
export function useFetchMixPreview() {
  const fetchJsonFileContents = FilesApi.useFetchJsonFileContents();
  return useCallback(
    async function fetchMixPreview(
      simulationId: SimulationId,
      simulationFiletreeLink: string,
    ): Promise<MixPreview | null> {
      const fetchFromCloudStorage = (fileName: string) =>
        // If we fail to download `actions.json` or `layout.json` at this point, there is a problem.
        // So we intentionally do not catch any error here, and let it surface to the user.
        // We used to silently catch the error to let very old jobs be displayed; not anymore.
        fetchJsonFileContents(concatURL(simulationFiletreeLink, fileName));

      // Unlike the fetches above, we do want to swallow errors, as most existing
      // workflows will not have a `steps.json` file.
      const fetchSteps = async () => {
        try {
          return await fetchFromCloudStorage('steps.json');
        } catch {
          console.warn(
            `Couldn't load steps.json. This is probably because it is an old workflow.`,
          );
        }

        return null;
      };

      const [maybeActions, maybeLayout, maybeSteps] = await Promise.all([
        ...['actions.json', 'layout.json'].map(fetchFromCloudStorage),
        fetchSteps(),
      ]);
      const mixPreview = createMixPreview(
        maybeLayout,
        maybeActions,
        maybeSteps,
        simulationId,
      );
      if (!mixPreview.valid) {
        return null;
      }
      return mixPreview;
    },
    [fetchJsonFileContents],
  );
}

type MixPreviewHookResult = {
  status: 'success' | 'loading' | 'error';
  /**
   * `null` if the simulation has not loaded yet or the workflow does no liquid handling,
   * we have no data for the Preview.
   */
  mixPreview: MixPreview | null;
};

/**
 * Get the mix preview for a given simulation. Internally this fetches actions.json and
 * layout.json from filetree and converts them to a MixPreview.
 */
export function useMixPreview(
  simulation?: SimulationQuery['simulation'],
): MixPreviewHookResult {
  const fetchMixPreview = useFetchMixPreview();

  const [result, setResult] = useState<MixPreviewHookResult>({
    status: 'loading',
    mixPreview: null,
  });

  useEffect(() => {
    setResult({ status: 'loading', mixPreview: null });

    let isOutdated = false;

    if (!simulation?.actionsLayoutFiletreeLink) {
      setResult({ status: 'success', mixPreview: null });
      return;
    }

    void fetchMixPreview(simulation.id, simulation.actionsLayoutFiletreeLink)
      .then(
        (mixPreview): MixPreviewHookResult => ({
          status: 'success',
          mixPreview,
        }),
      )
      .catch((err): MixPreviewHookResult => {
        console.error('Error fetching mix preview', err);
        return { status: 'error', mixPreview: null };
      })
      .then(result => !isOutdated && setResult(result));

    return () => {
      isOutdated = true;
    };
  }, [fetchMixPreview, simulation?.id, simulation?.actionsLayoutFiletreeLink]);

  return result;
}

/**
 * If the simulation is part of a DOE workflow, we need to extract the design
 * information from the workflow.json so it can be shown in the preview. The runs
 * are stored by factor id and run number, format like this:
 *
 * {
 *   "Factor ID": { "Run 1": "Value", "Run 2": "Value", ... }
 *   ...
 *  }
 *
 * This is inconvenient for UI purposes, so we transform it in to a nested array
 * format like this:
 *
 * [
 *   [
 *     { factor: "Factor Display Name": value: "Value" },
 *     { factor: "Factor Display Name": value: "Value" },
 *     ...
 *   ] // Run 1
 *   ...
 * ]
 */
export function useDOEDesign(simulation?: SimulationQuery['simulation']) {
  return useMemo(() => {
    const design = simulation?.workflow.workflow.Design;
    if (design) {
      const { factors, runs, meta } = design;

      const result: DOEDesign = {
        factors,
        runs: [],
      };

      if (factors && runs) {
        const runCount = meta.numRuns * meta.quasiReplicates * meta.replicates;

        for (let r = 1; r <= runCount; r++) {
          let key = `Run ${r}`;

          const run: DOEDesignRun = {
            id: r,
          };

          if (design.runs?.workflows) {
            // Workflow rendering historically used letters, but has moved to numbers.
            // We need to support both so convert between them if required.
            let part = design.runs.workflows[key];

            if (!part) {
              key = `${key} (Control)`;
              part = design.runs.workflows[key];
            }

            if (part) {
              run.part = typeof part === 'number' ? part : part.charCodeAt(0) - 64;
            }
          }

          factors.forEach(factor => {
            run[factor.id] = `${runs[factor.id][key]}`;
          });

          result.runs.push(run);
        }
      }

      return result;
    }

    return undefined;
  }, [simulation?.workflow.workflow.Design]);
}
