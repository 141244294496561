import React, { useCallback, useMemo, useRef, useState } from 'react';

import cx from 'classnames';
import entries from 'lodash/entries';
import { Transition } from 'react-transition-group';

import ExportDesignFactors from 'client/app/components/DOEBuilder/components/ExportDesignFactors';
import {
  CustomFactorsGroup,
  FactorGroup,
} from 'client/app/components/DOEBuilder/components/FactorGroup';
import {
  PANEL_TRANSITION,
  usePermittedFactorKinds,
} from 'client/app/components/DOEBuilder/factorUtils';
import useDerivableFactors from 'client/app/components/DOEBuilder/useDerivableFactors';
import { useElementFactorGroups } from 'client/app/components/DOEBuilder/useElementFactorGroups';
import DOEConstantForm from 'client/app/components/DOEConstantForm/DOEConstantForm';
import ChooseFactorType from 'client/app/components/DOEFactorForm/components/ChooseFactorType';
import DOEFactorForm from 'client/app/components/DOEFactorForm/DOEFactorForm';
import {
  FactorKind,
  FactorParameterInfo,
} from 'client/app/components/DOEFactorForm/types';
import DOEMutualExclusionForm from 'client/app/components/DOEMutualExclusionForm/DOEMutualExclusionForm';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  workflowId: WorkflowId;
  className?: string;
  isReadonly: boolean;
};

export const ADD_FACTOR_TOOLTIP = `Define your factor, it's levels and the constraints within which you want to investigate.`;

export default function Factors({ workflowId, className, isReadonly }: Props) {
  const classes = useStyles();

  const dispatch = useWorkflowBuilderDispatch();
  const factors = useWorkflowBuilderSelector(state => state.factors);
  const factorEditing = useWorkflowBuilderSelector(state => state.factorEditing);
  const { groups: factorGroups, customFactors } = useElementFactorGroups();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [typeToEdit, setTypeToEdit] = useState<FactorKind | undefined>();

  const [editingFactor, setEditingFactor] = useState<FactorItem | undefined>();
  const [factorisingParameter, setFactorisingParameter] = useState<
    FactorParameterInfo | undefined
  >();
  const [editingGroup, setEditingGroup] = useState<FactorItem[] | undefined>();

  const addItem = useCallback((parameterInfo?: FactorParameterInfo) => {
    setOpenPanel(true);
    setTypeToEdit(undefined);
    setFactorisingParameter(parameterInfo);
    setEditingFactor(undefined);
  }, []);

  const editFactor = useCallback(
    (factorId: string, parameterInfo?: FactorParameterInfo) => {
      const factorToEdit = factors?.find(factor => factor.id === factorId);

      if (factorToEdit) {
        setOpenPanel(true);
        setTypeToEdit(
          factorToEdit.variableTypeName === 'derived'
            ? 'derived'
            : factorToEdit.values.length === 1
            ? 'constant'
            : 'factor',
        );
        setEditingFactor(factorToEdit);
        setFactorisingParameter(parameterInfo);
      }
    },
    [factors],
  );

  const editMutualExclusion = useCallback(
    (groupName: string, factorToOpen?: string, parameterInfo?: FactorParameterInfo) => {
      const factorsToEdit = factors?.filter(
        factor => factor.mutualExclusionGroup === groupName,
      );

      if (factorsToEdit?.length) {
        if (factorToOpen) {
          setEditingFactor(factorsToEdit.find(f => f.id === factorToOpen));
        }

        setOpenPanel(true);
        setTypeToEdit('mutual-exclusion');
        setEditingGroup(factorsToEdit);
        setFactorisingParameter(parameterInfo);
      }
    },
    [factors],
  );

  const closePanel = useCallback(() => {
    setOpenPanel(false);
    setEditingFactor(undefined);
    setFactorisingParameter(undefined);
  }, []);

  const saveFactor = useCallback(
    (factor: FactorItem) => {
      dispatch({ type: 'addOrUpdateFactors', payload: [factor] });
      closePanel();
    },
    [closePanel, dispatch],
  );

  const saveMutualExclusion = useCallback(
    (group: FactorItem[]) => {
      closePanel();
      dispatch({ type: 'addOrUpdateFactors', payload: group });
    },
    [closePanel, dispatch],
  );

  const factorDescriptors = useMemo(
    () => factors?.map(f => (f.path ? f.path.join('/') : f.displayName)) ?? [],
    [factors],
  );
  const groupNames = useMemo(
    () =>
      Array.from(
        new Set(
          factors
            ?.map(f => f.mutualExclusionGroup)
            .filter((value): value is string => value !== undefined),
        ),
      ),
    [factors],
  );
  const derivableFactors = useDerivableFactors(editingFactor);

  const permittedFactorKinds = usePermittedFactorKinds(
    factorisingParameter,
    derivableFactors,
  );

  return (
    <div className={cx(classes.container, className)} ref={containerRef}>
      <div className={classes.scrollWrapper}>
        <div className={classes.factorsMain}>
          <div className={classes.header}>
            <InlineHelp
              size="large"
              heading="DOE Design Factors"
              classes={{ tooltip: classes.factorsHelp }}
            >
              Define the factors (the properties you want to investigate), levels (the set
              points at which you want to investigate your properties) and the
              relationship between factors (whether one factors levels depend on another)
              that you want to calculate a DOE design from.
            </InlineHelp>
            <ExportDesignFactors workflowId={workflowId} />
          </div>
          <ul className={classes.factorGroupList}>
            {entries(factorGroups).map(([elementName, values]) =>
              entries(values).map(([parameterName, { factors, parameterInfo }]) => (
                <FactorGroup
                  key={elementName + parameterName}
                  elementName={elementName}
                  parameterName={parameterName}
                  factors={factors}
                  parameterInfo={parameterInfo}
                  active={
                    elementName === factorEditing.selectedFactorElement &&
                    parameterName === factorEditing.selectedFactorParameter
                  }
                  addItem={() => addItem(parameterInfo)}
                  editFactor={factorId => editFactor(factorId, parameterInfo)}
                  isReadonly={isReadonly}
                  editMutualExclusion={(name, factorId) =>
                    editMutualExclusion(name, factorId, parameterInfo)
                  }
                />
              )),
            )}
            <CustomFactorsGroup
              addItem={addItem}
              editFactor={factorId => editFactor(factorId)}
              customFactors={customFactors}
              isReadonly={isReadonly}
              editMutualExclusion={editMutualExclusion}
            />
          </ul>
        </div>
      </div>

      <Transition
        in={!!openPanel}
        timeout={PANEL_TRANSITION}
        appear
        mountOnEnter
        unmountOnExit
      >
        {state => (
          <>
            {!typeToEdit ? (
              <ChooseFactorType
                className={cx(classes.factorForm, {
                  visible: state === 'entering' || state === 'entered',
                })}
                onCancel={closePanel}
                onChoice={setTypeToEdit}
                permittedFactorTypes={permittedFactorKinds}
              />
            ) : typeToEdit === 'factor' || typeToEdit === 'derived' ? (
              <DOEFactorForm
                className={cx(classes.factorForm, {
                  visible: state === 'entering' || state === 'entered',
                })}
                onSave={saveFactor}
                onCancel={closePanel}
                isReadonly={isReadonly}
                factorDescriptors={factorDescriptors}
                parameterInfo={factorisingParameter}
                factor={editingFactor}
                derivableFactors={derivableFactors}
                typeToAdd={typeToEdit}
              />
            ) : typeToEdit === 'mutual-exclusion' ? (
              <DOEMutualExclusionForm
                className={cx(classes.factorForm, {
                  visible: state === 'entering' || state === 'entered',
                })}
                isReadonly={isReadonly}
                factorDescriptors={factorDescriptors}
                parameterInfo={factorisingParameter}
                group={editingGroup}
                onCancel={closePanel}
                onSave={saveMutualExclusion}
                initialEditFactor={editingFactor}
                existingGroupNames={groupNames}
              />
            ) : (
              <DOEConstantForm
                className={cx(classes.factorForm, {
                  visible: state === 'entering' || state === 'entered',
                })}
                factor={editingFactor}
                onCancel={closePanel}
                onSave={saveFactor}
                isReadonly={isReadonly}
                factorDescriptors={factorDescriptors}
                parameter={factorisingParameter}
              />
            )}
            <div
              className={cx(classes.overlay, {
                visible: state === 'entering' || state === 'entered',
              })}
            />
          </>
        )}
      </Transition>
    </div>
  );
}

export const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    flex: '1',
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  scrollWrapper: {
    display: 'grid',
    overflow: 'auto',
    gridTemplate: `auto /minmax(0, 1280px)`,
    justifyContent: 'center',
    height: '100%',
    scrollbarGutter: 'stable both-edges',
  },
  factorsMain: {
    padding: spacing(6),
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),
  },
  header: {
    display: 'flex',
    gap: spacing(5),
    alignItems: 'center',
  },
  factorsHelp: {
    maxWidth: '600px',
  },
  factorGroupList: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(6),
    padding: 0,
    margin: 0,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: Colors.GREY_10,
    opacity: 0,
    transition: `opacity ${PANEL_TRANSITION}ms ease-in`,
    '&.visible': {
      opacity: 0.7,
    },
  },
  factorForm: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    width: 'min(100%, 480px)',
    zIndex: 10,
    transform: 'translateX(100%)',
    transition: `transform ${PANEL_TRANSITION}ms ease-in`,
    '&.visible': {
      transform: 'translateX(0%)',
    },
  },
}));
