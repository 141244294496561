import React, { useState } from 'react';

import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import LiquidsLayoutEditor from 'client/app/components/Parameters/PlateLayout/LiquidsLayoutEditor';
import MixturesPreview from 'client/app/components/Parameters/PlateLayout/MixturesPreview';
import PlateLayoutEditorContextProvider from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type Props = {
  onClose: () => void;
  className?: string;
};

enum TabIds {
  LAYOUT = 'layout',
  PREVIEW = 'preview',
}

const TABS: TabsInfo<TabIds> = [
  { value: TabIds.LAYOUT, label: 'Mix Layout' },
  {
    value: TabIds.PREVIEW,
    label: 'Mixtures Preview',
  },
];

function PlateLayoutEditorPanelContents({ onClose, className }: Props) {
  const [activeTabId, setActiveTabId] = useState(TabIds.LAYOUT);

  return (
    <Grow in unmountOnExit>
      <Panel className={className} elevation={4}>
        <TitleBar>
          <Tabs
            tabsInfo={TABS}
            activeTab={activeTabId}
            onChangeTab={id => setActiveTabId(id)}
          />
          <Button size="small" onClick={onClose} variant="tertiary" color="primary">
            Done
          </Button>
        </TitleBar>
        {activeTabId === TabIds.PREVIEW ? (
          <MixturesPreviewTab />
        ) : (
          <LiquidsLayoutEditor />
        )}
      </Panel>
    </Grow>
  );
}

export default function PlateLayoutEditorPanel(props: Props) {
  return (
    <PlateLayoutEditorContextProvider>
      <PlateLayoutEditorPanelContents {...props} />
    </PlateLayoutEditorContextProvider>
  );
}

const Panel = styled(Paper)({
  display: 'grid',
  gridTemplate: `
    'titlebar' auto
    'main' minmax(0, 1fr)
  `,
  backgroundColor: Colors.WHITE,
  borderRadius: '8px',
});

const TitleBar = styled('div')(({ theme }) => ({
  gridArea: 'titlebar',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const MixturesPreviewTab = styled(MixturesPreview)({
  gridArea: 'main',
  placeSelf: 'stretch',
});
