import React, {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import useResizeObserver from 'use-resize-observer';

import { CSVColumn, toCSV } from 'client/app/lib/csv';
import { downloadTextFile } from 'common/lib/download';
import { roundNumber } from 'common/lib/format';
import { Factors } from 'common/types/bundle';
import { DOEDesign, DOEDesignRun } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DownloadNotCloud } from 'common/ui/icons/DownloadNotCloud';

type Props = {
  design: DOEDesign;
  part: number | null;
  simulationName: string;
};

/**
 * This component uses an approach to virtualising a table using the react-window library
 * adapted from here: https://codesandbox.io/s/react-window-with-table-elements-d861o?file=/src/index.tsx:2223-2254
 */

const TableContext = createContext<{
  top: number;
  factors: Factors;
  runs: DOEDesignRun[];
  part: number | null;
  showAllRuns: boolean;
}>({
  top: 0,
  factors: [],
  runs: [],
  part: null,
  showAllRuns: false,
});

const Inner = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  ({ children, ...rest }, ref) => {
    const classes = useStyles();
    const { top, factors } = useContext(TableContext);

    return (
      <div ref={ref} {...rest}>
        <Table stickyHeader className={classes.table} style={{ top }}>
          <TableHead>
            <TableRow>
              <TableCell>Run</TableCell>
              {factors.map(factor => (
                <TableCell key={factor.id}>
                  {factor.displayName} {factor.unit ? `(${factor.unit})` : null}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </div>
    );
  },
);

function Row({ index }: ListChildComponentProps) {
  const classes = useStyles();
  const { factors, runs, part, showAllRuns } = useContext(TableContext);
  const row = runs[index];

  return (
    <TableRow
      key={row.id}
      className={cx({ [classes.highlight]: showAllRuns && part === row.part })}
    >
      <TableCell>{row.id}</TableCell>
      {factors.map(factor => {
        const rawValue = row[factor.id];
        const numericValue = +rawValue;
        const cellValue = Number.isNaN(numericValue)
          ? rawValue
          : roundNumber(numericValue);

        return <TableCell key={factor.id}>{cellValue}</TableCell>;
      })}
    </TableRow>
  );
}

export default function DesignScreen({ design, part, simulationName }: Props) {
  const classes = useStyles();

  const wrapperRef = useRef<HTMLDivElement>(null);

  const { height = 0 } = useResizeObserver({ ref: wrapperRef });
  const listRef = useRef<FixedSizeList | null>();
  const [top, setTop] = useState(0);
  const [showAllRuns, setShowAllRuns] = useState(part === null ? true : false);

  const runs = useMemo(() => {
    if (showAllRuns || part === null) {
      return design.runs;
    }

    return design.runs.filter(run => run.part === part);
  }, [design.runs, part, showAllRuns]);

  const context = useMemo(
    () => ({ top, runs, factors: design.factors, part, showAllRuns }),
    [design.factors, part, runs, showAllRuns, top],
  );

  const downloadFullDesign = useDownloadFullDesign(design, simulationName);

  return (
    <TableContext.Provider value={context}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h3">Runs</Typography>
          {part !== null && (
            <div className={classes.toggleWrapper}>
              <Typography variant="body1" color="textSecondary">
                Runs to show
              </Typography>
              <Toggle
                value={showAllRuns}
                onChange={(_, value) => setShowAllRuns(value)}
                exclusive
                className={classes.runsToggle}
              >
                <ToggleButton value={false}>Only this part</ToggleButton>
                <ToggleButton value>Full design</ToggleButton>
              </Toggle>
            </div>
          )}
          <Button
            variant="outlined"
            color="primary"
            startIcon={<DownloadNotCloud />}
            onClick={downloadFullDesign}
            className={classes.downloadButton}
          >
            Download Full Design
          </Button>
        </div>
        <div className={classes.tableWrapper} ref={wrapperRef}>
          <FixedSizeList
            innerElementType={Inner}
            height={height}
            itemSize={46}
            itemCount={runs.length}
            width="100%"
            onItemsRendered={props => {
              // This is copied from the virtualised table example above.
              // It seems necessary to correctly position the table based on the
              // virtualised window offset, using a private API method from the library.
              const style =
                // @ts-ignore private method access
                listRef.current?._getItemStyle(props.overscanStartIndex);
              setTop(style?.top ?? 0);
            }}
            ref={el => (listRef.current = el)}
          >
            {Row}
          </FixedSizeList>
        </div>
      </div>
    </TableContext.Provider>
  );
}

function useDownloadFullDesign(design: DOEDesign, simulationName: string) {
  return useCallback(() => {
    const columns: CSVColumn<DOEDesignRun>[] = [
      {
        title: 'Run',
        getValue: (data: DOEDesignRun) => `${data.id}`,
      },
      ...design.factors.map(f => ({
        title: `${f.displayName} ${f.unit ? `(${f.unit})` : ''}`,
        getValue: (data: DOEDesignRun) => `${data[f.id]}`,
      })),
    ];
    const fullDesignCSV = toCSV(columns, design.runs);

    downloadTextFile(fullDesignCSV, `${simulationName}-full-design.csv`, 'text/csv');
  }, [design.factors, design.runs, simulationName]);
}

const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    flex: 1,
    display: 'grid',
    gridTemplate: 'auto 1fr / 1fr',
    padding: spacing(5, 6),
    minHeight: 0,
    gap: spacing(5),
  },
  header: {
    display: 'grid',
    gridTemplate: `auto / minmax(0, 1fr) auto auto`,
    alignItems: 'end',
    gap: spacing(6),
  },
  table: {
    '& th': {
      whiteSpace: 'nowrap',
    },
    '& td': {
      height: '50px',
    },
    position: 'absolute',
  },
  tableWrapper: {
    overflow: 'hidden',
    border: `1px solid ${Colors.GREY_30}`,
    borderRadius: '4px',
    display: 'grid',
    placeItems: 'stretch',
  },
  toggleWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    gap: spacing(4),
    alignItems: 'center',
  },
  runsToggle: {
    width: '300px',
  },
  highlight: {
    background: Colors.BLUE_0,
  },
  downloadButton: {
    gridColumn: 3,
  },
}));
