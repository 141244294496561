import React, { useMemo } from 'react';

import Colors from 'common/ui/Colors';
import { ScreenIds } from 'common/ui/components/simulation-details/simulationDetailsScreenIds';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

const TABS: TabsInfo<ScreenIds> = [
  { value: ScreenIds.OVERVIEW, label: 'Overview' },
  { value: ScreenIds.SETUP, label: 'Setup' },
  { value: ScreenIds.DESIGN, label: 'Design' },
  { value: ScreenIds.INSTRUCTIONS, label: 'Instructions' },
  { value: ScreenIds.PREVIEW, label: 'Preview' },
];

type Props = {
  currentScreenId: ScreenIds;
  // Screens are dynamic, and we render a navigation button for each of them.
  screenIds: readonly ScreenIds[];
  onSubscreenButtonClick: (screenId: ScreenIds) => void;
};

export default function Navigation({
  currentScreenId,
  screenIds,
  onSubscreenButtonClick,
}: Props) {
  const classes = useStyles();
  const tabs = useMemo(
    () => TABS.filter(tab => screenIds.includes(tab.value)),
    [screenIds],
  );
  return (
    <Tabs
      tabsInfo={tabs}
      activeTab={currentScreenId}
      onChangeTab={tab => {
        onSubscreenButtonClick(tab);
        // Left/right keys are used in the preview to navigate the simulation. MUI also
        // binds to these keys when the tab bar is focused
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }}
      className={classes.tabs}
    />
  );
}

const useStyles = makeStylesHook(theme => ({
  tabs: {
    backgroundColor: Colors.GREY_10,
    borderBottom: `1px solid ${Colors.GREY_30}`,
    paddingLeft: theme.spacing(6),
  },
}));
