import React, { useContext } from 'react';

import { useMutation } from '@apollo/client';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';

import BioprocessBlockCard from 'client/app/apps/experiments/blocks/BioprocessBlock';
import ExperimentBlockWrapper from 'client/app/apps/experiments/blocks/ExperimentBlockWrapper';
import SimulationBlockCard from 'client/app/apps/experiments/blocks/SimulationBlock';
import TextBlockCard from 'client/app/apps/experiments/blocks/TextBlock';
import TitleBlockCard from 'client/app/apps/experiments/blocks/TitleBlock';
import { ExperimentDetailContext } from 'client/app/apps/experiments/ExperimentDetailScreen';
import { ExperimentReportToolbar } from 'client/app/apps/experiments/ExperimentReportToolbar';
import { MUTATION_REPOSITION_EXPERIMENT_BLOCK } from 'client/app/apps/experiments/gql/mutations';
import { QUERY_EXPERIMENT } from 'client/app/apps/experiments/gql/queries';
import {
  NewExperimentBlockMenu,
  NewExperimentBlockMenuItems,
} from 'client/app/apps/experiments/NewExperimentBlockMenu';
import OptimisticMutationWrapper from 'client/app/apps/experiments/OptimisticMutationWrapper';
import { useCreateAndLinkWorkflowToExperiment } from 'client/app/apps/experiments/useCreateAndLinkWorkflowToExperiment';
import { WorkflowCard } from 'client/app/components/cards/WorkflowCard';
import {
  ExperimentBlockFragmentFragment as ExperimentBlock,
  ExperimentQuery,
} from 'client/app/gql';
import EmptyExperimentBanner from 'common/assets/EmptyExperimentBanner';
import { IntercomTourIDs } from 'common/lib/intercom';
import { IntercomTarget } from 'common/lib/IntercomTarget';
import { EditorType } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { DraggableList } from 'common/ui/components/DragDrop/DraggableList';
import MenuItemWithIcon from 'common/ui/components/Menu/MenuItemWithIcon';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import CherryPickerIcon from 'common/ui/icons/CherryPickerIcon';
import { WorkflowIcon } from 'common/ui/icons/Workflow';

type ExperimentBodyProps = {
  experiment: ExperimentQuery['experiment'];
  className?: string;
};

export function ExperimentBody({ experiment, className }: ExperimentBodyProps) {
  const classes = useStyles();

  const [repositionBlockMutation] = useMutation(MUTATION_REPOSITION_EXPERIMENT_BLOCK);

  const createWorkflowInExperiment = useCreateAndLinkWorkflowToExperiment();

  const handleChangeOrder = (
    newBlockList: ExperimentBlock[],
    movedBlock: ExperimentBlock,
  ) => {
    const movedBlockIndex = newBlockList.indexOf(movedBlock);
    // Grab the block after. This will be undefined if moving to the end of the list.
    const insertBefore =
      movedBlockIndex > -1 ? newBlockList[movedBlockIndex + 1] : undefined;

    void repositionBlockMutation({
      variables: { id: movedBlock.id, insertBefore: insertBefore?.id ?? null },
      optimisticResponse: {
        __typename: 'Mutation',
        repositionExperimentBlock: {
          __typename: 'Experiment',
          id: experiment.id,
          blocks: newBlockList,
        },
      },
      refetchQueries: [{ query: QUERY_EXPERIMENT, variables: { id: experiment.id } }],
    });
  };

  return (
    <div className={className}>
      <ExperimentReportToolbar experiment={experiment} />
      {experiment.blocks.length === 0 ? (
        <EmptyExperiment
          experimentId={experiment.id}
          onCreateWorkflow={createWorkflowInExperiment}
        />
      ) : (
        <div className={classes.blocks}>
          <DraggableList
            items={[...experiment.blocks]}
            getIdFromItem={block => block.id}
            onChangeOrder={handleChangeOrder}
            renderItem={(block, dragProps, i) => (
              <>
                {/* Menu above each block. */}
                <NewExperimentBlockMenu
                  experimentId={experiment.id}
                  insertBeforeId={block.id}
                  experimentBlocks={experiment.blocks}
                />
                <OptimisticMutationWrapper key={block.id} objectId={block.id}>
                  <ExperimentBlockWrapper
                    experimentId={experiment.id}
                    block={block}
                    previousBlock={experiment.blocks[i - 1]}
                    nextBlock={experiment.blocks[i + 1]}
                    dragProps={dragProps}
                  >
                    <ExperimentBlockContainer
                      experimentId={experiment.id}
                      block={block}
                    />
                  </ExperimentBlockWrapper>
                </OptimisticMutationWrapper>
              </>
            )}
          />
          {/* Menu at the bottom. */}
          <NewExperimentBlockMenu
            experimentId={experiment.id}
            experimentBlocks={experiment.blocks}
          />
        </div>
      )}
    </div>
  );
}

type EmptyExperimentProps = {
  experimentId: ExperimentId;
  onCreateWorkflow: (experimentId: ExperimentId, workflowSource: EditorType) => void;
};

/**
 * Shows when an experiment has no blocks. Prompts user to create a block.
 */
function EmptyExperiment({ experimentId, onCreateWorkflow }: EmptyExperimentProps) {
  const classes = useStyles();

  const { isEditing } = useContext(ExperimentDetailContext);

  return (
    <>
      {isEditing ? (
        <div className={classes.emptyExperimentEditing}>
          <div>
            <Typography variant="h3" color="textPrimary">
              Start by adding:
            </Typography>
            <IntercomTarget tour={IntercomTourIDs.EXPERIMENT} name="content-menu">
              <MenuList>
                <NewExperimentBlockMenuItems
                  experimentId={experimentId}
                  hasDividerMargin
                />
              </MenuList>
            </IntercomTarget>
          </div>
          <div>
            <Typography variant="h3" color="textPrimary">
              Or by creating a workflow:
            </Typography>
            <MenuList>
              <MenuItemWithIcon
                text="Via Builder"
                icon={<WorkflowIcon />}
                onClick={() => onCreateWorkflow(experimentId, EditorType.WORKFLOW_EDITOR)}
              />
              <MenuItemWithIcon
                text="Via Cherry Picker"
                icon={<CherryPickerIcon />}
                onClick={() => onCreateWorkflow(experimentId, EditorType.CHERRY_PICKER)}
              />
            </MenuList>
          </div>
        </div>
      ) : (
        <div className={classes.emptyExperimentViewing}>
          <Typography variant="h3" color="textPrimary">
            Nothing to see here yet.
            <br />
            Come back later.
          </Typography>
        </div>
      )}
      <EmptyExperimentBanner />
    </>
  );
}

type ExperimentBlockContainerProps = {
  experimentId: ExperimentId;
  block: ExperimentBlock;
};

function ExperimentBlockContainer({
  experimentId,
  block,
}: ExperimentBlockContainerProps): JSX.Element {
  const { isEditing } = useContext(ExperimentDetailContext);

  switch (block.__typename) {
    case 'TitleBlock':
      return <TitleBlockCard experimentId={experimentId} block={block} />;
    case 'TextBlock':
      return <TextBlockCard experimentId={experimentId} block={block} />;
    case 'WorkflowBlock':
      return <WorkflowCard workflow={block.workflow} isLink />;
    case 'SimulationBlock':
      return <SimulationBlockCard block={block} />;
    case 'DatasetDerivationBlock':
      return (
        <BioprocessBlockCard
          derivationId={block.datasetDerivation.id}
          experimentId={experimentId}
          isReadonly={!isEditing}
        />
      );
    default:
      throw new Error(`Unknown block type: ${block.__typename}`);
  }
}

const useStyles = makeStylesHook(theme => ({
  dropdownButton: {
    textTransform: 'none',
    color: Colors.TEXT_PRIMARY,
  },
  emptyExperimentEditing: {
    margin: '0 auto',
    maxWidth: '600px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '40px',
    alignItems: 'start',
  },
  emptyExperimentViewing: {
    textAlign: 'center',
  },
  blocks: {
    marginBottom: theme.spacing(8),
  },
}));
