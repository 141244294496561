import React from 'react';

import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

import { isNumericFactor } from 'client/app/components/DOEBuilder/factorUtils';
import useHasDerivedFactors from 'client/app/components/DOEBuilder/useHasDerivedFactors';
import DerivedLevelEditor from 'client/app/components/DOEFactorForm/components/DerivedLevelEditor';
import DOEForm from 'client/app/components/DOEFactorForm/components/DOEForm';
import FactorDeriveSelect from 'client/app/components/DOEFactorForm/components/FactorDeriveSelect';
import FactorDerivingExpression from 'client/app/components/DOEFactorForm/components/FactorDerivingExpression';
import FactorLevelEditor from 'client/app/components/DOEFactorForm/components/FactorLevelEditor';
import FactorNameEditor from 'client/app/components/DOEFactorForm/components/FactorNameEditor';
import { DOEFactorSampling } from 'client/app/components/DOEFactorForm/components/FactorSampling';
import Help from 'client/app/components/DOEFactorForm/components/Help';
import { FactorParameterInfo } from 'client/app/components/DOEFactorForm/types';
import useDOEFactorForm, {
  mapStateToFactor,
  MutualExclusionInfo,
} from 'client/app/components/DOEFactorForm/useDOEFactorForm';
import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import { FactorItem } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Checkbox from 'common/ui/components/Checkbox';
import ItemList, { Item } from 'common/ui/components/ItemList/ItemList';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import TextField from 'common/ui/filaments/TextField';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  className?: string;
  onCancel?: () => void;
  isReadonly: boolean;
  parameterInfo?: FactorParameterInfo;
  factor?: FactorItem;
  onSave?: (factor: FactorItem) => void;
  factorDescriptors: string[];
  mutualExclusion?: MutualExclusionInfo;
  derivableFactors?: {
    all: FactorItem[] | undefined;
    numeric: FactorItem[] | undefined;
  };
  typeToAdd: 'factor' | 'derived';
};

const DOEFactorForm = ({
  className,
  onCancel,
  onSave,
  isReadonly,
  parameterInfo,
  factor,
  factorDescriptors,
  mutualExclusion,
  derivableFactors,
  typeToAdd,
}: Props) => {
  const classes = useStyles();

  const {
    state,
    updateFactor,
    updateLevels,
    updateBasicType,
    updateDerivingExpression,
    updateSourceFactor,
    mapFactorLevels,
    showErrors,
  } = useDOEFactorForm(
    parameterInfo,
    factor,
    factorDescriptors,
    typeToAdd,
    mutualExclusion,
  );

  const errors = state.showErrors ? state.errors : {};

  const isNumeric = isNumericFactor(state.factor);
  const isDerived = state.factor.variableTypeName === 'derived';
  const isQuasiRep = state.factor.variableTypeName === 'quasi-replicating';
  const sourceFactorItem = derivableFactors?.all?.find(
    f => f.id === state.factor.sourceFactor?.id,
  );

  const updateSingleLevel = (item: Item) => (value: string | undefined) =>
    updateLevels(
      state.levels.map(l => (l.id === item.id ? { id: l.id, value: value ?? '' } : l)),
    );
  const handleSubmit = () => {
    if (!isReadonly) {
      if (state.errors) {
        showErrors();
      } else {
        onSave?.(mapStateToFactor(state, parameterInfo));
      }
    }
  };
  const formActions = (
    <>
      <Button variant="tertiary" size="small" type="button" onClick={() => onCancel?.()}>
        Cancel
      </Button>
      <Button
        variant="secondary"
        color="primary"
        size="small"
        type="submit"
        disabled={isReadonly}
      >
        {state.isNew ? 'Add' : 'Save'} Factor
      </Button>
    </>
  );

  const showFactorName = true;
  const showReplicateMessage = !!mutualExclusion?.replicate;
  const showCustomFactorKind = state.isCustom;

  const showParameterFactorUnit = !!state.unitOptions;
  const showCustomFactorUnit = !showParameterFactorUnit && isNumeric && state.isCustom;
  const showFactorLevels = !isDerived;

  const showQuasiReplicate =
    !mutualExclusion &&
    (state.factor.variableTypeName === 'factor' ||
      state.factor.variableTypeName === 'quasi-replicating');

  const showHargeToChange = showQuasiReplicate;

  const showSampling = isNumeric && !isDerived;
  const showDerivingExpression = isNumeric && isDerived;
  const showDeriveFromFactor = !isNumeric && isDerived;
  const showDerivedLevels = showDeriveFromFactor && !!sourceFactorItem;

  const { isPartOfDerivingExpression, isDerivationSourceFactor } = useHasDerivedFactors(
    factor?.id,
  );

  const disableNameEditing =
    isReadonly || isPartOfDerivingExpression || isDerivationSourceFactor;
  const disableLevelEditing = isReadonly || isDerivationSourceFactor;
  const disableNumericalToCategoricalToggle =
    isReadonly || isPartOfDerivingExpression || isDerivationSourceFactor;
  const disableCategoricalToNumericalToggle =
    isReadonly ||
    isPartOfDerivingExpression ||
    isDerivationSourceFactor ||
    (isDerived && !derivableFactors?.numeric?.length);

  const disableQuasiRep = isReadonly;
  const disableHardToChange = isReadonly || state.factor.variableTypeName !== 'factor';
  const disableSampling =
    isReadonly || isQuasiRep || !!mutualExclusion || state.factor.hardToChange;

  return (
    <DOEForm
      className={className}
      classes={{ body: classes.container }}
      onSubmit={handleSubmit}
      actions={formActions}
    >
      {showFactorName && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Factor Name"
            isRequired
            help={Help.factorNameHelp}
          />
          <FactorNameEditor
            displayName={state.factor.displayName}
            onChange={name => updateFactor({ displayName: name })}
            errors={errors}
            parameter={parameterInfo}
            disabled={disableNameEditing}
          />
        </div>
      )}
      {showReplicateMessage && (
        <Typography className={classes.replicateMessage}>
          <InfoOutlined fontSize="small" />
          The following settings will be replicated across
          <br />
          all the factors in the mutual exclusion group.
        </Typography>
      )}
      {showCustomFactorKind && (
        <div className={classes.field}>
          <ParameterHeader displayName="Kind" isRequired help={Help.factorKindHelp} />
          <Toggle
            value={isNumeric ? 'numerical' : 'categorical'}
            onChange={(_, value) => updateBasicType(value)}
            exclusive
          >
            <ToggleButton
              value="numerical"
              disabled={disableCategoricalToNumericalToggle}
            >
              Numerical
            </ToggleButton>
            <ToggleButton
              value="categorical"
              disabled={disableNumericalToCategoricalToggle}
            >
              Categorical
            </ToggleButton>
          </Toggle>
        </div>
      )}
      {showParameterFactorUnit && (
        <div className={classes.field}>
          <ParameterHeader displayName="Unit" isRequired />
          <Autocomplete
            autoSelect
            disableClearable
            disabled={isReadonly}
            options={state.unitOptions ?? []}
            value={state.factor.unit}
            onChange={(_, value) => updateFactor({ unit: value })}
            placeholder="Unit"
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        </div>
      )}
      {showCustomFactorUnit && (
        <div className={classes.field}>
          <ParameterHeader displayName="Unit" isRequired={false} />
          <TextField
            value={state.factor.unit}
            onChange={e => updateFactor({ unit: e.target.value })}
            placeholder="Unit"
            disabled={isReadonly}
          />
        </div>
      )}
      {showFactorLevels && (
        <div className={classes.field}>
          <ParameterHeader displayName="Levels" isRequired help={Help.factorLevelsHelp} />
          <ItemList
            value={state.levels}
            onChange={updateLevels}
            disabled={disableLevelEditing}
            renderItemEditor={(item, autoFocus, onPressEnter) => (
              <FactorLevelEditor
                value={item.value}
                onChange={updateSingleLevel(item)}
                autoFocus={autoFocus}
                onPressEnter={onPressEnter}
                parameterInfo={parameterInfo}
                disabled={disableLevelEditing}
                error={!!errors?.levelValues?.[item.id]}
                helperText={errors?.levelValues?.[item.id]}
              />
            )}
          />
          {!!errors?.levels && <FormHelperText error>{errors?.levels}</FormHelperText>}
        </div>
      )}

      {(showHargeToChange || showQuasiReplicate) && (
        <div className={classes.checkboxOptions}>
          {showQuasiReplicate && (
            <div className={classes.field}>
              <FormControlLabel
                className={classes.checkboxOption}
                classes={{ label: classes.checkboxOptionLabel }}
                control={
                  <Checkbox
                    checked={state.factor.variableTypeName === 'quasi-replicating'}
                    onChange={(_, value) =>
                      updateFactor({
                        variableTypeName: value ? 'quasi-replicating' : 'factor',
                        hardToChange: false,
                      })
                    }
                    disabled={disableQuasiRep}
                  />
                }
                label={
                  <>
                    Quasi-Replicate
                    {Help.quasiReplicateHelp}
                  </>
                }
              />
            </div>
          )}

          {showHargeToChange && (
            <div className={classes.field}>
              <FormControlLabel
                className={classes.checkboxOption}
                classes={{ label: classes.checkboxOptionLabel }}
                control={
                  <Checkbox
                    checked={state.factor.hardToChange}
                    onChange={(_, value) => updateFactor({ hardToChange: value })}
                    disabled={disableHardToChange}
                  />
                }
                label={
                  <>
                    Hard to change
                    {Help.hardToChangeHelp}
                  </>
                }
              />
            </div>
          )}
        </div>
      )}

      {showSampling && (
        <DOEFactorSampling
          factor={state.factor}
          errors={errors}
          onUpdate={updateFactor}
          disabled={disableSampling}
        />
      )}
      {showDerivingExpression && !!derivableFactors?.numeric?.length && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Equation"
            isRequired
            help={Help.derivingExpressionHelp}
          />
          <FactorDerivingExpression
            value={state.factor.derivingExpression}
            onChange={updateDerivingExpression}
            factors={derivableFactors.numeric}
            errorMsg={errors?.derivingExpression}
            isReadonly={isReadonly}
          />
        </div>
      )}
      {showDeriveFromFactor && !!derivableFactors?.all?.length && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Derive From Factor"
            isRequired
            help={Help.sourceFactorHelp}
          />
          <FactorDeriveSelect
            value={state.factor.sourceFactor}
            onChange={updateSourceFactor}
            factors={derivableFactors.all}
            errorMsg={errors?.sourceFactor}
            isReadonly={isReadonly}
          />
        </div>
      )}
      {showDerivedLevels && (
        <div className={classes.field}>
          <ParameterHeader
            displayName="Levels"
            isRequired
            help={Help.derivedLevelsHelp}
          />
          <ItemList
            value={state.levels}
            onChange={updateLevels}
            disabled={isReadonly}
            renderItemEditor={(item, autoFocus, onPressEnter) => (
              <DerivedLevelEditor
                value={item.value}
                onChange={updateSingleLevel(item)}
                onPressEnter={onPressEnter}
                onMultiselectChange={selected => mapFactorLevels(selected, item.value)}
                autoFocus={autoFocus}
                parameterInfo={parameterInfo}
                deriveFrom={sourceFactorItem}
                sourceFactorValueMap={state.levelMapping}
                disabled={isReadonly}
                error={!!errors?.levelValues?.[item.id]}
                helperText={errors?.levelValues?.[item.id]}
              />
            )}
          />
          {errors?.unmappedLevels && (
            <FormHelperText error>
              {`${errors.unmappedLevels} of "${sourceFactorItem.displayName}" has not been assigned to a level in this factor. All levels of the derived-from factor must be assigned.`}
            </FormHelperText>
          )}
          {errors?.levels && <FormHelperText error>{errors?.levels}</FormHelperText>}
        </div>
      )}
    </DOEForm>
  );
};

export default DOEFactorForm;

const useStyles = makeStylesHook(({ spacing }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(5),
  },
  field: {
    display: 'flex',
    flexDirection: 'column',
  },
  checkboxOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(2),
  },
  checkboxOption: {
    alignSelf: 'start',
  },
  checkboxOptionLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(3),
  },
  replicateMessage: {
    display: 'flex',
    gap: spacing(5),
    padding: spacing(3, 10, 3, 5),
    background: Colors.BLUE_5,
    borderRadius: spacing(2),
    alignItems: 'center',
  },
}));
