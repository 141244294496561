import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';

import { usePlatesByType } from 'client/app/api/PlateTypesApi';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import CarrierSelect from 'client/app/components/Parameters/PlateType/CarrierSelect';
import ExistingPlateSelect from 'client/app/components/Parameters/PlateType/ExistingPlateSelect';
import PlateLibraryDialog from 'client/app/components/Parameters/PlateType/PlateLibraryDialog';
import PlateTypeEditor from 'client/app/components/Parameters/PlateType/PlateTypeEditor';
import {
  getPlateParameterDisplayValueAsString,
  getPlateParameterValueAsString,
  getValueWithNewCarrier,
  PlateParameterValue,
} from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import { splitFullPlateNameWithDefault } from 'client/app/components/Parameters/PlateType/splitFullPlateName';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { PlateType } from 'common/types/plateType';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import { PlateIcon } from 'common/ui/icons/Plate';

type PlatesByType = { [type: string]: PlateType };
type PlateParameterKind = 'PLATE' | 'PLATE_TYPE' | 'EXISTING_PLATE';
type Props = {
  value: PlateParameterValue;
  onChange: (value?: PlateParameterValue) => void;
  isDisabled?: boolean;
  fullWidth?: boolean;
  /** There are two kinds of plates the backend accepts:
   * PlateType, which is a string. And Plate, which is a plate object.
   * For PlateType, we only allow users to select a plate via the Plate Library.
   * For Plate, we also allow users to select an existing plate from a previous job.
   */
  plateParameterKind?: PlateParameterKind;
};
export type PlateSelection = string | null | undefined | typeof CANCEL_CHOICE;

export default function PlateSelectionEditor({
  onChange,
  value,
  isDisabled,
  plateParameterKind,
}: Props) {
  const classes = useStyles();
  const [dialog, openDialog] = useDialog(PlateLibraryDialog);

  const [platesByType] = usePlatesByType();

  const handleSelectNewPlate = useCallback(async () => {
    const plateType = await openDialog({});

    if (typeof plateType === 'string') {
      onChange(plateType);
    }
  }, [onChange, openDialog]);

  // In some cases, it only makes sense to select a plate from the inventory, hence
  // the UI should not show the "Plate from Simulation" button.
  const canSelectPlateType = plateParameterKind !== 'EXISTING_PLATE';
  // Some elements (e.g. Get Liquid Set From Plates) will retrieve the *liquids*
  // from a plate. Thus, we should only allow users to select existing plates with
  //  liquids, not empty plates.
  const canSelectExistingPlate = plateParameterKind !== 'PLATE_TYPE';

  return (
    <>
      {!value ? (
        <div className={classes.btnsContainer}>
          {canSelectPlateType && (
            <Button
              variant="secondary"
              startIcon={<PlateIcon />}
              className={classes.selectPlateBtn}
              onClick={handleSelectNewPlate}
              disabled={isDisabled}
            >
              Plate type
            </Button>
          )}
          {canSelectExistingPlate && (
            <ExistingPlateSelect
              onChange={onChange}
              value={value}
              isDisabled={isDisabled}
            />
          )}
        </div>
      ) : (
        <PlateAndCarrier
          isDisabled={isDisabled}
          onChange={onChange}
          value={value}
          platesByType={platesByType}
        />
      )}
      {dialog}
    </>
  );
}

type PlateAndCarrierProps = {
  isDisabled?: boolean;
  value: PlateParameterValue;
  onChange: (value?: PlateParameterValue) => void;
  platesByType: PlatesByType;
  fullWidth?: boolean;
};

function PlateAndCarrier({
  isDisabled,
  onChange,
  value,
  platesByType,
  fullWidth,
}: PlateAndCarrierProps) {
  const classes = useStyles();

  // Only Gilson devices can have plates on a riser.
  const canUseRisers = useWorkflowBuilderSelector(
    state => state.config.GilsonPipetMax !== undefined,
  );

  // We keep plate and carrier separate as each of them has its own dropdown
  const [plateType, carrier] = useMemo((): string[] => {
    const plateAndCarrier = getPlateParameterValueAsString(value);
    return splitFullPlateNameWithDefault(plateAndCarrier);
  }, [value]);

  const onCarrierChange = useCallback(
    (carrier: string) => {
      const newValue = getValueWithNewCarrier(value, plateType, carrier);
      onChange(newValue);
    },
    [onChange, plateType, value],
  );

  const onPlateChange = useCallback(
    (plate: PlateSelection) => {
      if (plate === CANCEL_CHOICE) {
        return;
      }

      if (typeof plate === 'string') {
        onChange(plate + carrier);
      } else {
        // Users selected "Select none" or pressed the Clear icon
        onChange();
      }
    },
    [carrier, onChange],
  );

  const onExistingPlateChange = useCallback(
    (plate?: PlateParameterValue) => {
      if (typeof plate === 'object') {
        onChange(plate);
      } else {
        // Users selected "Select none" or pressed the Clear icon
        onChange();
      }
    },
    [onChange],
  );

  // The value for plate types are strings and the value for existing plates
  // are objects.
  const isPlateType = typeof value === 'string';
  const displayValue = getPlateParameterDisplayValueAsString(
    value,
    platesByType[plateType]?.name || plateType,
  );

  return (
    <>
      <div className={cx({ [classes.fullWidth]: fullWidth })}>
        {isPlateType ? (
          <PlateTypeEditor
            // If users select an existing plate, we display its name e.g. "My Aliquot Plate"
            // rather than the human readable plate type
            displayValue={displayValue}
            selectedPlateType={plateType}
            platesByType={platesByType}
            onChange={onPlateChange}
            isDisabled={isDisabled}
          />
        ) : (
          <ExistingPlateSelect
            displayValue={displayValue}
            value={value}
            onChange={onExistingPlateChange}
            isDisabled={isDisabled}
          />
        )}
      </div>
      {(canUseRisers || !!carrier) && (
        <div className={cx(classes.select, { [classes.fullWidth]: fullWidth })}>
          <CarrierSelect
            value={carrier}
            onChange={onCarrierChange}
            isDisabled={isDisabled}
          />
        </div>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  fullWidth: { width: '100%' },
  select: { marginTop: theme.spacing(3) },
  btnsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectPlateBtn: {
    justifyContent: 'left',
  },
}));
