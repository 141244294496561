/**
 * All Colors used in the Antha app live here.
 * If adding a new Color, first decide if an existing Color here will suffice (and use that).
 * Otherwise, a new Color can be added and defined inline in the default export below.
 * If the Color is to be re-used within this file, define it outside the default export first.
 */

/** Antha theme colors are defined here */
const BLUE_0 = '#F2F7FF';
const BLUE_5 = '#D9E8FF';
const BLUE_10 = '#BFD9FF';
const BLUE_20 = '#8CBAFF';
const BLUE_30 = '#5E9DFF';
const BLUE_40 = '#3684FF';
const BLUE_50 = '#1670FF';
const BLUE_60 = '#0862ED';
const BLUE_70 = '#0058D7';
const BLUE_80 = '#0050BE';
const BLUE_90 = '#0047A3';
const BLUE_100 = '#003D87';

const INDIGO_0 = '#FBF7FF';
const INDIGO_5 = '#F6E3FF';
const INDIGO_10 = '#F0CEFF';
const INDIGO_20 = '#E0A5FF';
const INDIGO_30 = '#D07EFF';
const INDIGO_40 = '#C15CFF';
const INDIGO_50 = '#B33EFF';
const INDIGO_60 = '#A525FF';
const INDIGO_70 = '#9710FF';
const INDIGO_80 = '#851BD9';
const INDIGO_90 = '#7123B0';
const INDIGO_100 = '#5B2687';

const GREY_0 = '#FFFFFF';
const GREY_5 = '#FAFBFA';
const GREY_10 = '#F5F7F7';
const GREY_20 = '#E8E9E9';
const GREY_30 = '#D1D2D1';
const GREY_40 = '#AFB0AF';
const GREY_50 = '#858685';
const GREY_60 = '#5A5A5A';
const GREY_70 = '#353535';
const GREY_80 = '#1C1C1C';
const GREY_90 = '#0D0D0D';
const GREY_100 = '#080808';

/** Custom re-usable colors */
const MIX_PREVIEW_EDGE = '#252525';
// Lighter blue than the slider button, to make them easy to distinguish
const LIGHT_BLUE_HIGHLIGHT = '#3fc3ff';

/** Misc colors - try not to use these (e.g. use the Antha theme BLUE_40 instead of BLUE) */
const WHITE = '#FFFFFF';
const BLACK = '#000000';
const RED = '#D7412D';
const YELLOW = '#FAD228';
const DARK_YELLOW = '#FFE266';
const GREEN = '#3CAF1A';
const TEAL = '#46C8C8';
const BLUE = '#299BF0';
const LIGHT_BLUE = '#ADD3F0';
const INDIGO = '#3F51B5';

/** Legacy antha colors - please instead using Error or Warning colors below */
const ERROR = RED;
const WARNING = YELLOW;

export default {
  /** Antha theme colors */
  BLUE_0,
  BLUE_5,
  BLUE_10,
  BLUE_20,
  BLUE_30,
  BLUE_40,
  BLUE_50,
  BLUE_60,
  BLUE_70,
  BLUE_80,
  BLUE_90,
  BLUE_100,

  INDIGO_0,
  INDIGO_5,
  INDIGO_10,
  INDIGO_20,
  INDIGO_30,
  INDIGO_40,
  INDIGO_50,
  INDIGO_60,
  INDIGO_70,
  INDIGO_80,
  INDIGO_90,
  INDIGO_100,

  GREY_0,
  GREY_5,
  GREY_10,
  GREY_20,
  GREY_30,
  GREY_40,
  GREY_50,
  GREY_60,
  GREY_70,
  GREY_80,
  GREY_90,
  GREY_100,

  /** Primary shades */
  PRIMARY_MAIN: BLUE_50,
  PRIMARY_LIGHT: BLUE_10,
  PRIMARY_DARK: BLUE_80,
  PRIMARY_CONTRAST: WHITE,

  /** Secondary shades */
  SECONDARY_MAIN: INDIGO_70,
  SECONDARY_LIGHT: INDIGO_10,
  SECONDARY_DARK: INDIGO_90,
  SECONDARY_CONTRAST: WHITE,

  /** Text */
  TEXT_PRIMARY: '#333333',
  TEXT_SECONDARY: '#666666',
  TEXT_DISABLED: '#A7A7A7',

  /** Error */
  ERROR_MAIN: '#DF304A',
  ERROR_DARK: '#CE002B',
  ERROR_LIGHT: '#FF6875',
  ERROR_CONTRAST: '#FFFFFF',
  ERROR_LIGHT_BG: '#FCEAED',

  /** Info */
  INFO_MAIN: '#1670FF',
  INFO_DARK: '#0050BE',
  INFO_LIGHT: '#BFD9FF',
  INFO_CONTRAST: '#FFFFFF',

  /** Warning */
  WARNING_MAIN: '#FF9800',
  WARNING_LIGHT: '#FFB547',
  WARNING_DARK: '#C77700',
  WARNING_CONTRAST: '#FFFFFF',
  WARNING_LIGHT_BG: '#FFF5E5',

  /** Success */
  SUCCESS_MAIN: '#26A414', // Semantic green 60
  SUCCESS_DARK: '#11830A', // Semantic green 70
  SUCCESS_LIGHT: '#32C026', // Semantic green 50
  SUCCESS_CONTRAST: '#FFFFFF',
  SUCCESS_LIGHT_BG: '#E6F7EC',

  /** Brand greens */
  GREEN_5: '#D4EFD6',
  GREEN_10: '#C5EDC8',
  GREEN_40: '#5CD864',
  GREEN_70: '#3AA13D',
  GREEN_80: '#368637',
  GREEN_90: '#156617',
  GREEN_100: '#004503',

  /** Green palette */
  GREEN_TERTIARY_0: '#D1E3DD',
  GREEN_TERTIARY_70: '#1D4A32',

  /** Actions */
  ACTION_PRIMARY_MAIN_HOVER: 'rgba(22, 112, 255, 0.12)', // 12% of PRIMARY_MAIN
  ACTION_PRIMARY_MAIN_ACTIVE: 'rgba(22, 112, 255, 0.30)', // 30% of PRIMARY_MAIN
  ACTION_HOVER: 'rgba(0, 0, 0, 0.08)',
  ACTION_SELECTED: 'rgba(0, 0, 0, 0.16)',
  ACTION_DISABLED: '#A6A6A6',
  ACTION_DISABLED_BACKGROUND: '#E0E0E0',

  /** Dark theme */
  DARK_ACTION_HOVER: 'rgba(255, 255, 255, 0.24)',
  DARK_ACTION_SELECTED: 'rgba(255, 255, 255, 0.32)',
  DARK_ACTION_DISABLED: 'rgba(255, 255, 255, 0.26)',
  DARK_DISABLED_BACKGROUND: 'rgba(255, 255, 255, 0.2)',
  DARK_TEXT_PRIMARY: GREY_20,
  DARK_TEXT_SECONDARY: GREY_40,
  DARK_TEXT_DISABLED: 'rgba(255, 255, 255, 0.38)',

  /** Custom colors */
  SIMULATION_DETAILS_BACKGROUND_GRAY: GREY_10,
  // Same colour, to make it clear the tip and the edge are related
  CURRENT_TIP: MIX_PREVIEW_EDGE,
  // Orange - nice contrast against the blue slider
  KEY_POINT_DEFAULT: '#ffd251',
  KEY_POINT_TRANSFER: LIGHT_BLUE_HIGHLIGHT,
  MISSING_TIP: '#efe69e',
  MIX_PREVIEW_EDGE: MIX_PREVIEW_EDGE,
  // Make sure labels are readable when they overlap with coloured wells
  MIX_PREVIEW_LABEL: BLACK,
  PLATE_HIGHLIGHT: LIGHT_BLUE_HIGHLIGHT,
  SELECTED_WELL_BORDER: '#333333',
  SLIDER_BAR: '#d7ebf4',
  SLIDER_BUTTON: '#07a3ea',
  TIP: WHITE,
  TIPBOX: '#fcf4b3',
  TIPBOX_REFILL_HIGHLIGHT: GREEN,
  // Liquids
  WATER: '#add8e6',
  PLATE_DETAIL_FOREGROUND: '#666',
  WELL_BORDER: GREY_30,

  /** Misc colors - try not to use these (e.g. use one of the Antha theme BLUE_40 instead of BLUE) */
  ERROR,
  WARNING,
  BLACK,
  BLUE,
  LIGHT_BLUE,
  GREEN,
  INDIGO,
  RED,
  TEAL,
  YELLOW,
  WHITE,
  DARK_YELLOW,

  /** Legacy antha colors - please avoid using */
  GREY_DIVIDER: '#EEE',
  DARK_BORDER: '#3C3246',
  NAV_DARK: '#313535',
  DEVICE_LIBRARY_CARD_HEADER: '#E4E8E8',
  SIMULATION_DETAILS_APP: '#46C9C8',
  BACKGROUND: '#fff',
  TEXT_HIGHLIGHT: '#cef',
};

/**
 * A set of colours optimised for perceptual difference between them. These are mainly
 * used for colouring liquids in the preview.
 */
export const PERCEPTUAL_PALETTE: Record<string, string> = {
  aqua: '#4CC3B6',
  tan: '#DBDABF',
  lavender: '#AEABD6',
  peach: '#FCA69C',
  blue: '#518BB1',
  yellow: '#FFC34C',
  green: '#AFC28C',
  pink: '#D682B4',
  greyBlue: '#91ADBE',
  purple: '#AE86B0',
  lightGreen: '#CDF5C6',
  lightYellow: '#FFF37A',
};
