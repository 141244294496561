import React from 'react';

import { Query } from '@apollo/client/react/components';
import UploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/GetApp';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES } from 'client/app/api/gql/queries';
import useHandleDownloadLiquidClassCsv from 'client/app/components/DeviceLibrary/useHandleDownloadLiquidClassCsv';
import { useHandleDownloadLiquidClassCsvTemplate } from 'client/app/components/DeviceLibrary/useHandleDownloadLiquidClassCsvTemplate';
import { useHandleUploadLiquidClassCsvForExistingDevice } from 'client/app/components/DeviceLibrary/useHandleUploadLiquidClassCsv';
import {
  DeviceCommonFragment as DeviceCommon,
  MissingDeviceLiquidClassesQuery,
  MissingDeviceLiquidClassesQueryVariables,
} from 'client/app/gql';
import { pluralize } from 'common/lib/format';
import Button from 'common/ui/components/Button';

export type Props = {
  device: DeviceCommon;
  onRefresh: () => void;
};

function LiquidClassesPanel(props: Props) {
  const { device, onRefresh } = props;

  const handleDownloadCSV = useHandleDownloadLiquidClassCsv(device);
  const handleUploadCSV = useHandleUploadLiquidClassCsvForExistingDevice(
    device,
    onRefresh,
  );
  const handleDownloadTemplateCSV = useHandleDownloadLiquidClassCsvTemplate(device);

  const onDownloadTemplateCSVClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await handleDownloadTemplateCSV();
  };

  return (
    <Box p={3}>
      <Typography variant="h5" gutterBottom>
        Device liquid classes
      </Typography>
      <Button startIcon={<DownloadIcon />} onClick={handleDownloadCSV} variant="tertiary">
        Download as CSV
      </Button>
      <input id="uploadCSV" type="file" hidden onChange={handleUploadCSV} accept=".csv" />
      <label htmlFor="uploadCSV">
        <Button startIcon={<UploadIcon />} component="span" variant="tertiary">
          Upload CSV
        </Button>
      </label>
      {device.anthaHubGUID && (
        <Query<MissingDeviceLiquidClassesQuery, MissingDeviceLiquidClassesQueryVariables>
          query={QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES}
          variables={{
            anthaHubGUID: device.anthaHubGUID,
          }}
          fetchPolicy="network-only"
        >
          {({ loading, data }) => {
            if (loading || !data) {
              return null;
            }
            const { devices } = data;
            if (devices.length === 0) {
              return null;
            }
            const { liquidClasses } = devices[0];
            if (liquidClasses.missing.length === 0) {
              return null;
            }
            return (
              <Typography color="error">
                {pluralize(
                  liquidClasses.missing.length,
                  'liquid class',
                  'liquid classes',
                )}{' '}
                defined in the device configs have no definition. <br />
                Please{' '}
                <a href="" onClick={onDownloadTemplateCSVClick}>
                  download a CSV template
                </a>{' '}
                to fill in the missing data.
              </Typography>
            );
          }}
        </Query>
      )}
    </Box>
  );
}

export default LiquidClassesPanel;
