import React, { useEffect, useState } from 'react';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import { useFetchGraphQLElementSet } from 'client/app/api/ElementsApi';
import { useFetchFileStructure } from 'client/app/api/FilesApi';
import { getLiquidHandlingTasks } from 'client/app/apps/simulation-details/dataUtils';
import FileBrowserLink from 'client/app/apps/simulation-details/overview/FileBrowserLink';
import {
  fetchOutputPlateFiles,
  fetchReagentsFile,
  useFetchElementOutputFiles,
  useFetchLiquidHandlingInstructions,
  useGetDebuggingFiles,
} from 'client/app/apps/simulation-details/overview/simulationDetailsFiles';
import TreeEntryList, {
  TreeEntry,
} from 'client/app/apps/simulation-details/overview/TreeEntryList';
import VisualizationLinks, {
  useShowVisualizationLinks,
} from 'client/app/apps/simulation-details/overview/VisualizationLinks';
import { ElementSetQuery, SimulationQuery } from 'client/app/gql';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { hasManualDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import { parseFiletreeLink } from 'common/types/filetree';
import { Deck } from 'common/types/mix';
import Colors from 'common/ui/Colors';
import UIBox from 'common/ui/components/UIBox';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

/** Allows us to configure which sections are shown in this sidebar. */
export type FilesSidebarSection =
  | 'visualization_links'
  | 'output_plates'
  | 'element_outputs'
  | 'device_instructions'
  | 'reagents'
  | 'debugging';

type Props = {
  simulation: SimulationQuery['simulation'];
  deck?: Deck;
  /** Allows us to configure which sections are shown in this sidebar. */
  sections: Set<FilesSidebarSection>;
};

export default React.memo(function FilesSidebar({ simulation, deck, sections }: Props) {
  const classes = useStyles();

  const [liquidHandlingInstructions, setLiquidHandlingInstructions] = useState<
    readonly TreeEntry[] | null
  >(null);
  const [outputPlateFiles, setOutputPlateFiles] = useState<readonly TreeEntry[] | null>(
    null,
  );
  const [elementOutputFiles, setElementOutputFiles] = useState<
    readonly TreeEntry[] | null
  >(null);
  const [reagentsFiles, setReagentsFiles] = useState<readonly TreeEntry[] | null>(null);
  const [internalDebuggingFiles, setInternalDebuggingFiles] = useState<TreeEntry[]>([]);
  const [elementSet, setElementSet] = useState<ElementSetQuery['elementSet'] | null>(
    null,
  );

  const fetchLiquidHandlingInstructions = useFetchLiquidHandlingInstructions();
  const fetchElementOutputFiles = useFetchElementOutputFiles();
  const fetchFileStructure = useFetchFileStructure();

  const fetchGraphQLElementSet = useFetchGraphQLElementSet();
  const getDebuggingFiles = useGetDebuggingFiles();
  const userProfile = useUserProfile();

  useEffect(() => {
    let didCancel = false;
    const checkIfValid =
      <T, U>(fn: (val: T) => U) =>
      (value: T) =>
        !didCancel && fn(value);

    const liquidHandlingTasks = getLiquidHandlingTasks(simulation.tasks);

    if (sections.has('device_instructions') && liquidHandlingTasks) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchLiquidHandlingInstructions(
        simulation.id,
        simulation.name,
        liquidHandlingTasks,
      ).then(checkIfValid(setLiquidHandlingInstructions));
    }

    if (sections.has('output_plates') && liquidHandlingTasks && deck) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchOutputPlateFiles(simulation.id, deck).then(checkIfValid(setOutputPlateFiles));
    }

    if (sections.has('element_outputs')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchElementOutputFiles(simulation.filetreeLink).then(
        checkIfValid(setElementOutputFiles),
      );
    }

    if (sections.has('reagents') && simulation.reagents !== null) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchReagentsFile(simulation.reagents).then(checkIfValid(setReagentsFiles));
    }

    if (userProfile?.isSynthaceEmployee) {
      fetchGraphQLElementSet(simulation.workflow.workflow.elementSetId)
        .then(elementSet => {
          setElementSet(elementSet);
        })
        .catch(() => {
          console.error('Failed to get branch information for this simulation');
        });

      if (sections.has('debugging')) {
        // Check files actually exist to avoid showing a download button in the UI
        // which fails silently with a 404. (T2746)
        fetchFileStructure(simulation.filetreeLink)
          .then(({ resp }) => {
            if (!didCancel) {
              const hasDebuggingFiles = resp.find(
                file => file.name === 'composer_input.tar.gz',
              );
              if (hasDebuggingFiles) {
                const debuggingFiles = getDebuggingFiles(simulation);
                setInternalDebuggingFiles(debuggingFiles);
              }
            }
          })
          .catch(error => {
            console.error(
              'Failed to get internal debugging files for this simulation: ' + error,
            );
          });
      }
    }

    return function cleanup() {
      didCancel = true;
    };
  }, [deck, fetchElementOutputFiles, fetchFileStructure, fetchGraphQLElementSet, fetchLiquidHandlingInstructions, getDebuggingFiles, sections, simulation, userProfile?.isSynthaceEmployee]);

  const isManual = hasManualDevice(simulation.workflow.workflow.Config);

  const showVisualizationLinks = useShowVisualizationLinks(simulation);

  return (
    <div className={classes.filesSidebar}>
      {sections.has('visualization_links') && showVisualizationLinks && (
        <div className={classes.visualizationLinks}>
          <Typography variant="h4">Data Visualizations</Typography>
          <VisualizationLinks simulation={simulation} />
        </div>
      )}
      {!!outputPlateFiles?.length && (
        <>
          <Typography variant="h4">Plate Files</Typography>
          <TreeEntryList entries={outputPlateFiles} />
        </>
      )}
      {!!elementOutputFiles?.length && (
        <>
          <Typography variant="h4">Element Outputs</Typography>
          <TreeEntryList entries={elementOutputFiles} />
        </>
      )}
      {!!liquidHandlingInstructions?.length && (
        <>
          <Typography variant="h4">
            {isManual ? 'Manual' : 'Device'} Instructions
          </Typography>
          <TreeEntryList entries={liquidHandlingInstructions} />
        </>
      )}
      {!!reagentsFiles?.length && (
        <>
          <Typography variant="h4">Reagents</Typography>
          <TreeEntryList entries={reagentsFiles} />
        </>
      )}
      {userProfile?.isSynthaceEmployee && (
        <>
          <Typography variant="h4">Debugging</Typography>
          <Typography variant="caption">Visible to Synthace employees only</Typography>

          {elementSet && (
            <Typography
              className={cx(classes.branchName, {
                [classes.notReleaseBranch]: elementSet.isRelease === false,
              })}
            >
              Simulated from branch: {elementSet.name}
            </Typography>
          )}
          {!!internalDebuggingFiles?.length && (
            <TreeEntryList entries={internalDebuggingFiles} />
          )}
        </>
      )}
      <Divider />
      <UIBox padding="vs">
        <FileBrowserLink
          simulationFiletreePath={parseFiletreeLink(simulation.filetreeLink).path}
        />
      </UIBox>
    </div>
  );
});

const useStyles = makeStylesHook({
  filesSidebar: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    backgroundColor: Colors.SIMULATION_DETAILS_BACKGROUND_GRAY,
    flexShrink: 0,
    width: '320px',
    padding: '32px 0px 32px 24px',
  },
  visualizationLinks: {
    marginBottom: '16px',
  },
  branchName: {
    marginTop: '8px',
  },
  notReleaseBranch: {
    color: Colors.ERROR_DARK,
  },
});
