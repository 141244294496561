import React, { useEffect, useState } from 'react';

import { useQuery } from '@apollo/client';
import Typography from '@mui/material/Typography';

import { QUERY_VIS_METADATA } from 'client/app/api/gql/queries';
import VisualizationIFrame from 'client/app/apps/Visualization/VisualizationIFrame';
import { VisualizationRouteProps } from 'client/app/lib/nav/actions';
import setBrowserTabTitle from 'client/app/lib/setBrowserTabTitle';
import GraphQLErrorPanel from 'common/ui/components/GraphQLErrorPanel';
import LinearProgress from 'common/ui/components/LinearProgress';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = VisualizationRouteProps;

/**
 * Displays a visserver visualization in an iframe, with a header detailing
 * simulation details.
 */
export default function Visualization({ id, visserverApp, simulationID }: Props) {
  const classes = useStyles();

  const [visserverLoading, setVisserverLoading] = useState<boolean>(true);

  const {
    loading: metadataLoading,
    data,
    error,
  } = useQuery(QUERY_VIS_METADATA, {
    variables: {
      simulationId: simulationID as SimulationId,
      dataVisualisationId: id as DataVisualisationId,
    },
  });

  useEffect(() => {
    setBrowserTabTitle(data?.simulation.name ?? 'Loading...', data?.visualisation.name);
  }, [data?.simulation.name, data?.visualisation.name]);

  if (error) {
    return <GraphQLErrorPanel error={error} />;
  }
  if (!metadataLoading && !data?.simulation) {
    return <Typography>Simulation not found</Typography>;
  }

  const loading = metadataLoading || visserverLoading;

  return (
    <>
      {loading && <LinearProgress />}
      {data && (
        <>
          {!loading && (
            <Typography variant="h1" className={classes.visualizationName}>
              {data.visualisation.name}
            </Typography>
          )}
          <VisualizationIFrame
            id={id}
            visserverApp={visserverApp}
            executionID={data.simulation.execution?.id}
            onReady={() => setVisserverLoading(false)}
          />
        </>
      )}
    </>
  );
}

const useStyles = makeStylesHook(theme => ({
  visualizationName: {
    margin: theme.spacing(5, 0, 0, 6),
  },
}));
