import React, { useMemo } from 'react';

import { styled } from '@mui/styles';

import { DeckItem, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreviewStep } from 'common/types/mixPreview';
import { PlateNameData } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import EdgesSvgOverlay from 'common/ui/components/simulation-details/mix/EdgesSvgOverlay';
import MixGrid from 'common/ui/components/simulation-details/mix/MixGrid';
import MixPositions from 'common/ui/components/simulation-details/mix/MixPositions';
import { Edge, MixState } from 'common/ui/components/simulation-details/mix/MixState';

const StyledMixView = styled('div')({
  position: 'relative',
});

export type PlateSettings = {
  overlayText?: { [plate: string]: string };
  plateTypes: readonly PlateNameData[];
  selectedWells?: readonly WellLocationOnDeckItem[];
  liquidColors: LiquidColors;
  hidePlateLabels?: boolean;
  onPlatePointerUp?: (plate: string) => void;
  onWellPointerUp?: (loc: WellLocationOnDeckItem, e: React.PointerEvent) => void;
  onWellMouseEnter?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
  onWellMouseLeave?: (loc: WellLocationOnDeckItem, e: React.MouseEvent) => void;
};

type Props = {
  deckLayout: DeckLayout;
  filteredState: MixState;
  gridVisible: boolean;
  steps?: MixPreviewStep[];
  highlightedPlate?: string;
  highlightedDeckPositionName?: string;
  selectedEdge?: Edge;
  plateSettings: Omit<PlateSettings, 'liquidColors'> & {
    /**
     * By default, MixView will use graph coloring to determine liquid colors.
     */
    liquidColorsOverride?: LiquidColors;
  };
  onEdgeClick?: (edge: Edge) => void;
  onDeckItemPointerUp?: (deckPositionName: string, item?: DeckItem) => void;
};

// The main piece of UI on the MixScreen.
// Shows plates, wells, and arrows representing moving liquids.
export default React.memo(function MixView({
  deckLayout,
  filteredState,
  gridVisible,
  highlightedPlate,
  highlightedDeckPositionName,
  steps,
  plateSettings,
  onDeckItemPointerUp,
  selectedEdge,
  onEdgeClick,
}: Props) {
  const gridPositions = useGridPositions(deckLayout);
  const deckPositions = useDeckPositions(deckLayout);
  return (
    <StyledMixView style={deckLayout.deckBounds}>
      <EdgesSvgOverlay
        deckLayout={deckLayout}
        deckItems={filteredState.deck.items}
        edges={filteredState.edges}
        selectedEdge={selectedEdge}
        onEdgeClick={onEdgeClick}
      />
      {/* ordering is important here, grid should always show below the deck so render it first */}
      <MixGrid grid={gridPositions} gridVisible={gridVisible} />
      <MixPositions
        deckLayout={deckLayout}
        deckPositions={deckPositions}
        filteredState={filteredState}
        steps={steps}
        highlightedDeckPositionName={highlightedDeckPositionName}
        highlightedPlate={highlightedPlate}
        plateSettings={plateSettings}
        onDeckItemPointerUp={onDeckItemPointerUp}
      />
    </StyledMixView>
  );
});

function useDeckPositions(deckLayout: DeckLayout) {
  return useMemo(() => deckLayout.getAllDeckPositions(), [deckLayout]);
}

function useGridPositions(deckLayout: DeckLayout) {
  return useMemo(() => deckLayout.getAllGridPositions(), [deckLayout]);
}
