import React from 'react';

import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { SimulationQuery } from 'client/app/gql';
import { visualizationRoutes } from 'client/app/lib/nav/actions';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';

type Props = {
  simulation: SimulationQuery['simulation'];
};

export const ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME = 'Chromatography results';

export default function AnalyseChromatographyResultsButton({ simulation }: Props) {
  const navigation = useNavigation();
  const chromatographyVisualisations =
    simulation.visualisations?.filter(
      v => v.name === ANALYSE_CHROMATOGRAPHY_VISUALISATION_NAME,
    ) ?? [];

  if (
    chromatographyVisualisations.length === 0 ||
    chromatographyVisualisations.length > 1
  ) {
    if (chromatographyVisualisations.length > 1) {
      console.error(
        'More than one chromatography visualisation for simulation ',
        simulation.id,
      );
    }
    return null;
  }

  const visualisation = chromatographyVisualisations[0];

  const handleNavigate = () => {
    navigation.navigate(visualizationRoutes.base, {
      id: visualisation.id,
      visserverApp: visualisation.path,
      simulationID: simulation.id,
    });
  };

  return (
    <Fab color="secondary" size="medium" variant="extended" onClick={handleNavigate}>
      <>
        <StyledDataSetIcon />
        <Typography variant="button">Analyse chromatography result</Typography>
      </>
    </Fab>
  );
}

const StyledDataSetIcon = styled(InsertChartOutlinedIcon)(({ theme: { spacing } }) => ({
  marginRight: spacing(3),
}));
