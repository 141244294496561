import React, { CSSProperties, Ref } from 'react';

import MUIFab, { FabProps } from '@mui/material/Fab';
import cx from 'classnames';

import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  icon: React.ReactElement;
  label?: string;
  color?: FabProps['color'];
  size?: FabProps['size'];
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /** Use sparingly. */
  styleOverrides?: CSSProperties;
  className?: string;
};

export default React.forwardRef(function Fab(
  { icon, label, onClick, color, size, styleOverrides, className }: Props,
  ref: Ref<HTMLButtonElement>,
) {
  const classes = useStyles();
  return (
    <MUIFab
      className={cx(classes.fab, className)}
      onClick={onClick}
      color={color || 'secondary'}
      size={size || 'medium'}
      variant={label ? 'extended' : 'circular'}
      style={styleOverrides}
      ref={ref}
    >
      {icon} {label && <span className={classes.label}>{label}</span>}
    </MUIFab>
  );
});

const useStyles = makeStylesHook(theme => ({
  fab: {
    position: 'absolute',
    bottom: '32px',
    right: '32px',
  },
  label: {
    marginLeft: theme.spacing(3),
  },
}));
