import React from 'react';

import { styled } from '@mui/material/styles';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';

import Colors from 'common/ui/Colors';

type PropsWithoutArrow = Omit<TooltipProps, 'arrow'>;

/**
 * Styled Tooltip with a dark background.
 * Use for showing short, descriptive text to the user.
 *
 * If more verbose text is needed, consider using the Popover component.
 */
const StyledTooltip = styled((props: PropsWithoutArrow) => (
  <MuiTooltip arrow {...props} />
))(({ theme }) => ({
  arrow: {
    color: Colors.GREY_60,
  },
  tooltip: {
    color: Colors.DARK_TEXT_PRIMARY,
    backgroundColor: Colors.GREY_60,
    fontSize: theme.typography.caption.fontSize,
  },
}));

export default StyledTooltip;
