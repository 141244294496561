import { WellCoord } from 'common/types/bundle';
import { Measurement, VolumeOrConcentration } from 'common/types/mix';

// These types must match the corresponding Go structs in the antha repo.

export enum PlateAssignmentMode {
  COMBINATORIAL = 'combinatorial',
  DESCRIPTIVE = 'descriptive',
}

export enum LiquidSortMode {
  CONCENTRATION = 'concentration',
}

export enum WellSortMode {
  BY_ROW = 'byRow',
  BY_COLUMN = 'byColumn',
}

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export type PlateAssignment = {
  plateType: string; // name; on deserialisation in core-antha this is converted to true PlateType
  assignmentMode: PlateAssignmentMode;
  plateLayers: PlateLayer[];
  totalVolume?: Measurement;
  diluentName?: string;
  replicates: number;
};

export type PlateLayer = {
  id: string;
  name: string;
  wellSets: WellSet[];
  liquids: LiquidAssignment[];
};

export type LiquidAssignment = {
  wellSetID: string;
  liquidGroup?: string;
  liquidName?: string;
  sortBy?: LiquidSortMode;
  sortOrder?: SortOrder;
  subComponentName?: string;
  target: VolumeOrConcentration;
};

export type WellSet = {
  id: string;
  wells: WellCoord[];
  sortBy?: WellSortMode;
};

export type PlateNamesWithAssignment = {
  plateNames: string[];
  plateAssignment: PlateAssignment;
};
