import React from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { useMixPreview } from 'client/app/api/MixPreviewApi';
import ExecutionDetailsHeader from 'client/app/apps/simulation-details/ExecutionDetailsHeader';
import ExecutionResultsScreen, {
  SimulationWithExecution,
} from 'client/app/apps/simulation-details/overview/results/ExecutionResultsScreen';
import UIErrorBox from 'client/app/components/UIErrorBox';

type ExecutionDetailsProps = {
  simulation: SimulationWithExecution;
  onRefresh: () => Promise<unknown>;
};

export default function ExecutionDetails(props: ExecutionDetailsProps) {
  const { simulation, onRefresh } = props;
  const { status: fetchPreviewStatus, mixPreview } = useMixPreview(simulation);
  if (fetchPreviewStatus === 'loading') {
    return <LinearProgress />;
  }

  if (fetchPreviewStatus === 'error' || !mixPreview) {
    return (
      <>
        <ExecutionDetailsHeader simulationwithExecution={simulation} />
        <UIErrorBox>
          Sorry. We could not retrieve the proper information needed for this simulation.
          Please contact our Support team for assistance.
        </UIErrorBox>
      </>
    );
  }

  return (
    <>
      <ExecutionDetailsHeader
        simulationwithExecution={simulation}
        deck={mixPreview.deck}
      />
      <ExecutionResultsScreen
        simulation={simulation}
        onRefresh={onRefresh}
        deck={mixPreview.deck}
      />
    </>
  );
}
