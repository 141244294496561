import React, { useCallback } from 'react';

import { QueryResult } from '@apollo/client';
import cx from 'classnames';

import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { useMeasureVisibleCanvasArea } from 'client/app/apps/workflow-builder/lib/useMeasureVisibleCanvasArea';
import DOEElementInstancePanel from 'client/app/apps/workflow-builder/panels/doe-element-instance-panel/DOEElementInstancePanel';
import ElementInstancePanel from 'client/app/apps/workflow-builder/panels/element-instance-panel/ElementInstancePanel';
import ElementsListPanel, {
  ElementsListPanelBaseProps,
} from 'client/app/apps/workflow-builder/panels/elements-list/ElementsListPanel';
import { PanelContent } from 'client/app/apps/workflow-builder/panels/Panel';
import SimulationsPanel from 'client/app/apps/workflow-builder/panels/simulations/SimulationsPanel';
import { PollingErrors } from 'client/app/apps/workflow-builder/panels/simulations/useSimulations';
import {
  ElementBranchSwitcher,
  ElementBranchSwitcherResults,
} from 'client/app/apps/workflow-builder/panels/switch-element-set/SwitchElementBranch';
import DeckOptionsPanel from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/DeckOptionsPanel';
import { useIsMissingSelectedRunConfig } from 'client/app/apps/workflow-builder/panels/workflow-settings/deck-options/deckOptionsPanelUtils';
import DeviceSelectorPanel from 'client/app/apps/workflow-builder/panels/workflow-settings/devices/DeviceSelectorPanel';
import SettingsPanel from 'client/app/apps/workflow-builder/panels/workflow-settings/SettingsPanel';
import Toolbar from 'client/app/apps/workflow-builder/Toolbar';
import ValidationIndicator from 'client/app/apps/workflow-builder/validation/ValidationIndicator';
import WorkflowBuilderMode from 'client/app/apps/workflow-builder/WorkflowBuilderMode';
import DOEBuilderPanel from 'client/app/components/DOEBuilder/DOEBuilderPanel';
import DeckPositionsEditorPanel from 'client/app/components/Parameters/DeckPositions/DeckPositionsEditorPanel';
import FiltrationPlateLayoutEditorPanel from 'client/app/components/Parameters/FiltrationPlateLayout/FiltrationPlateLayoutEditorPanel';
import FiltrationProtocolDesignPanel from 'client/app/components/Parameters/FiltrationProtocolDesign/FiltrationProtocolDesignPanel';
import PlateContentsEditorPanel from 'client/app/components/Parameters/PlateContents/PlateContentsEditorPanel';
import PlateLayoutEditorPanel from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorPanel';
import { simulationsForWorkflowQuery } from 'client/app/gql';
import { configHasNoDevices } from 'client/app/lib/workflow/deviceConfigUtils';
import { ScreenRegistry } from 'client/app/registry';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { WorkflowConfig } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { DateRange } from 'common/ui/components/FilterChip/FilterChipWithDateRange';
import Tooltip from 'common/ui/components/Tooltip';
import { CANVAS_CONTROL_HEIGHT } from 'common/ui/components/Workspace/CanvasControl';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import DOEIcon from 'common/ui/icons/DOEIcon';
import { SimulationIcon } from 'common/ui/icons/SimulationIcon';

type Props = {
  elementsListProps: ElementsListPanelBaseProps;
  DOETemplateMode: boolean;
  onActivePanelChange: (panel: PanelContent) => void;
  isReadonly: boolean;
  onLaunchDOEDesignTool: () => void;
  onSimulate: () => void;
  onSwitchElementBranch: (result?: ElementBranchSwitcherResults) => void;
  workflowId: WorkflowId;
  workflowConfig: WorkflowConfig;
  simulationsForWorkflowQueryResult: QueryResult<simulationsForWorkflowQuery>;
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  favorited: boolean;
  setFilterFavorited: React.Dispatch<React.SetStateAction<boolean>>;
  filterSuccessfulSimulations: boolean;
  setFilterSuccessful: React.Dispatch<React.SetStateAction<boolean>>;
  filterDateRange: DateRange;
  setFilterDateRange: React.Dispatch<React.SetStateAction<DateRange>>;
  unreadSimulations: number;
  setUnreadSimulations: React.Dispatch<React.SetStateAction<number>>;
  pollingErrors: PollingErrors;
  isSimulationBusy: boolean;
  isSaving: boolean;
};

/**
 * ControlOverlay contains the set of components used to create, configure and simulate a workflow
 * in the WorkflowBuilder. It contains:
 * - Toolbar with buttons for opening the ActivePanel.
 * - ActivePanel (which displays content to create and configure a workflow, such as Workflow Settings and Elements).
 * - ElementInstancePanel to set parameters for elements.
 * - Action button for Simulating or launching the DOE Design tool.
 */
export default React.memo(function ControlOverlay({
  elementsListProps,
  onActivePanelChange,
  isReadonly,
  DOETemplateMode,
  onSimulate,
  onSwitchElementBranch,
  onLaunchDOEDesignTool,
  simulationsForWorkflowQueryResult,
  workflowId,
  workflowConfig,
  searchQuery,
  setSearchQuery,
  favorited,
  setFilterFavorited,
  filterSuccessfulSimulations,
  setFilterSuccessful,
  filterDateRange,
  setFilterDateRange,
  unreadSimulations,
  setUnreadSimulations,
  pollingErrors,
  isSimulationBusy,
  isSaving,
}: Props) {
  const isEnabledDOE = useFeatureToggle('NEW_DOE');
  const requiresDevice = workflowConfig.GlobalMixer.requiresDevice;
  const mode = useWorkflowBuilderSelector(state => state.mode);
  const activePanel = useWorkflowBuilderSelector(state => state.activePanel);
  const additionalPanel = useWorkflowBuilderSelector(state => state.additionalPanel);
  const selectedFactorParameter = useWorkflowBuilderSelector(
    state => state.factorEditing.selectedFactorParameter,
  );
  const elementInstance = useElementInstance();

  const classes = useStyles({
    activePanel: !!activePanel,
  });
  const dispatch = useWorkflowBuilderDispatch();

  const simulationType =
    isEnabledDOE && activePanel === 'DOEBuilder' ? 'Simulate with design' : 'Simulate';
  const showDesignButton = mode === 'DOE' && activePanel !== 'DOEBuilder';

  let primaryActionIcon: JSX.Element;
  let primaryActionText: string;

  if (DOETemplateMode || showDesignButton) {
    primaryActionIcon = <DOEIcon className={classes.primaryActionIcon} />;
    primaryActionText = 'Design';
  } else {
    primaryActionIcon = <SimulationIcon className={classes.primaryActionIcon} />;
    primaryActionText = simulationType;
  }

  const handleAdditionalPanelClose = useCallback(() => {
    logEvent('close-additional-panel', ScreenRegistry.WORKFLOW);
    dispatch({ type: 'setAdditionalPanel', payload: undefined });
  }, [dispatch]);

  const handleSetActivePanel = (panel: PanelContent) => {
    // If a user selects the same panel again, set it to undefined. This allows us to
    // reset the panel so it is not shown.
    const newPanel = panel === activePanel ? undefined : panel;
    logEvent('set-active-panel', ScreenRegistry.WORKFLOW, newPanel);
    onActivePanelChange(newPanel);
  };

  const handleClosePanel = useCallback(() => {
    onActivePanelChange(undefined);
  }, [onActivePanelChange]);

  // Once the element branch is switched, close the ElementsBranch panel.
  const handleCloseElementBranchSwitcher = (result?: ElementBranchSwitcherResults) => {
    onSwitchElementBranch(result);
    handleClosePanel();
  };

  const handleClickFabIcon = () => {
    if (DOETemplateMode) {
      /**
       * TODO: remove this conditional with DOE Alpha legacy
       */
      onLaunchDOEDesignTool();
    } else if (mode === 'DOE' && activePanel !== 'DOEBuilder') {
      handleSetActivePanel('DOEBuilder');
      dispatch({ type: 'deselectAll' });
    } else {
      onSimulate();
    }
  };

  let activePanelContent = null;
  switch (activePanel) {
    case 'ElementsBranch':
      activePanelContent = (
        <ElementBranchSwitcher
          onClose={handleCloseElementBranchSwitcher}
          workflowId={workflowId}
          className={classes.activePanel}
        />
      );
      break;
    case 'ElementsList':
      activePanelContent = (
        <ElementsListPanel
          {...elementsListProps}
          onClose={handleClosePanel}
          className={classes.activePanel}
          tagsPanelClassName={classes.additionalPanel}
        />
      );
      break;
    case 'WorkflowSettings':
      activePanelContent = (
        <SettingsPanel className={classes.activePanel} onClose={handleClosePanel} />
      );
      break;
    case 'Simulations':
      activePanelContent = (
        <SimulationsPanel
          className={classes.activePanel}
          errorPanelClassName={classes.additionalPanel}
          onClose={handleClosePanel}
          simulationsForWorkflowQueryResult={simulationsForWorkflowQueryResult}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          favorited={favorited}
          setFilterFavorited={setFilterFavorited}
          filterSuccessfulSimulations={filterSuccessfulSimulations}
          filterDateRange={filterDateRange}
          setFilterDateRange={setFilterDateRange}
          setFilterSuccessful={setFilterSuccessful}
          unreadSimulations={unreadSimulations}
          setUnreadSimulations={setUnreadSimulations}
          workflowId={workflowId}
          pollingErrors={pollingErrors}
        />
      );
      break;
    case 'DOEBuilder':
      activePanelContent = (
        <DOEBuilderPanel
          workflowId={workflowId}
          className={cx(
            classes.activePanel,
            !elementInstance ? classes.fullPanel : classes.editorPanel,
          )}
          isEditingParameterFactor={!!selectedFactorParameter}
          shouldLoadIframe={!isSaving}
          isReadonly={isReadonly}
          onClose={handleClosePanel}
        />
      );
      break;
  }

  let additionalPanelContent;
  switch (additionalPanel) {
    case 'DeckOptions':
      additionalPanelContent = (
        <DeckOptionsPanel
          onClose={handleAdditionalPanelClose}
          className={classes.additionalPanel}
        />
      );
      break;
    case 'DeviceSelector':
      additionalPanelContent = (
        <DeviceSelectorPanel
          onClose={handleAdditionalPanelClose}
          className={classes.additionalPanel}
        />
      );
      break;
    case 'PlateContentsEditor':
      additionalPanelContent = (
        <PlateContentsEditorPanel
          onClose={handleAdditionalPanelClose}
          className={cx(classes.additionalPanel, classes.editorPanel)}
        />
      );
      break;
    case 'DeckPositions':
      additionalPanelContent = (
        <DeckPositionsEditorPanel
          onClose={handleAdditionalPanelClose}
          className={cx(classes.additionalPanel, classes.editorPanel)}
          isReadonly={isReadonly}
        />
      );
      break;
    case 'FilterPlateEditor':
      additionalPanelContent = (
        <FiltrationPlateLayoutEditorPanel
          onClose={handleAdditionalPanelClose}
          className={cx(classes.additionalPanel, classes.editorPanel)}
        />
      );
      break;
    case 'FiltrationProtocolDesign':
      additionalPanelContent = (
        <FiltrationProtocolDesignPanel
          onClose={handleAdditionalPanelClose}
          className={cx(classes.additionalPanel, classes.editorPanel)}
          isReadonly={isReadonly}
        />
      );
      break;
    case 'PlateLayoutEditor':
      additionalPanelContent = (
        <PlateLayoutEditorPanel
          onClose={handleAdditionalPanelClose}
          className={cx(classes.additionalPanel, classes.editorPanel)}
        />
      );
      break;
  }

  const hasNoDevices = configHasNoDevices(workflowConfig);
  const hasNoSelectedRunConfig = useIsMissingSelectedRunConfig();

  const shouldDisableSimulateButton =
    !DOETemplateMode && requiresDevice && (hasNoDevices || hasNoSelectedRunConfig);
  const simulateButtonTooltipTitle = (() => {
    if (!shouldDisableSimulateButton) {
      return '';
    }
    if (requiresDevice && hasNoDevices) {
      return 'No device has been selected';
    } else {
      return 'No deck layout has been selected';
    }
  })();

  const measurerRef = useMeasureVisibleCanvasArea();

  return (
    <div className={classes.builderControls}>
      {/**
       * This element measures the area of the canvas that is not obscured by panels. Note that we
       * don't consider if `additionalPanel` is visible, even though this can block the whole canvas.
       * This is because the use case for the measurement is auto-sizing the workflow to the visible
       * area, and there's no possible auto-size if the whole canvas is obscured. In these cases
       * it makes more sense to ignore the `additionalPanel` and size to its portion of the canvas.
       */}
      <div
        className={classes.visibleAreaMeasurer}
        ref={measurerRef}
        style={{ gridColumnEnd: elementInstance ? undefined : 'instancePanel-end' }}
      />
      {!DOETemplateMode && (
        <Toolbar
          handleClick={handleSetActivePanel}
          isReadonly={isReadonly}
          className={classes.toolbar}
          workflowId={workflowId}
        />
      )}
      <WorkflowBuilderMode workflowId={workflowId} />
      {activePanelContent}
      <div className={classes.primaryActionButton}>
        <ValidationIndicator isLoading={isSaving} />
        <Tooltip title={simulateButtonTooltipTitle}>
          <span>
            <FabWithFX
              busy={isSimulationBusy}
              color={showDesignButton ? 'primary' : 'secondary'}
              onClick={handleClickFabIcon}
              size="medium"
              disabled={isReadonly || shouldDisableSimulateButton}
              variant="extended"
            >
              {primaryActionIcon} {primaryActionText}
            </FabWithFX>
          </span>
        </Tooltip>
      </div>
      {elementInstance ? (
        mode === 'DOE' ? (
          <DOEElementInstancePanel
            isReadonly={isReadonly}
            workflowId={workflowId}
            elementInstance={elementInstance}
          />
        ) : (
          <ElementInstancePanel
            DOETemplateMode={DOETemplateMode}
            isReadonly={isReadonly}
            workflowId={workflowId}
            elementInstance={elementInstance}
          />
        )
      ) : null}
      {additionalPanelContent}
    </div>
  );
});

export const BUILDER_CONTROLS_PADDING_TOP_BOTTOM = 12;
export const BUILDER_CONTROLS_PADDING_LEFT_RIGHT = 12;

const useStyles = makeStylesHook<string, { activePanel: boolean }>(theme => ({
  activePanel: {
    gridArea: 'activePanel',
    zIndex: 2,
  },
  additionalPanel: {
    gridArea: 'additionalPanel',
    // we want the additional panel to go to the far end and cover the simulate button.
    gridColumnEnd: 'instancePanel-end',
    gridColumnStart: ({ activePanel }) =>
      activePanel ? 'additionalPanel-start' : 'activePanel-start',
    zIndex: 2,
    overflow: 'hidden',
  },
  editorPanel: {
    gridColumnEnd: 'instancePanel-start',
  },
  fullPanel: {
    gridColumnEnd: 'instancePanel-end',
  },
  builderControls: {
    position: 'absolute',
    height: `calc(100% - ${CANVAS_CONTROL_HEIGHT})`,
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr max-content',
    gridTemplateRows: 'min-content min-content repeat(2, 1fr)',
    gap: theme.spacing(4),
    gridTemplateAreas: `
         'toolbar     toolbar         primaryActionButton'
         'activePanel additionalPanel instancePanel'
         'activePanel additionalPanel instancePanel'
         'activePanel additionalPanel instancePanel'
         'activePanel additionalPanel instancePanel'
        `,
    padding: `${BUILDER_CONTROLS_PADDING_TOP_BOTTOM}px ${BUILDER_CONTROLS_PADDING_LEFT_RIGHT}px`,
  },
  divider: {
    width: '24px',
    margin: theme.spacing(4, 0),
  },
  primaryActionButton: {
    gridArea: 'primaryActionButton',
    justifySelf: 'end',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
  },
  primaryActionIcon: {
    marginRight: theme.spacing(3),
  },
  toolbar: {
    alignItems: 'center',
    backgroundColor: Colors.GREY_5,
    borderRadius: '8px',
    display: 'flex',
    gap: theme.spacing(4),
    gridArea: 'toolbar',
    justifySelf: 'flex-start',
    padding: theme.spacing(2, 3),
    zIndex: 2,
  },
  visibleAreaMeasurer: {
    gridArea: 'additionalPanel',
    placeSelf: 'stretch',
    pointerEvents: 'none',
  },
}));
