import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import LayersIcon from '@mui/icons-material/Layers';
import LockIcon from '@mui/icons-material/Lock';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import LiquidAssignment from 'client/app/components/Parameters/PlateLayout/Liquid';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import {
  useDeleteLayerOrLiquid,
  useFormatLayerAutomaticName,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { PlateLayer } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type LayerProps = {
  layer: PlateLayer;
};

export default function Layer(props: LayerProps) {
  const { layer } = props;

  const {
    isReadonly,
    selectedLiquidOrLayerId,
    setFocusedLayerId,
    focusedLayerId,
    plateAssignment,
    setSelectedLiquidOrLayerId,
  } = usePlateLayoutEditorContext();

  const { handleDelete, confirmDeleteDialog } = useDeleteLayerOrLiquid();
  const layerName = useFormatLayerAutomaticName(layer.id);
  const isOnlyLayer = plateAssignment.plateLayers.length === 1;

  const handleDeleteLayer = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await handleDelete(layer.id, 'layer');
  };

  const [isHovering, setIsHovering] = useState(false);

  const enableHoverFunctionality = isHovering && !isReadonly;

  const focused = focusedLayerId === layer.id;

  const selected = selectedLiquidOrLayerId === layer.id;

  return (
    <ListWrapper onClick={() => setFocusedLayerId(layer.id)}>
      <StyledListItemButton
        dense
        focused={focused}
        selected={selected}
        isHovering={enableHoverFunctionality}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => focused && setSelectedLiquidOrLayerId(layer.id)}
      >
        <StyledLayersIcon selected={selected} focused={focused} />
        <TypographyWithTooltip variant={selected || focused ? 'subtitle2' : 'body1'}>
          {layerName}
        </TypographyWithTooltip>
        <StyledEndIconsWrapper>
          {enableHoverFunctionality ? (
            isOnlyLayer ? (
              <Tooltip title="At least one layer must be defined">
                <span>
                  <IconButton
                    onClick={handleDeleteLayer}
                    icon={<CloseIcon />}
                    size="xsmall"
                    disabled
                    color="error"
                  />
                </span>
              </Tooltip>
            ) : (
              <IconButton
                onClick={handleDeleteLayer}
                icon={<CloseIcon color="error" />}
                size="xsmall"
                color="error"
              />
            )
          ) : isReadonly ? (
            <LockIcon />
          ) : null}
        </StyledEndIconsWrapper>
      </StyledListItemButton>
      <StyledList disablePadding>
        {layer.liquids.length === 0 && (
          <EmptyListText
            showBorder={focused}
            onClick={() => setFocusedLayerId(layer.id)}
            allowClick={selectedLiquidOrLayerId !== layer.id}
            variant="body2"
          >
            Empty Layer
          </EmptyListText>
        )}
        {layer.liquids.map(liquid => (
          <LiquidAssignment
            layerId={layer.id}
            key={liquid.wellSetID}
            liquidAssignment={liquid}
            parentFocused={focused}
          />
        ))}
      </StyledList>
      {confirmDeleteDialog}
    </ListWrapper>
  );
}

const ListWrapper = styled('div')({
  '&:hover': {
    background: Colors.GREY_10,
    cursor: 'pointer',
  },
});

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: propName =>
    propName !== 'focused' && propName !== 'selected' && propName !== 'isHovering',
})<{ focused: boolean; selected: boolean; isHovering: boolean }>(
  ({ theme: { palette }, focused, selected, isHovering }) => ({
    backgroundColor: selected ? Colors.BLUE_5 : 'default',
    '&:hover': {
      backgroundColor: selected ? Colors.BLUE_5 : 'transparent',
    },
    padding: isHovering ? '5px 12px 5px 12px' : '8px 12px 8px 12px',
    gap: '6px',
    boxShadow: focused || selected ? `inset 3px 0 0 0 ${palette.primary.main}` : 'none',
    '& .MuiTypography-root': {
      color: selected ? palette.primary.main : palette.text.primary,
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

const StyledEndIconsWrapper = styled('div')({
  display: 'flex',
  marginLeft: 'auto',
  alignItems: 'center',
});

const StyledList = styled(List)({
  display: 'flex',
  flexDirection: 'column',
});

const StyledLayersIcon = styled(LayersIcon, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'focused',
})<{ selected: boolean; focused: boolean }>(({ theme, selected, focused }) => ({
  color: selected
    ? theme.palette.primary.main
    : focused
    ? theme.palette.text.secondary
    : theme.palette.grey[400],
  fontSize: '16px',
  marginLeft: '-2px',
}));

const EmptyListText = styled(Typography, {
  shouldForwardProp: propName => propName !== 'showBorder' && propName !== 'allowClick',
})<{ showBorder: boolean; allowClick: boolean }>(
  ({ theme: { palette }, showBorder, allowClick }) => ({
    color: palette.text.disabled,
    padding: '6px 16px 6px 48px;',
    boxShadow: showBorder ? `inset 3px 0 0 0 ${palette.primary.main}` : 'none',
    marginTop: 0,
    marginBottom: 0,
    cursor: allowClick ? 'pointer' : 'default',
  }),
);
