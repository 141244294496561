import React, { memo, useCallback } from 'react';

import { WellContents, WellLocationOnDeckItem } from 'common/types/mix';
import RoboColumn from 'common/ui/components/simulation-details/mix/RoboColumn';
import {
  Props as WellProps,
  Well,
} from 'common/ui/components/simulation-details/mix/Well';
import { WellLabelContent } from 'common/ui/components/simulation-details/mix/WellLabel';

type Props = {
  wellLocationOnDeckItem: WellLocationOnDeckItem;
  wellContents?: WellContents;
  onWellMouseEnter?: (
    loc: WellLocationOnDeckItem,
    e: React.MouseEvent<SVGGElement>,
  ) => void;
  onWellMouseLeave?: (
    loc: WellLocationOnDeckItem,
    e: React.MouseEvent<SVGGElement>,
  ) => void;
  onWellPointerUp?: (
    loc: WellLocationOnDeckItem,
    e: React.PointerEvent<SVGGElement>,
  ) => void;
  onWellPointerDown?: (
    loc: WellLocationOnDeckItem,
    e: React.PointerEvent<SVGGElement>,
  ) => void;
  /** Whether to show the contents of the well (used in Setup) */
  showContentLabel?: boolean;
  contentLabelOverride?: WellLabelContent;
  row: number;
  col: number;
} & WellProps;

export default memo(function InteractiveWell({
  wellContents,
  onWellPointerUp,
  onWellPointerDown,
  onWellMouseEnter,
  onWellMouseLeave,
  showContentLabel,
  wellLocationOnDeckItem,
  wellPos,
  contentLabelOverride,
  wellType,
  color,
  strokeColor,
  isHighlighted,
  isDisabled,
  hasError,
  highlightMode,
  isFaded,
  row,
  col,
}: Props) {
  const handlePointerUp = useCallback(
    (e: React.PointerEvent<SVGGElement>) => onWellPointerUp?.(wellLocationOnDeckItem, e),
    [onWellPointerUp, wellLocationOnDeckItem],
  );

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<SVGGElement>) =>
      onWellPointerDown?.(wellLocationOnDeckItem, e),
    [onWellPointerDown, wellLocationOnDeckItem],
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<SVGGElement>) => onWellMouseEnter?.(wellLocationOnDeckItem, e),
    [onWellMouseEnter, wellLocationOnDeckItem],
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<SVGGElement>) => onWellMouseLeave?.(wellLocationOnDeckItem, e),
    [onWellMouseLeave, wellLocationOnDeckItem],
  );

  const WellComponent =
    wellContents?.kind === 'filter_matrix_summary' ? RoboColumn : Well;

  return (
    <g
      onMouseEnter={isDisabled ? undefined : handleMouseEnter}
      onMouseLeave={isDisabled ? undefined : handleMouseLeave}
      onPointerUp={isDisabled ? undefined : handlePointerUp}
      onPointerDown={isDisabled ? undefined : handlePointerDown}
      data-row={row}
      data-col={col}
    >
      <WellComponent
        wellPos={wellPos}
        wellType={wellType}
        wellContents={wellContents}
        showContentLabel={showContentLabel}
        contentLabelOverride={contentLabelOverride}
        color={color}
        strokeColor={strokeColor}
        isHighlighted={isHighlighted}
        isDisabled={isDisabled}
        hasError={hasError}
        highlightMode={highlightMode}
        isFaded={isFaded}
      />
    </g>
  );
});
