import React from 'react';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import CollapsibleParameter from 'client/app/components/Parameters/PlateLayout/CollapsibleParameter';
import Help from 'client/app/components/Parameters/PlateLayout/Help';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { useInputLiquids } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import PlateSelectionEditor from 'client/app/components/Parameters/PlateType/PlateSelectionEditor';
import { PlateParameterValue } from 'client/app/components/Parameters/PlateType/processPlateParameterValue';
import { formatVolumeObj, parseMeasurement } from 'common/lib/format';
import { PlateAssignmentMode, WellSortMode } from 'common/types/plateAssignments';
import ConfirmationDialog from 'common/ui/components/Dialog/ConfirmationDialog';
import IntegerEditor from 'common/ui/components/ParameterEditors/IntegerEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import useDialog from 'common/ui/hooks/useDialog';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';

export default function PlateSetup() {
  const {
    plateAssignment,
    isReadonly,
    plateType,
    setPlateType,
    setPlateAssignmentMode,
    setReplicates,
    setTotalVolume,
    setDiluentName,
    setLayerSortMode,
    liquidParameters,
    isMixOnto,
  } = usePlateLayoutEditorContext();

  const volumeUnits = getSensibleMeasurementUnits('Volume');
  const plateTypeNameWithRiser = plateType?.name;
  const {
    inputLiquids: [inputLiquids],
  } = useInputLiquids(liquidParameters.inputLiquids);
  const sortMode =
    plateAssignment.plateLayers[plateAssignment.plateLayers.length - 1]?.wellSets[0]
      ?.sortBy ?? undefined;

  const [confirmSwitchModeDialog, openConfirmSwitchModeDialog] =
    useDialog(ConfirmationDialog);
  const [confirmSwitchPlateDialog, openConfirmSwitchPlateDialog] =
    useDialog(ConfirmationDialog);

  const handleSwitchPlate = async (
    plateParameterValue: PlateParameterValue | undefined,
  ) => {
    const userClearedOrChangedPlate =
      !plateParameterValue ||
      (plateAssignment.plateType && plateParameterValue !== plateAssignment.plateType);
    if (
      userClearedOrChangedPlate &&
      (plateAssignment.plateLayers.length > 1 ||
        plateAssignment.plateLayers.some(layer => layer.wellSets.length > 0))
    ) {
      const action = !plateParameterValue ? 'clear' : 'change';
      const isConfirmed = await openConfirmSwitchPlateDialog({
        action: action,
        isActionDestructive: true,
        object: 'plate type',
        additionalMessage:
          'This will result in your liquids and layers being removed. This cannot be undone.',
      });
      if (!isConfirmed) {
        return;
      }
      setPlateType(plateParameterValue, true);
    } else {
      setPlateType(plateParameterValue);
    }
  };

  const handleSwitchMode = async (plateAssignmentMode: PlateAssignmentMode) => {
    if (
      plateAssignment.plateLayers.length > 1 ||
      plateAssignment.plateLayers.some(layer => layer.wellSets.length > 0)
    ) {
      const isConfirmed = await openConfirmSwitchModeDialog({
        action: 'switch',
        isActionDestructive: true,
        object: 'mixing mode',
        additionalMessage:
          'This will result in your liquids and layers being removed. This cannot be undone.',
      });
      if (!isConfirmed) {
        return;
      }
      setPlateAssignmentMode(plateAssignmentMode, true);
    } else {
      setPlateAssignmentMode(plateAssignmentMode);
    }
  };

  return (
    <Wrapper>
      <div>
        <CollapsibleParameter in>
          <ParameterHeader
            displayName="Plate Type"
            isRequired
            help={Help.plateTypeHelp}
          />
          <PlateSelectionEditor
            value={plateTypeNameWithRiser ?? null}
            onChange={handleSwitchPlate}
            plateParameterKind="PLATE_TYPE"
            isDisabled={isReadonly || isMixOnto}
          />
        </CollapsibleParameter>
        <CollapsibleParameter in={!!plateAssignment.plateType && !isMixOnto}>
          <ParameterHeader
            displayName="Mixing Mode"
            isRequired
            help={Help.assignmentModeHelp}
          />
          <Toggle
            value={plateAssignment.assignmentMode}
            onChange={(_, value) => handleSwitchMode(value)}
            exclusive
            disabled={isReadonly || !plateAssignment.plateType}
          >
            <ToggleButton value="descriptive">Descriptive</ToggleButton>
            <ToggleButton value="combinatorial">Combinatorial</ToggleButton>
          </Toggle>
        </CollapsibleParameter>
        <CollapsibleParameter in={!!plateAssignment.plateType}>
          <ParameterHeader displayName="Total Volume" help={Help.totalVolumeHelp} />
          <MeasurementEditor
            onChange={value => {
              setTotalVolume(value ? parseMeasurement(value) : undefined);
            }}
            units={volumeUnits}
            value={
              plateAssignment.totalVolume && formatVolumeObj(plateAssignment.totalVolume)
            }
            isDisabled={isReadonly || !plateAssignment.plateType}
            placeholder="Volume"
          />
        </CollapsibleParameter>
        <CollapsibleParameter in={!!plateAssignment.plateType}>
          <ParameterHeader displayName="Diluent Name" help={Help.diluentNameHelp} />
          <Autocomplete
            value={plateAssignment.diluentName}
            freeSolo
            size="small"
            options={(inputLiquids ?? [])?.map(liquid => liquid.name)}
            onInputChange={(_, option) => setDiluentName(option)}
            disabled={isReadonly}
            renderInput={params => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: <LiquidsIcon />,
                  placeholder: 'Liquid Name',
                }}
              />
            )}
          />
        </CollapsibleParameter>

        <CollapsibleParameter
          in={plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL}
        >
          <ParameterHeader
            displayName="Replicates"
            isRequired
            help={Help.replicatesHelp}
          />
          <IntegerEditor
            value={plateAssignment.replicates}
            onChange={newValue => newValue && setReplicates(newValue)}
            isDisabled={isReadonly}
            placeholder="Number of Replicates"
            type=""
          />
        </CollapsibleParameter>
        <CollapsibleParameter
          in={plateAssignment.assignmentMode === PlateAssignmentMode.COMBINATORIAL}
        >
          <ParameterHeader
            displayName="Layout"
            isRequired
            help={Help.wellSetSortByHelp}
          />
          <StyledToggle
            value={sortMode}
            onChange={(_, value) => setLayerSortMode(value as WellSortMode)}
            exclusive
            disabled={isReadonly}
          >
            <ToggleButton value={WellSortMode.BY_ROW}>
              <ArrowForwardIcon />
            </ToggleButton>
            <ToggleButton value={WellSortMode.BY_COLUMN}>
              <ArrowDownwardIcon />
            </ToggleButton>
          </StyledToggle>
        </CollapsibleParameter>
        {confirmSwitchModeDialog}
        {confirmSwitchPlateDialog}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
}));

const StyledToggle = styled(Toggle)({
  width: 'min-content',
});
