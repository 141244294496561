import React from 'react';

import { useMutation } from '@apollo/client';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import produce from 'immer';

import { MUTATION_MARK_EXECUTION_AS_COMPLETED } from 'client/app/api/gql/mutations';
import {
  QUERY_BREADCRUMBS,
  QUERY_SIMULATION_FROM_EXECUTION_ID,
} from 'client/app/api/gql/queries';
import { SimulationWithExecution } from 'client/app/apps/simulation-details/overview/results/ExecutionResultsScreen';
import { useIsDOEAllowedForSimulation } from 'client/app/apps/simulation-details/SimulationDetails';
import {
  BreadcrumbsEntityEnum,
  ExecutionStatusEnum,
  SimulationOrExecutionStatusesEnum,
} from 'client/app/gql';
import { hasDispenserDevice } from 'client/app/lib/workflow/deviceConfigUtils';
import Tooltip from 'common/ui/components/Tooltip';

type Props = {
  simulationWithExecution: SimulationWithExecution;
};

export default function MarkExecutionCompletedButton({ simulationWithExecution }: Props) {
  const [markExecutionAsCompleted, { loading }] = useMutation(
    MUTATION_MARK_EXECUTION_AS_COMPLETED,
  );

  const isDispenser = hasDispenserDevice(
    simulationWithExecution.workflow.workflow.Config,
  );

  const getTooltipTitle = () => {
    if (isDispenser && !simulationWithExecution.instructionsDownloaded) {
      return 'Please download the instructions and manually upload to your dispenser to start this execution in the lab first.';
    }
    return executionSucceeded && DOESimulationType === 'new'
      ? 'This execution is already completed. Please upload analytical data to output plate(s) and navigate to the Map for data processing.'
      : '';
  };

  const handleMarkExecutionAsCompleted = async () => {
    await markExecutionAsCompleted({
      variables: { executionId: simulationWithExecution.execution.id },
      refetchQueries: [
        {
          query: QUERY_SIMULATION_FROM_EXECUTION_ID,
          variables: { id: simulationWithExecution.execution.id },
        },
      ],
      awaitRefetchQueries: true,
      update(cache) {
        const executionId = simulationWithExecution.execution.id;
        const executionEntity = BreadcrumbsEntityEnum.EXECUTION;

        cache.updateQuery(
          {
            query: QUERY_BREADCRUMBS,
            variables: {
              id: executionId,
              entity: executionEntity,
            },
          },
          data =>
            produce(data, draft => {
              for (const breadcrumbsItem of draft?.breadcrumbs ?? []) {
                if (breadcrumbsItem.entity === executionEntity) {
                  for (const subItem of breadcrumbsItem.items) {
                    if (subItem.id === executionId) {
                      subItem.status = ExecutionStatusEnum.EXECUTION_SUCCESS;
                      break;
                    }
                  }
                  break;
                }
              }
            }),
        );
      },
    });
  };

  const executionSucceeded =
    simulationWithExecution.transitiveStatus ===
    SimulationOrExecutionStatusesEnum.EXECUTION_SUCCESS;

  const disableButton =
    loading ||
    executionSucceeded ||
    (isDispenser && !simulationWithExecution.instructionsDownloaded);

  const DOESimulationType = useIsDOEAllowedForSimulation(simulationWithExecution);

  return (
    <Tooltip title={getTooltipTitle()}>
      <span>
        <StyledFab
          data-heap-tracking="mark-as-completed-button"
          color="secondary"
          size="medium"
          variant="extended"
          onClick={handleMarkExecutionAsCompleted}
          disabled={disableButton}
        >
          <CheckCircleOutlinedIcon />
          Mark As Complete
        </StyledFab>
      </span>
    </Tooltip>
  );
}

const StyledFab = styled(Fab)(({ theme: { spacing } }) => ({
  gap: spacing(3),
}));
