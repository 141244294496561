import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import IconFullscreen from '@mui/icons-material/Fullscreen';
import IconFullscreenExit from '@mui/icons-material/FullscreenExit';
import IconButton from '@mui/material/IconButton';
import cx from 'classnames';
import clamp from 'lodash/clamp';

import { DOEDesign, MoveLabwareAction, WellLocationOnDeckItem } from 'common/types/mix';
import { MixPreview } from 'common/types/mixPreview';
import {
  SIMULATION_DETAILS_PREVIEW_TAB_ID,
  TEXT_INPUT_LOG_DEBOUNCE_MS,
} from 'common/ui/AnalyticsConstants';
import Colors from 'common/ui/Colors';
import { useDialogManager } from 'common/ui/components/DialogManager';
import InfoDialog from 'common/ui/components/InfoDialog';
import { PlateNameData } from 'common/ui/components/simulation-details/data-utils/PlatesDataUtils';
import computeSelectedWells from 'common/ui/components/simulation-details/mix/computeSelectedWells';
import { getWellContents } from 'common/ui/components/simulation-details/mix/deckContents';
import DeckLayout from 'common/ui/components/simulation-details/mix/DeckLayout';
import PreviewError from 'common/ui/components/simulation-details/mix/Error';
import {
  filterState,
  getStepsAffectingWells,
} from 'common/ui/components/simulation-details/mix/filterState';
import HelpDialogContents from 'common/ui/components/simulation-details/mix/HelpDialogContents';
import { collapseHotels } from 'common/ui/components/simulation-details/mix/hotels';
import MixControls from 'common/ui/components/simulation-details/mix/MixControls';
import { DeckState, Edge } from 'common/ui/components/simulation-details/mix/MixState';
import MixStateCache from 'common/ui/components/simulation-details/mix/MixStateCache';
import MixView from 'common/ui/components/simulation-details/mix/MixView';
import MovePlatePrompt, {
  isPlateMovement,
} from 'common/ui/components/simulation-details/mix/MovePlatePrompt';
import Prompt from 'common/ui/components/simulation-details/mix/Prompt';
import RightPanel, {
  DeckItemInfo,
  ExtendedEdgeInfo,
  WellInfo,
} from 'common/ui/components/simulation-details/mix/RightPanel';
import { buildSimulationStateGraph } from 'common/ui/components/simulation-details/mix/SimulationStateGraph';
import zIndex from 'common/ui/components/simulation-details/mix/zIndex';
import { KeyPoint } from 'common/ui/components/simulation-details/StepSlider';
import Workspace from 'common/ui/components/Workspace/Workspace';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';
import useThrottle from 'common/ui/hooks/useThrottle';
import { getPosFromEvent, isDrag, Point2D } from 'common/ui/lib/ClickRecognizer';
import Keys from 'common/ui/lib/keyboard';
import { isMultiSelectClick } from 'common/ui/lib/mouse';
import { RouteHiddenContext } from 'common/ui/lib/router/RouteHiddenContext';

type Props = {
  mixPreview: MixPreview;
  /** So that we can show human-readable plate labels */
  plateTypes: readonly PlateNameData[];
  highlightedPlate?: string;
  overlayText?: { [plate: string]: string };
  isPlaybackEnabled?: boolean;
  design?: DOEDesign;
};

/**
 * The whole screen with controls and the fancy MixView UI
 */
export default function MixScreen({
  mixPreview,
  plateTypes,
  highlightedPlate,
  overlayText,
  isPlaybackEnabled,
  design,
}: Props) {
  const classes = useStyles();
  const context = useContext(RouteHiddenContext);

  const [contentFilter, setContentFilter] = useState<string>(''); // Find wells with specific contents
  const [stepFromURL, setCurrentStep] = useStateWithURLParams({
    paramName: 'step',
    paramType: 'number',
    defaultValue: 0,
  });

  const currentStepIndex = clamp(stepFromURL ?? 0, 0, mixPreview.steps.length);
  const currentStep = mixPreview.steps[currentStepIndex - 1];

  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);
  const [policyFilter, setPolicyFilter] = useState<string>(''); // Find edges with specific policy
  const [selectedWells, setSelectedWells] = useState<readonly WellLocationOnDeckItem[]>(
    [],
  );
  const [selectedEdge, setSelectedEdge] = useState<Edge | null>(null);
  const [selectedDeckPositionName, setSelectedDeckPositionName] = useState<string>();
  // If true, show all transfers at once. If false, only show transfers
  // for the `currentStep` selected on the slider.
  const [showWellProvenance, setShowWellProvenance] = useState<boolean>(false);

  // Store the position where the user pressed down the mouse button. Store in a ref, not
  // a state, such that it doesn't trigger re-renders when it changes.
  const pointerDownPosRef = useRef<Point2D | null>(null);

  // Show well provenance when (and only when) the user selects some wells.
  useEffect(() => setShowWellProvenance(selectedWells.length > 0), [selectedWells]);

  const deckLayout = useMemo(() => {
    // In the main preview, we vertically shrink the size of hotels (which comprise
    // multiple vertically stacked plates) so they do not take up lots of vertical space.
    // We only want this behaviour in the preview (e.g., not in the Setup screen), so we
    // only run this function here and not in DeckLayout.
    const deckScaled = collapseHotels(mixPreview.deck);
    return new DeckLayout(deckScaled);
  }, [mixPreview]);

  // Create a cache to store each step that the user navigates to.
  const mixStateCache = useMemo(() => new MixStateCache(mixPreview), [mixPreview]);

  // Compute the state of the deck at the currently viewed step. Using the cache means the
  // step is computed using the closest previous step that's been cached.
  const currentState = useMemo(
    () => mixStateCache.computeState(currentStepIndex ?? 0),
    [currentStepIndex, mixStateCache],
  );

  // The graph is for showing the provenance and we only show that when a well is
  // selected.
  const currentStateGraph = useMemo(
    () =>
      selectedWells || contentFilter
        ? buildSimulationStateGraph(currentState)
        : undefined,
    [contentFilter, currentState, selectedWells],
  );

  // Compute the very last step of the deck, used to show keypoints on the slider
  const { endState, endStateGraph } = useMemo(() => {
    const endState = mixStateCache.computeState(mixPreview.steps.length);
    const endStateGraph = buildSimulationStateGraph(endState);
    return { endState, endStateGraph };
  }, [mixPreview.steps.length, mixStateCache]);

  const currentPrompt = useMemo(
    () => currentState.prompts.find(p => p.stepNumber === currentStepIndex),
    [currentStepIndex, currentState],
  );

  const currentError = useMemo(
    () => currentState.errors.find(p => p.stepNumber === currentStepIndex),
    [currentStepIndex, currentState],
  );

  // Generate the key steps to highlight on the step slider when no well is selected.
  const defaultKeyPoints = useMemo(() => {
    const allTipboxKeypoints: KeyPoint[] = endState.tipboxReplacements.map(tr => ({
      step: tr.step,
      kind: 'tipbox',
    }));
    const allPromptKeyPoints: KeyPoint[] = endState.prompts.map(p => ({
      step: p.stepNumber,
      kind: 'prompt',
    }));
    const allErrorKeyPoints: KeyPoint[] = endState.errors.map(p => ({
      step: p.stepNumber,
      kind: 'error',
    }));
    return [...allTipboxKeypoints, ...allPromptKeyPoints, ...allErrorKeyPoints];
  }, [endState]);

  // Cache expensive computation of current state of the UI.
  const selectedWellsKeyPoints = useMemo(() => {
    return selectedWells.length === 0
      ? []
      : getStepsAffectingWells(endState, endStateGraph, selectedWells).map(
          step =>
            ({
              step,
              kind: 'transfer',
            } as KeyPoint),
        );
  }, [endState, endStateGraph, selectedWells]);

  /**
   * playbackTime is only set when the user has initiated playback, such that
   * the time shown can be continuous between steps. When adjusting the slider,
   * the playbackTime will be unset and the actual time estimate of the step
   * will be used.
   */
  const [playbackTime, setPlaybackTime] = useState<number | undefined>();

  /**
   * Update the slider with new step number and time. playbackTime is provided
   * by SimulationPlayBack control.
   */
  const handleSliderChange = useCallback(
    (stepNumber: number, playbackTime?: number) => {
      setCurrentStep(stepNumber);
      setPlaybackTime(playbackTime);
    },
    [setCurrentStep],
  );

  const debouncedContentFilterChangeLog = useThrottle(
    (newValue: string) =>
      logEvent('update-liquid-filter', SIMULATION_DETAILS_PREVIEW_TAB_ID, newValue),
    TEXT_INPUT_LOG_DEBOUNCE_MS,
  );

  const handleContentFilterChange = useCallback(
    (contentFilter: string) => {
      debouncedContentFilterChangeLog(contentFilter);
      setContentFilter(contentFilter);
    },
    [debouncedContentFilterChangeLog],
  );

  const debouncedPolicyFilterChangeLog = useThrottle(
    (newValue: string) =>
      logEvent('update-policy-filter', SIMULATION_DETAILS_PREVIEW_TAB_ID, newValue),
    TEXT_INPUT_LOG_DEBOUNCE_MS,
  );

  const handlePolicyFilterChange = (policyFilter: string) => {
    debouncedPolicyFilterChangeLog(policyFilter);
    setPolicyFilter(policyFilter);
  };

  const rememberPointerDownPos = useCallback(
    (e: React.PointerEvent) => (pointerDownPosRef.current = getPosFromEvent(e)),
    [],
  );

  // All pointer up events, regardless if they happen on a well, a tipbox or
  // the background.
  const handleAllPointerUp = useCallback((e: React.PointerEvent) => {
    const targetEl = e.target as HTMLElement;
    const isWellClick = targetEl.hasAttribute('data-well');
    const isEdgeClick = targetEl.hasAttribute('data-edge');

    if (
      !isWellClick &&
      !isEdgeClick &&
      !isDrag(pointerDownPosRef.current, e) &&
      !isMultiSelectClick(e) // there is no shift/ctrl pressed
    ) {
      // Unselect all wells
      setSelectedWells([]);
      setSelectedEdge(null);
      setSelectedDeckPositionName(undefined);
    }
    pointerDownPosRef.current = null;
  }, []);

  const handleWellPointerUp = useCallback(
    (clickedLoc: WellLocationOnDeckItem, e: React.PointerEvent) => {
      if (isDrag(pointerDownPosRef.current, e)) {
        // If the user dragged, do nothing. Dragging should never select wells.
        return;
      }

      // Keep event around for setSelectedWells callback
      e.persist();
      setSelectedWells(selectedWells => {
        const { newSelectedWells } = computeSelectedWells(
          selectedWells,
          clickedLoc,
          e,
          SIMULATION_DETAILS_PREVIEW_TAB_ID,
        );
        return newSelectedWells;
      });
    },
    [],
  );

  const getWellInfo = useCallback(
    (loc: WellLocationOnDeckItem): WellInfo => ({
      loc,
      contents: getWellContents(currentState.deck, loc, design),
    }),
    [currentState.deck, design],
  );

  const handleEdgeClick = useCallback((edge: Edge) => setSelectedEdge(edge), []);

  // keyup - this only gets called once the key is released
  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (context.hidden) {
        // the view is hidden, we do nothing;
        return;
      }
      if (e.key === Keys.ESCAPE) {
        // Unselect all wells
        setSelectedWells([]);
      }
    },
    [context.hidden],
  );

  const handleFullscreenClick = useCallback(() => {
    logEvent('fullscreen', SIMULATION_DETAILS_PREVIEW_TAB_ID);
    setIsFullscreen(isFullscreen => !isFullscreen);
  }, []);

  const handleFullscreenButtonPointerDown = useCallback(
    (event: React.MouseEvent) =>
      // The screen handles background clicks in order to unselect wells.
      // When someone clicks the button, we want wells to stay selected.
      event.stopPropagation(),
    [],
  );

  const dialogManager = useDialogManager();
  const handleShowHelp = useCallback(async () => {
    logEvent('open-help-dialog', SIMULATION_DETAILS_PREVIEW_TAB_ID);
    await dialogManager.openDialogPromise(
      'EXECUTION_PREVIEW_HELP',
      InfoDialog,
      HelpDialogContents,
    );
  }, [dialogManager]);

  // If a well is selected, always show that.
  // Otherwise show the well that's under the cursor.
  const wellInfoToShowInRightPanel = useMemo<WellInfo | null>(() => {
    // We could show all (or last 5) selected wells in the right panel.
    // For now, we show just the well that was selected last.
    const lastSelectedLocation = selectedWells[selectedWells.length - 1];
    const lastSelectedWellInfo =
      lastSelectedLocation && getWellInfo(lastSelectedLocation);
    if (lastSelectedWellInfo) {
      // If a well is selected, always show that. A selected well has priority.
      return { ...lastSelectedWellInfo, hover: false };
    }
    return null;
  }, [getWellInfo, selectedWells]);

  const filteredState = useMemo(() => {
    const stepOrDefaultStep = currentStepIndex ?? 0;
    return filterState(currentState, {
      edgesFromStepOnly: showWellProvenance ? null : stepOrDefaultStep,
      content: contentFilter,
      policy: policyFilter,
      selectedWells: showWellProvenance ? selectedWells : [],
      // Needed to find edges affecting the selected wells
      stateGraph: currentStateGraph,
    });
  }, [
    currentState,
    showWellProvenance,
    currentStepIndex,
    contentFilter,
    policyFilter,
    selectedWells,
    currentStateGraph,
  ]);

  const edgeInfoToShowInRightPanel = useMemo(
    () =>
      extendedEdgeInfo(
        // If we're showing literally one edge on the screen, show its details
        // right away. This way the user doesn't have to mouse over the edge
        // to see details.
        // Single-channel transfers are quite common, so this saves people some
        // time.
        filteredState.edges.length === 1 ? filteredState.edges[0] : selectedEdge,
        filteredState.edges.length !== 1,
        currentState.deck,
      ),
    [selectedEdge, filteredState.edges, currentState.deck],
  );

  const plateSettings = useMemo(() => {
    return {
      overlayText,
      plateTypes,
      selectedWells,
      onWellPointerUp: handleWellPointerUp,
    };
  }, [handleWellPointerUp, overlayText, plateTypes, selectedWells]);

  const [gridVisible, setGridVisible] = useState(true);

  const deckItemInfoToShowInRightPanel = useMemo<DeckItemInfo | undefined>(() => {
    if (!selectedDeckPositionName) {
      return;
    }
    return {
      deckItems: filteredState.deck.items.filter(
        item => item.currentDeckPositionName === selectedDeckPositionName,
      ),
      deckPositionName: selectedDeckPositionName,
    };
  }, [filteredState.deck.items, selectedDeckPositionName]);

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [handleKeyUp]);

  // If some wells are selected, show key points for those
  const keyPoints =
    selectedWellsKeyPoints.length > 0 ? selectedWellsKeyPoints : defaultKeyPoints;

  if (context.hidden) {
    // Don't unnecessarily render the large React tree when
    // we are on a different tab of the Simulation Details.
    return null;
  }

  const showRightPanel = !!(
    edgeInfoToShowInRightPanel ||
    wellInfoToShowInRightPanel ||
    deckItemInfoToShowInRightPanel
  );

  return (
    <div
      className={cx(classes.mixScreen, {
        // Render full screen if enabled
        [classes.mixScreenFullscreen]: isFullscreen,
      })}
      onPointerDown={rememberPointerDownPos}
      onPointerUp={handleAllPointerUp}
    >
      <div className={classes.mixControls}>
        <MixControls
          contentFilter={contentFilter}
          currentStep={currentStepIndex ?? 0}
          steps={mixPreview.steps}
          keyPoints={keyPoints}
          policyFilter={policyFilter}
          timeElapsed={playbackTime || currentState.timeElapsed}
          deckItems={filteredState.deck.items}
          onContentFilterChange={handleContentFilterChange}
          onPolicyFilterChange={handlePolicyFilterChange}
          onStepChange={handleSliderChange}
          isPlaybackEnabled={isPlaybackEnabled}
        />
      </div>
      <div className={classes.workspaceWrapper}>
        <Workspace
          isGridSwitchVisible
          gridVisible={gridVisible}
          setGridVisible={setGridVisible}
          isShowAllButtonVisible
          isShowHelpButtonVisible
          initialShowAll
          onShowHelp={handleShowHelp}
          logCategory={SIMULATION_DETAILS_PREVIEW_TAB_ID}
          canvasControlVariant="light_float"
        >
          <MixView
            gridVisible={gridVisible}
            deckLayout={deckLayout}
            filteredState={filteredState}
            highlightedPlate={highlightedPlate}
            highlightedDeckPositionName={selectedDeckPositionName}
            plateSettings={plateSettings}
            selectedEdge={selectedEdge ?? undefined}
            onEdgeClick={handleEdgeClick}
            onDeckItemPointerUp={setSelectedDeckPositionName}
            steps={mixPreview.steps}
          />
        </Workspace>
        <IconButton
          className={classes.fullscreenButton}
          onClick={handleFullscreenClick}
          onPointerDown={handleFullscreenButtonPointerDown}
        >
          {isFullscreen ? <IconFullscreenExit /> : <IconFullscreen />}
        </IconButton>
        {currentError && <PreviewError error={currentError.error} />}
        {currentPrompt && <Prompt prompt={currentPrompt.prompt} />}
        {isPlateMovement(currentStep) && (
          <MovePlatePrompt action={currentStep as MoveLabwareAction} />
        )}
      </div>
      {showRightPanel && (
        <div className={classes.rightPanel}>
          <RightPanel
            edgeInfo={edgeInfoToShowInRightPanel}
            wellInfo={wellInfoToShowInRightPanel}
            deckItemInfo={deckItemInfoToShowInRightPanel}
            rules={mixPreview.rules}
            showWellProvenanceToggle
            showWellProvenance={showWellProvenance}
            onShowWellProvenanceChange={setShowWellProvenance}
            factors={design?.factors}
          />
        </div>
      )}
    </div>
  );
}

/**
 * Info about an edge (i.e. a liquid transfer), plus the contents of the well we
 * transfer from, and the well we transfer to. It is useful to see all the three
 * pieces of information at a glance.
 */
function extendedEdgeInfo(
  edge: Edge | null,
  hover: boolean,
  deck: DeckState,
): ExtendedEdgeInfo | null {
  if (!edge) {
    return null;
  }
  switch (edge.type) {
    case 'liquid_transfer':
    case 'liquid_dispense':
    case 'filtration': {
      const sourceLocation = edge.action.from.loc;
      const targetLocation = edge.action.tipDestination.loc;
      const sourceWellContents = getWellContents(deck, sourceLocation);
      return {
        edge,
        sourceLocation,
        targetLocation,
        sourceWellContents,
        hover,
      };
    }
    case 'move_plate':
      // TODO: show move plate edge info (T2799)
      return null;
  }
}

const useStyles = makeStylesHook(theme => ({
  mixScreen: {
    background: 'white',
    display: 'grid',
    gridTemplate: `'controls right' auto 'workspace right' 1fr / 1fr auto`,
    flex: '1',
    // Prevent scrolling the entire mix screen if the rightPanel is over length
    overflow: 'hidden',
  },
  mixScreenFullscreen: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: theme.zIndex.modal,
  },
  mixControls: {
    gridArea: 'controls',
    padding: '24px 0 0 16px',
    borderBottom: `1px solid ${Colors.GREY_30}`,
  },
  fullscreenButton: {
    '&.MuiButtonBase-root': {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: zIndex.fullscreenButton,
    },
  },
  workspaceWrapper: {
    // We wrap the <Workspace> in an extra div. Without this, the Workspace has
    // 0 height, likely because we mix flexbox and non-flexbox layout.
    gridArea: 'workspace',
    display: 'flex',
    position: 'relative',
  },
  rightPanel: {
    gridArea: 'right',
    display: 'flex',
    maxHeight: '100%',
    placeSelf: 'stretch',
    borderLeft: `1px solid ${Colors.GREY_30}`,
    width: '280px',
    zIndex: 2,
    boxShadow: '0 0 8px rgba(0,0,0,.15)',
  },
}));
