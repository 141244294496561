import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useLocation } from 'react-router-dom';

export type PopoverType = 'standard' | 'DOE' | 'structured-data';

type ExperimentPopoverContextState = {
  popover: PopoverType | null;
};

const ExperimentPopoverContext = createContext<
  ExperimentPopoverContextState & {
    showPopover: (popover: PopoverType) => void;
    hidePopover: () => void;
  }
>({
  popover: null,
  showPopover: () => {},
  hidePopover: () => {},
});

export default function ExperimentPopoverContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const location = useLocation();

  const [state, setState] = useState<ExperimentPopoverContextState>({ popover: null });

  const showPopover = useCallback((popover: PopoverType) => setState({ popover }), []);
  const hidePopover = useCallback(() => setState({ popover: null }), []);

  useEffect(() => {
    hidePopover();
  }, [hidePopover, location.pathname]);

  const value = useMemo(
    () => ({
      showPopover,
      hidePopover,
      ...state,
    }),
    [hidePopover, showPopover, state],
  );

  return (
    <ExperimentPopoverContext.Provider value={value}>
      {children}
    </ExperimentPopoverContext.Provider>
  );
}

export const useExperimentPopoverContext = () => useContext(ExperimentPopoverContext);
