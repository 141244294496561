import React, { useMemo, useState } from 'react';

import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LayersIcon from '@mui/icons-material/Layers';
import WavesIcon from '@mui/icons-material/Waves';
import Autocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ParameterHeader } from 'client/app/components/Parameters/ElementParameterHeader';
import CollapsibleParameter from 'client/app/components/Parameters/PlateLayout/CollapsibleParameter';
import Help from 'client/app/components/Parameters/PlateLayout/Help';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import {
  useFormatLayerAutomaticName,
  useInputLiquidNamesAndGroups,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { formatVolumeObj, pluralize } from 'common/lib/format';
import { Liquid } from 'common/types/bundle';
import { Measurement } from 'common/types/mix';
import { Option } from 'common/types/Option';
import {
  LiquidAssignment,
  PlateAssignmentMode,
  PlateLayer,
  SortOrder,
  WellSortMode,
} from 'common/types/plateAssignments';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import GenericInputEditor from 'common/ui/components/ParameterEditors/GenericInputEditor';
import MeasurementEditor from 'common/ui/components/ParameterEditors/MeasurementEditor';
import getMeasurementFromString, {
  getSensibleMeasurementUnits,
} from 'common/ui/components/ParameterEditors/unitRegistry';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import Dropdown from 'common/ui/filaments/Dropdown';
import { AddSelectedWellsIcon } from 'common/ui/icons/AddSelectedWellsIcon';
import LiquidsIcon from 'common/ui/icons/LiquidsIcon';
import { RemoveSelectedWellsIcon } from 'common/ui/icons/RemoveSelectedWellsIcon';

export default function Details() {
  const { plateAssignment, selectedLiquidOrLayerId: selectedLayerOrLiquidAssignmentId } =
    usePlateLayoutEditorContext();

  const [layer, liquid] = useMemo(() => {
    if (!selectedLayerOrLiquidAssignmentId) {
      return [undefined, undefined];
    }

    for (const layer of plateAssignment.plateLayers) {
      if (layer.id === selectedLayerOrLiquidAssignmentId) return [layer, undefined];

      for (const liquid of layer.liquids) {
        if (liquid.wellSetID === selectedLayerOrLiquidAssignmentId) {
          return [layer, liquid];
        }
      }
    }

    return [undefined, undefined];
  }, [plateAssignment.plateLayers, selectedLayerOrLiquidAssignmentId]);

  if (!layer) {
    return null;
  }

  if (!liquid) {
    return <LayerDetails layer={layer} />;
  }

  return <LiquidDetails liquid={liquid} layer={layer} />;
}

type LayerDetailsProps = {
  layer: PlateLayer;
};

function LayerDetails(props: LayerDetailsProps) {
  const { layer } = props;
  const { isReadonly, setPlateLayerName, setSelectedLiquidOrLayerId } =
    usePlateLayoutEditorContext();

  const handleSetPlateLayerName = (newValue: string | undefined) => {
    setPlateLayerName(layer.id, newValue ?? '');
  };

  const layerName = useFormatLayerAutomaticName(layer.id);

  return (
    <Wrapper>
      <StyledHeader>
        <StyledIconButton
          onClick={() => setSelectedLiquidOrLayerId(undefined)}
          size="xsmall"
          icon={<ArrowBack />}
        />
        <StyledLayersIcon />
        <TypographyWithTooltip variant="subtitle2">{layerName}</TypographyWithTooltip>
      </StyledHeader>
      <InnerWrapper>
        <div>
          <CollapsibleParameter in>
            <ParameterHeader displayName="Layer Descriptor" help={Help.layerNameHelp} />
            <GenericInputEditor
              type=""
              onChange={handleSetPlateLayerName}
              value={layer.name}
              isDisabled={isReadonly}
              placeholder="Descriptor"
            />
          </CollapsibleParameter>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
}

type LiquidDetailsProps = {
  liquid: LiquidAssignment;
  layer: PlateLayer;
};

function LiquidDetails(props: LiquidDetailsProps) {
  const { liquid, layer } = props;
  const {
    isReadonly,
    setLiquidIdentifier,
    setLiquidVolumeOrConcentration,
    setSelectedLiquidOrLayerId,
    setWellSetSortMode,
    liquidColors,
    selectedWells,
    addSelectedWellsToSelectedLiquid,
    removeSelectedWellsFromSelectedLiquid,
    setLiquidSortOrder,
    plateAssignment,
    setHighlightedLiquidId,
    setLiquidSortSubComponent,
    inputLiquids,
  } = usePlateLayoutEditorContext();

  const isPartOfGroup = !!liquid.liquidGroup;

  const wellSet = layer.wellSets.find(wellSet => wellSet.id === liquid.wellSetID);

  // We use these liquids and units to populate the parameter editors below
  // TODO - Use the 'type' here to render out icons in autocomplete for name or group
  const inputLiquidNamesAndGroups = useInputLiquidNamesAndGroups(inputLiquids);
  const volumeUnits = getSensibleMeasurementUnits('Volume');
  const concentrationUnits = getSensibleMeasurementUnits('Concentration');

  const color = liquidColors.getColorFromLiquidString(
    (isPartOfGroup ? liquid.liquidGroup : liquid.liquidName) ?? '',
  );

  const liquidVolumeOrConcentration =
    'concentration' in liquid.target
      ? formatVolumeObj(liquid.target.concentration)
      : formatVolumeObj(liquid.target.volume);

  const [liquidIdentifierError, setLiquidIdentifierError] = useState<string | undefined>(
    undefined,
  );
  const [liquidVolumeOrConcentrationError, setLiquidVolumeOrConcentrationError] =
    useState<string | undefined>(undefined);

  const handleSetLiquidIdentifier = (newValue: string | undefined) => {
    setLiquidIdentifierError(undefined);
    if (newValue === '' || newValue === undefined) {
      setLiquidIdentifierError('Liquid identifier cannot be empty');
      return;
    }
    const isNewValueAnExistingValue = inputLiquidNamesAndGroups.find(
      nameOrGroup => nameOrGroup.name === newValue,
    );

    const shouldSetThisPartOfAGroup =
      isNewValueAnExistingValue?.type !== 'name' &&
      isNewValueAnExistingValue?.type === 'group';

    setLiquidIdentifier(liquid.wellSetID, newValue ?? '', shouldSetThisPartOfAGroup);
  };

  const handleSetVolumeOrConcentration = (newValue: string | undefined) => {
    setLiquidVolumeOrConcentrationError(undefined);
    if (newValue === '' || newValue === undefined) {
      setLiquidVolumeOrConcentrationError(
        'Liquid volume or concentration cannot be empty',
      );
      return;
    }
    const parsedMeasurements = getMeasurementFromString(newValue);
    const measurement: Measurement = {
      unit: parsedMeasurements.unit,
      value: parsedMeasurements.value,
    };
    const isVolume = volumeUnits.includes(measurement.unit);
    setLiquidVolumeOrConcentration(
      liquid.wellSetID,
      isVolume ? { volume: measurement } : { concentration: measurement },
    );
  };

  const [canAddWells, canRemoveWells] = useMemo(() => {
    if (selectedWells.length) {
      const wellSet = layer.wellSets.find(ws => ws.id === liquid.wellSetID);
      let canAdd = false;
      let canRemove = false;

      for (const well of selectedWells) {
        const isInLiquid = !!wellSet?.wells.some(
          value => value.x === well.col && value.y === well.row,
        );

        canAdd = canAdd || !isInLiquid;
        canRemove = canRemove || isInLiquid;

        if (canAdd && canRemove) {
          // Early return if we know we have to show both buttons.
          break;
        }
      }

      return [canAdd, canRemove];
    }

    return [false, false];
  }, [layer.wellSets, liquid.wellSetID, selectedWells]);

  const subComponentOptions = useSubComponentOptions(liquid, inputLiquids);

  const LiquidSortModeOptions = [
    { label: 'Concentration (Low to High)', value: SortOrder.ASCENDING },
    { label: 'Concentration (High to Low)', value: SortOrder.DESCENDING },
  ];

  return (
    <Wrapper>
      <StyledHeader>
        <StyledIconButton
          onClick={() => {
            setSelectedLiquidOrLayerId(undefined);
            setHighlightedLiquidId(undefined);
          }}
          size="xsmall"
          icon={<ArrowBack />}
        />
        <TypographyWithTooltip variant="subtitle2">
          {liquid.liquidName ?? liquid.liquidGroup}
        </TypographyWithTooltip>
        <LiquidColor sx={{ backgroundColor: color }} />
      </StyledHeader>
      <InnerWrapper>
        <div>
          <CollapsibleParameter in>
            <ParameterHeader
              displayName="Liquid Identifier"
              isRequired
              help={Help.liquidNameHelp}
            />
            <Autocomplete
              value={liquid.liquidName ?? liquid.liquidGroup}
              freeSolo
              size="small"
              options={inputLiquidNamesAndGroups.map(nameAndGroup => nameAndGroup.name)}
              onInputChange={(_, option) => handleSetLiquidIdentifier(option)}
              disabled={isReadonly}
              disableClearable
              placeholder="Liquid or Tag name..."
              renderInput={params => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: liquid.liquidName ? (
                      <LiquidsIcon />
                    ) : liquid.liquidGroup ? (
                      <WavesIcon />
                    ) : null,
                  }}
                />
              )}
              renderOption={(props, option) => {
                const existingOption = inputLiquidNamesAndGroups.find(
                  nameAndGroup => nameAndGroup.name === option,
                );
                if (!existingOption) {
                  return null;
                }
                return (
                  <StyledOptionListItem {...props}>
                    {existingOption.type === 'name' ? <LiquidsIcon /> : <WavesIcon />}
                    <TypographyWithTooltip variant="subtitle2">
                      {existingOption.name}
                    </TypographyWithTooltip>
                    {existingOption.type === 'group' && existingOption.liquidsInGroup && (
                      <StyledOptionListItemCaption>
                        {pluralize(existingOption.liquidsInGroup, 'liquid')}
                      </StyledOptionListItemCaption>
                    )}
                  </StyledOptionListItem>
                );
              }}
            />
            <ErrorText variant="caption">{liquidIdentifierError}</ErrorText>
          </CollapsibleParameter>
          <CollapsibleParameter in>
            <ParameterHeader
              displayName="Volume or Concentration"
              isRequired
              help={Help.liquidTargetHelp}
            />
            <MeasurementEditor
              onChange={handleSetVolumeOrConcentration}
              units={[...volumeUnits, ...concentrationUnits]}
              value={liquidVolumeOrConcentration}
              isDisabled={isReadonly}
              placeholder="Volume or Concentration"
            />
            <ErrorText variant="caption">{liquidVolumeOrConcentrationError}</ErrorText>
          </CollapsibleParameter>
          {wellSet && (
            <CollapsibleParameter
              in={
                isPartOfGroup &&
                plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE
              }
            >
              <ParameterHeader
                displayName="Layout"
                isRequired
                help={Help.liquidSortByHelp}
              />
              <Toggle
                value={wellSet.sortBy}
                onChange={(_, value) =>
                  setWellSetSortMode(wellSet.id, value as WellSortMode)
                }
                exclusive
                disabled={isReadonly}
              >
                <ToggleButton value={WellSortMode.BY_ROW}>
                  <ArrowForwardIcon />
                </ToggleButton>
                <ToggleButton value={WellSortMode.BY_COLUMN}>
                  <ArrowDownwardIcon />
                </ToggleButton>
              </Toggle>
            </CollapsibleParameter>
          )}
          {wellSet && (
            <>
              <CollapsibleParameter
                in={
                  isPartOfGroup &&
                  plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE
                }
              >
                <ParameterHeader
                  displayName="Order by"
                  isRequired
                  help={Help.liquidSortOrderHelp}
                />
                <Dropdown
                  options={LiquidSortModeOptions}
                  valueLabel={
                    LiquidSortModeOptions.find(
                      option => option.value === liquid.sortOrder,
                    )?.label ?? ''
                  }
                  onChange={value => value && setLiquidSortOrder(wellSet.id, value)}
                  isDisabled={isReadonly}
                  isRequired
                />
              </CollapsibleParameter>
              <CollapsibleParameter
                in={
                  isPartOfGroup &&
                  plateAssignment.assignmentMode === PlateAssignmentMode.DESCRIPTIVE &&
                  !!liquid.sortOrder &&
                  !!subComponentOptions
                }
              >
                <ParameterHeader
                  displayName="Sub-Component to Order By"
                  help={Help.liquidSubComponentNameHelp}
                />
                <Dropdown
                  options={subComponentOptions ?? []}
                  valueLabel={liquid.subComponentName ?? ''}
                  onChange={value => setLiquidSortSubComponent(liquid.wellSetID, value)}
                  isDisabled={isReadonly}
                  placeholder="Sub-Component..."
                />
              </CollapsibleParameter>
            </>
          )}
          <CollapsibleParameter in={canAddWells}>
            <Button
              fullWidth
              variant="secondary"
              size="small"
              startIcon={<AddSelectedWellsIcon />}
              onClick={() => addSelectedWellsToSelectedLiquid()}
            >
              Add Selected Wells
            </Button>
          </CollapsibleParameter>
          <CollapsibleParameter in={canRemoveWells}>
            <Button
              fullWidth
              variant="secondary"
              size="small"
              startIcon={<RemoveSelectedWellsIcon />}
              onClick={() => removeSelectedWellsFromSelectedLiquid()}
            >
              Remove Selected Wells
            </Button>
          </CollapsibleParameter>
        </div>
      </InnerWrapper>
    </Wrapper>
  );
}

function useSubComponentOptions(
  liquid: LiquidAssignment,
  inputLiquids: Liquid[],
): Option<string>[] | undefined {
  return useMemo(() => {
    if (inputLiquids && liquid.liquidGroup) {
      const subComponents: Record<string, number> = {};
      const liquidsInGroup = inputLiquids.filter(item =>
        item.groups?.includes(liquid.liquidGroup ?? ''),
      );

      liquidsInGroup
        .flatMap(liquid => Object.keys(liquid.subComponents))
        .forEach(subComponent => {
          subComponents[subComponent] = (subComponents[subComponent] ?? 0) + 1;
        });

      const options = Object.entries(subComponents)
        .filter(([, count]) => count === liquidsInGroup.length)
        .map(([label]) => ({ label, value: label }));

      return options.length ? options : undefined;
    }

    return undefined;
  }, [inputLiquids, liquid.liquidGroup]);
}

const InnerWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(5),
  overflowY: 'auto',
}));

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  overflowX: 'hidden',
}));

const StyledHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const StyledLayersIcon = styled(LayersIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '16px',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const LiquidColor = styled('div')({
  minWidth: '16px',
  minHeight: '16px',
  borderRadius: '50%',
});

const ErrorText = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const StyledOptionListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
    color: theme.palette.text.primary,
  },
}));

const StyledOptionListItemCaption = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginLeft: 'auto',
}));
