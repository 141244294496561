import React, { useMemo } from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useElementInstance } from 'client/app/apps/workflow-builder/lib/useElementInstance';
import { useParameterContext } from 'client/app/components/Parameters/ParameterEditor';
import { defaultPlateAssignment } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import {
  getLiquidParametersForElement,
  useInputLiquids,
} from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import { ParameterValue } from 'common/types/bundle';
import { Option } from 'common/types/Option';
import { PlateAssignment, PlateNamesWithAssignment } from 'common/types/plateAssignments';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

type Props = {
  value: PlateNamesWithAssignment | null;
  onChange: (param: ParameterValue, instanceName?: string) => void;
  isDisabled?: boolean;
  index: number;
};

export default function PlateNamesWithAssignmentParameter({
  value,
  onChange,
  isDisabled,
  index,
}: Props) {
  const { loading, inputPlates: plateOptions } = useInputPlates();
  const dispatch = useWorkflowBuilderDispatch();
  const param = useParameterContext();
  const activePlateIndex = useWorkflowBuilderSelector(
    state => state.plateEditorPanelProps.plateNameIndex,
  );

  const isActive = activePlateIndex === index;

  const handleClick = () => {
    if (value?.plateNames.length && !!param) {
      dispatch({
        type: 'openPlateLayoutEditor',
        payload: {
          plateName: value.plateNames[0],
          plateIndex: index,
          outputParam: param?.name,
        },
      });
    }
  };

  return (
    <Wrapper>
      <Select
        multiple
        value={value?.plateNames ?? []}
        displayEmpty
        onChange={e => {
          const plateName = Array.isArray(e.target.value)
            ? e.target.value[0]
            : e.target.value;
          const option = plateName
            ? plateOptions?.find(o => o.value === plateName)
            : undefined;

          if (option) {
            const plateAssignment: PlateAssignment = {
              ...defaultPlateAssignment(),
              ...value?.plateAssignment,
              plateType: option.plateType,
            };

            onChange({
              ...value,
              plateNames: Array.isArray(e.target.value)
                ? e.target.value
                : [e.target.value],
              plateAssignment,
            });
          } else {
            onChange({
              plateNames: [],
              plateAssignment: undefined,
            });
          }
        }}
        size="small"
        renderValue={selected => (
          <MenuBox>
            {loading ? (
              <Typography color="textSecondary">Loading...</Typography>
            ) : selected.length === 0 ? (
              <Typography color="textSecondary">Plates to mix onto...</Typography>
            ) : (
              selected.map(value => <Chip key={value} label={value} size="small" />)
            )}
          </MenuBox>
        )}
        disabled={isDisabled || loading}
      >
        {plateOptions?.map(option => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <EditButton
        variant="secondary"
        startIcon={<ChevronLeftOutlinedIcon />}
        disabled={value?.plateNames === undefined || value.plateNames.length === 0}
        onClick={handleClick}
        active={isActive}
      >
        <Typography variant="body2">Edit Mix Layout</Typography>
      </EditButton>
    </Wrapper>
  );
}

type InputPlateOption = Option<string> & { plateType: string };

function useInputPlates(): {
  loading: boolean;
  inputPlates: InputPlateOption[] | undefined;
} {
  const elementInstance = useElementInstance();

  if (!elementInstance) {
    throw new Error('No element selected');
  }

  const liquidProperties = getLiquidParametersForElement(elementInstance);

  const {
    loading,
    inputLiquids: [inputLiquids],
  } = useInputLiquids(liquidProperties.existingLiquids ?? '');

  return useMemo(() => {
    if (loading) {
      return { loading: true, inputPlates: undefined };
    }

    const inputPlates = new Map<string, string>();

    for (const inputLiquid of inputLiquids) {
      if (inputLiquid.position) {
        inputPlates.set(inputLiquid.position.plateName, inputLiquid.position.plateType);
      }
    }

    return {
      loading: false,
      inputPlates: [...inputPlates.entries()].map(([plateName, plateType]) => ({
        label: plateName,
        value: plateName,
        plateType,
      })),
    };
  }, [inputLiquids, loading]);
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
}));

const EditButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})<{
  active: boolean;
}>(({ active, theme }) =>
  active
    ? {
        background: Colors.BLUE_5,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      }
    : {},
);
