import produce from 'immer';
import { v4 as uuid } from 'uuid';

import { EditorType } from 'common/elementConfiguration/EditorType';
import { Markdown } from 'common/lib/markdown';
import { OpaqueAlias } from 'common/types/OpaqueAlias';
import { Position2d } from 'common/types/Position';
import { ColumnConfiguration } from 'common/types/spreadsheet';
import { CellValue, DataTable, Row, TableColumn } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';
import { MetadataPrefixOption } from 'common/ui/components/Dialog/AddColumnDialog';

export type SelectionRange = [Position2d, Position2d] | null;

export const SOLUTE_CONC_PREFIX = 'solute_concentration';
export const SOLUTE_UNIT_PREFIX = 'solute_concentration_unit';
export const TAG_PREFIX = 'tag';
export const CONCENTRATION_UNIT_FIELD_NAME = 'Concentration Unit';

export const FIRST_CELL = { x: 0, y: 0 };

export const RESIZE_COLUMN_HANDLE_WIDTH = 6;
export const ROW_HANDLE_COLUMN_WIDTH = `73px`;
export const DRAG_FILL_HANDLE_SIZE = 6;
export const NO_TITLE_DIALOG_PADDING = '20px';
export const SCROLLBAR_WIDTH = '15px';

export const DIALOG_TITLE_HEIGHT = '58px';
export const DIALOG_PADDING_HORIZONTAL = '24px';
export const DIALOG_PADDING_VERTICAL = '8px';

export const CELL_BORDER = Colors.GREY_20;
export const HEADER_CELL_BACKGROUND = Colors.GREY_10;
export const HEADER_CELL_RADIUS = '8px';

/**
 * Returns a copy of the data from the given DataTable with the cell values in the
 * selection range set to null.
 * */
export function clearSelectedCells(
  dataTable: DataTable,
  selectionRange: NonNullable<SelectionRange>,
): Row[] {
  const [{ x: startColumn, y: startRow }, { x: endColumn, y: endRow }] = selectionRange;

  const columnsToClear = dataTable.schema.fields
    .slice(startColumn, endColumn + 1)
    .map(({ name }) => name);

  const newTable = produce(dataTable.data, draft => {
    for (
      let rowIndex = Math.max(0, startRow);
      rowIndex < Math.min(endRow + 1, dataTable.data.length);
      rowIndex++
    ) {
      for (const columnName of columnsToClear) {
        draft[rowIndex][columnName] = null;
      }
    }
  });

  return newTable;
}

/** Returns the selection range that encompasses the given cell positions */
export function findSelectionRange(
  cell1: Position2d | null,
  cell2: Position2d | null,
): SelectionRange {
  if (!cell1 || !cell2) {
    return null;
  }
  const startColumn = Math.min(cell1.x, cell2.x);
  const startRow = Math.min(cell1.y, cell2.y);
  const endColumn = Math.max(cell1.x, cell2.x);
  const endRow = Math.max(cell1.y, cell2.y);
  return [
    { x: startColumn, y: startRow },
    { x: endColumn, y: endRow },
  ];
}

/** Returns the data from the given DataTable that is within the given selection range */
export function getSelectedData(
  dataTable: DataTable,
  selectionRange: SelectionRange,
): Row[] | null {
  if (!selectionRange) {
    return null;
  }
  const [{ x: startColumn, y: startRow }, { x: endColumn, y: endRow }] = selectionRange;
  const selectedData: Row[] = dataTable.data.slice(startRow, endRow + 1).map(row => {
    const fieldEntries = dataTable.schema.fields
      .slice(startColumn, endColumn + 1)
      .map(({ name: field }) => [field, row[field]]);
    return Object.fromEntries(fieldEntries);
  });
  return selectedData;
}

/** Returns the number of rows within the given selection range */
export function getSelectedRowCount(selectionRange: SelectionRange): number {
  if (!selectionRange) {
    return 0;
  }

  const [start, end] = selectionRange;
  return end.y - start.y + 1;
}

export function isBottomRightSelectedCell(
  rowIndex: number,
  columnIndex: number,
  selectionRange: SelectionRange,
) {
  if (!selectionRange) {
    return false;
  }
  return selectionRange[1].x === columnIndex && selectionRange[1].y === rowIndex;
}

export function isCellInSelectionRange(
  rowIndex: number,
  columnIndex: number,
  selectionRange: SelectionRange,
): boolean {
  if (!selectionRange) {
    return false;
  }

  const [start, end] = selectionRange;
  return (
    columnIndex >= start.x &&
    columnIndex <= end.x &&
    rowIndex >= start.y &&
    rowIndex <= end.y
  );
}

/**
 * Finds the last contiguous cell below the given cell position that is of the
 * same type (empty or filled).
 * */
export function getLastContiguousSimilarRowIndexInColumn(
  dataTable: DataTable,
  rowIndex: number,
  columnIndex: number,
): number {
  const {
    data: rows,
    schema: { fields },
  } = dataTable;

  if (rowIndex === rows.length - 1) {
    return rowIndex;
  }

  const columnName = fields[columnIndex].name;
  const isTargetCellEmpty = isEmptyCellValue(rows[rowIndex + 1][columnName]);

  let lastContiguousRowIndexSoFar = rowIndex;

  while (lastContiguousRowIndexSoFar < rows.length - 1) {
    const currentRow = rows[lastContiguousRowIndexSoFar + 1];
    const isCurrentCellEmpty = isEmptyCellValue(currentRow[columnName]);

    if (
      isCurrentCellEmpty !== isTargetCellEmpty ||
      Object.values(currentRow).every(value => isEmptyCellValue(value))
    ) {
      // Hit a cell that is not the same type as our contiguous block
      // or a cell in a completely empty row, so bail out.
      return lastContiguousRowIndexSoFar;
    }
    lastContiguousRowIndexSoFar++;
  }

  return lastContiguousRowIndexSoFar;
}

export function isEmptyCellValue(cell: CellValue) {
  if (cell === null || cell === undefined || cell === '') {
    return true;
  }

  return false;
}

export function generateRowIds(count: number) {
  return Array.from({ length: count }, () => uuid());
}

export function getHeader(field: TableColumn) {
  function stripPrefix(prefix: string) {
    return field.name.split(`${prefix}:`)[1];
  }

  if (field.name.startsWith(`${TAG_PREFIX}:`)) {
    return stripPrefix(TAG_PREFIX);
  }
  if (field.name.startsWith(`${SOLUTE_CONC_PREFIX}:`)) {
    return `${stripPrefix(SOLUTE_CONC_PREFIX)} Concentration`;
  }
  if (field.name.startsWith(`${SOLUTE_UNIT_PREFIX}:`)) {
    return `${stripPrefix(SOLUTE_UNIT_PREFIX)} Concentration Unit`;
  }

  return field.name;
}

export function getCellSelectionStyle(
  columnIndex: number,
  selectionBorder: SelectionBorder,
  hasTrailingGap: boolean,
  isAfterGap: boolean,
  isCopying: boolean,
  isDragFill: boolean,
): React.CSSProperties | undefined {
  if (!selectionBorder) {
    return undefined;
  }
  const borderStyle = `1px ${isCopying || isDragFill ? 'dashed' : 'solid'} ${
    isDragFill ? Colors.GREY_80 : Colors.BLUE_80
  }`;
  const properties: React.CSSProperties = columnIndex === 0 ? { zIndex: 2 } : {};

  if (selectionBorder.includes('L') || isAfterGap) {
    properties.borderLeft = borderStyle;
  }
  if (selectionBorder.includes('T')) {
    properties.borderTop = borderStyle;
  }
  if (selectionBorder.includes('R') || hasTrailingGap) {
    properties.borderRight = borderStyle;
  }
  if (selectionBorder.includes('B')) {
    properties.borderBottom = borderStyle;
  }

  return properties;
}

export function makeColumnResizable(col: HTMLTableCellElement, resizer: Element) {
  let mouseX = 0;
  let columnWidth = 0;

  const onPointerDown = (e: any) => {
    mouseX = e.clientX;
    const styles = window.getComputedStyle(col);
    columnWidth = parseInt(styles.width, 10);

    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);
  };

  const onPointerMove = (e: any) => {
    const amountMouseMoved = e.clientX - mouseX;
    col.style.width = `${columnWidth + amountMouseMoved}px`;
  };

  const onPointerUp = () => {
    document.removeEventListener('pointermove', onPointerMove);
    document.removeEventListener('pointerup', onPointerUp);
  };

  resizer.addEventListener('pointerdown', onPointerDown);
}

/**
 * Returns a SelectionBorder string which indicates the sides of a cell that
 * are to have styling applied to form part of the current selection. A string
 * is used here rather than some object to help prevent re-renders.
 *
 * Returns null if none of the sides should have a border, or a string
 * containing one or more of the following letters "L" (left), "R" (right),
 * "T" (top), "B" (bottom).
 * */
export function getSelectionBorder(
  selectionRange: SelectionRange,
  position: Position2d,
): SelectionBorder {
  if (!selectionRange) {
    return null;
  }

  const [selectionStart, selectionEnd] = selectionRange;

  const isInXRange = position.x >= selectionStart.x && position.x <= selectionEnd.x;
  const isInYRange = position.y >= selectionStart.y && position.y <= selectionEnd.y;

  let border = '';
  if (position.x === selectionStart.x && isInYRange) {
    border += 'L';
  }
  if (position.x === selectionEnd.x && isInYRange) {
    border += 'R';
  }
  if (position.y === selectionStart.y && isInXRange) {
    border += 'T';
  }
  if (position.y === selectionEnd.y && isInXRange) {
    border += 'B';
  }

  return border !== '' ? (border as SelectionBorder) : null;
}

/**
 * Columns that have a column metadata type as a prefix were added by users (either via file
 * upload or with the "+" button). Show the menu to allow actions on these.
 */
export function isCustomColumn(column: TableColumn) {
  return (
    column.name.startsWith(`${SOLUTE_CONC_PREFIX}:`) ||
    column.name.startsWith(`${SOLUTE_UNIT_PREFIX}:`) ||
    column.name.startsWith(`${TAG_PREFIX}:`)
  );
}

/**
 * In case of adding a solute custom column with "+" button 2 columns are added where one
 * of them is a unit column which has to display a dropdown with unit options.
 */
export function isCustomUnitColumn(column: TableColumn) {
  return column.name.startsWith(`${SOLUTE_UNIT_PREFIX}:`);
}

/**
 * Returns a configuration for a custom column added by user with a "+" button
 * or uploaded with a file.
 */
export function getCustomColumnConfigByName(nameWithPrefix: string): ColumnConfiguration {
  const [prefix, name] = nameWithPrefix.split(':');
  return getCustomColumnConfig({ name, prefix: prefix as MetadataPrefixOption });
}

/**
 * Returns a configuration for a custom column added by user with a "+" button
 * or uploaded with a file.
 */
export function getCustomColumnConfig({
  name,
  prefix,
}: {
  name: string;
  prefix: MetadataPrefixOption;
}): ColumnConfiguration {
  const columnName = `${prefix}:${name}`;
  const columnType = prefix === SOLUTE_CONC_PREFIX ? 'number' : 'string';

  return {
    name: columnName,
    anthaType: columnType,
    dataType: columnType,
    displayName: null,
    description: '' as Markdown,
    editor: {
      type: EditorType.STRING,
      additionalProps: null,
    },
    hasTrailingGap: false,
    dragToFillBehaviour: 'copy',
  };
}

export type SelectionBorder = OpaqueAlias<string, 'Table.SelectionBorder'> | null;
