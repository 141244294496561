import React, { useMemo, useState } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useElementErrors } from 'client/app/apps/workflow-builder/validation/ValidationIndicator';
import { ActivePlateSelector } from 'client/app/components/Parameters/PlateLayout/LayoutPlate';
import MixturesPlate from 'client/app/components/Parameters/PlateLayout/MixturesPlate';
import MixturesTable from 'client/app/components/Parameters/PlateLayout/MixturesTable';
import { usePlateLayoutEditorContext } from 'client/app/components/Parameters/PlateLayout/PlateLayoutEditorContext';
import { useOutputLiquidsByPlate } from 'client/app/components/Parameters/PlateLayout/plateLayoutUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import Feedback from 'common/ui/components/Feedback';
import LinearProgress from 'common/ui/components/LinearProgress';
import LiquidColors from 'common/ui/components/simulation-details/LiquidColors';
import { PlateState } from 'common/ui/components/simulation-details/mix/MixState';
import Toggle, { ToggleButton } from 'common/ui/components/Toggle/Toggle';

type Props = { className?: string };

export default function MixturesPreview({ className }: Props) {
  const [activeView, setActiveView] = useState<'plate' | 'table'>('plate');

  const { combinatorialPlateName, liquidParameters } = usePlateLayoutEditorContext();
  const liquidColors = useMemo(() => LiquidColors.createAvoidingAllColorCollisions(), []);
  const [loading, plates, uniqueMixtures] = useOutputLiquidsByPlate(
    liquidColors,
    liquidParameters,
  );

  const isEnabledParameterValidation = useFeatureToggle('PARAMETER_VALIDATION');

  const { elementErrors } = useElementErrors(loading);

  const visiblePlate: PlateState = combinatorialPlateName
    ? plates.get(combinatorialPlateName)
    : plates.values().next().value;

  const showErrors = useMemo(() => {
    return !loading && !visiblePlate && elementErrors.length > 0;
  }, [elementErrors.length, loading, visiblePlate]);

  return (
    <Wrapper className={className}>
      <Header>
        <Feedback
          variant="info"
          content={
            <Typography>
              This is a preview of the mixtures generated by the configured layout. They
              cannot be edited directly here.
            </Typography>
          }
        />
        <ViewToggle
          value={activeView}
          onChange={(_, value) => setActiveView(value)}
          exclusive
          disabled={loading || showErrors}
        >
          <ToggleButton value="plate">Plate</ToggleButton>
          <ToggleButton value="table">Table</ToggleButton>
        </ViewToggle>
      </Header>
      {loading && <StyledLinearProgress />}
      {!loading && visiblePlate && (
        <>
          {activeView === 'plate' ? (
            <MixturesPlate
              plate={visiblePlate}
              uniqueMixtures={uniqueMixtures}
              liquidColors={liquidColors}
            />
          ) : (
            <MixturesTable />
          )}
        </>
      )}
      {showErrors && (
        <div>
          {isEnabledParameterValidation
            ? elementErrors
            : 'Errors encountered in workflow, please fix to allow preview'}
        </div>
      )}
      <ActivePlateSelector disabled={loading || showErrors} />
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(6),
  display: 'grid',
  gridTemplate: `
    'header header' auto
    'plate mixtures' minmax(0, 1fr)
    'plateSelect mixtures' auto / minmax(250px, 1fr) 250px`,
  placeItems: 'stretch',
  gap: theme.spacing(6),
}));

const Header = styled('div')({
  gridArea: 'header',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const ViewToggle = styled(Toggle)({
  width: '250px',
});

const StyledLinearProgress = styled(LinearProgress)({
  gridColumnStart: 'plate',
  gridColumnEnd: 'mixtures',
});
