import React from 'react';

import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import { useParameterContext } from 'client/app/components/Parameters/ParameterEditor';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

type Props = {
  value: string | null | undefined;
};

export default function PlateLayoutParameter({ value: plateName }: Props) {
  const active =
    useWorkflowBuilderSelector(state => state.additionalPanel) === 'PlateLayoutEditor';
  const param = useParameterContext();

  const classes = useStyles({ active });
  const dispatch = useWorkflowBuilderDispatch();

  const handleClick = () => {
    if (plateName && param) {
      dispatch({
        type: 'openPlateLayoutEditor',
        payload: { plateName, outputParam: param.name },
      });
    }
  };

  return (
    <Button
      className={cn(classes.launchButton, { [classes.active]: active })}
      variant="secondary"
      onClick={handleClick}
      startIcon={<ChevronLeftOutlinedIcon />}
      disabled={plateName === null || plateName === undefined}
    >
      <Typography className={classes.buttonText} variant="body2">
        Edit Mix Layout
      </Typography>
    </Button>
  );
}

const useStyles = makeStylesHook(({ palette }) => ({
  launchButton: {
    width: '100%',
    justifyContent: 'center',
  },
  buttonText: {
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  errorText: {
    color: palette.error.main,
  },
  progress: {
    color: 'inherit',
    margin: 'auto',
  },
  active: {
    background: Colors.BLUE_5,
    borderColor: palette.primary.main,
    color: palette.primary.main,
  },
}));
